import { useLocale } from '@app/core/hooks/useLocale';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import {
  calculatedMultipleValueFormatterProps,
  numberValueFormatter,
  toPercentageUsingFormatter,
} from '@app/shared/formatters';
import {
  DenominationMultiplier,
  InstrumentBlockKey,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import styles from '../../../widgets/total-equity/total-equity-chart.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockDto,
  Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockOwnershipDto,
} from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { enumKeyByValue, instrumentOwnersSortFn } from '@app/shared/helpers';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import SvgChevron from '@app/shared/icons/Chevron';

interface InstrumentsDataDto
  extends Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockDto {
  rank?: number;
}

interface TotalEquityInstrumentTableSectionProps {
  instrumentsType: InstrumentType | null;
  instrumentsData: InstrumentsDataDto[];
  isEntryStack: boolean;
  setExpandedInstruments: (expandedInstruments: Dictionary<boolean>) => void;
  expandedInstruments: Dictionary<boolean>;
  selectedBlock: string | null;
}

export const TotalEquityInstrumentTableSection: FC<TotalEquityInstrumentTableSectionProps> = ({
  instrumentsType,
  instrumentsData,
  isEntryStack = false,
  setExpandedInstruments,
  expandedInstruments,
  selectedBlock,
}) => {
  const { l } = useLocale();

  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];

  const getTitle = () => {
    switch (instrumentsType) {
      case InstrumentType.ShareholderLoanNotes:
        return l('_ShareholderLoanNotesTitle');
      case InstrumentType.PreferredShares:
        return l('_PreferredShares');
      case InstrumentType.OrdinaryEquity:
        return l('_OrdinaryEquityTitle');
      default:
        return l('_Other');
    }
  };

  const getIconClassSuffix = (
    instrument: Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockDto
  ) => {
    switch (instrumentsType) {
      case InstrumentType.ShareholderLoanNotes:
        return 'sln';
      case InstrumentType.PreferredShares:
        return 'pref';
      case InstrumentType.OrdinaryEquity:
        return instrument.isSweet ? 'sweet' : 'ord';
      default:
        return 'net-debt';
    }
  };

  const renderProceedsByOwner = (
    proceedsByOwner: Dictionary<Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockOwnershipDto>,
    selectedBlock: string | null,
    instrumentId: string
  ) => {
    return Object.entries(proceedsByOwner)
      .sort((a, b) =>
        instrumentOwnersSortFn(a[0] as keyof typeof OwnerType, b[0] as keyof typeof OwnerType)
      )
      .map(([owner, data]) => (
        <tr
          key={owner}
          className={classNames(styles['modal-instrument'], styles['italic-text'], {
            [styles['modal-instrument--faded']]: selectedBlock && selectedBlock !== instrumentId,
          })}>
          <td className={classNames(styles['no-padding-top'], styles['modal-proceeds-title'])}>
            {owner === enumKeyByValue(OwnerType, OwnerType.Institution) ? l('_Sponsor') : owner}
          </td>
          <td className={styles['no-padding-top']}>
            <CellValue value={data.proceeds * multiplier} {...amountRules} isAbbreviated />
          </td>
          <td className={styles['no-padding-top']}>
            <CellValue
              value={toPercentageUsingFormatter(data.proceedsPercentageOfNetExitProceeds)}
              alignment={Alignment.Right}
            />
          </td>
          {!isEntryStack && (
            <>
              <td />
              <td />
            </>
          )}
        </tr>
      ));
  };

  return (
    <>
      <tr className={styles['modal-instrument-type-title']}>
        <td
          className={classNames(styles['table-subtitle'], styles['no-padding-left'])}
          data-testid="instrument-title">
          {getTitle()}
        </td>
      </tr>
      {instrumentsData
        .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0))
        .map((instrument) => (
          <Fragment key={instrument.id}>
            <tr
              data-testid={`instrument-${instrument.id}`}
              className={classNames(styles['modal-instrument'], {
                [styles['modal-instrument--faded']]:
                  selectedBlock && selectedBlock !== instrument.id,
              })}>
              <td
                className={classNames(
                  styles['modal-instrument-title'],
                  styles['no-padding-left'],
                  'table-primary'
                )}>
                <SvgChevron
                  onClick={(event) => {
                    event.stopPropagation();
                    setExpandedInstruments({
                      ...expandedInstruments,
                      [instrument.id]: !expandedInstruments[instrument.id],
                    });
                  }}
                  className={classNames(styles['chevron'], {
                    [styles['chevron__collapsed']]: !expandedInstruments[instrument.id],
                    [styles['chevron__expanded']]: expandedInstruments[instrument.id],
                    [styles['chevron__hidden']]: instrument.id === InstrumentBlockKey.NetDebtId,
                  })}
                />
                <Button
                  appearance={ButtonAppearance.CLEAN}
                  cursorDefault
                  ignoreReadOnly
                  className={styles['icon--button']}>
                  <span
                    className={classNames([
                      styles['icon'],
                      styles[`icon--${getIconClassSuffix(instrument)}`],
                    ])}
                  />
                  {instrument.id === InstrumentBlockKey.NetDebtId
                    ? l('_NetDebtOrNetCashNegative')
                    : instrument.narrative ?? ''}
                </Button>
              </td>
              <td>
                <CellValue
                  value={instrument.totalProceeds * multiplier}
                  {...amountRules}
                  isAbbreviated
                />
              </td>
              <td>
                <CellValue
                  value={toPercentageUsingFormatter(
                    instrument.totalProceedsPercentageOfNetExitProceeds
                  )}
                  alignment={Alignment.Right}
                />
              </td>
              {!isEntryStack && (
                <>
                  {instrument.id !== InstrumentBlockKey.NetDebtId ? (
                    <>
                      <td>
                        <CellValue
                          value={numberValueFormatter({
                            value: instrument.instrumentMoM,
                            ...calculatedMultipleValueFormatterProps,
                          })}
                          alignment={Alignment.Right}
                        />
                      </td>
                      <td>
                        <CellValue
                          value={toPercentageUsingFormatter(instrument.instrumentIRR)}
                          alignment={Alignment.Right}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td />
                      <td />
                    </>
                  )}
                </>
              )}
            </tr>
            {expandedInstruments[instrument.id] &&
              renderProceedsByOwner(instrument.proceedsByOwner, selectedBlock, instrument.id)}
          </Fragment>
        ))}
    </>
  );
};
