import { FC, Fragment, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  monthDayCellViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import classNames from 'classnames';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { Navigate, generatePath } from 'react-router';
import { useLocale } from '@app/core/hooks/useLocale';
import { setCostOfEquitySelectedTab } from '@app/core/store/ui-values-slice';
import { Pwerm2CalculationCostOfEquityTotalAttributableEquityYearDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { getTotalAttributableEquityYears } from '@app/core/store/pwerm2-calculation-slice-selectors';
import { translationKeys } from '@locale/setupI18n';

interface GetRefProp {
  getRef?: (ref: HTMLTableElement) => void;
}

const TotalEquityCrossCheck2: FC<GetRefProp> = ({ getRef }): JSX.Element => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setCostOfEquitySelectedTab(RouteConstants.TotalEquityCrossCheckLegacy));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const totalAttributableEquityResults = results.costOfEquity.totalAttributableEquity;
  const totalAttributableEquityResultsYears = totalAttributableEquityResults.years;
  const forecastLength = totalAttributableEquityResultsYears.length;
  const colCount = 2 + forecastLength;

  const renderRow = ({
    title,
    renderCellValue,
    renderAdditionalCellValue,
    extraClassNames,
    additionalTitleClassNames,
  }: {
    title: translationKeys;
    renderCellValue: (
      forecast: Pwerm2CalculationCostOfEquityTotalAttributableEquityYearDto
    ) => JSX.Element;
    renderAdditionalCellValue?: (
      forecast: Pwerm2CalculationCostOfEquityTotalAttributableEquityYearDto
    ) => JSX.Element;
    extraClassNames?: string;
    additionalTitleClassNames?: string;
  }) => (
    <tr>
      <th
        className={classNames(
          'table-primary__cell--vertical-separator',
          extraClassNames,
          additionalTitleClassNames
        )}>
        {l(title)}
      </th>
      {totalAttributableEquityResultsYears.map((forecast, index) => (
        <Fragment key={forecast.exitDate + index}>
          {index === 0 ? (
            renderAdditionalCellValue ? (
              <td
                className={classNames('table-primary__cell--vertical-separator', extraClassNames)}>
                {renderAdditionalCellValue(forecast)}
              </td>
            ) : (
              <td
                className={classNames('table-primary__cell--vertical-separator', extraClassNames)}
              />
            )
          ) : undefined}
          <td
            className={classNames(extraClassNames, {
              'table-primary__cell--vertical-separator': index === forecastLength - 1,
            })}>
            {renderCellValue(forecast)}
          </td>
        </Fragment>
      ))}
    </tr>
  );

  const exitDateRow = renderRow({
    title: '_ExitDate',
    renderCellValue: (forecast) => (
      <CellValue value={forecast.exitDate} {...monthDayCellViewRules} />
    ),
    renderAdditionalCellValue: () => (
      <CellValue
        value={totalAttributableEquityResults.initialDate}
        {...monthDayCellViewRules}
        theme={CellValueTheme.Tertiary}
      />
    ),
  });

  return (
    <div className="main-container-padding-remove">
      <table
        ref={tableRef}
        className="table-primary table-primary--framed table-primary--zebra table-primary--title-column-nowrap table-primary--sticky-first-column">
        <colgroup>
          <col className={styles['labels-col']} />
          <col />
          {totalAttributableEquityResultsYears.map((forecast, index) => (
            <Fragment key={'colspan' + forecast.exitDate + index}>
              <col />
            </Fragment>
          ))}
        </colgroup>
        <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
          <tr>
            <th className="table-primary__cell--vertical-separator" />
            <th className="table-primary__cell--vertical-separator" />
            <th
              colSpan={forecastLength}
              className="table-primary__cell--header-tertiary table-primary__cell--center table-primary__cell--vertical-separator">
              <span className={styles['case-name']}>{l('_AcrossCases')}</span>
            </th>
          </tr>
          {exitDateRow}
          <tr>
            <th className="table-primary__cell--vertical-separator">{l('_TimeToExit')}</th>
            {totalAttributableEquityResultsYears.map((forecast, index) => (
              <Fragment key={'time-to-exit' + forecast.exitDate + index}>
                {index === 0 && <td className="table-primary__cell--vertical-separator" />}
                <td
                  className={classNames({
                    'table-primary__cell--vertical-separator': index === forecastLength - 1,
                  })}>
                  <CellValue
                    value={getTotalAttributableEquityYears(
                      results,
                      forecast.exitDate,
                      (year) => year.timeToExit
                    )}
                    {...numberCalculatedViewRules}
                    {...numberCalculatedViewRules}
                    normalize
                  />
                </td>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="table-primary__cell--header">
              {l('_EnterpriseValue')} [{l('_EV')}]
            </th>
            <td className="table-primary__cell--header" colSpan={colCount - 1} />
          </tr>
          {renderRow({
            title: '_CaseWeightedEv',
            renderCellValue: (forecast) => (
              <CellValue
                strong
                value={getTotalAttributableEquityYears(
                  results,
                  forecast.exitDate,
                  (year) => year.ev
                )}
                {...amountRules}
              />
            ),
            renderAdditionalCellValue: (_forecast) => (
              <CellValue
                {...amountRules}
                value={totalAttributableEquityResults.initialEv}
                theme={CellValueTheme.Tertiary}
              />
            ),
            additionalTitleClassNames: 'table-primary__cell--strong',
          })}
          <tr>
            <th className="table-primary__cell--vertical-separator table-primary__cell--section-separator" />
            <td className="table-primary__cell--vertical-separator table-primary__cell--section-separator" />
            {totalAttributableEquityResultsYears.map((forecast, index) => (
              <Fragment key={'colspan' + forecast.exitDate + index}>
                <td className="table-primary__cell--section-separator" />
              </Fragment>
            ))}
          </tr>
          <tr>
            <th className="table-primary__cell--header">{l('_NetDebt')}</th>
            <td className="table-primary__cell--header" colSpan={colCount - 1} />
          </tr>
          {renderRow({
            title: '_CaseWeightedNetDebt',
            renderCellValue: (forecast) => (
              <CellValue
                value={getTotalAttributableEquityYears(
                  results,
                  forecast.exitDate,
                  (year) => year.netDebt
                )}
                {...amountRules}
              />
            ),
          })}
          {renderRow({
            title: '_SpotGearing',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getTotalAttributableEquityYears(
                    results,
                    forecast.exitDate,
                    (year) => year.spotGearing
                  )
                )}
              />
            ),
          })}
          {renderRow({
            title: '_AverageGearing',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getTotalAttributableEquityYears(
                    results,
                    forecast.exitDate,
                    (year) => year.averageGearing
                  )
                )}
              />
            ),
            extraClassNames:
              'table-primary__cell--strong table-primary__cell--section-start-separator',
          })}
          {renderRow({
            title: '_DebtBeta',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getTotalAttributableEquityYears(
                  results,
                  forecast.exitDate,
                  (year) => year.debtBeta
                )}
              />
            ),
          })}
          {renderRow({
            title: '_EquityBeta',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getTotalAttributableEquityYears(
                  results,
                  forecast.exitDate,
                  (year) => year.equityBeta
                )}
              />
            ),
          })}
          {renderRow({
            title: '_CostOfEquitySpot',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getTotalAttributableEquityYears(
                    results,
                    forecast.exitDate,
                    (year) => year.spotCostOfEquity
                  )
                )}
              />
            ),
            extraClassNames:
              'table-primary__cell--strong table-primary__cell--section-start-separator',
          })}
          {renderRow({
            title: '_DiscountFactorPoint',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getTotalAttributableEquityYears(
                  results,
                  forecast.exitDate,
                  (year) => year.pointDiscountFactor
                )}
              />
            ),
          })}
          {renderRow({
            title: '_DiscountFactorCumulative',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getTotalAttributableEquityYears(
                  results,
                  forecast.exitDate,
                  (year) => year.cumulativeDiscountFactor
                )}
              />
            ),
          })}
          {renderRow({
            title: '_CostOfEquityCumulative',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getTotalAttributableEquityYears(
                    results,
                    forecast.exitDate,
                    (year) => year.cumulativeCostOfEquity
                  )
                )}
              />
            ),
            extraClassNames:
              'table-primary__cell--strong table-primary__cell--section-start-separator',
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TotalEquityCrossCheck2;
