import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { FC } from 'react';
import { RouteConstants } from '../../RouteConstants';
import { cloneDeep, instrumentByTypeOrTranchesOrDefaultSortFn } from '@app/shared/helpers';
import { Navigate } from 'react-router-dom';
import { selectCapitalStructure } from '@app/core/store/capital-structure-slice-selectors';

export const CostOfEquity2Page: FC = (): JSX.Element => {
  const { isPwermValid } = useAppSelector((state) => state.project);
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const storedCostOfEquityView = useAppSelector(
    (state) => state.uiValues.userSelections.costOfEquity.selectedTab
  );
  const { generatePath } = usePwcNavigate();
  const instrumentDefinitions = Object.entries(capitalStructure.instrumentDefinitions).map(
    ([instrumentId, instrument]) => {
      return { instrumentId, ...instrument };
    }
  );
  const instrumentTabs = cloneDeep(instrumentDefinitions)
    .sort(instrumentByTypeOrTranchesOrDefaultSortFn)
    .filter((instrument) => instrument.shouldBeValued);

  const isStoredCostOfEquityViewInInstruments = instrumentTabs.some(
    (instrument) => instrument.instrumentId === storedCostOfEquityView
  );

  if (
    !storedCostOfEquityView ||
    (!isStoredCostOfEquityViewInInstruments &&
      storedCostOfEquityView !== RouteConstants.TotalEquityCrossCheckLegacy)
  ) {
    return <PwcNavigate route={generatePath(RouteConstants.Summary)} />;
  } else if (
    storedCostOfEquityView &&
    storedCostOfEquityView === RouteConstants.TotalEquityCrossCheckLegacy
  ) {
    return <PwcNavigate route={generatePath(RouteConstants.TotalEquityCrossCheck)} />;
  }

  if (!isPwermValid) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <PwcNavigate
      route={generatePath(RouteConstants.InstrumentById, {
        instrumentId: storedCostOfEquityView,
      })}
    />
  );
};
