import { enumKeyByValue } from '@app/shared/helpers';
import { CouponType, InterestPaymentType } from '@app/shared/models/contracts/enums/shared-enums';
import { translationKeys } from '@locale/setupI18n';
import { t } from 'i18next';

const translatedCoupon: { [key: string]: translationKeys } = {
  [enumKeyByValue(CouponType, CouponType.CompoundInterest)]: '_CompoundInterest',
  [enumKeyByValue(CouponType, CouponType.SimpleInterest)]: '_SimpleInterest',
};

export const mapCouponTypeInterestToTranslation = (
  text: keyof typeof CouponType
): translationKeys | undefined => {
  return text ? (t(translatedCoupon[text]) as translationKeys) : undefined;
};

const translatedInterest: { [key: string]: translationKeys } = {
  [enumKeyByValue(InterestPaymentType, InterestPaymentType.CashPay)]: '_CashPay',
  [enumKeyByValue(InterestPaymentType, InterestPaymentType.PaymentInKind)]: '_PaymentInKind',
  [enumKeyByValue(InterestPaymentType, InterestPaymentType.PaymentInKindAndCashPay)]:
    '_PaymentInKindAndCashPay',
};

export const mapInterestToTranslation = (text: string): translationKeys => {
  return t(translatedInterest[text]);
};
