import { OpmInputDataSource } from '@app/shared/models/contracts/enums/shared-enums';
import {
  CaseDto,
  DebtItemDto,
  ForecastInputDto,
  MarketValueDlomDto,
  NetDebtItemValueDto,
  ProjectDto,
} from '@app/shared/models/contracts/project-dto';
import { getOpmInputsFromCapitalStructure } from './getOpmInputsFromCapitalStructure';
import { InstrumentDefinitionWithId } from '../store/capital-structure-slice-selectors';
import { Pwerm2CalculationResultsDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';

export interface OpmInputsFromPwerm {
  hypotheticalDebtWhenIpo: number;
  ipoProbability: number;
  ipoCostsOfEv: number;
  secondarySaleCostsOfEv: number;
  ipoDiscount: number;
  equitySoldInIpo: number;
  postIpoSaleDlom: number;
  cashValue: number;
  netDebtItems: NetDebtItemValueDto[];
  financingCosts: number;
  marketValueDloms: MarketValueDlomDto[];
  totalNetDebt: number;
  operationalFreeCashFlow: number;
  operationalEv: number;
}

const getPwermForecastInputs = (
  caseItem: CaseDto,
  forecastDate: string
): ForecastInputDto | undefined =>
  caseItem.forecasts.find((f) => f.forecastYear === forecastDate)?.inputs;

const getPwermForecastCashValue = (caseItem: CaseDto, forecastDate: string): number =>
  caseItem.forecasts.find((f) => f.forecastYear === forecastDate)?.cashValue ?? 0;

const getPwermInitialCashValue = (caseItem: CaseDto): number => caseItem.historicalCashValue ?? 0;

const getPwermForecastNetDebtItemValue = (
  caseItem: CaseDto,
  forecastDate: string,
  narrative: string
): number => {
  const netDebtItemId = caseItem.netDebtItems.find(
    (ndi) => ndi.narrative === narrative
  )?.netDebtItemId;

  return netDebtItemId
    ? caseItem.forecasts
        .find((f) => f.forecastYear === forecastDate)
        ?.netDebtItems.find((ndi) => ndi.netDebtItemId === netDebtItemId)?.value ?? 0
    : 0;
};

export const getOpmInputsFromPwerm = (
  project: ProjectDto,
  calculationResult: Pwerm2CalculationResultsDto
) => {
  const selectedCaseId = project.opmInput.selectedCaseId;
  const selectedCase = selectedCaseId
    ? project.pwermInput.cases.find((caseItem) => caseItem.caseId === selectedCaseId)
    : undefined;
  const netDebtItemsFromCases = selectedCase
    ? selectedCase.netDebtItems
    : project.pwermInput.cases.flatMap((caseItem) => caseItem.netDebtItems);
  const netDebtItemsListFromPwerm = netDebtItemsFromCases.reduce((map, netDebtItem) => {
    const { narrative } = netDebtItem;
    map.get(narrative)?.push(netDebtItem) ?? map.set(narrative, [netDebtItem]);
    return map;
  }, new Map<string, DebtItemDto[]>());

  const instrumentDefinitions = Object.values(project.capitalStructures)[0].instrumentDefinitions;
  const valuedInstruments: InstrumentDefinitionWithId[] = Object.entries(instrumentDefinitions)
    .filter(([_, instrument]) => instrument.shouldBeValued)
    .map(([instrumentId, instrument]) => ({ instrumentId, ...instrument }));

  const getCasesWithSpecificForecast = (forecastDate: string): CaseDto[] =>
    project.pwermInput.cases.filter((caseItem) =>
      caseItem.forecasts.find((forecast) => forecast.forecastYear === forecastDate)
    );

  const getCaseWeightedAverageFromPwerm = (getValue: (caseItem: CaseDto) => number | undefined) => {
    // find case via selectedCaseId and return its value
    if (project.opmInput.selectedCaseId) {
      const projectCases = project.pwermInput.cases;
      const selectedCase = projectCases.find((c) => c.caseId === project.opmInput.selectedCaseId);
      return selectedCase ? getValue(selectedCase) ?? 0 : 0;
    }

    return project.pwermInput.cases.reduce((weightedValue, caseItem) => {
      const value = getValue(caseItem) ?? 0;

      return weightedValue + ((caseItem.probability ?? NaN) * value) / 100;
    }, 0);
  };

  const getCaseWeightedAverageFromPwermAtForecast = (
    forecastDate: string,
    getValue: (caseItem: CaseDto) => number | undefined
  ) => {
    const projectCases = project.pwermInput.cases;

    if (project.opmInput.selectedCaseId) {
      const selectedCase = projectCases.find((c) => c.caseId === project.opmInput.selectedCaseId);
      return selectedCase ? getValue(selectedCase) ?? 0 : 0;
    }

    const relevantCases = getCasesWithSpecificForecast(forecastDate);
    const totalProbabilityAcrossRelevantCases = relevantCases.reduce(
      (totalProbability, caseItem) => totalProbability + (caseItem.probability ?? 0),
      0
    );

    if (totalProbabilityAcrossRelevantCases === 0) {
      return 0;
    }

    const weightedAverage = relevantCases.reduce((weightedValue, caseItem) => {
      const value: number = getValue(caseItem) ?? 0;
      return weightedValue + (caseItem.probability ?? NaN) * value;
    }, 0);

    const normalisedWeightedAverage = weightedAverage / totalProbabilityAcrossRelevantCases;
    return normalisedWeightedAverage;
  };

  const getMaxForecastYearsFromPwerm = () => {
    if (project.opmInput.selectedCaseId) {
      const projectCases = project.pwermInput.cases;
      const selectedCase = projectCases.find((c) => c.caseId === project.opmInput.selectedCaseId);
      return selectedCase?.forecasts.length ?? 0;
    }

    return Math.max(...project.pwermInput.cases.map((c) => c.forecasts.length));
  };

  const getIpoProbabilityFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) => getPwermForecastInputs(caseItem, forecastDate)?.probabilityOfIpo
    );

  const getHypotheticalDebtWhenIpoFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) => getPwermForecastInputs(caseItem, forecastDate)?.hypotheticalDebtAtIpo
    );

  const getIpoCostsOfEvFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) => getPwermForecastInputs(caseItem, forecastDate)?.ipoTransactionCosts
    );

  const getSecondarySaleCostsOfEvFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) =>
        getPwermForecastInputs(caseItem, forecastDate)?.tradeSaleTransactionCost
    );

  const getIpoDiscountFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) => getPwermForecastInputs(caseItem, forecastDate)?.ipoDiscount
    ) ?? 0;

  const getEquitySoldInIpoFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) =>
        getPwermForecastInputs(caseItem, forecastDate)?.percentOfEquitySoldInIpo
    );

  const getPostIpoSaleDlomFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) =>
        getPwermForecastInputs(caseItem, forecastDate)?.dlomForPostIpoSaleRestrictions
    );

  const getCashValueFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(forecastDate, (caseItem: CaseDto) =>
      getPwermForecastCashValue(caseItem, forecastDate)
    );

  const getOperationalFreeCashFlowFromPwerm = (forecastDate: string): number =>
    getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) => getPwermForecastInputs(caseItem, forecastDate)?.operationalFreeCashFlow
    );

  const getOperationalFreeCashFlowAverageFromPwerm = (forecastDate: string) => {
    const timesToExit = calculationResult.timesToExit;

    if (timesToExit === undefined) {
      return 0;
    }

    const individualTimeToExit = timesToExit[forecastDate];

    if (typeof individualTimeToExit !== 'number') {
      return 0;
    }

    const totalOperationalFreeCashFlow = Object.entries(timesToExit).reduce((total, [date, _]) => {
      if (date && date.localeCompare(forecastDate) <= 0) {
        return total + (getOperationalFreeCashFlowFromPwerm(date) || 0);
      }
      return total;
    }, 0);

    return totalOperationalFreeCashFlow / individualTimeToExit;
  };

  const getNetDebtItemValuesFromPwerm = (forecastDate: string): NetDebtItemValueDto[] => {
    const netDebtNarratives = Array.from(netDebtItemsListFromPwerm.keys());
    return netDebtNarratives.map((narrative) => {
      const netDebtItems = netDebtItemsListFromPwerm.get(narrative) ?? [];
      const weightedValue = getCaseWeightedAverageFromPwermAtForecast(
        forecastDate,
        (caseItem: CaseDto) => getPwermForecastNetDebtItemValue(caseItem, forecastDate, narrative)
      );
      return {
        netDebtItemId: netDebtItems[0].netDebtItemId,
        value: weightedValue,
      };
    });
  };

  const getNetDebtItemsFromPwerm = (): DebtItemDto[] => {
    const netDebtItemsList: DebtItemDto[] = [];
    netDebtItemsListFromPwerm.forEach((netDebtItems, narrative) => {
      const weightedValue = getCaseWeightedAverageFromPwerm(
        (caseItem: CaseDto) =>
          caseItem.netDebtItems.find((ndi) => ndi.narrative === narrative)?.historicalValue ?? 0
      );
      netDebtItemsList.push({
        ...netDebtItems[0],
        historicalValue: weightedValue,
      });
    });
    return netDebtItemsList;
  };

  const getTotalNetDebtFromPwerm = (forecastDate: string): number => {
    const cashValue = getCashValueFromPwerm(forecastDate);
    const netDebtItems = getNetDebtItemValuesFromPwerm(forecastDate);
    const sumNetDebtItems = netDebtItems.reduce((sum, netDebt) => (sum += netDebt.value ?? 0), 0);
    const totalNetDebt = cashValue + sumNetDebtItems;
    return totalNetDebt;
  };

  const getInitialTotalNetDebtFromPwerm = () => {
    const initialCash = getInitialCashValueFromPwerm();
    const initialNetDebt = getNetDebtItemsFromPwerm();
    const initialSumNetDebtItems = initialNetDebt.reduce(
      (sum, netDebt) => (sum += netDebt.historicalValue ?? 0),
      0
    );
    const initialTotalNetDebt = initialCash + initialSumNetDebtItems;
    return initialTotalNetDebt;
  };

  const getEvFromPwerm = (forecastDate: string): number => {
    const evAtForecast = getCaseWeightedAverageFromPwermAtForecast(
      forecastDate,
      (caseItem: CaseDto) => {
        const forecast = calculationResult.waterfall
          ? Object.values(calculationResult.waterfall.cases[caseItem.caseId].years).find(
              (f) => f.exitDate === forecastDate
            )
          : null;
        return forecast?.weightedExitEv ?? 0;
      }
    );
    return evAtForecast;
  };

  const getEvAverageFromPwerm = (forecastDate: string): number => {
    const { getEvOrEquityValueFromCapitalStructure } = getOpmInputsFromCapitalStructure(project);
    const startingEv =
      project.opmInput.evValueSource !== OpmInputDataSource.Override
        ? getEvOrEquityValueFromCapitalStructure()
        : project.opmInput.evValue ?? 0;

    const evAtForecastDate = getEvFromPwerm(forecastDate);
    const evAverage = (Number(startingEv) + evAtForecastDate) / 2;
    return evAverage;
  };

  const getFinancingCostsFromPwerm = (forecastDate: string): number => {
    const operationalAverageFreeCashFlow = getOperationalFreeCashFlowAverageFromPwerm(forecastDate);
    const evAverage = getEvAverageFromPwerm(forecastDate);
    return evAverage === 0
      ? 0
      : Number(((operationalAverageFreeCashFlow / evAverage) * 100).toFixed(8)) ?? 0;
  };

  const getPwermForecastInstrumentDlom = (
    caseItem: CaseDto,
    forecastDate: string,
    instrumentId: string,
    trancheId: Nullable<string>
  ) =>
    caseItem.forecasts
      .find((f) => f.forecastYear === forecastDate)
      ?.dlom.find((d) => d.instrumentId === instrumentId && d.trancheId === trancheId)?.value ?? 0;

  const getInitialCashValueFromPwerm = (): number =>
    getCaseWeightedAverageFromPwerm((caseItem: CaseDto) => getPwermInitialCashValue(caseItem));

  const getControlPremiumForDlocFromPwerm = (): number =>
    project.valuationInputs.minorityDiscounts.dloc ?? 0;

  const getMarketVolatilityFromPwerm = (): Nullable<number> =>
    project.valuationInputs.minorityDiscounts.marketVolatility;

  const getPeerSetVolatilityFromPwerm = (): Nullable<number> =>
    project.valuationInputs.minorityDiscounts.peerSetVolatility;

  const getMarketValueDlomsFromPwerm = (forecastDate: string): MarketValueDlomDto[] =>
    valuedInstruments.map((instrument) => {
      return {
        instrumentId: instrument.instrumentId,
        instrumentDlom:
          instrument.payoutLogic && instrument.payoutLogic?.length > 0
            ? null
            : getCaseWeightedAverageFromPwermAtForecast(forecastDate, (caseItem: CaseDto) =>
                getPwermForecastInstrumentDlom(
                  caseItem,
                  forecastDate,
                  instrument.instrumentId,
                  null
                )
              ),
        instrumentDlomSource: OpmInputDataSource.FromPWERM,
        trancheDloms:
          instrument.payoutLogic?.map((tranche) => {
            return {
              trancheId: tranche.id,
              dlom: getCaseWeightedAverageFromPwermAtForecast(forecastDate, (caseItem: CaseDto) =>
                getPwermForecastInstrumentDlom(
                  caseItem,
                  forecastDate,
                  instrument.instrumentId,
                  tranche.id
                )
              ),
              dlomSource: OpmInputDataSource.FromPWERM,
            };
          }) ?? [],
      };
    });

  const getAllOpmValuesFromPwerm = (forecastDates: Nullable<string>[]): OpmInputsFromPwerm[] =>
    forecastDates.map((forecastDate) => {
      return {
        hypotheticalDebtWhenIpo: forecastDate
          ? getHypotheticalDebtWhenIpoFromPwerm(forecastDate)
          : 0,
        ipoProbability: forecastDate ? getIpoProbabilityFromPwerm(forecastDate) : 0,
        ipoCostsOfEv: forecastDate ? getIpoCostsOfEvFromPwerm(forecastDate) : 0,
        secondarySaleCostsOfEv: forecastDate ? getSecondarySaleCostsOfEvFromPwerm(forecastDate) : 0,
        ipoDiscount: forecastDate ? getIpoDiscountFromPwerm(forecastDate) : 0,
        equitySoldInIpo: forecastDate ? getEquitySoldInIpoFromPwerm(forecastDate) : 0,
        postIpoSaleDlom: forecastDate ? getPostIpoSaleDlomFromPwerm(forecastDate) : 0,
        cashValue: forecastDate ? getCashValueFromPwerm(forecastDate) : 0,
        netDebtItems: forecastDate ? getNetDebtItemValuesFromPwerm(forecastDate) : [],
        financingCosts: forecastDate ? getFinancingCostsFromPwerm(forecastDate) : 0,
        marketValueDloms: forecastDate ? getMarketValueDlomsFromPwerm(forecastDate) : [],
        totalNetDebt: forecastDate ? getTotalNetDebtFromPwerm(forecastDate) : 0,
        operationalFreeCashFlow: forecastDate
          ? getOperationalFreeCashFlowFromPwerm(forecastDate)
          : 0,
        operationalEv: forecastDate ? getEvFromPwerm(forecastDate) : 0,
      };
    });

  const getOpmValuesFromPwerm = (forecastDate: Nullable<string>): OpmInputsFromPwerm => {
    return {
      hypotheticalDebtWhenIpo: forecastDate ? getHypotheticalDebtWhenIpoFromPwerm(forecastDate) : 0,
      ipoProbability: forecastDate ? getIpoProbabilityFromPwerm(forecastDate) : 0,
      ipoCostsOfEv: forecastDate ? getIpoCostsOfEvFromPwerm(forecastDate) : 0,
      secondarySaleCostsOfEv: forecastDate ? getSecondarySaleCostsOfEvFromPwerm(forecastDate) : 0,
      ipoDiscount: forecastDate ? getIpoDiscountFromPwerm(forecastDate) : 0,
      equitySoldInIpo: forecastDate ? getEquitySoldInIpoFromPwerm(forecastDate) : 0,
      postIpoSaleDlom: forecastDate ? getPostIpoSaleDlomFromPwerm(forecastDate) : 0,
      cashValue: forecastDate ? getCashValueFromPwerm(forecastDate) : 0,
      netDebtItems: forecastDate ? getNetDebtItemValuesFromPwerm(forecastDate) : [],
      financingCosts: forecastDate ? getFinancingCostsFromPwerm(forecastDate) : 0,
      marketValueDloms: forecastDate ? getMarketValueDlomsFromPwerm(forecastDate) : [],
      totalNetDebt: forecastDate ? getTotalNetDebtFromPwerm(forecastDate) : 0,
      operationalFreeCashFlow: forecastDate ? getOperationalFreeCashFlowFromPwerm(forecastDate) : 0,
      operationalEv: forecastDate ? getEvFromPwerm(forecastDate) : 0,
    };
  };

  return {
    getMaxForecastYearsFromPwerm,
    getControlPremiumForDlocFromPwerm,
    getMarketVolatilityFromPwerm,
    getPeerSetVolatilityFromPwerm,
    getAllOpmValuesFromPwerm,
    getOpmValuesFromPwerm,
    getNetDebtItemsFromPwerm,
    getInitialCashValueFromPwerm,
    getInitialTotalNetDebtFromPwerm,
    getFinancingCostsFromPwerm,
  };
};
