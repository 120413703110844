import { RouteItem } from '@app/Routes';
import { FC } from 'react';
import ProjectPagesList from '@app/modules/projects/project-pages-list/ProjectPagesList';
import styles from './project-navigation-sidebar.module.scss';
import Sidebar, { SidebarModifier } from '@app/shared/components/sidebar/Sidebar';
import { useLocale } from '@app/core/hooks/useLocale';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';

export const PARENT_CLASSNAME = 'project-navigation';

const ProjectNavigationSidebar: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { isDevFeatureEnabled } = useDevFeatures();
  const project = useAppSelector((state) => state.project.projectDraft);
  const { details: projectDetails } = project;
  const isLegacyPwermEnabled = isDevFeatureEnabled(DevFeature.ShowLegacyPwerm);
  const isOverallConclusionsEnabled = isDevFeatureEnabled(DevFeature.OverallConclusions);

  const findIndex = (route: RouteItem[], routeName: string) => {
    const index = route.findIndex((item) => item.route === routeName);
    return index;
  };

  const inputsRoutes: RouteItem[] = [
    {
      route: RouteConstants.ProjectDetails,
      title: l('_ProjectDetails'),
    },
    {
      route: RouteConstants.CapitalStructure,
      title: l('_CapitalStructure'),
    },
    {
      route: RouteConstants.DealThesis,
      title: l('_DealThesis'),
    },
    {
      route: RouteConstants.DealThesisLegacy,
      title: l('_DealThesis') + ' Legacy',
    },
    {
      route: RouteConstants.ValuationInputs,
      title: l('_ValuationInputs'),
    },
    {
      route: RouteConstants.OpmInputs,
      title: l('_OpmInputs'),
    },
    {
      route: RouteConstants.BenchmarkingInputs,
      title: l('_BenchmarkingInputs'),
    },
    {
      route: RouteConstants.BenchmarkingInputsLegacy,
      title: l('_BenchmarkingInputs') + ' Legacy',
    },
  ];

  let inputsRoutesToHide: string[] = [];
  if (projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM)) {
    inputsRoutesToHide = [...inputsRoutesToHide, RouteConstants.OpmInputs];
  }

  if (projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.OPM)) {
    inputsRoutesToHide = [
      ...inputsRoutesToHide,
      RouteConstants.DealThesisLegacy,
      RouteConstants.DealThesis,
      RouteConstants.ValuationInputs,
    ];
  }

  if (!isLegacyPwermEnabled) {
    inputsRoutesToHide = [
      ...inputsRoutesToHide,
      RouteConstants.DealThesisLegacy,
      RouteConstants.BenchmarkingInputsLegacy,
    ];
  }

  inputsRoutesToHide.forEach((route) => {
    const index = findIndex(inputsRoutes, route);
    if (index !== -1) {
      inputsRoutes.splice(index, 1);
    }
  });

  const overallConclusionRoutes: RouteItem[] = [
    {
      route: RouteConstants.PwermOpmConclusions,
      title: l('_PwermOpm'),
    },
  ];

  const calculationOutputsRoutes: RouteItem[] = [
    {
      route: RouteConstants.DashboardPwerm,
      title: l('_PWERMDashboard'),
    },
    {
      route: RouteConstants.DashboardPwermLegacy,
      title: l('_PWERMDashboard') + ' Legacy',
    },
    {
      route: RouteConstants.CostOfEquity,
      title: l('_CostOfEquity'),
    },
    {
      route: RouteConstants.CostOfEquityLegacy,
      title: l('_CostOfEquity') + ' Legacy',
    },
    {
      route: RouteConstants.CostOfEquityComparator,
      title: 'Cost of Equity Comparator',
    },
    {
      route: RouteConstants.PwermWaterfall,
      title: l('_PWERMWaterfall'),
    },
    {
      route: RouteConstants.PwermWaterfallLegacy,
      title: l('_PWERMWaterfall') + ' Legacy',
    },
    {
      route: RouteConstants.PwermComparator,
      title: 'Waterfall Comparator',
    },
  ];

  if (!isLegacyPwermEnabled) {
    const routesToHide = [
      RouteConstants.DashboardPwermLegacy,
      RouteConstants.CostOfEquityLegacy,
      RouteConstants.PwermWaterfallLegacy,
      RouteConstants.CostOfEquityComparator,
      RouteConstants.PwermComparator,
    ];

    routesToHide.forEach((route) => {
      const index = findIndex(calculationOutputsRoutes, route);
      if (index !== -1) {
        calculationOutputsRoutes.splice(index, 1);
      }
    });
  }

  const calculationOutputsRoutesOPM: RouteItem[] = [
    {
      route: RouteConstants.DashboardOpm,
      title: l('_OPMDashboard'),
    },
    {
      route: RouteConstants.RealWorldDistributions,
      title: l('_RealWorldDistributions'),
    },
    {
      route: RouteConstants.OpmWaterfall,
      title: l('_OPMWaterfall'),
    },
  ];

  return (
    <Sidebar data-testid="project-sidebar" sidebarModifier={SidebarModifier.Primary}>
      <div
        className={`${styles[`${PARENT_CLASSNAME}__heading`]} ${
          styles[`${PARENT_CLASSNAME}__heading--no-border-no-margin`]
        }`}>
        {l('_Inputs')}
      </div>
      <ProjectPagesList
        routes={inputsRoutes}
        className={styles[`${PARENT_CLASSNAME}__first-item`]}
      />
      <div className={styles[`${PARENT_CLASSNAME}__heading`]}>{l('_AnalysisAndConclusion')}</div>
      {projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM) &&
        isOverallConclusionsEnabled && (
          <ProjectPagesList
            subtitle={l('_OverallConclusion')}
            routes={overallConclusionRoutes}
            className={styles[`${PARENT_CLASSNAME}__second-item`]}
            indented
          />
        )}
      {projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM) ||
      projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM) ? (
        <ProjectPagesList
          subtitle={l('_Pwerm')}
          routes={calculationOutputsRoutes}
          className={styles[`${PARENT_CLASSNAME}__second-item`]}
          indented
        />
      ) : null}
      {projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.OPM) ||
      projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM) ? (
        <ProjectPagesList
          subtitle={l('_Opm')}
          routes={calculationOutputsRoutesOPM}
          className={styles[`${PARENT_CLASSNAME}__first-item`]}
          indented
        />
      ) : null}
    </Sidebar>
  );
};

export default ProjectNavigationSidebar;
