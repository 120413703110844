import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DealThesisSidebarTabs } from '@app/shared/models/deal-thesis-sidebar-context-enum';
import { OverallExpandState } from '@app/shared/components/expand-all/ExpandAllOpm';
import { AppDispatch, RootState } from './store';
import {
  ApplicationConfigurationDto,
  MaintenanceModeDto,
} from '@app/shared/models/contracts/application-configuration-dto';
import api from '@server/api-config';
import env from '@environment';
import { LOADER_HIDE_TIMEOUT } from '@app/shared/constants/duration';
import {
  OperationProgressUpdateDto,
  OperationsStepType,
} from '@app/shared/models/contracts/operation-progress-update-dto';
import { ChildRowState } from '@app/modules/projects/dashboard/widgets/valuation-conclusion/ValuationConclusions';
import { ChartZoomMode } from '@app/modules/projects/dashboard/opm-charts/opm-enums';

type PageScrollPosition = {
  pageName: string;
  scrollTop: number;
  scrollLeft: number;
};

type UserSelections = {
  opmWaterfall: {
    selectedTab: Optional<string>;
    firstLastTable: {
      selectedYear: Optional<string>;
      selectedInstrument: Optional<string>;
    };
    sampleWaterfall: {
      selectedYear: Optional<string>;
      selectedInstrument: Optional<string>;
      selectedEnterpriseValue: Nullable<number>;
    };
  };
  dealThesis: {
    selectedTab: Optional<string>;
    showHistoricDataColumns: Optional<boolean>;
  };
  pwermDashboard: {
    valuationEstimatesTable: {
      selectedInstrument: Optional<string>;
    };
    evEvolutionChart: {
      selectedCase: Optional<string>;
      isExpanded: Optional<boolean>;
    };
    totalEquityChart: {
      selectedCase: Optional<string>;
      isExpanded: Optional<boolean>;
    };
    keyMetricsAndCrossChecksTable: {
      isExpanded: Optional<boolean>;
    };
  };
  pwerm2Dashboard: {
    valuationEstimatesTable: {
      selectedInstrument: Optional<string>;
    };
    evEvolutionChart: {
      selectedCase: Optional<string>;
      isExpanded: Optional<boolean>;
    };
    totalEquityChart: {
      selectedCase: Optional<string>;
      isExpanded: Optional<boolean>;
    };
    keyMetricsAndCrossChecksTable: {
      isExpanded: Optional<boolean>;
    };
  };
  opmDashboard: {
    riskNeutralChart: {
      selectedZoom: {
        selectedZoomMode: Optional<ChartZoomMode>;
        selectedZoomMaximum: Optional<number>;
        selectedZoomMinimum: Optional<number>;
      };
      selectedYear: Optional<string>;
      selectedInstrument: Optional<string>;
      isExpanded: Optional<boolean>;
    };
    valuationEstimatesTable: {
      childRowStates: ChildRowState;
    };
    inputSummaryTable: {
      isExpanded: Optional<boolean>;
    };
  };
  realWorldDistributions: {
    realWorldChart: {
      selectedZoom: {
        selectedZoomMode: Optional<ChartZoomMode>;
        selectedZoomMaximum: Optional<number>;
        selectedZoomMinimum: Optional<number>;
      };
      selectedYear: Optional<string>;
      selectedInstrument: Optional<string>;
    };
    realWorldEvChart: {
      selectedZoom: {
        selectedZoomMode: Optional<ChartZoomMode>;
        selectedZoomMaximum: Optional<number>;
        selectedZoomMinimum: Optional<number>;
      };
      selectedYear: Optional<string>;
    };
    realWorldTotalAttributableEquityChart: {
      selectedZoom: {
        selectedZoomMode: Optional<ChartZoomMode>;
        selectedZoomMaximum: Optional<number>;
        selectedZoomMinimum: Optional<number>;
      };
      selectedYear: Optional<string>;
    };
    realWorldByInstrumentChart: {
      selectedZoom: {
        selectedZoomMode: Optional<ChartZoomMode>;
        selectedZoomMaximum: Optional<number>;
        selectedZoomMinimum: Optional<number>;
      };
      selectedYear: Optional<string>;
      selectedInstrument: Optional<string>;
    };
  };
  costOfEquity: {
    selectedTab: Optional<string>;
  };
  capitalStructure: {
    selectedTab: Optional<string>;
    buildStructureOwnerTabs: {
      [key: string]: number;
    };
    payoutLogic: {
      selectedSubTab: Optional<string>;
      tabsHorizontalScrollPosition: Nullable<number>;
    };
    participationTable: {
      selectedYear: Optional<string>;
    };
  };
  valuationInputs: {
    selectedTab: Optional<string>;
  };
  overallConclusions: {
    showPerShareFigures: Optional<boolean>;
  };
};

const initialUserSelections: UserSelections = {
  opmWaterfall: {
    selectedTab: undefined,
    firstLastTable: {
      selectedYear: undefined,
      selectedInstrument: undefined,
    },
    sampleWaterfall: {
      selectedYear: undefined,
      selectedInstrument: undefined,
      selectedEnterpriseValue: null,
    },
  },
  dealThesis: {
    selectedTab: undefined,
    showHistoricDataColumns: true,
  },
  pwermDashboard: {
    valuationEstimatesTable: {
      selectedInstrument: undefined,
    },
    evEvolutionChart: {
      selectedCase: undefined,
      isExpanded: undefined,
    },
    totalEquityChart: {
      selectedCase: undefined,
      isExpanded: undefined,
    },
    keyMetricsAndCrossChecksTable: {
      isExpanded: undefined,
    },
  },
  pwerm2Dashboard: {
    valuationEstimatesTable: {
      selectedInstrument: undefined,
    },
    evEvolutionChart: {
      selectedCase: undefined,
      isExpanded: undefined,
    },
    totalEquityChart: {
      selectedCase: undefined,
      isExpanded: undefined,
    },
    keyMetricsAndCrossChecksTable: {
      isExpanded: undefined,
    },
  },
  opmDashboard: {
    riskNeutralChart: {
      selectedYear: undefined,
      selectedInstrument: undefined,
      isExpanded: undefined,
      selectedZoom: {
        selectedZoomMode: undefined,
        selectedZoomMinimum: undefined,
        selectedZoomMaximum: undefined,
      },
    },
    valuationEstimatesTable: {
      childRowStates: {},
    },
    inputSummaryTable: {
      isExpanded: undefined,
    },
  },
  realWorldDistributions: {
    realWorldChart: {
      selectedYear: undefined,
      selectedInstrument: undefined,
      selectedZoom: {
        selectedZoomMode: undefined,
        selectedZoomMinimum: undefined,
        selectedZoomMaximum: undefined,
      },
    },
    realWorldEvChart: {
      selectedYear: undefined,
      selectedZoom: {
        selectedZoomMode: undefined,
        selectedZoomMinimum: undefined,
        selectedZoomMaximum: undefined,
      },
    },
    realWorldTotalAttributableEquityChart: {
      selectedYear: undefined,
      selectedZoom: {
        selectedZoomMode: undefined,
        selectedZoomMinimum: undefined,
        selectedZoomMaximum: undefined,
      },
    },
    realWorldByInstrumentChart: {
      selectedYear: undefined,
      selectedInstrument: undefined,
      selectedZoom: {
        selectedZoomMode: undefined,
        selectedZoomMinimum: undefined,
        selectedZoomMaximum: undefined,
      },
    },
  },
  capitalStructure: {
    selectedTab: undefined,
    buildStructureOwnerTabs: {},
    payoutLogic: {
      selectedSubTab: undefined,
      tabsHorizontalScrollPosition: null,
    },
    participationTable: {
      selectedYear: undefined,
    },
  },
  costOfEquity: {
    selectedTab: undefined,
  },
  valuationInputs: {
    selectedTab: undefined,
  },
  overallConclusions: {
    showPerShareFigures: true,
  },
};

export interface UiValuesState {
  activeTab: DealThesisSidebarTabs;
  activeInstrument?: {
    instrumentId?: string;
    eventId?: string;
  };
  isAuthenticated: boolean;
  highlightEditableCells: boolean;
  hideUnrelatedEvents: boolean;
  hideUnrelatedEventsToggleDisplay: boolean;
  obtrusiveRequestsInProgressCount: number;
  unobtrusiveRequestsInProgressCount: number;
  appInsightsConnectionString?: string;
  datadogApplicationId?: string;
  datadogClientToken?: string;
  datadogEnvironment?: string;
  probabilityWeighted?: {
    maxCaseNameLength: Nullable<number>;
    maxCases: Nullable<number>;
    maxFinancialMetricNameLength: Nullable<number>;
    maxFinancialMetrics: Nullable<number>;
    maxForecastYears: Nullable<number>;
    maxMultiplesPerCase: Nullable<number>;
    maxValuationDateNarrativeLength: Nullable<number>;
    maxHistoricalEntries: Nullable<number>;
  };
  sessionSampleRate?: number;
  sessionReplaySampleRate?: number;
  versionTag?: string;
  branchName?: string;
  commitId?: string;
  validOpmIterationCounts: number[];
  maxRefinancingEventsPerCalc?: number;
  maxRefinancingEventNameLength: number;
  maxYearsBetweenEventsAndValuation: number;
  maxMonthsBetweenValuationAndFYED: number;
  opmWaterfallIterations?: number;
  opmTableCollapseStates: {
    [key: string]: boolean;
  };
  expandAll: OverallExpandState;
  userSelections: UserSelections;
  displayOperationProgress: boolean;
  operationProgress?: OperationProgressUpdateDto;
  operationStatus: {
    isSaving: boolean;
    isOpmCalcRunning: boolean;
    isExportRunning: boolean;
  };
  showOnlyUserAccessibleProjects: boolean;
  pageScrollPositionList: {
    [key: string]: PageScrollPosition[];
  };
  maintenance: MaintenanceModeDto;
}

export const fetchApplicationConfiguration = createAsyncThunk<
  { configuration: ApplicationConfigurationDto },
  void,
  { state: RootState; dispatch: AppDispatch }
>('uiValues/fetchApplicationConfiguration', async () => {
  const response = await api.get<ApplicationConfigurationDto>(`${env.apiUrl}/configuration`);
  return { configuration: response.data };
});

export const setOperationStepComplete = createAsyncThunk(
  'uiValues/setOperationStepComplete',
  async (payload: { stepType: OperationsStepType; progress: number }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentOperationProgress = state.uiValues.operationProgress;
    if (currentOperationProgress) {
      const stepIndex = currentOperationProgress.steps.findIndex(
        (s) => s.type === payload.stepType
      );
      if (stepIndex !== -1) {
        const newSteps = [...currentOperationProgress.steps];
        newSteps[stepIndex] = { ...newSteps[stepIndex], complete: true };
        const newOperationProgress = {
          ...currentOperationProgress,
          steps: newSteps,
          progress: payload.progress,
        };
        dispatch(setOperationProgress(newOperationProgress));
      }
    }
  }
);

export const setDisplayOperationProgress = createAsyncThunk(
  'uiValues/setDisplayOperationProgress',
  async (payload: boolean, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isOpmCalcRunning = state.uiValues.operationStatus.isOpmCalcRunning;
    const isExportRunning = state.uiValues.operationStatus.isExportRunning;
    if (payload === false) {
      await new Promise((resolve) => setTimeout(resolve, LOADER_HIDE_TIMEOUT));
      // TODO: if we allow simultaneous operations, we need to change this logic
      if (isOpmCalcRunning) {
        dispatch(setOpmCalcRunningStatus(false));
      }
      if (isExportRunning) {
        dispatch(setExportRunningStatus(false));
      }
    }
    dispatch(setDisplayOperationProgressSync(payload));
    dispatch(clearOperationProgress());
  }
);

export const resetUserSelections = createAsyncThunk(
  'uiValues/resetUserSelections',
  async (_, { dispatch }) => {
    dispatch(setUserSelections(initialUserSelections));
  }
);

export const uiValuesSlice = createSlice({
  name: 'uiValues',
  initialState: {
    activeTab: DealThesisSidebarTabs.Inputs,
    activeInstrument: {
      instrumentId: undefined,
      eventId: undefined,
    },
    isAuthenticated: false,
    highlightEditableCells: false,
    hideUnrelatedEvents: true,
    hideUnrelatedEventsToggleDisplay: true,
    obtrusiveRequestsInProgressCount: 0,
    unobtrusiveRequestsInProgressCount: 0,
    appInsightsConnectionString: undefined,
    versionTag: undefined,
    branchName: undefined,
    commitId: undefined,
    validOpmIterationCounts: [],
    maxRefinancingEvents: undefined,
    maxHistoricalEntries: undefined,
    maxRefinancingEventNameLength: 0,
    maxYearsBetweenEventsAndValuation: 0,
    maxMonthsBetweenValuationAndFYED: 0,
    waterfallIterations: undefined,
    opmTableCollapseStates: {},
    expandAll: OverallExpandState.Other,
    userSelections: initialUserSelections,
    displayOperationProgress: false,
    operationProgress: undefined,
    operationStatus: {
      isSaving: false,
      isOpmCalcRunning: false,
      isExportRunning: false,
    },
    showOnlyUserAccessibleProjects: true,
    pageScrollPositionList: {},
    maintenance: {
      start: null,
      timeToStartMins: null,
      end: null,
      message: null,
      active: null,
    },
  } as UiValuesState,
  reducers: {
    setDisplayOperationProgressSync: (state, { payload }) => {
      state.displayOperationProgress = payload;
    },
    setOperationProgress: (state, { payload }) => {
      state.operationProgress = payload;
    },
    clearOperationProgress: (state) => {
      state.operationProgress = undefined;
    },
    editInputs: (state) => {
      state.activeTab = DealThesisSidebarTabs.Inputs;
    },
    setActiveInstrument: (state, payload) => {
      state.activeInstrument = payload.payload;
    },
    setIsLoggedIn: (state) => {
      state.isAuthenticated = true;
    },
    setHighlightEditableCells: (state, payload) => {
      state.highlightEditableCells = payload.payload;
    },
    setHideUnrelatedEvents: (state, payload) => {
      state.hideUnrelatedEvents = payload.payload;
    },
    setHideUnrelatedEventsToggleDisplay: (state, payload) => {
      state.hideUnrelatedEventsToggleDisplay = payload.payload;
    },
    increaseObtrusiveRequestsInProgressCount: (state) => {
      state.obtrusiveRequestsInProgressCount = state.obtrusiveRequestsInProgressCount + 1;
    },
    decreaseObtrusiveRequestsInProgressCount: (state) => {
      state.obtrusiveRequestsInProgressCount =
        state.obtrusiveRequestsInProgressCount === 0
          ? 0
          : state.obtrusiveRequestsInProgressCount - 1;
    },
    increaseUnobtrusiveRequestsInProgressCount: (state) => {
      state.unobtrusiveRequestsInProgressCount = state.unobtrusiveRequestsInProgressCount + 1;
    },
    decreaseUnobtrusiveRequestsInProgressCount: (state) => {
      state.unobtrusiveRequestsInProgressCount =
        state.unobtrusiveRequestsInProgressCount === 0
          ? 0
          : state.unobtrusiveRequestsInProgressCount - 1;
    },
    setAppConfigurationData: (state, { payload }) => {
      state.versionTag = payload.versionTag;
      state.commitId = payload.commitId;
      state.branchName = payload.branchName;
      state.validOpmIterationCounts = payload.validOpmIterationCounts;
    },
    setTableCollapseState: (state, payload) => {
      const { key, value } = payload.payload;
      state.opmTableCollapseStates[key] = value;
    },
    clearTableCollapseStates: (state) => {
      state.opmTableCollapseStates = {};
    },
    setExpandAllState: (state, payload) => {
      state.expandAll = payload.payload;
    },
    setUserSelections: (state, payload) => {
      state.userSelections = payload.payload;
    },
    setOpmWaterfallSelectedTab: (state, payload) => {
      state.userSelections.opmWaterfall.selectedTab = payload.payload;
    },
    setOpmWaterfallFirstLastTableSelectedYear: (state, payload) => {
      state.userSelections.opmWaterfall.firstLastTable.selectedYear = payload.payload;
    },
    setOpmWaterfallFirstLastTableSelectedInstrument: (state, payload) => {
      state.userSelections.opmWaterfall.firstLastTable.selectedInstrument = payload.payload;
    },
    setOpmWaterfallSampleWaterfallSelectedYear: (state, payload) => {
      state.userSelections.opmWaterfall.sampleWaterfall.selectedYear = payload.payload;
    },
    setOpmWaterfallSampleWaterfallSelectedInstrument: (state, payload) => {
      state.userSelections.opmWaterfall.sampleWaterfall.selectedInstrument = payload.payload;
    },
    setOpmWaterfallSampleWaterfallSelectedEnterpriseValue: (state, payload) => {
      state.userSelections.opmWaterfall.sampleWaterfall.selectedEnterpriseValue = payload.payload;
    },
    setDealThesisSelectedTab: (state, payload) => {
      state.userSelections.dealThesis.selectedTab = payload.payload;
    },
    setShowHistoricDataColumns: (state, payload) => {
      state.userSelections.dealThesis.showHistoricDataColumns = payload.payload;
    },
    setPwermDashboardValuationEstimatesTableSelectedInstrument: (state, payload) => {
      state.userSelections.pwermDashboard.valuationEstimatesTable.selectedInstrument =
        payload.payload;
    },
    setPwermDashboardEvolutionChartSelectedCase: (state, payload) => {
      state.userSelections.pwermDashboard.evEvolutionChart.selectedCase = payload.payload;
    },
    setPwermDashboardEvolutionChartIsExpanded: (state, payload) => {
      state.userSelections.pwermDashboard.evEvolutionChart.isExpanded = payload.payload;
    },
    setPwermDashboardTotalEquityChartSelectedCase: (state, payload) => {
      state.userSelections.pwermDashboard.totalEquityChart.selectedCase = payload.payload;
    },
    setPwermDashboardTotalEquityChartIsExpanded: (state, payload) => {
      state.userSelections.pwermDashboard.totalEquityChart.isExpanded = payload.payload;
    },
    setPwermDashboardKeyMetricsAndCrossChecksTableIsExpanded: (state, payload) => {
      state.userSelections.pwermDashboard.keyMetricsAndCrossChecksTable.isExpanded =
        payload.payload;
    },
    setPwermDashboard2ValuationEstimatesTableSelectedInstrument: (state, payload) => {
      state.userSelections.pwerm2Dashboard.valuationEstimatesTable.selectedInstrument =
        payload.payload;
    },
    setPwermDashboard2EvolutionChartSelectedCase: (state, payload) => {
      state.userSelections.pwerm2Dashboard.evEvolutionChart.selectedCase = payload.payload;
    },
    setPwermDashboard2EvolutionChartIsExpanded: (state, payload) => {
      state.userSelections.pwerm2Dashboard.evEvolutionChart.isExpanded = payload.payload;
    },
    setPwermDashboard2TotalEquityChartSelectedCase: (state, payload) => {
      state.userSelections.pwerm2Dashboard.totalEquityChart.selectedCase = payload.payload;
    },
    setPwermDashboard2TotalEquityChartIsExpanded: (state, payload) => {
      state.userSelections.pwerm2Dashboard.totalEquityChart.isExpanded = payload.payload;
    },
    setPwermDashboard2KeyMetricsAndCrossChecksTableIsExpanded: (state, payload) => {
      state.userSelections.pwerm2Dashboard.keyMetricsAndCrossChecksTable.isExpanded =
        payload.payload;
    },
    setOpmDashboardRiskNeutralChartSelectedYear: (state, payload) => {
      state.userSelections.opmDashboard.riskNeutralChart.selectedYear = payload.payload;
    },
    setOpmDashboardRiskNeutralChartSelectedInstrument: (state, payload) => {
      state.userSelections.opmDashboard.riskNeutralChart.selectedInstrument = payload.payload;
    },
    setOpmDashboardRiskNeutralChartIsExpanded: (state, payload) => {
      state.userSelections.opmDashboard.riskNeutralChart.isExpanded = payload.payload;
    },
    setOpmDashboardValuationEstimatesTableChildRowStates: (state, payload) => {
      state.userSelections.opmDashboard.valuationEstimatesTable.childRowStates = payload.payload;
    },
    setOpmDashboardInputSummaryTableIsExpanded: (state, payload) => {
      state.userSelections.opmDashboard.inputSummaryTable.isExpanded = payload.payload;
    },
    setRealWorldDistributionsRealWorldChartSelectedYear: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldChart.selectedYear = payload.payload;
    },
    setRealWorldDistributionsRealWorldChartSelectedInstrument: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldChart.selectedInstrument =
        payload.payload;
    },
    setRealWorldDistributionsRealWorldEvChartSelectedYear: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldEvChart.selectedYear = payload.payload;
    },
    setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedYear: (
      state,
      payload
    ) => {
      state.userSelections.realWorldDistributions.realWorldTotalAttributableEquityChart.selectedYear =
        payload.payload;
    },
    setRealWorldDistributionsRealWorldByInstrumentChartSelectedYear: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldByInstrumentChart.selectedYear =
        payload.payload;
    },
    setRealWorldDistributionsRealWorldByInstrumentChartSelectedInstrument: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldByInstrumentChart.selectedInstrument =
        payload.payload;
    },
    setCostOfEquitySelectedTab: (state, payload) => {
      state.userSelections.costOfEquity.selectedTab = payload.payload;
    },
    setCapitalStructureSelectedTab: (state, payload) => {
      state.userSelections.capitalStructure.selectedTab = payload.payload;
    },
    setCapitalStructurePayoutLogicSelectedSubTab: (state, payload) => {
      state.userSelections.capitalStructure.payoutLogic.selectedSubTab = payload.payload;
    },
    setCapitalStructurePayoutLogicTabsHorizontalScrollPosition: (state, payload) => {
      state.userSelections.capitalStructure.payoutLogic.tabsHorizontalScrollPosition =
        payload.payload;
    },
    setCapitalStructureBuildStructure: (state, payload) => {
      const { key, value } = payload.payload;
      state.userSelections.capitalStructure.buildStructureOwnerTabs[key] = value;
    },
    setCapitalStructureParticipationTableSelectedYear: (state, payload) => {
      state.userSelections.capitalStructure.participationTable.selectedYear = payload.payload;
    },
    setValuationInputsSelectedTab: (state, payload) => {
      state.userSelections.valuationInputs.selectedTab = payload.payload;
    },
    setPageScrollPosition: (state, payload) => {
      const { key, value } = payload.payload;
      if (!state.pageScrollPositionList[key]) {
        state.pageScrollPositionList[key] = [];
      }
      const index = state.pageScrollPositionList[key].findIndex(
        (pageScrollPosition) => pageScrollPosition.pageName === value.pageName
      );
      if (index !== -1) {
        state.pageScrollPositionList[key][index] = value;
      } else {
        state.pageScrollPositionList[key].push(value);
      }
    },
    setIsSaving: (state, payload) => {
      state.operationStatus.isSaving = payload.payload;
    },
    setOpmCalcRunningStatus: (state, { payload }) => {
      state.operationStatus.isOpmCalcRunning = payload;
    },
    setExportRunningStatus: (state, { payload }) => {
      state.operationStatus.isExportRunning = payload;
    },
    setSystemSettings: (state, { payload }) => {
      state.maintenance = {
        start: payload.maintenance.start,
        end: payload.maintenance.end,
        message: payload.maintenance.message,
        active: payload.maintenance.active,
      };
    },
    setOpmDashboardRiskNeutralChartSelectedZoom: (state, payload) => {
      state.userSelections.opmDashboard.riskNeutralChart.selectedZoom = payload.payload;
    },
    setRealWorldDistributionsRealWorldChartSelectedZoom: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldChart.selectedZoom = payload.payload;
    },
    setRealWorldDistributionsRealWorldEvChartSelectedZoom: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldEvChart.selectedZoom = payload.payload;
    },
    setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedZoom: (
      state,
      payload
    ) => {
      state.userSelections.realWorldDistributions.realWorldTotalAttributableEquityChart.selectedZoom =
        payload.payload;
    },
    setRealWorldDistributionsRealWorldByInstrumentChartSelectedZoom: (state, payload) => {
      state.userSelections.realWorldDistributions.realWorldByInstrumentChart.selectedZoom =
        payload.payload;
    },
    setOverallConclusionsShowPerShareFigures: (state, payload) => {
      state.userSelections.overallConclusions.showPerShareFigures = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationConfiguration.fulfilled, (state, action) => {
      state.appInsightsConnectionString =
        action.payload.configuration.applicationInsights.connectionString ?? undefined;
      state.datadogApplicationId =
        action.payload.configuration.datadogConfiguration.applicationId ?? undefined;
      state.datadogClientToken =
        action.payload.configuration.datadogConfiguration.clientToken ?? undefined;
      state.sessionReplaySampleRate =
        action.payload.configuration.datadogConfiguration.sessionReplaySampleRate ?? 0;
      state.sessionSampleRate =
        action.payload.configuration.datadogConfiguration.sessionSampleRate ?? 0;
      state.datadogEnvironment =
        action.payload.configuration.datadogConfiguration.environment ?? '';
      state.probabilityWeighted = action.payload.configuration.probabilityWeighted;
      state.versionTag = action.payload.configuration.versionInfo?.versionTag ?? undefined;
      state.commitId = action.payload.configuration.versionInfo?.commitId ?? undefined;
      state.branchName = action.payload.configuration.versionInfo?.branchName ?? undefined;
      state.validOpmIterationCounts =
        action.payload.configuration.optionPricing.validIterationCounts;
      state.maxRefinancingEventsPerCalc =
        action.payload.configuration.capitalStructure.maxRefinancingEventsPerCalc;
      state.probabilityWeighted.maxHistoricalEntries =
        action.payload.configuration.probabilityWeighted.maxHistoricalEntries;
      state.maxRefinancingEventNameLength =
        action.payload.configuration.capitalStructure.maxRefinancingEventNameLength;
      state.maxYearsBetweenEventsAndValuation =
        action.payload.configuration.capitalStructure.maxYearsBetweenEventsAndValuation;
      state.maxMonthsBetweenValuationAndFYED =
        action.payload.configuration.capitalStructure.maxMonthsBetweenValuationAndFYED;
      state.opmWaterfallIterations = action.payload.configuration.optionPricing.waterfallIterations;
      state.showOnlyUserAccessibleProjects =
        action.payload.configuration.global.showOnlyUserAccessibleProjects;
      state.maintenance = {
        start: action.payload.configuration.systemSettings.maintenance.start,
        end: action.payload.configuration.systemSettings.maintenance.end,
        message: action.payload.configuration.systemSettings.maintenance.message,
        active: action.payload.configuration.systemSettings.maintenance.active,
      };
    });
  },
});

export const {
  setDisplayOperationProgressSync,
  setOperationProgress,
  clearOperationProgress,
  editInputs,
  setActiveInstrument,
  setHighlightEditableCells,
  setOverallConclusionsShowPerShareFigures,
  setHideUnrelatedEvents,
  setHideUnrelatedEventsToggleDisplay,
  increaseObtrusiveRequestsInProgressCount,
  decreaseObtrusiveRequestsInProgressCount,
  increaseUnobtrusiveRequestsInProgressCount,
  decreaseUnobtrusiveRequestsInProgressCount,
  setAppConfigurationData,
  setTableCollapseState,
  setExpandAllState,
  clearTableCollapseStates,
  setUserSelections,
  setOpmWaterfallSelectedTab,
  setOpmWaterfallFirstLastTableSelectedYear,
  setOpmWaterfallFirstLastTableSelectedInstrument,
  setOpmWaterfallSampleWaterfallSelectedYear,
  setOpmWaterfallSampleWaterfallSelectedInstrument,
  setOpmWaterfallSampleWaterfallSelectedEnterpriseValue,
  setDealThesisSelectedTab,
  setShowHistoricDataColumns,
  setPwermDashboardValuationEstimatesTableSelectedInstrument,
  setPwermDashboardEvolutionChartSelectedCase,
  setPwermDashboardEvolutionChartIsExpanded,
  setPwermDashboardTotalEquityChartSelectedCase,
  setPwermDashboardTotalEquityChartIsExpanded,
  setPwermDashboardKeyMetricsAndCrossChecksTableIsExpanded,
  setPwermDashboard2ValuationEstimatesTableSelectedInstrument,
  setPwermDashboard2EvolutionChartSelectedCase,
  setPwermDashboard2EvolutionChartIsExpanded,
  setPwermDashboard2TotalEquityChartSelectedCase,
  setPwermDashboard2TotalEquityChartIsExpanded,
  setPwermDashboard2KeyMetricsAndCrossChecksTableIsExpanded,
  setOpmDashboardRiskNeutralChartSelectedYear,
  setOpmDashboardRiskNeutralChartSelectedInstrument,
  setOpmDashboardRiskNeutralChartIsExpanded,
  setOpmDashboardRiskNeutralChartSelectedZoom,
  setOpmDashboardValuationEstimatesTableChildRowStates,
  setOpmDashboardInputSummaryTableIsExpanded,
  setRealWorldDistributionsRealWorldChartSelectedYear,
  setRealWorldDistributionsRealWorldChartSelectedInstrument,
  setRealWorldDistributionsRealWorldChartSelectedZoom,
  setRealWorldDistributionsRealWorldEvChartSelectedYear,
  setRealWorldDistributionsRealWorldEvChartSelectedZoom,
  setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedYear,
  setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedZoom,
  setRealWorldDistributionsRealWorldByInstrumentChartSelectedYear,
  setRealWorldDistributionsRealWorldByInstrumentChartSelectedInstrument,
  setRealWorldDistributionsRealWorldByInstrumentChartSelectedZoom,
  setCostOfEquitySelectedTab,
  setCapitalStructureSelectedTab,
  setCapitalStructurePayoutLogicSelectedSubTab,
  setCapitalStructurePayoutLogicTabsHorizontalScrollPosition,
  setCapitalStructureBuildStructure,
  setCapitalStructureParticipationTableSelectedYear,
  setValuationInputsSelectedTab,
  setIsSaving,
  setOpmCalcRunningStatus,
  setExportRunningStatus,
  setPageScrollPosition,
  setSystemSettings,
} = uiValuesSlice.actions;

export default uiValuesSlice.reducer;
