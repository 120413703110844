import { IMaskInputProps } from 'react-imask/dist/mixin';
import { multiplierPatternViewOnly } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import { Alignment } from '@app/shared/enums/alignment.enum';

export interface cellValueFormattingRules {
  alignment?: Alignment;
  decimalPoint?: number;
  formatPattern?: IMaskInputProps;
  dateFormat?: Intl.DateTimeFormatOptions;
  prefix?: string;
  suffix?: string;
  normalize?: boolean;
  removeTrailingZeroes?: boolean;
}

export const amountRules: cellValueFormattingRules = {
  decimalPoint: 0,
  alignment: Alignment.Right,
};

export const yearRules: cellValueFormattingRules = {
  decimalPoint: 1,
  alignment: Alignment.Right,
  suffix: ' years',
  normalize: true,
};

export const multiplierRules: cellValueFormattingRules = {
  formatPattern: multiplierPatternViewOnly,
  decimalPoint: 1,
  alignment: Alignment.Right,
};

export const calculatedMultiplierRules: cellValueFormattingRules = {
  decimalPoint: 2,
  alignment: Alignment.Right,
  suffix: 'x ',
  normalize: true,
};

export const monthDayCellViewRules: cellValueFormattingRules = {
  alignment: Alignment.Right,
  dateFormat: { month: 'short', year: '2-digit' },
};

export const shortDateViewRules: cellValueFormattingRules = {
  alignment: Alignment.Right,
  dateFormat: { dateStyle: 'short' },
};

export const enteredPercentageViewRules: cellValueFormattingRules = {
  decimalPoint: 2,
  alignment: Alignment.Right,
  suffix: '% ',
  normalize: true,
};

export const numberCalculatedViewRules: cellValueFormattingRules = {
  decimalPoint: 2,
  alignment: Alignment.Right,
};

export const decimalCalculatedViewRules: cellValueFormattingRules = {
  decimalPoint: 2,
  alignment: Alignment.Right,
  normalize: true,
};

export const textViewRules: cellValueFormattingRules = {
  alignment: Alignment.Right,
};

export const amountWithDecimalRules: cellValueFormattingRules = {
  decimalPoint: 2,
  alignment: Alignment.Right,
  removeTrailingZeroes: true,
};
