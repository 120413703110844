import styles from '../opm-summary-waterfall.module.scss';
import { SelectOption } from '@app/shared/models/option';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import SummaryCalculations from './summary-calculations/SummaryCalculations';
import InstrumentCalculations from './instrument-calculations/InstrumentCalculations';
import { Navigate, generatePath } from 'react-router-dom';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { dateFormatter } from '@app/shared/formatters';
import { GridSelectField } from '@app/shared/components/grid-controls/grid-select-field/GridSelectField';
import {
  setOpmWaterfallFirstLastTableSelectedInstrument,
  setOpmWaterfallFirstLastTableSelectedYear,
  setOpmWaterfallSelectedTab,
} from '@core/store/ui-values-slice';
import { sortDictionaryPerInstrumentFn } from '@app/shared/helpers';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  FirstLastSummaryIndexDictionaryDto,
  OpmFirstLastWaterfallDto,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import classNames from 'classnames';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';

const FirstLastTable = (): JSX.Element => {
  useSetSelectedTab(RouteConstants.FirstLastSimulations, setOpmWaterfallSelectedTab);

  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const results = useAppSelector((state) => state.opmCalculation.riskFreeValues);
  const opmWaterfall = results?.waterfalls;

  const storedYear = useAppSelector(
    (state) => state.uiValues.userSelections.opmWaterfall.firstLastTable.selectedYear
  );
  const storedInstrument = useAppSelector(
    (state) => state.uiValues.userSelections.opmWaterfall.firstLastTable.selectedInstrument
  );
  const waterfallIterations = useAppSelector((state) => state.uiValues.opmWaterfallIterations ?? 5);

  let firstKey: string;
  let instrumentOptions: SelectOption[] = [];
  let yearOptions: SelectOption[] = [];

  if (opmWaterfall && Object.keys(opmWaterfall).length > 0) {
    firstKey = Object.keys(opmWaterfall)[0];
    const sortedInstruments = sortDictionaryPerInstrumentFn(
      opmWaterfall[firstKey].first[1].instruments
    );
    instrumentOptions = Object.keys(sortedInstruments)
      .filter((key) => sortedInstruments[key].shouldBeValued)
      .map((key) => ({
        value: key,
        viewValue: sortedInstruments[key].instrumentName,
      }));

    yearOptions = Object.keys(opmWaterfall).map((key) => ({
      value: key,
      viewValue: dateFormatter(key, { month: 'short', year: 'numeric' }),
    }));
  }

  const isStoredInstrumentValid =
    storedInstrument && instrumentOptions.some((option) => option.value === storedInstrument);
  const isStoredYearValid = storedYear && yearOptions.some((option) => option.value === storedYear);

  const defaultInstrument = isStoredInstrumentValid
    ? storedInstrument
    : String(instrumentOptions[0]?.value);
  const defaultYear = isStoredYearValid ? storedYear : String(yearOptions[0]?.value);

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: {
      instrumentSelect: defaultInstrument,
      yearSelect: defaultYear,
    },
  });

  const { setValue, watch } = formMethods;

  const selectedInstrument = watch('instrumentSelect');
  const selectedYear = watch('yearSelect');

  const selectedInstrumentViewValue = String(
    instrumentOptions.find((option) => option.value === selectedInstrument)?.viewValue
  );

  if (!results || Object.keys(results).length === 0) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const handleYearSelectChange = (val: string) => {
    dispatch(setOpmWaterfallFirstLastTableSelectedYear(val));
    setValue('yearSelect', val);
  };

  const handleInstrumentSelectChange = (val: string) => {
    dispatch(setOpmWaterfallFirstLastTableSelectedInstrument(val));
    setValue('instrumentSelect', val);
  };

  const titleWidth = '300px';
  const cellWidth = '100px';

  // measure taken to prevent the table from breaking if selectedYear
  // is removed, and the user re-runs the calc whilst viewing the table
  if (!yearOptions.find((y) => y.value === selectedYear)) {
    setValue('yearSelect', String(yearOptions[0].value));
  }

  // measure taken to prevent the table from breaking if selectedInstrument
  // is removed, and the user re-runs the calc whilst viewing the table
  if (!instrumentOptions.find((v) => v.value === selectedInstrument)) {
    setValue('instrumentSelect', String(instrumentOptions[0].value));
  }

  return (
    <div className="main-container-padding-remove">
      <FormProvider {...formMethods}>
        <table
          className={`table-primary table-primary--zebra table-primary--framed table-primary--title-column-nowrap ${styles['opm-top-bottom-waterfall-table']}`}
          data-testid="opm--top-bottom-waterfall-table">
          <colgroup>
            <col style={{ width: titleWidth }} />
            {new Array(waterfallIterations).map((_, index) => (
              <col key={index} style={{ width: cellWidth }} />
            ))}
          </colgroup>
          <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
            <tr>
              <th
                className={`${styles['options-select']} ${styles['border-cell-bottom']} ${styles['border-cell-right']}`}>
                <GridSelectField
                  aria-label={l('_SelectorAriaLabel', { label: l('_Instrument') })}
                  name="instrumentSelect"
                  options={instrumentOptions}
                  data-testid="instrument-select"
                  onChange={(e) => handleInstrumentSelectChange(e.target.value)}
                />
              </th>
              <th colSpan={waterfallIterations} rowSpan={2} className={styles['top-5-header']}>
                {l('_FirstNSimulations', { n: waterfallIterations })}
              </th>
              <th colSpan={waterfallIterations} rowSpan={2} className={styles['bottom-5-header']}>
                {l('_LastNSimulations', { n: waterfallIterations })}
              </th>
            </tr>
            <tr>
              <th
                className={`${styles['options-select']} ${styles['border-cell-bottom']} ${styles['border-cell-right']}`}>
                <GridSelectField
                  aria-label={l('_SelectorAriaLabel', { label: l('_Year') })}
                  name="yearSelect"
                  options={yearOptions}
                  data-testid="year-select"
                  onChange={(e) => handleYearSelectChange(e.target.value)}
                />
              </th>
            </tr>
            <tr>
              <td className={classNames(styles['border-right'])} data-testid="exitYears-title">
                {l('_TimeToExit')}
              </td>
              {(['first', 'last'] as (keyof OpmFirstLastWaterfallDto)[]).map(
                (section, outerIndex) =>
                  Object.entries(
                    opmWaterfall[
                      selectedYear === undefined ? String(yearOptions[0]?.value) : selectedYear
                    ][section]
                  ).map(([key, _], innerIndex) => {
                    const value = (
                      opmWaterfall[
                        selectedYear === undefined ? String(yearOptions[0]?.value) : selectedYear
                      ][section] as FirstLastSummaryIndexDictionaryDto
                    )[key]['exitYears'];

                    return (
                      <td
                        key={`${section}-${key}`}
                        className={classNames({
                          [styles['border-right']]:
                            outerIndex === 0 && innerIndex + 1 === waterfallIterations,
                        })}
                        data-testid={`exitYears-simulation-${key}`}>
                        <CellValue
                          value={value}
                          {...amountRules}
                          {...numberCalculatedViewRules}
                          normalize
                        />
                      </td>
                    );
                  })
              )}
            </tr>
          </thead>
          <tbody>
            <SummaryCalculations
              opmWaterfall={opmWaterfall}
              selectedYear={
                selectedYear === undefined ? String(yearOptions[0]?.value) : selectedYear
              }
              yearOptions={yearOptions}
            />
            <InstrumentCalculations
              opmWaterfall={opmWaterfall}
              selectedInstrument={
                selectedInstrument === undefined
                  ? String(instrumentOptions[0]?.value)
                  : selectedInstrument
              }
              selectedYear={
                selectedYear === undefined ? String(yearOptions[0]?.value) : selectedYear
              }
              selectedInstrumentViewValue={
                selectedInstrumentViewValue === 'undefined'
                  ? String(instrumentOptions[0]?.viewValue)
                  : selectedInstrumentViewValue
              }
            />
          </tbody>
        </table>
      </FormProvider>
    </div>
  );
};

export default FirstLastTable;
