import { FC } from 'react';
import styles from '../../opm-summary-waterfall.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  OpmFirstLastWaterfallDictionaryDto,
  OpmFirstLastWaterfallDto,
  FirstLastSummaryIndexDictionaryDto,
  FirstLastTranche,
  FirstLastInstrument,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import { translationKeys } from '@locale/setupI18n';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import classNames from 'classnames';
import { enumKeyByValue } from '@app/shared/helpers';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import TrancheCalculationsErf from '../tranche-calculations-erf/TrancheCalculationsErf';

type TrancheValueKeys = Omit<FirstLastTranche, 'conditions'>;
export type InstrumentValueKeys = Pick<
  FirstLastInstrument,
  | 'totalAddition'
  | 'totalRedemption'
  | 'totalCashPayInterest'
  | 'fairValueOfCashflowMovements'
  | 'cumulativeFairValueOfCashflowMovements'
>;
interface InstrumentCalculationsErfProps {
  opmWaterfall: OpmFirstLastWaterfallDictionaryDto;
  selectedInstrument: string;
  selectedYear: string;
  selectedInstrumentViewValue: string;
}

const InstrumentCalculationsErf: FC<InstrumentCalculationsErfProps> = ({
  opmWaterfall,
  selectedInstrument,
  selectedYear,
  selectedInstrumentViewValue,
}): JSX.Element => {
  const { l } = useLocale();
  const interpolationOption = {
    escapeValue: false,
  };
  const project = useAppSelector((state) => state.project.projectDraft);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const waterfallIterations = useAppSelector((state) => state.uiValues.opmWaterfallIterations ?? 5);
  const titleColSpan = waterfallIterations * 2 + 1;
  const firstSimulation = Object.keys(opmWaterfall[selectedYear].first)[0];

  const instrument =
    opmWaterfall[selectedYear].first[firstSimulation].instruments[selectedInstrument];

  const instrumentHasTranches = instrument.numberOfPayoutLogicTranches > 0;
  const instrumentHasDloc =
    instrument.tranches[Object.keys(instrument.tranches)[0]].dlocPc !== null;

  const isErfProject = buildStructures?.[project.pwermInput.cases[0].capitalStructureId]?.isErf;
  const isOrdinaryEquity =
    instrument.instrumentType === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);
  const isTotalAdditionPresent =
    typeof opmWaterfall[selectedYear].first[firstSimulation].instruments[selectedInstrument]
      .totalAddition === 'number';
  const isTotalRedemptionPresent =
    typeof opmWaterfall[selectedYear].first[firstSimulation].instruments[selectedInstrument]
      .totalRedemption === 'number';
  const isCashInterestPresent =
    typeof opmWaterfall[selectedYear].first[firstSimulation].instruments[selectedInstrument]
      .totalCashPayInterest === 'number';
  const shouldDisplayOtherCashflowMovement =
    isTotalAdditionPresent || isTotalRedemptionPresent || isCashInterestPresent;

  const createTrancheLevelRow = (
    label: translationKeys,
    valueKey: keyof TrancheValueKeys,
    isPercentage = false
  ) => {
    return (
      <tr>
        <td
          className={classNames(styles['border-right'])}
          data-testid={`${valueKey}-instrument-title`}>
          {l(label)}
        </td>
        {(['first', 'last'] as (keyof OpmFirstLastWaterfallDto)[]).map((section, outerIndex) =>
          Object.entries(opmWaterfall[selectedYear][section]).map(([key, _], innerIndex) => {
            const firstTrancheName = Object.keys(
              (opmWaterfall[selectedYear][section] as FirstLastSummaryIndexDictionaryDto)[key]
                .instruments[selectedInstrument].tranches
            )[0];
            const firstTranche = (
              opmWaterfall[selectedYear][section] as FirstLastSummaryIndexDictionaryDto
            )[key].instruments[selectedInstrument].tranches[firstTrancheName];
            const value = firstTranche[valueKey];

            return (
              <td
                key={`${section}-${key}`}
                className={classNames({
                  [styles['border-right']]:
                    outerIndex === 0 && innerIndex + 1 === waterfallIterations,
                })}
                data-testid={`${valueKey}-instrument-simulation-${key}`}>
                <CellValue
                  value={isPercentage && typeof value === 'number' ? value * 100 : value}
                  {...(isPercentage ? enteredPercentageViewRules : amountRules)}
                />
              </td>
            );
          })
        )}
      </tr>
    );
  };

  const createInstrumentLevelRow = (
    label: translationKeys,
    valueKey: keyof InstrumentValueKeys,
    isBold = false
  ) => {
    const hasValue = (['first', 'last'] as (keyof OpmFirstLastWaterfallDto)[]).some((section) =>
      Object.entries(opmWaterfall[selectedYear][section]).some(
        ([key, _]) =>
          typeof (opmWaterfall[selectedYear][section] as FirstLastSummaryIndexDictionaryDto)[key]
            .instruments[selectedInstrument][valueKey] === 'number'
      )
    );

    if (!hasValue) {
      return null;
    }

    return (
      <tr>
        <td
          className={classNames(styles['border-right'], { [styles['bold-text']]: isBold })}
          data-testid={`${valueKey}-instrument-title`}>
          {l(label)}
        </td>
        {(['first', 'last'] as (keyof OpmFirstLastWaterfallDto)[]).map((section, outerIndex) =>
          Object.entries(opmWaterfall[selectedYear][section]).map(([key, _], innerIndex) => {
            const value = (
              opmWaterfall[selectedYear][section] as FirstLastSummaryIndexDictionaryDto
            )[key].instruments[selectedInstrument][valueKey];

            return (
              <td
                key={`${section}-${key}`}
                className={classNames({
                  [styles['border-right']]:
                    outerIndex === 0 && innerIndex + 1 === waterfallIterations,
                })}
                data-testid={`${valueKey}-instrument-simulation-${key}`}>
                <CellValue value={value} {...amountRules} />
              </td>
            );
          })
        )}
      </tr>
    );
  };

  return (
    <>
      <tr>
        <th
          colSpan={titleColSpan}
          className={styles['section-header']}
          data-testid="instrument-title">
          {selectedInstrumentViewValue}
        </th>
      </tr>
      {shouldDisplayOtherCashflowMovement && (
        <>
          <tr>
            <th className={styles['sub-section-header']} colSpan={titleColSpan}>
              {l('_OtherCashflowMovement')}
            </th>
          </tr>
          {createInstrumentLevelRow('_Addition', 'totalAddition')}
          {createInstrumentLevelRow('_Redemption', 'totalRedemption')}
          {createInstrumentLevelRow('_CashPayInterest', 'totalCashPayInterest')}
        </>
      )}
      {instrumentHasTranches ? (
        <TrancheCalculationsErf
          opmWaterfall={opmWaterfall}
          selectedInstrument={selectedInstrument}
          selectedInstrumentViewValue={selectedInstrumentViewValue}
          selectedYear={selectedYear}
          isErfProject={isErfProject}
        />
      ) : (
        <>
          <tr>
            <th className={styles['sub-section-header']} colSpan={titleColSpan}>
              {l('_ExitDistribution')}
            </th>
          </tr>
          {isOrdinaryEquity &&
            createTrancheLevelRow('_PcParticipationTitle', 'trancheParticipationPc', true)}
          {createTrancheLevelRow('_DistributionsPaid', 'trancheDistribution')}
          {createTrancheLevelRow('_PostIPOLockInDiscount', 'postIpoDlomPc', true)}
          {createTrancheLevelRow(
            l('_NetProceedsToInstrumentName', {
              instrument: selectedInstrumentViewValue,
              interpolation: interpolationOption,
            }),
            'netDistribution'
          )}
          {createTrancheLevelRow('_RiskFreeRate', 'riskFreeRate', true)}
          {createInstrumentLevelRow('_PvOfInterimCashflowMovement', 'fairValueOfCashflowMovements')}
          {createInstrumentLevelRow(
            '_CumulativePvOfInterimCashflowMovement',
            'cumulativeFairValueOfCashflowMovements'
          )}
          {isErfProject &&
            createTrancheLevelRow(
              l('_PVOf', {
                Instrument: selectedInstrumentViewValue,
                interpolation: interpolationOption,
              }),
              'fairValue'
            )}
          {instrumentHasDloc && createTrancheLevelRow('_DLOC', 'dlocPc', true)}
          {createTrancheLevelRow('_DLOM', 'dlomPc', true)}
        </>
      )}
      <tr>
        <td
          className={classNames(
            {
              [styles['border-cell-top']]: instrumentHasTranches,
            },
            {
              [styles['bold-text']]: instrumentHasTranches,
            },
            styles['border-right']
          )}
          data-testid="mV-instrument-title">
          {l('_MVOf', {
            Instrument: selectedInstrumentViewValue,
            interpolation: interpolationOption,
          })}
        </td>
        {Object.entries(opmWaterfall[selectedYear].first).map(([key, _], index) => {
          const marketValue = (
            opmWaterfall[selectedYear].first as FirstLastSummaryIndexDictionaryDto
          )[key].instruments[selectedInstrument].discountedFairValue;
          return (
            <td
              key={`first-instrument-${key}`}
              className={classNames(
                { [styles['border-cell-top']]: instrumentHasTranches },
                { [styles['border-right']]: index + 1 === waterfallIterations }
              )}
              data-testid={`mV-instrument-simulation-${key}`}>
              <CellValue value={marketValue} alignment={Alignment.Right} {...amountRules} />
            </td>
          );
        })}
        {Object.entries(opmWaterfall[selectedYear].last).map(([key, _]) => {
          const marketValue = (
            opmWaterfall[selectedYear].last as FirstLastSummaryIndexDictionaryDto
          )[key].instruments[selectedInstrument].discountedFairValue;
          return (
            <td
              key={`last-instrument-${key}`}
              className={instrumentHasTranches ? styles['border-cell-top'] : ''}
              data-testid={`mV-instrument-simulation-${key}`}>
              <CellValue value={marketValue} alignment={Alignment.Right} {...amountRules} />
            </td>
          );
        })}
      </tr>
      {instrumentHasTranches && (
        <>
          {createInstrumentLevelRow(
            '_PvOfInterimCashflowMovement',
            'fairValueOfCashflowMovements',
            true
          )}
          {createInstrumentLevelRow(
            '_CumulativePvOfInterimCashflowMovement',
            'cumulativeFairValueOfCashflowMovements',
            true
          )}
        </>
      )}
    </>
  );
};

export default InstrumentCalculationsErf;
