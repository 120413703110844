import { FC, Fragment } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { useParams } from 'react-router-dom';
import { InstrumentDefinitionDto } from '@app/shared/models/contracts/project-dto';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import CellValue from '@app/shared/components/cell-value/CellValue';
import styles from './ordinary-equity-add-redemption-section.module.scss';
import classNames from 'classnames';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { InstrumentType, TooltipWidth } from '@app/shared/models/contracts/enums/shared-enums';
import Button from '@app/shared/components/button/Button';
import SvgWarningStatus from '@app/shared/icons/WarningStatus';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { enumKeyByValue } from '@app/shared/helpers';
import DealThesisEmptyHistoricDataColumns from '../../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';
import { Pwerm2InputYearOrdinaryEquityInstrumentCalculationResultsDto } from '@app/shared/models/contracts/pwerm2-input-calculation-results-dto';
import { selectCapitalStructure } from '@app/core/store/capital-structure-slice-selectors';

const OrdinaryEquityAdditionRedemptionSection: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);

  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);

  const ordinaryEquityInstruments: { [key: string]: InstrumentDefinitionDto } = Object.entries(
    capitalStructure.instrumentDefinitions
  )
    .filter(
      ([_, value]) => value.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
    )
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as { [key: string]: InstrumentDefinitionDto });

  // TO BE REPLACED ON HOW ITS BEING DONE ON OPM CASE SELECTOR
  const capitalStructureKey = Object.keys(buildStructures)?.[0];
  const isErfProject = buildStructures?.[capitalStructureKey].isErf;
  if (!isErfProject) {
    return <></>;
  }

  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId)!;
  const numberOfInputForecastYears = caseData?.forecasts.length ?? 0;
  const caseResults = results.cases[caseId!];
  const caseForecasts = Object.entries(caseResults.forecastDates)
    .map(([forecastId, value]) => ({
      forecastId,
      ...value,
    }))
    .slice(0, numberOfInputForecastYears);

  const numberOfInputHistoricForecastYears = project.pwermInput.historicForecasts.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicForecasts = Object.entries(caseResults.historicDates.historicForecastYears ?? {})
    .map(([historicYears, value]) => ({
      historicYears,
      ...value,
    }))
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicEventsData = Object.entries(caseResults.historicDates.historicEventsYears ?? {})
    .map(([historicEventsYear, value]) => ({
      historicEventsYear,
      ...value,
    }))
    .reverse();
  const maxHistoricalDataLength = Math.max(historicForecasts.length, historicEventsData.length);

  const maxColspanCount =
    (caseForecasts ? caseForecasts.length : 0) +
    maxHistoricalDataLength +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const renderTooltipContent = (
    instruments: Dictionary<Pwerm2InputYearOrdinaryEquityInstrumentCalculationResultsDto>,
    instrumentId: string
  ) => (
    <>
      <table>
        <thead>
          <tr className="table-primary__row--plain">
            <th>
              <strong>{l('_EventDate')}</strong>
            </th>
            <th>
              <strong>{l('_AdditionOrRedemption')}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {instruments[instrumentId].periodByEvent.map((event) => {
            if (event.additions === 0) {
              return null;
            }
            return (
              <tr className="table-primary--no-__row--plain" key={`${instrumentId}-${event.date}`}>
                <td>{event.date}</td>
                <td>
                  <CellValue alignment={Alignment.Right} {...amountRules} value={event.additions} />
                </td>
              </tr>
            );
          })}
          <tr>
            <th className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
              <strong>{l('_Total')}</strong>
            </th>
            <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
              <CellValue
                alignment={Alignment.Right}
                {...amountRules}
                value={instruments[instrumentId]?.periodTotal?.additions}
                strong
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const renderTableRowsCell = (
    instruments: Dictionary<Pwerm2InputYearOrdinaryEquityInstrumentCalculationResultsDto>,
    instrumentId: string,
    index?: number
  ) => {
    const needsTooltip =
      instruments[instrumentId]?.periodByEvent.filter((event) => event.additions !== 0).length > 1;

    return (
      <td
        key={index !== undefined ? `${instrumentId}-${index}` : 'oe-valuation-total'}
        className={classNames('', {
          [styles['non-existent-instrument-value']]: instruments && !(instrumentId in instruments),
        })}>
        {instruments &&
          instrumentId in instruments &&
          instruments[instrumentId]?.periodTotal !== null &&
          instruments[instrumentId]?.periodTotal?.additions !== 0 && (
            <>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <CellValue
                  {...amountRules}
                  alignment={Alignment.Right}
                  value={instruments[instrumentId]?.periodTotal?.additions}
                />
                {needsTooltip && (
                  <Tooltip
                    placement={TooltipPlacement.Top}
                    width={`${TooltipWidth.OrdinaryEquityDealThesisSection}px`}
                    content={renderTooltipContent(instruments, instrumentId)}>
                    <Button
                      className={styles['tooltip-button']}
                      startIcon={<SvgWarningStatus />}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.SMALL}
                    />
                  </Tooltip>
                )}
              </div>
            </>
          )}
      </td>
    );
  };

  const calculateTotalAdditions = (
    instruments: Dictionary<Pwerm2InputYearOrdinaryEquityInstrumentCalculationResultsDto>
  ) => {
    return instruments
      ? Object.values(instruments).reduce((acc, value) => {
          if (value.periodTotal !== null && value.periodTotal.additions !== 0) {
            acc += value.periodTotal.additions;
          }
          return acc;
        }, 0)
      : null;
  };

  const renderTableTotalsCell = (totalAdditions: number | null, index?: number) => (
    <td
      className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator"
      key={index !== undefined ? `${index}` : 'oe-valuation-total'}>
      <CellValue
        {...amountRules}
        alignment={Alignment.Right}
        value={totalAdditions !== 0 ? totalAdditions : null}
        strong
      />
    </td>
  );

  return (
    <tbody>
      <tr>
        <th className="table-primary__cell--header" colSpan={maxColspanCount}>
          {l('_OrdinaryEquityInstruments')}
        </th>
      </tr>
      <tr className="table-primary__row--header">
        <th
          className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator"
          colSpan={maxColspanCount}>
          <strong>{l('_AdditionOrRedemption')}</strong>
        </th>
      </tr>
      {Object.entries(ordinaryEquityInstruments).map(([instrumentId, instrument]) => {
        const isInstrumentPresentAcrossYears = Object.values(caseForecasts).some(
          (f) => instrumentId in f.ordinaryInstruments
        );
        if (!isInstrumentPresentAcrossYears) {
          return <Fragment key={instrumentId} />;
        }
        return (
          <tr key={instrumentId}>
            <th className="table-primary__cell--vertical-separator">
              {instrument.instrumentNarrative}
            </th>
            <DealThesisEmptyHistoricDataColumns isHistoricEventsSection />
            {showHistoricDataColumns &&
              historicEventsData.map((historicEventYear, index) =>
                renderTableRowsCell(historicEventYear.ordinaryInstruments, instrumentId, index)
              )}
            <td />
            {caseForecasts.map((forecast, index) =>
              renderTableRowsCell(forecast.ordinaryInstruments, instrumentId, index)
            )}
            <td />
          </tr>
        );
      })}
      <tr className="table-primary__row--plain">
        <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
          <strong>{l('_Total')}</strong>
        </th>
        <DealThesisEmptyHistoricDataColumns isHistoricEventsSection isStartSeparator />
        {showHistoricDataColumns &&
          historicEventsData.map((historicEventYear, index) => {
            const totalAdditions = calculateTotalAdditions(historicEventYear.ordinaryInstruments);
            return renderTableTotalsCell(totalAdditions, index);
          })}
        <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator" />
        {caseForecasts.map((forecast, index) => {
          const totalAdditions = calculateTotalAdditions(forecast.ordinaryInstruments);
          return renderTableTotalsCell(totalAdditions, index);
        })}
        <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator" />
      </tr>
    </tbody>
  );
};

export default OrdinaryEquityAdditionRedemptionSection;
