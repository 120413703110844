import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import styles from './base-modal.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import SvgXMark from '@app/shared/icons/XMark';
import { useLocale } from '@app/core/hooks/useLocale';

// https://github.com/reactjs/react-modal/issues/632
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

interface BaseModalProps extends Modal.Props {
  buttonCancelLabel?: string;
  buttonConfirmLabel?: string;
  children?: ReactNode;
  size: ModalSize;
  className?: string;
  title?: string;
  blockedUrl?: string;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  preventModalFullHeight?: boolean;

  onClick?: (event: React.MouseEvent<any> | React.KeyboardEvent<any>) => void;
  onCloseButtonClick: (event: React.MouseEvent<any> | React.KeyboardEvent<any>) => void;
  onConfirm?: (event?: React.MouseEvent<any> | React.KeyboardEvent<any>) => void;
  onCancel?: (event?: React.MouseEvent<any> | React.KeyboardEvent<any>) => void;
}

const PARENT_CLASSNAME = 'modal-base';
const BaseModal: React.FC<BaseModalProps> = ({
  buttonCancelLabel = 'Cancel',
  buttonConfirmLabel = 'OK',
  children,
  onCloseButtonClick,
  onCancel,
  onConfirm,
  blockedUrl,
  title,
  size,
  ignoreReadOnly = false,
  disabled = false,
  preventModalFullHeight = false,
  onClick,
  ...props
}) => {
  const { l } = useLocale();

  useEffect(() => {
    const modalContent = document.querySelector('.ReactModal__Content');
    if (modalContent && onClick) {
      const handleClick = (event: Event) => onClick(event as unknown as React.MouseEvent<any>);
      modalContent.addEventListener('click', handleClick);
      return () => modalContent.removeEventListener('click', handleClick);
    }
  }, [onClick]);

  if (blockedUrl?.startsWith('/projects/')) {
    return null;
  }

  return (
    <Modal
      ariaHideApp={process.env.NODE_ENV !== 'test'} // https://github.com/reactjs/react-modal/issues/632
      bodyOpenClassName="ui-modal-open"
      className={classNames(styles[`${PARENT_CLASSNAME}__inner`], {
        [styles[`${PARENT_CLASSNAME}__inner--without-title`]]: !title,
        [styles[`${PARENT_CLASSNAME}__inner--prevent-full-height`]]: preventModalFullHeight,
      })}
      onRequestClose={(event) => {
        event.stopPropagation();
        onCancel && onCancel(event);
      }}
      overlayClassName={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--size-${size}`]]: size,
      })}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      {...props}>
      <div className={styles[`${PARENT_CLASSNAME}__body`]}>
        <header className={styles[`${PARENT_CLASSNAME}__header`]}>
          <h2 className={styles[`${PARENT_CLASSNAME}__title`]} title={title}>
            {title}
          </h2>
          <Button
            data-testid="modal-close"
            className={styles[`${PARENT_CLASSNAME}__close-button`]}
            aria-label={l('_CloseModal')}
            appearance={ButtonAppearance.CLEAN}
            onClick={(event) => {
              event.stopPropagation();
              onCloseButtonClick(event);
            }}
            ignoreReadOnly={ignoreReadOnly}
            disabled={disabled}>
            <SvgXMark />
          </Button>
        </header>

        <div className={styles[`${PARENT_CLASSNAME}__body-inner`]}>{children}</div>
        {(onConfirm || onCancel) && (
          <footer className={styles[`${PARENT_CLASSNAME}__footer`]}>
            <>
              {onConfirm && (
                <Button
                  data-testid="modal-confirm"
                  onClick={(event) => {
                    event.stopPropagation();
                    onConfirm && onConfirm(event);
                  }}
                  appearance={ButtonAppearance.DEFAULT_SECONDARY}>
                  {buttonConfirmLabel}
                </Button>
              )}
              {onCancel && (
                <Button
                  data-testid="modal-cancel"
                  appearance={ButtonAppearance.DEFAULT_PRIMARY}
                  onClick={(event) => {
                    event.stopPropagation();
                    onCancel && onCancel(event);
                  }}>
                  {buttonCancelLabel}
                </Button>
              )}
            </>
          </footer>
        )}
      </div>
    </Modal>
  );
};

export default BaseModal;
