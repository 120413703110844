import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import FormCheckbox, {
  FormCheckboxOption,
} from '@app/shared/components/form-controls/form-checkbox/FormCheckbox';
import { getRequiredValidator } from '@app/core/validations/hook-forms/validators';
import { useLocale } from '@app/core/hooks/useLocale';
import { cloneDeep } from '@app/shared/helpers';
import { findSelectedCases, mapBuildStructureErrorToMessage } from '../utils/EventBuilderHelpers';
import { calculateBuildStructure } from '@app/core/store/capital-structure-slice';
import { CapitalStructureEventFaults } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { CapitalStructureEventSeverity } from '@app/shared/models/contracts/enums/shared-enums';
import '../event-form-modal-module.scss';
import store from '@app/core/store/store';
import * as notificationActions from '@app/core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { selectCapitalStructure } from '@app/core/store/capital-structure-slice-selectors';

interface CopyEventModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  eventId: string;
  eventDate: string;
  eventNarrative: string;
}

interface CopyEventFormDto {
  selectedCases: string[];
}

const PARENT_CLASSNAME = 'form';
export const CopyEventFormModal: FC<CopyEventModalProps> = ({
  isOpen,
  setIsOpen,
  eventId,
  eventDate,
  eventNarrative,
}): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const [faults, setFaults] = useState<CapitalStructureEventFaults[]>([]);
  const [caseEventSetErrorPairings, setCaseEventSetErrorPairings] = useState<{
    [key: string]: string;
  }>({});

  const options: FormCheckboxOption[] = project.pwermInput.cases.map((cases) => ({
    viewValue: cases.narrative,
    value: cases.caseId,
    checked: findSelectedCases(project, eventId, capitalStructureKey).includes(
      cases.eventSetId ?? ''
    ),
  }));

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: {
      selectedCases: findSelectedCases(project, eventId, capitalStructureKey),
    },
  });

  useEffect(() => {
    const newDefaultValues = {
      selectedCases: findSelectedCases(project, eventId, capitalStructureKey),
    };
    formMethods.reset(newDefaultValues);
  }, [capitalStructureKey, eventId, formMethods, isOpen, project]);

  useEffect(() => {
    setFaults([]);
  }, [isOpen]);

  const formSubmitHandler: SubmitHandler<CopyEventFormDto> = async (data) => {
    const { selectedCases } = data;
    let cases = cloneDeep(project.pwermInput.cases);
    let eventSets = cloneDeep(capitalStructure.eventSets);

    const originallySelectedCases = findSelectedCases(project, eventId, capitalStructureKey);
    const removedCases = originallySelectedCases.filter(
      (caseId) => !selectedCases.includes(caseId)
    );
    const addedCases = selectedCases.filter((caseId) => !originallySelectedCases.includes(caseId));

    removedCases.forEach((caseId) => {
      const previousEventSetId = cases.find((cases) => cases.caseId === caseId)?.eventSetId;
      const newEventSetId = previousEventSetId
        ? eventSets[previousEventSetId].events.length === 1
          ? null
          : uuidv4()
        : null;
      cases = cases.map((cases) => {
        if (cases.caseId === caseId) {
          return {
            ...cases,
            eventSetId: newEventSetId,
          };
        }
        return cases;
      });
      if (newEventSetId) {
        eventSets = {
          ...eventSets,
          [newEventSetId]: {
            events: previousEventSetId
              ? eventSets[previousEventSetId].events.filter((event: string) => event !== eventId)
              : [],
          },
        };
      }
    });
    addedCases.forEach((caseId) => {
      const previousEventSetId = cases.find((cases) => cases.caseId === caseId)?.eventSetId;
      const newEventSetId = uuidv4();
      cases = cases.map((cases) => {
        if (cases.caseId === caseId) {
          return {
            ...cases,
            eventSetId: newEventSetId,
          };
        }
        return cases;
      });
      eventSets = {
        ...eventSets,
        [newEventSetId]: {
          events: previousEventSetId
            ? [...eventSets[previousEventSetId].events, eventId]
            : [eventId],
        },
      };
    });

    const result = await dispatch(
      calculateBuildStructure({
        project: {
          ...project,
          capitalStructures: {
            ...project.capitalStructures,
            [capitalStructureKey]: {
              ...capitalStructure,
              eventSets: eventSets,
            },
          },
          pwermInput: {
            ...project.pwermInput,
            cases: cases,
          },
        },
      })
    ).unwrap();

    if (result.hasErrors) {
      const errors = result.buildStructure.buildStructures[capitalStructureKey].faults.filter(
        (f) => f.severity === CapitalStructureEventSeverity.Error
      );
      const errorsWithoutReferenceToEvents = errors.filter((e) => !e.event);
      const caseEventSetErrorPairings = errors.reduce((acc, error) => {
        const eventSetId = error.eventSet;
        const caseId = cases.find((c) => c.eventSetId === eventSetId)?.caseId;
        return {
          ...acc,
          [caseId!]: eventSetId,
        };
      }, {});
      setFaults(errorsWithoutReferenceToEvents);
      setCaseEventSetErrorPairings(caseEventSetErrorPairings);
    } else {
      setFaults([]);
      setCaseEventSetErrorPairings({});
      setIsOpen(false);
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.success,
          message: l('_EventCopiedSuccessfully', { eventNarrative }),
          autoHide: true,
        })
      );
    }
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      title={l('_AssignEvent')}
      isOpen={isOpen}
      onCloseButtonClick={() => setIsOpen(false)}>
      <FormProvider {...formMethods}>
        <form
          data-testid="copy-event-form"
          onSubmit={formMethods.handleSubmit(formSubmitHandler)}
          onBlur={() => formMethods.trigger()}>
          <FormCheckbox
            name="selectedCases"
            label={l('_SelectCases')}
            rules={{
              ...getRequiredValidator(),
            }}
            options={options}
          />
          {faults.length > 0 && (
            <div className={`${PARENT_CLASSNAME}__errors`}>
              {l('_CannotAssignEventDueToTheFollowingErrors')}
              <ul>
                {faults.map((f, i) => {
                  const caseId = Object.keys(caseEventSetErrorPairings).find(
                    (key) => caseEventSetErrorPairings[key] === f.eventSet
                  );
                  const caseNarrative = project.pwermInput.cases.find(
                    (c) => c.caseId === caseId
                  )?.narrative;
                  return (
                    <li key={f.errorCode + i}>
                      {mapBuildStructureErrorToMessage(f, { caseNarrative, eventDate })}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className={`${PARENT_CLASSNAME}__actions`}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              data-testid="cancel-button"
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(false);
              }}>
              {l('_Cancel')}
            </Button>
            <Button
              type="submit"
              data-testid="submit-button"
              appearance={ButtonAppearance.DEFAULT_PRIMARY}>
              {l('_Update')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  );
};
