import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC, Fragment, useEffect } from 'react';
import {
  CalcMethod,
  Denomination,
  EventKey,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import {
  currencyDenominationPair,
  enumKeyByValue,
  instrumentsByTypeSortFn,
} from '@app/shared/helpers';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  decimalCalculatedViewRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import styles from './cap-table.module.scss';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { getEquityInstrumentCountByType } from '@app/shared/helpers/equity-instruments/get-equity-instrument-count-by-type';
import classNames from 'classnames';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import { dateFormatter } from '@app/shared/formatters';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { selectCapitalStructures } from '@app/core/store/capital-structure-slice-selectors';

const PARENT_CLASSNAME = 'cap-table';

const CapTableErf: FC = () => {
  const { caseId } = useParams();
  const { l } = useLocale();

  const { capTable: tableResults, buildStructures: buildStructureResults } = useAppSelector(
    (state) => state.capitalStructure.values
  );
  const { isCapitalStructureValid, projectDraft: project } = useAppSelector(
    (state) => state.project
  );
  const capitalStructures = useAppSelector(selectCapitalStructures);
  const capitalStructureId = Object.keys(capitalStructures)[0];
  const isErfBuildStructure = buildStructureResults?.[capitalStructureId].isErf;
  const {
    details: { currency, denomination },
  } = project;
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;

  const eventSetId = isOpmOnly
    ? tableResults && Object.keys(tableResults.eventSets).length > 1
      ? EventKey.OpmOnly
      : EventKey.EmptyEventSet
    : project.pwermInput.cases.find((item) => item.caseId === caseId)?.eventSetId ??
      EventKey.EmptyEventSet;
  const caseSelectorShown =
    isErfBuildStructure && project.pwermInput.cases.length > 1 && !isOpmOnly;
  const eventSet = tableResults?.eventSets[eventSetId];
  const eventDateOptions = eventSet
    ? Object.values(eventSet?.events).map((event) => {
        const formattedDate = dateFormatter(event.eventDate, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
        return {
          value: event.eventDate,
          viewValue: `${formattedDate} - ${event.eventNarrative}`,
        };
      })
    : [];
  const defaultEventDate = eventDateOptions.length > 0 ? eventDateOptions[0].value : '';
  const formMethods = useForm<{ selectedEventDate: string }>({
    ...formConfigBase,
    defaultValues: {
      selectedEventDate: defaultEventDate,
    },
  });
  const { reset } = formMethods;
  const { selectedEventDate } = formMethods.watch();

  const isSelectedEventDateValid = eventDateOptions.some(
    (option) => option.value === selectedEventDate
  );

  useEffect(() => {
    // if the selected option is no longer relevant (i.e. event date doesn't exist for the current case)
    // then reset the selection to the default value
    if (!isSelectedEventDateValid) {
      reset();
    }
  }, [reset, isSelectedEventDateValid]);

  if (!tableResults) {
    return null;
  }

  const selectedEvent = isSelectedEventDateValid
    ? eventSet.events[selectedEventDate]
    : Object.values(eventSet.events)[0];

  const instruments = Object.entries(selectedEvent.instruments).map(([key, value]) => ({
    ...value,
    id: key,
  }));
  const sortedInstruments = instruments?.slice().sort(instrumentsByTypeSortFn) || [];

  const consistsShareHolderLoanNotes = getEquityInstrumentCountByType(
    instruments,
    InstrumentType.ShareholderLoanNotes
  );
  const consistsPreferenceShares = getEquityInstrumentCountByType(
    instruments,
    InstrumentType.PreferredShares
  );
  const consistsOrdinaryEquities = getEquityInstrumentCountByType(
    instruments,
    InstrumentType.OrdinaryEquity
  );

  const totalInvestment = selectedEvent.summary.totalInvestment;
  const instrumentTypes = selectedEvent.summary.instrumentTypes;

  const countColSpan = () => {
    return (
      (consistsOrdinaryEquities ? 1 : 0) +
      (consistsPreferenceShares ? 1 : 0) +
      (consistsShareHolderLoanNotes ? 1 : 0)
    );
  };

  if (!isCapitalStructureValid) {
    const newPath = caseId
      ? generatePath(`../../../${RouteConstants.ProjectDetails}`)
      : generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div
        className={classNames('main-container-padding-remove', {
          [styles[`${PARENT_CLASSNAME}__container`]]: caseSelectorShown,
        })}>
        <FormProvider {...formMethods}>
          <form>
            {eventDateOptions.length > 1 && (
              <div className={styles[`${PARENT_CLASSNAME}__event-date`]}>
                <span className={styles[`${PARENT_CLASSNAME}__event-date-label`]}>
                  {l('_EventDate')}
                </span>
                <div className={styles[`${PARENT_CLASSNAME}__event-date-selector`]}>
                  <FormSelect
                    name="selectedEventDate"
                    options={eventDateOptions}
                    ariaLabel={l('_SelectorAriaLabel', { label: l('_EventDate') })}
                    isGapless
                  />
                </div>
              </div>
            )}
            <table className="table-primary table-primary--zebra table-primary--framed-bottom table-primary--framed">
              <colgroup>
                <col className={styles[`${PARENT_CLASSNAME}__labels-col`]} />
                <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
                {Boolean(consistsShareHolderLoanNotes) && (
                  <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
                )}
                {Boolean(consistsPreferenceShares) && (
                  <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
                )}
                {Boolean(consistsOrdinaryEquities) && (
                  <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
                )}
                {(Object.keys(OwnerType) as Array<keyof typeof OwnerType>).map((key) => {
                  return <col key={`cap-table-col-${key}`} />;
                })}
              </colgroup>
              <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
                <tr className="table-primary__row--secondary">
                  <th className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-end-separator table-primary__cell--center table-primary__cell--align-bottom">
                    <strong>{l('_Owner')}</strong>
                  </th>
                  <th
                    className="table-primary__cell--header-secondary table-primary__cell--center table-primary__cell--section-end-separator table-primary__cell--align-bottom"
                    colSpan={1 + countColSpan()}>
                    <strong>
                      {l('_Summary')} ({currencyDenominationPair(currency, denomination)})
                    </strong>
                  </th>
                  {sortedInstruments.map((field, index) => (
                    <Fragment key={field.narrative + index + 'headers'}>
                      <th
                        className={classNames(
                          'table-primary__cell--header-secondary table-primary__cell--vertical-separator-start table-primary__cell--section-end-separator table-primary__cell--center table-primary__cell--align-bottom',
                          {
                            [styles[`${PARENT_CLASSNAME}__instrument-col`]]: true,
                          }
                        )}
                        colSpan={2}>
                        <strong>{field.narrative}</strong>
                      </th>
                    </Fragment>
                  ))}
                </tr>
                <tr className="table-primary__row--secondary">
                  <th className="table-primary__cell--header-secondary table-primary__cell--vertical-separator" />
                  <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                    <strong>{l('_Investment')}</strong>
                  </th>
                  {Boolean(consistsShareHolderLoanNotes) && (
                    <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                      <strong>{l('_SHLs')}</strong>
                    </th>
                  )}
                  {Boolean(consistsPreferenceShares) && (
                    <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                      <strong>{l('_PrefShares')}</strong>
                    </th>
                  )}
                  {Boolean(consistsOrdinaryEquities) && (
                    <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                      <strong>{l('_OrdinaryEquityTitle')}</strong>
                    </th>
                  )}
                  {sortedInstruments.map((field, index) => (
                    <Fragment key={field.narrative + index + 'headers'}>
                      <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--vertical-separator-start table-primary__cell--nowrap">
                        <CellValue
                          strong
                          alignment={Alignment.Right}
                          value={
                            denomination === enumKeyByValue(Denomination, Denomination.Units)
                              ? l('_CountSymbol')
                              : l('_CountSymbolWithDenomination', {
                                  denomination: Denomination[denomination],
                                })
                          }
                        />
                      </th>
                      <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                        <CellValue
                          strong
                          alignment={Alignment.Right}
                          value={currencyDenominationPair(currency, denomination)}
                        />
                      </th>
                    </Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                <>
                  {(Object.keys(OwnerType) as Array<keyof typeof OwnerType>).map((key) => {
                    const owner = key as keyof typeof OwnerType;
                    const ownerResults = selectedEvent.summary.totalInvestment.owners[owner];
                    const ownerTotal = selectedEvent.summary.totalInvestment.owners[owner];
                    return (
                      ownerResults !== undefined && (
                        <Fragment key={`cap-item-${key}`}>
                          <tr className="table-primary__row--secondary">
                            <th className="table-primary__cell--vertical-separator">
                              <strong>{OwnerType[key]}</strong>
                            </th>
                            <td>
                              <CellValue {...amountRules} value={ownerTotal.value} />
                            </td>
                            {Boolean(consistsShareHolderLoanNotes) && (
                              <td>
                                <CellValue
                                  {...amountRules}
                                  value={instrumentTypes.ShareholderLoanNotes.owners[owner]?.value}
                                />
                              </td>
                            )}
                            {Boolean(consistsPreferenceShares) && (
                              <td>
                                <CellValue
                                  {...amountRules}
                                  value={instrumentTypes.PreferredShares.owners[owner]?.value}
                                />
                              </td>
                            )}
                            {Boolean(consistsOrdinaryEquities) && (
                              <td>
                                <CellValue
                                  {...amountRules}
                                  value={instrumentTypes.OrdinaryEquity.owners[owner]?.value}
                                />
                              </td>
                            )}
                            {sortedInstruments.map((field, index) => {
                              const instrumentOwnerTotals =
                                selectedEvent.instruments[field.id].owners[owner];
                              return (
                                <Fragment key={field.narrative + index + OwnerType[key]}>
                                  <td className="table-primary__cell--vertical-separator-start">
                                    <CellValue
                                      {...decimalCalculatedViewRules}
                                      value={instrumentOwnerTotals?.shares}
                                    />
                                  </td>
                                  <td>
                                    <CellValue
                                      {...amountRules}
                                      value={instrumentOwnerTotals?.value}
                                    />
                                  </td>
                                </Fragment>
                              );
                            })}
                          </tr>
                          <tr className="table-primary__row--plain">
                            <th className="table-primary__cell--vertical-separator">
                              {l('_PcShareholdingTitle')}
                            </th>
                            <td>
                              <CellValue
                                {...enteredPercentageViewRules}
                                value={getCalculatedProjectValueInPercent(
                                  totalInvestment.owners[owner].valuePercentage
                                )}
                              />
                            </td>
                            {Boolean(consistsShareHolderLoanNotes) && (
                              <td>
                                <CellValue
                                  {...enteredPercentageViewRules}
                                  value={getCalculatedProjectValueInPercent(
                                    instrumentTypes.ShareholderLoanNotes.owners[owner]
                                      ?.valuePercentage
                                  )}
                                />
                              </td>
                            )}
                            {Boolean(consistsPreferenceShares) && (
                              <td>
                                <CellValue
                                  {...enteredPercentageViewRules}
                                  value={getCalculatedProjectValueInPercent(
                                    instrumentTypes.PreferredShares.owners[owner]?.valuePercentage
                                  )}
                                />
                              </td>
                            )}
                            {Boolean(consistsOrdinaryEquities) && (
                              <td>
                                <CellValue
                                  {...enteredPercentageViewRules}
                                  value={getCalculatedProjectValueInPercent(
                                    instrumentTypes.OrdinaryEquity.owners[owner]?.valuePercentage
                                  )}
                                />
                              </td>
                            )}
                            {sortedInstruments.map((field, index) => {
                              const instrumentOwnerTotals =
                                selectedEvent.instruments[field.id].owners[owner];
                              return (
                                <Fragment key={field.id + index + OwnerType[key]}>
                                  <td className="table-primary__cell--vertical-separator-start">
                                    <CellValue
                                      {...enteredPercentageViewRules}
                                      value={getCalculatedProjectValueInPercent(
                                        instrumentOwnerTotals?.sharePercentage
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <CellValue
                                      {...enteredPercentageViewRules}
                                      value={getCalculatedProjectValueInPercent(
                                        instrumentOwnerTotals?.valuePercentage
                                      )}
                                    />
                                  </td>
                                </Fragment>
                              );
                            })}
                          </tr>
                          <tr className="table-primary__row--plain">
                            <td className="table-primary__cell--vertical-separator  table-primary__cell--section-separator" />
                            <td
                              className="table-primary__cell--section-separator"
                              colSpan={1 + countColSpan()}
                            />
                            {sortedInstruments.map((field, index) => (
                              <td
                                key={'spacing-row' + index}
                                colSpan={2}
                                className="table-primary__cell--empty-spacer table-primary__cell--vertical-separator-start  table-primary__cell--section-separator"
                              />
                            ))}
                          </tr>
                        </Fragment>
                      )
                    );
                  })}
                </>
                <tr>
                  <th className="table-primary__cell--vertical-separator table-primary__cell--highlight table-primary__cell--section-start-separator">
                    <strong>{l('_Total')}</strong>
                  </th>
                  <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                    <CellValue {...amountRules} value={totalInvestment.total.value} />
                  </td>
                  {Boolean(consistsShareHolderLoanNotes) && (
                    <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                      <CellValue
                        {...amountRules}
                        value={instrumentTypes.ShareholderLoanNotes.total.value}
                      />
                    </td>
                  )}
                  {Boolean(consistsPreferenceShares) && (
                    <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                      <CellValue
                        {...amountRules}
                        value={instrumentTypes.PreferredShares.total.value}
                      />
                    </td>
                  )}
                  {Boolean(consistsOrdinaryEquities) && (
                    <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                      <CellValue
                        {...amountRules}
                        value={instrumentTypes.OrdinaryEquity.total.value}
                      />
                    </td>
                  )}
                  {sortedInstruments.map((field, index) => {
                    const instrumentTotals = selectedEvent.instruments[field.id].total;
                    return (
                      <Fragment key={field.narrative + index + 'numOfShares'}>
                        <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator table-primary__cell--vertical-separator-start">
                          <CellValue
                            {...decimalCalculatedViewRules}
                            value={instrumentTotals.shares}
                          />
                        </td>
                        <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                          <CellValue {...amountRules} value={instrumentTotals.value} />
                        </td>
                      </Fragment>
                    );
                  })}
                </tr>
                <tr>
                  <th className="table-primary__cell--vertical-separator" />
                  <td />
                  {Boolean(consistsShareHolderLoanNotes) && (
                    <td className="table-primary__cell--strong">
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          instrumentTypes.ShareholderLoanNotes.total.valuePercentage
                        )}
                      />
                    </td>
                  )}
                  {Boolean(consistsPreferenceShares) && (
                    <td className="table-primary__cell--strong">
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          instrumentTypes.PreferredShares.total.valuePercentage
                        )}
                      />
                    </td>
                  )}
                  {Boolean(consistsOrdinaryEquities) && (
                    <td className="table-primary__cell--strong">
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          instrumentTypes.OrdinaryEquity.total.valuePercentage
                        )}
                      />
                    </td>
                  )}
                  {sortedInstruments.map((field, index) => {
                    const instrumentTotals = selectedEvent.instruments[field.id].total;
                    return (
                      <Fragment key={field.narrative + index + InstrumentType[field.type]}>
                        <td className="table-primary__cell--strong table-primary__cell--vertical-separator-start">
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              instrumentTotals.sharePercentage
                            )}
                          />
                        </td>
                        <td className="table-primary__cell--strong table-primary--section-end-separator">
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              instrumentTotals.valuePercentage
                            )}
                          />
                        </td>
                      </Fragment>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default CapTableErf;
