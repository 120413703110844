import { OwnerType, TargetMetric } from '@app/shared/models/contracts/enums/shared-enums';
import {
  Pwerm2CalculationCostOfEquityCaseSummaryCaseDto,
  Pwerm2CalculationCostOfEquityCaseSummaryDebtInstrumentDto,
  Pwerm2CalculationCostOfEquityInstrumentCaseYearDebtInstrumentDto,
  Pwerm2CalculationCostOfEquityInstrumentCaseYearDto,
  Pwerm2CalculationCostOfEquityInstrumentCaseYearTrancheDto,
  Pwerm2CalculationCostOfEquityTotalAttributableEquityYearDto,
  Pwerm2CalculationResultsDto,
  Pwerm2CalculationWaterfallCaseDto,
  Pwerm2CalculationWaterfallCaseInstrumentDto,
  Pwerm2CalculationWaterfallCaseInstrumentTrancheDto,
  Pwerm2CalculationWaterfallCaseYearDto,
  Pwerm2CalculationWaterfallCaseYearInstrumentDto,
  Pwerm2CalculationWaterfallCaseYearInstrumentTrancheConditionDto,
  Pwerm2CalculationWaterfallCaseYearInstrumentTrancheDto,
  Pwerm2CalculationWaterfallCaseYearMultipleDto,
  Pwerm2CalculationWaterfallCaseYearMultipleInstrumentDto,
  Pwerm2CalculationWaterfallCaseYearMultipleInstrumentTrancheDto,
  Pwerm2CalculationWaterfallInstrumentDto,
  Pwerm2CalculationWaterfallInstrumentTrancheDto,
} from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import {
  Pwerm2InputCalculationResultsDto,
  Pwerm2InputCaseCalculationResultsDto,
  Pwerm2InputsHistoricEventsCalculationResultsDto,
  Pwerm2InputsHistoricYearCalculationResultsDto,
  Pwerm2InputYearCalculationResultsDto,
} from '@app/shared/models/contracts/pwerm2-input-calculation-results-dto';

// Waterfall selectors
export const getWaterfallCaseValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  valueSelector: (caseItem: Pwerm2CalculationWaterfallCaseDto) => number | null
) => valueSelector(calculationResults.waterfall.cases[caseId]);

export const getWaterfallCaseYearValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  valueSelector: (year: Pwerm2CalculationWaterfallCaseYearDto) => number | boolean | null
) => valueSelector(calculationResults.waterfall.cases[caseId].years[yearId]);

export const getWaterfallCaseYearInstrumentValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  instrumentId: string,
  valueSelector: (instrument: Pwerm2CalculationWaterfallCaseYearInstrumentDto) => number
) => {
  if (instrumentId in calculationResults.waterfall.cases[caseId].years[yearId].instruments) {
    return valueSelector(
      calculationResults.waterfall.cases[caseId].years[yearId].instruments[instrumentId]
    );
  }

  return null;
};

export const getWaterfallCaseYearInstrumentTrancheValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  instrumentId: string,
  trancheId: string,
  valueSelector: (tranche: Pwerm2CalculationWaterfallCaseYearInstrumentTrancheDto) => number
) => {
  if (instrumentId in calculationResults.waterfall.cases[caseId].years[yearId].instruments) {
    return valueSelector(
      calculationResults.waterfall.cases[caseId].years[yearId].instruments[instrumentId].tranches[
        trancheId
      ]
    );
  }

  return null;
};

export const getWaterfallCaseYearInstrumentTrancheConditionValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  instrumentId: string,
  trancheId: string,
  conditionMetric: keyof typeof TargetMetric,
  valueSelector: (
    condition: Pwerm2CalculationWaterfallCaseYearInstrumentTrancheConditionDto
  ) => number
) => {
  if (instrumentId in calculationResults.waterfall.cases[caseId].years[yearId].instruments) {
    const metricToFind = conditionMetric;

    const conditions =
      calculationResults.waterfall.cases[caseId].years[yearId].instruments[instrumentId].tranches[
        trancheId
      ].conditions;

    const condition = conditions.find((condition) => condition.metric === metricToFind);

    return valueSelector(condition!);
  }

  return null;
};

export const getWaterfallCaseYearMultipleValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  multipleId: string,
  valueSelector: (multiple: Pwerm2CalculationWaterfallCaseYearMultipleDto) => number
) => valueSelector(calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId]);

export const getWaterfallCaseYearMultipleInstrumentValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  multipleId: string,
  instrumentId: string,
  valueSelector: (instrument: Pwerm2CalculationWaterfallCaseYearMultipleInstrumentDto) => number
) => {
  if (
    instrumentId in
    calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId].instruments
  ) {
    return valueSelector(
      calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId].instruments[
        instrumentId
      ]
    );
  }

  return null;
};

export const getWaterfallCaseYearMultipleInstrumentTrancheValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  multipleId: string,
  instrumentId: string,
  trancheId: string,
  valueSelector: (
    tranche: Pwerm2CalculationWaterfallCaseYearMultipleInstrumentTrancheDto
  ) => number | boolean
) => {
  if (
    instrumentId in
    calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId].instruments
  ) {
    return valueSelector(
      calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId].instruments[
        instrumentId
      ].tranches[trancheId]
    );
  }

  return null;
};

export const getWaterfallCaseInstrumentValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  instrumentId: string,
  valueSelector: (instrument: Pwerm2CalculationWaterfallCaseInstrumentDto) => number
) => valueSelector(calculationResults.waterfall.cases[caseId].instruments[instrumentId]);

export const getWaterfallCaseInstrumentTrancheValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  instrumentId: string,
  trancheId: string,
  valueSelector: (tranche: Pwerm2CalculationWaterfallCaseInstrumentTrancheDto) => number
) =>
  valueSelector(
    calculationResults.waterfall.cases[caseId].instruments[instrumentId].tranches[trancheId]
  );

export const getWaterfallInstrumentValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  valueSelector: (instrument: Pwerm2CalculationWaterfallInstrumentDto) => number
) => valueSelector(calculationResults.waterfall.instruments[instrumentId]);

export const getWaterfallInstrumentTrancheValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  trancheId: string,
  valueSelector: (tranche: Pwerm2CalculationWaterfallInstrumentTrancheDto) => number
) => valueSelector(calculationResults.waterfall.instruments[instrumentId].tranches[trancheId]);

export const getWaterfallCaseYearInstrumentFirstTrancheValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  instrumentId: string,
  valueSelector: (tranche: Pwerm2CalculationWaterfallCaseYearInstrumentTrancheDto) => number
) => {
  if (instrumentId in calculationResults.waterfall.cases[caseId].years[yearId].instruments) {
    return valueSelector(
      calculationResults.waterfall.cases[caseId].years[yearId].instruments[instrumentId].tranches[
        Object.keys(
          calculationResults.waterfall.cases[caseId].years[yearId].instruments[instrumentId]
            .tranches
        )[0]
      ]
    );
  }

  return null;
};

export const getWaterfallCaseYearMultipleInstrumentFirstTrancheValue = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  yearId: string,
  multipleId: string,
  instrumentId: string,
  valueSelector: (tranche: Pwerm2CalculationWaterfallCaseYearMultipleInstrumentTrancheDto) => number
) => {
  if (
    instrumentId in
    calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId].instruments
  ) {
    return valueSelector(
      calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId].instruments[
        instrumentId
      ].tranches[
        Object.keys(
          calculationResults.waterfall.cases[caseId].years[yearId].multiples[multipleId]
            .instruments[instrumentId].tranches
        )[0]
      ]
    );
  }

  return null;
};

// Cost of Equity selectors
export const getCaseSummariesCaseYear = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  forecastId: string,
  valueSelector: (year: Pwerm2CalculationCostOfEquityCaseSummaryCaseDto) => number
) => valueSelector(calculationResults.costOfEquity.caseSummaries[caseId].years[forecastId]);

export const getCaseSummariesCaseInitial = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  valueSelector: (initial: Pwerm2CalculationCostOfEquityCaseSummaryCaseDto) => number
) => valueSelector(calculationResults.costOfEquity.caseSummaries[caseId].initial);

export const getCaseSummariesCaseYearDebtInstrument = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  forecastId: string,
  debtInstrumentId: string,
  valueSelector: (instrument: Pwerm2CalculationCostOfEquityCaseSummaryDebtInstrumentDto) => number
) => {
  if (
    debtInstrumentId in
    calculationResults.costOfEquity.caseSummaries[caseId].years[forecastId].debtInstruments
  ) {
    return valueSelector(
      calculationResults.costOfEquity.caseSummaries[caseId].years[forecastId].debtInstruments[
        debtInstrumentId
      ]
    );
  }

  return null;
};

export const getCaseSummariesCaseInitialDebtInstrument = (
  calculationResults: Pwerm2CalculationResultsDto,
  caseId: string,
  debtInstrumentId: string,
  valueSelector: (instrument: Pwerm2CalculationCostOfEquityCaseSummaryDebtInstrumentDto) => number
) => {
  if (
    debtInstrumentId in
    calculationResults.costOfEquity.caseSummaries[caseId].initial.debtInstruments
  ) {
    return valueSelector(
      calculationResults.costOfEquity.caseSummaries[caseId].initial.debtInstruments[
        debtInstrumentId
      ]
    );
  }

  return null;
};

export const getInstrumentsCaseYear = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  forecastId: string,
  valueSelector: (year: Pwerm2CalculationCostOfEquityInstrumentCaseYearDto) => number
) =>
  valueSelector(
    calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].years[forecastId]
  );

export const getInstrumentsCaseInitial = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  valueSelector: (initial: Pwerm2CalculationCostOfEquityInstrumentCaseYearDto) => number
) => valueSelector(calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].initial);

export const getInstrumentCaseYearTranche = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  forecastId: string,
  trancheId: string,
  valueSelector: (tranche: Pwerm2CalculationCostOfEquityInstrumentCaseYearTrancheDto) => number
) =>
  valueSelector(
    calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].years[forecastId]
      .tranches[trancheId]
  );

export const getInstrumentCaseYearFirstTranche = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  forecastId: string,
  valueSelector: (tranche: Pwerm2CalculationCostOfEquityInstrumentCaseYearTrancheDto) => number
) =>
  valueSelector(
    calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].years[forecastId]
      .tranches[
      Object.keys(
        calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].years[forecastId]
          .tranches
      )[0]
    ]
  );

export const getInstrumentCaseInitialTranche = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  trancheId: string,
  valueSelector: (tranche: Pwerm2CalculationCostOfEquityInstrumentCaseYearTrancheDto) => number
) =>
  valueSelector(
    calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].initial.tranches[
      trancheId
    ]
  );

export const getInstrumentCaseInitialFirstTranche = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  valueSelector: (tranche: Pwerm2CalculationCostOfEquityInstrumentCaseYearTrancheDto) => number
) =>
  valueSelector(
    calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].initial.tranches[
      Object.keys(
        calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].initial.tranches
      )[0]
    ]
  );

export const getInstrumentCaseYearDebtInstrument = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  forecastId: string,
  debtInstrumentId: string,
  valueSelector: (
    debtInstrument: Pwerm2CalculationCostOfEquityInstrumentCaseYearDebtInstrumentDto
  ) => number
) => {
  if (
    debtInstrumentId in
    calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].years[forecastId]
      .debtInstruments
  ) {
    return valueSelector(
      calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].years[forecastId]
        .debtInstruments[debtInstrumentId]
    );
  }

  return null;
};

export const getInstrumentCaseInitialDebtInstrument = (
  calculationResults: Pwerm2CalculationResultsDto,
  instrumentId: string,
  caseId: string,
  debtInstrumentId: string,
  valueSelector: (
    debtInstrument: Pwerm2CalculationCostOfEquityInstrumentCaseYearDebtInstrumentDto
  ) => number
) => {
  if (
    debtInstrumentId in
    calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].initial.debtInstruments
  ) {
    return valueSelector(
      calculationResults.costOfEquity.instruments[instrumentId].cases[caseId].initial
        .debtInstruments[debtInstrumentId]
    );
  }

  return null;
};

export const getTotalAttributableEquityYears = (
  calculationResults: Pwerm2CalculationResultsDto,
  forecastId: string,
  valueSelector: (years: Pwerm2CalculationCostOfEquityTotalAttributableEquityYearDto) => number
) =>
  valueSelector(
    calculationResults.costOfEquity.totalAttributableEquity.years.find(
      (year) => year.exitDate === forecastId
    )!
  );

// Deal Thesis Input selectors
export const getDealThesisInputTotalCaseProbability = (
  calculationResults: Pwerm2InputCalculationResultsDto
) => calculationResults.totalCaseProbability;

export const getDealThesisInputCaseValue = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  valueSelector: (caseItem: Pwerm2InputCaseCalculationResultsDto) => number
) => valueSelector(calculationResults.cases[caseId]);

export const getDealThesisInputCaseValuationDate = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  valueSelector: (caseItem: Pwerm2InputYearCalculationResultsDto) => number
) => valueSelector(calculationResults.cases[caseId].valuationDate);

export const getDealThesisInputCaseValuationDateShareholderDebtInstrumentsOwner = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  instrumentId: string,
  owner: OwnerType
) =>
  calculationResults.cases[caseId].valuationDate.shareholderDebtInstruments[instrumentId]
    .closingBalanceByOwner[owner];

export const getDealThesisInputCaseForecastDate = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  forecastDate: string,
  valueSelector: (caseItem: Pwerm2InputYearCalculationResultsDto) => number | null
) => valueSelector(calculationResults.cases[caseId].forecastDates[forecastDate]);

export const getDealThesisInputCaseHistoricForecastDate = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  historicForecastDate: string,
  valueSelector: (caseItem: Pwerm2InputsHistoricYearCalculationResultsDto) => number | null
) => {
  const caseData = calculationResults.cases?.[caseId];
  const historicDates = caseData?.historicDates;
  const historicYearData = historicDates?.historicForecastYears?.[historicForecastDate];

  if (historicYearData) {
    return valueSelector(historicYearData);
  }

  return null;
};

export const getDealThesisInputCaseHistoricEventsDate = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  historicEventsDate: string,
  valueSelector: (caseItem: Pwerm2InputsHistoricEventsCalculationResultsDto) => number | null
) => {
  const caseData = calculationResults.cases?.[caseId];
  const historicDates = caseData?.historicDates;
  const historicEventData = historicDates?.historicEventsYears?.[historicEventsDate];

  if (historicEventData) {
    return valueSelector(historicEventData);
  }

  return null;
};

export const getDealThesisInputCaseForecastDateShareholderDebtInstrumentsOwner = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  forecastDate: string,
  instrumentId: string,
  owner: OwnerType
) =>
  calculationResults.cases[caseId].forecastDates[forecastDate]?.shareholderDebtInstruments[
    instrumentId
  ].closingBalanceByOwner[owner];

export const getDealThesisInputCaseValuationDateShareholderDebtTotalByType = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  instrumentType: string
) => calculationResults.cases[caseId].valuationDate.shareholderDebtTotalByType[instrumentType];

export const getDealThesisInputCaseForecastDateShareholderDebtTotalByType = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  forecastDate: string,
  instrumentType: string
) =>
  calculationResults.cases[caseId].forecastDates[forecastDate]?.shareholderDebtTotalByType[
    instrumentType
  ];

export const getDealThesisInputCaseHistoricForecastDateShareholderDebtTotalByType = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  historicForecastDate: string,
  instrumentType: string
) => {
  const caseData = calculationResults.cases?.[caseId];
  const historicDates = caseData?.historicDates;
  const historicYearData = historicDates?.historicForecastYears?.[historicForecastDate];
  const shareholderDebtTotalByType = historicYearData?.shareholderDebtTotalByType;

  if (shareholderDebtTotalByType) {
    return shareholderDebtTotalByType[instrumentType];
  }

  return null;
};

export const getDealThesisInputCaseHistoricEventsDateShareholderDebtTotalByType = (
  calculationResults: Pwerm2InputCalculationResultsDto,
  caseId: string,
  historicEventsDate: string,
  instrumentType: string
) => {
  const caseData = calculationResults.cases?.[caseId];
  const historicDates = caseData?.historicDates;
  const historicEventData = historicDates?.historicEventsYears?.[historicEventsDate];
  const shareholderDebtTotalByType = historicEventData?.shareholderDebtTotalByType;

  if (shareholderDebtTotalByType) {
    return shareholderDebtTotalByType[instrumentType];
  }

  return null;
};
