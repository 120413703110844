import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import BaseModal from '@app/shared/components/modal/BaseModal';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { EquityRefinancingEventDto } from '@app/shared/models/contracts/project-dto';
import NewEventFormElements from './NewEventFormElements';
import { cloneDeep } from '@app/shared/helpers';
import { useLocale } from '@app/core/hooks/useLocale';
import { calculateBuildStructure } from '@app/core/store/capital-structure-slice';
import {
  CalcMethod,
  CapitalStructureEventSeverity,
  EventKey,
} from '@app/shared/models/contracts/enums/shared-enums';
import { CapitalStructureEventFaults } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import '../event-form-modal-module.scss';
import { mapBuildStructureErrorToMessage } from '../utils/EventBuilderHelpers';
import store from '@app/core/store/store';
import * as notificationActions from '@app/core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { selectCapitalStructure } from '@app/core/store/capital-structure-slice-selectors';

interface NewEventFormModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

interface NewEventFormDto extends EquityRefinancingEventDto {
  selectedCases: string[];
}

const PARENT_CLASSNAME = 'form';
const NewEventFormModal: FC<NewEventFormModalProps> = ({ setIsOpen, isOpen }): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const [faults, setFaults] = useState<CapitalStructureEventFaults[]>([]);
  const [caseEventSetErrorPairings, setCaseEventSetErrorPairings] = useState<{
    [key: string]: string;
  }>({});
  const maxYearsBetweenEventsAndValuation = useAppSelector(
    (state) => state.uiValues.maxYearsBetweenEventsAndValuation
  );
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;

  const formMethods = useForm<NewEventFormDto>({
    ...formConfigBase,
    defaultValues: {
      narrative: '',
      eventDate: new Date(project.investmentDate).addDays(1).toISODateString(),
      movements: {},
      selectedCases: [],
    },
  });

  const { getValues } = formMethods;

  useEffect(() => {
    formMethods.reset();
  }, [formMethods, isOpen]);

  useEffect(() => {
    setFaults([]);
  }, [isOpen]);

  const formSubmitHandler: SubmitHandler<NewEventFormDto> = async (data) => {
    const { selectedCases, ...payload } = data;
    let cases = cloneDeep(project.pwermInput.cases);
    let eventSets = cloneDeep(capitalStructure.eventSets);
    const newEventId = uuidv4();

    if (isOpmOnly) {
      const eventSetExists = Object.keys(eventSets).includes(EventKey.OpmOnly);
      if (eventSetExists) {
        eventSets[EventKey.OpmOnly].events.push(newEventId);
      } else {
        eventSets = {
          ...eventSets,
          [EventKey.OpmOnly]: {
            events: [newEventId],
          },
        };
      }
    } else {
      selectedCases.forEach((caseId) => {
        const previousEventSetId = cases.find((cases) => cases.caseId === caseId)?.eventSetId;
        const newEventSetId = uuidv4();
        cases = cases.map((cases) => {
          if (cases.caseId === caseId) {
            return {
              ...cases,
              eventSetId: newEventSetId,
            };
          }
          return cases;
        });
        eventSets = {
          ...eventSets,
          [newEventSetId]: {
            events: previousEventSetId
              ? [...eventSets[previousEventSetId].events, newEventId]
              : [newEventId],
          },
        };
      });
    }

    const result = await dispatch(
      calculateBuildStructure({
        project: {
          ...project,
          capitalStructures: {
            ...project.capitalStructures,
            [capitalStructureKey]: {
              ...capitalStructure,
              events: {
                ...capitalStructure.events,
                [newEventId]: {
                  ...payload,
                },
              },
              eventSets: eventSets,
            },
          },
          pwermInput: {
            ...project.pwermInput,
            cases: cases,
          },
        },
      })
    ).unwrap();

    if (result.hasErrors) {
      const errors = result.buildStructure.buildStructures[capitalStructureKey].faults.filter(
        (f) => f.severity === CapitalStructureEventSeverity.Error
      );
      const errorsWithoutReferenceToEvents = errors.filter((e) => !e.event);
      const caseEventSetErrorPairings = errors.reduce((acc, error) => {
        const eventSetId = error.eventSet;
        const caseId = cases.find((c) => c.eventSetId === eventSetId)?.caseId;
        return {
          ...acc,
          [caseId!]: eventSetId,
        };
      }, {});
      setFaults(errorsWithoutReferenceToEvents);
      setCaseEventSetErrorPairings(caseEventSetErrorPairings);
    } else {
      setFaults([]);
      setCaseEventSetErrorPairings({});
      setIsOpen(false);
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.success,
          message: l('_EventAddedSuccessfully', { eventNarrative: payload.narrative }),
          autoHide: true,
        })
      );
      dispatch(
        uiValuesSlice.actions.setActiveInstrument({
          instrumentId: undefined,
          eventId: undefined,
        })
      );
    }
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      title="Add Event"
      isOpen={isOpen}
      onCloseButtonClick={() => setIsOpen(false)}>
      <FormProvider {...formMethods}>
        <form
          data-testid="new-event-form"
          onSubmit={formMethods.handleSubmit(formSubmitHandler)}
          onBlur={() => formMethods.trigger()}>
          <NewEventFormElements />
          {faults.length > 0 && (
            <div className={`${PARENT_CLASSNAME}__errors`}>
              {l('_CannotAddEventDueToTheFollowingErrors')}
              <ul>
                {faults.map((f, i) => {
                  const caseId = Object.keys(caseEventSetErrorPairings).find(
                    (key) => caseEventSetErrorPairings[key] === f.eventSet
                  );
                  const caseNarrative = project.pwermInput.cases.find(
                    (c) => c.caseId === caseId
                  )?.narrative;
                  return (
                    <li key={f.errorCode + i}>
                      {mapBuildStructureErrorToMessage(f, {
                        caseNarrative,
                        eventDate: getValues().eventDate,
                        maxYearsBetweenEventsAndValuation,
                      })}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className={`${PARENT_CLASSNAME}__actions`}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              data-testid="cancel-button"
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(false);
              }}>
              {l('_Cancel')}
            </Button>
            <Button
              type="submit"
              data-testid="submit-button"
              appearance={ButtonAppearance.DEFAULT_PRIMARY}>
              {l('_Create')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  );
};

export default NewEventFormModal;
