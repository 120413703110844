import { cloneDeep } from '@app/shared/helpers';
import { EventKey } from '@app/shared/models/contracts/enums/shared-enums';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import { v4 as uuidv4 } from 'uuid';

export const createOpmOnlyEventSet = (project: ProjectDto): ProjectDto => {
  const capitalStructureId = project.pwermInput.cases[0].capitalStructureId;
  const capitalStructureEventSets = project.capitalStructures[capitalStructureId].eventSets;
  const opmOnlyEventSetExists = Object.keys(capitalStructureEventSets).includes(EventKey.OpmOnly);
  const casesWithEventSets = project.pwermInput.cases.filter((x) => x.eventSetId);

  // if the OPM Only event set doesn't exist AND we have some events applied to PWERM cases
  // then create the event set as a copy of the first case's event set
  // if we don't have any events yet, the OPM Only event set will be created when the first event is added
  if (!opmOnlyEventSetExists) {
    if (casesWithEventSets.length > 0) {
      const firstEventSet = casesWithEventSets[0].eventSetId!;
      const firstEventSetEvents =
        project.capitalStructures[capitalStructureId].eventSets[firstEventSet].events;
      const updatedEvents = cloneDeep(project.capitalStructures[capitalStructureId].events);
      const addedOpmEvents: string[] = [];
      firstEventSetEvents.forEach((eventId) => {
        const newEventId = uuidv4();
        updatedEvents[newEventId] = {
          ...updatedEvents[eventId],
        };
        addedOpmEvents.push(newEventId);
      });
      return {
        ...project,
        opmInput: {
          ...project.opmInput,
          selectedCaseId: casesWithEventSets[0].caseId,
          selectedEventSetId: EventKey.OpmOnly,
        },
        capitalStructures: {
          ...project.capitalStructures,
          [capitalStructureId]: {
            ...project.capitalStructures[capitalStructureId],
            eventSets: {
              ...project.capitalStructures[capitalStructureId].eventSets,
              [EventKey.OpmOnly]: {
                events: addedOpmEvents,
              },
            },
            events: updatedEvents,
          },
        },
      };
    }
  } else {
    if (!project.opmInput.selectedCaseId || !project.opmInput.selectedEventSetId) {
      return {
        ...project,
        opmInput: {
          ...project.opmInput,
          selectedCaseId:
            casesWithEventSets.length > 0
              ? casesWithEventSets[0].caseId
              : project.pwermInput.cases[0].caseId,
          selectedEventSetId: EventKey.OpmOnly,
        },
      };
    }
  }

  return project;
};
