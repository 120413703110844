import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '@app/shared/models/notification';

export interface NotificationState {
  isSnackBarVisible: boolean;
  isErrorModalVisible: boolean;
  notification: Notification;
  opmErrorMessageList?: string[];
  pwermErrorMessageList?: string[];
  pwermInputsErrorMessageList?: string[];
  benchmarkingInputsErrorMessageList?: string[];
  capitalStructureErrorMessageList?: string[];
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: { isSnackBarVisible: false, isErrorModalVisible: false } as NotificationState,
  reducers: {
    showNotificationSnackbar: (state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
      state.isSnackBarVisible = true;
    },
    closeNotification: (state) => {
      state.isSnackBarVisible = false;
    },
    showNotificationModal: (state) => {
      state.isErrorModalVisible = true;
    },
    closeNotificationModal: (state) => {
      state.isErrorModalVisible = false;
    },
    setOpmErrorMessageList: (state, action: PayloadAction<string[] | undefined>) => {
      state.opmErrorMessageList = action.payload;
    },
    setPwermErrorMessageList: (state, action: PayloadAction<string[] | undefined>) => {
      state.pwermErrorMessageList = action.payload;
    },
    setPwermInputsErrorMessageList: (state, action: PayloadAction<string[] | undefined>) => {
      state.pwermInputsErrorMessageList = action.payload;
    },
    setBenchmarkingInputsErrorMessageList: (state, action: PayloadAction<string[] | undefined>) => {
      state.benchmarkingInputsErrorMessageList = action.payload;
    },
    setCapitalStructureErrorMessageList: (state, action: PayloadAction<string[] | undefined>) => {
      state.capitalStructureErrorMessageList = action.payload;
    },
  },
});

export const {
  showNotificationSnackbar,
  closeNotification,
  showNotificationModal,
  closeNotificationModal,
  setOpmErrorMessageList,
  setPwermErrorMessageList,
  setPwermInputsErrorMessageList,
  setBenchmarkingInputsErrorMessageList,
  setCapitalStructureErrorMessageList,
} = notificationSlice.actions;

export default notificationSlice.reducer;
