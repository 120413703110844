import { FC, FormEvent, InputHTMLAttributes, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import styles from './grid-text-field.module.scss';
import classNames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { DateFormatType } from '@app/shared/enums/date-format-enum';
import {
  MAX_SCALE,
  swapMaskForValueDisplay,
} from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import ExclamationMarkCircled from '@app/shared/icons/ExclamationMarkCircled';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '@app/shared/components/tooltip/Tooltip';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { formatUnfocusedInputValue } from '@app/shared/formatters/format-unfocused-input-value';
import { FormControlPropsBase } from '@app/shared/components/form-controls/shared-types';
import {
  GridFieldTooltipVariation,
  GridFieldVariation,
} from '@app/shared/components/grid-controls/grid-field-options';
import { returnFormattedInputValue } from '@app/shared/components/form-controls/field-helpers';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useSelectOnFocus } from '@app/modules/projects/outputs/custom-hooks/useSelectOnFocus';
import { handleOnCopy } from '@app/shared/formatters/handleOnCopy';

interface GridTextFieldProps extends InputHTMLAttributes<HTMLInputElement>, FormControlPropsBase {
  name: string;
  onChange?: any;
  alignment?: Alignment;
  tooltipMsg?: string;
  variation?: GridFieldVariation;
  tooltipVariation?: GridFieldTooltipVariation;
  inputFormat?: DateFormatType;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  className?: any;
  showValidationErrorsAsWarnings?: boolean;
  isDealThesisHeader?: boolean;
}

export const PARENT_CLASSNAME = 'grid-field';
const GridTextField: FC<GridTextFieldProps> = ({
  alignment,
  tooltipMsg,
  decimalPoint,
  defaultValue,
  fieldValueMask,
  isInputMasked = true,
  isNormalised = false,
  label,
  labelIcon,
  valueAsNumber,
  name,
  rules,
  type = 'text',
  viewValuePrefix,
  viewValueSuffix,
  variation,
  tooltipVariation,
  ignoreReadOnly = false,
  disabled = false,
  showValidationErrorsAsWarnings = false,
  className,
  isDealThesisHeader = false,
  ...rest
}): JSX.Element => {
  const {
    field: { value: fieldValue, onChange: fieldOnChange, ref },
    fieldState: { error },
  } = useController({ name, rules, defaultValue });
  const [inputValue, setInputValue] = useState<string | number>(fieldValue);
  const [isFocused, setFocus] = useState(false);

  const { onBlur, onKeyDown, registerSelectAll } = useSelectOnFocus(isFocused);

  const formMethods = useFormContext();

  const errorMessageId = uuidv4();

  const isReadOnly = useIsReadOnly();
  const shouldHighlight = useAppSelector((state) => state.uiValues.highlightEditableCells);

  const isScientificNotation =
    typeof inputValue === 'number' && inputValue.toString().includes('e');

  const getDecimalPlacesFromScientificNotation = () => {
    const [, exponent] = inputValue.toString().split('e');
    if (Number(exponent) < 0) {
      return Math.abs(Number(exponent));
    }
  };

  const handleInputValue = (inputValue: string | number) => {
    // Early exit for non-masked input
    if (false || !isInputMasked) {
      return inputValue;
    }

    // Early exit for empty string/null checks
    if (inputValue === '' || inputValue === null) {
      return inputValue?.toString();
    }

    // If input is focused and in scientific notation, format to MAX_SCALE decimal places
    if (isFocused && isScientificNotation && typeof inputValue === 'number') {
      return `${viewValuePrefix ?? ''}${inputValue?.toFixed(MAX_SCALE)}${viewValueSuffix ?? ''}`;
    }

    // If input is not focused and in scientific notation, format to provided decimal places
    if (!isFocused && isScientificNotation && typeof inputValue === 'number') {
      return `${viewValuePrefix ?? ''}${inputValue?.toFixed(decimalPoint ?? 0)}${
        viewValueSuffix ?? ''
      }`;
    }

    // Handle formatting for unfocused input that is not in scientific notation
    if (!isFocused) {
      return formatUnfocusedInputValue(
        Number(inputValue),
        decimalPoint,
        viewValuePrefix,
        viewValueSuffix,
        isNormalised
      );
    }

    // Default case to handle any other input scenarios
    return inputValue?.toString();
  };

  const handleOnPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('Text');
    const rows = clipboardData.split(/\r\n|\r|\n/g);
    const cells = rows.map((row) => row.split('\t'));
    cells.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        if (fieldValueMask?.mask.toString().includes('num')) {
          // Checks for parentheses that represents negative numbers
          if (cell.trim().match(/^\(.*\)$/g)) {
            cell = `-${cell.replace(/[()]/g, '')}`;
          }
          cells[rowIndex][cellIndex] = cell.replace(/[^\-0-9.]/g, '');
        }
      });
    });

    const tables = Array.from(document.querySelectorAll('tbody'));
    const inputElementsMatrix = tables.map((section) => {
      const rows = Array.from(section.querySelectorAll('tr'));
      const elements = rows
        .map((row) =>
          Array.from(row.querySelectorAll('input')).filter((input) => input.type === 'text')
        )
        .filter((row) => row.length > 0);
      return elements;
    });
    const selectedIndex = findSelectedIndex(inputElementsMatrix, name);

    if (
      selectedIndex.selectedSection === -1 ||
      selectedIndex.selectedRow === -1 ||
      selectedIndex.selectedCell === -1
    ) {
      return;
    }

    for (
      let rowIndex = 0;
      rowIndex < cells.length &&
      rowIndex + selectedIndex.selectedRow <
        inputElementsMatrix[selectedIndex.selectedSection].length;
      rowIndex++
    ) {
      for (
        let cellIndex = 0;
        cellIndex < cells[rowIndex].length &&
        cellIndex + selectedIndex.selectedCell <
          inputElementsMatrix[selectedIndex.selectedSection][selectedIndex.selectedRow].length;
        cellIndex++
      ) {
        const fieldName =
          inputElementsMatrix[selectedIndex.selectedSection][rowIndex + selectedIndex.selectedRow][
            cellIndex + selectedIndex.selectedCell
          ].name;
        if (
          formMethods.getValues(fieldName) !== undefined &&
          cells[rowIndex][cellIndex].length > 0
        ) {
          formMethods.setValue(fieldName, cells[rowIndex][cellIndex]);
        }
      }
    }
  };

  const findSelectedIndex = (
    inputElementsMatrix: Array<Array<Array<HTMLInputElement>>>,
    currentInputName: string
  ) => {
    let selectedSection = -1,
      selectedCell = -1,
      selectedRow = -1;
    inputElementsMatrix.forEach((section, sectionIndex) => {
      section.forEach((row, rowIndex) => {
        row.forEach((cell, cellIndex) => {
          if (cell.name === currentInputName) {
            selectedSection = sectionIndex;
            selectedCell = cellIndex;
            selectedRow = rowIndex;
            return;
          }
        });
      });
      if (selectedCell !== -1 && selectedRow !== -1) {
        return;
      }
    });

    return { selectedSection, selectedRow, selectedCell };
  };

  useEffect(() => {
    setInputValue(fieldValue);
    fieldOnChange(returnFormattedInputValue(fieldValue, valueAsNumber));
  }, [fieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--error`]]: error?.message,
        [styles[`${PARENT_CLASSNAME}--${alignment}`]]: alignment,
        [styles[`${PARENT_CLASSNAME}--${variation}`]]: variation,
        [styles[`${PARENT_CLASSNAME}--highlight`]]: shouldHighlight,
      })}>
      {label && (
        <label className={styles[`${PARENT_CLASSNAME}__label`]}>
          {labelIcon}
          {label}
        </label>
      )}
      <div className={classNames(styles[`${PARENT_CLASSNAME}__container`])}>
        <ExclamationMarkCircled
          className={classNames(styles[`${PARENT_CLASSNAME}__icon`], {
            [styles[`${PARENT_CLASSNAME}__icon--error`]]: !showValidationErrorsAsWarnings,
            [styles[`${PARENT_CLASSNAME}__icon--warning`]]: showValidationErrorsAsWarnings,
          })}
        />
        <Tooltip
          data-testid="GridTextFieldTooltip"
          content={error?.message ? error?.message : tooltipMsg}
          className={styles[`${PARENT_CLASSNAME}__tooltip`]}
          tooltipVariation={tooltipVariation}>
          {/* @ts-ignore: datepicker vs Imask component, properties which are added are compatible */}
          <IMaskInput
            {...(isFocused ? { ...fieldValueMask } : { ...swapMaskForValueDisplay })}
            name={name}
            type={type}
            title={
              isScientificNotation
                ? inputValue?.toFixed(getDecimalPlacesFromScientificNotation())
                : inputValue?.toString()
            }
            onChange={
              !isInputMasked
                ? (event: FormEvent<HTMLInputElement>) => {
                    setInputValue(event.currentTarget.value);
                    fieldOnChange(event.currentTarget.value);
                  }
                : undefined
            }
            unmask
            onAccept={(value: unknown) => {
              if (isFocused && typeof value === 'string' && value !== '-0') {
                setInputValue(value);
                fieldOnChange(returnFormattedInputValue(value, valueAsNumber));
              }
            }}
            className={classNames(styles[`${PARENT_CLASSNAME}__control`], className, {
              [styles[`${PARENT_CLASSNAME}__control--strong`]]: label,
              [styles[`${PARENT_CLASSNAME}__deal-thesis-header-padding`]]: isDealThesisHeader,
            })}
            inputRef={(r) => {
              ref(r);
              if (r) {
                registerSelectAll(() => r.select(0, r.value.length));
              }
            }}
            onFocus={() => setFocus(true)}
            onKeyDown={onKeyDown}
            onBlur={(event: FormEvent<HTMLInputElement>) => {
              // Trim only string value inputs
              if (!valueAsNumber && !isInputMasked) {
                setInputValue(event.currentTarget.value.trim());
                fieldOnChange(event.currentTarget.value.trim());
              }
              setFocus(false);
              onBlur();
            }}
            onCopy={(event) => handleOnCopy(event, inputValue.toString())}
            onPaste={handleOnPaste}
            {...rest}
            value={handleInputValue(inputValue)}
            aria-describedby={error?.message ? errorMessageId : undefined}
            disabled={disabled || (!ignoreReadOnly && isReadOnly)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default GridTextField;
