import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { FC } from 'react';
import { RouteConstants } from '../../../RouteConstants';
import { Navigate } from 'react-router-dom';
import { selectValuedInstruments } from '@app/core/store/capital-structure-slice-selectors';

export const CostOfEquityComparator: FC = (): JSX.Element => {
  const { isPwermValid } = useAppSelector((state) => state.project);
  const valuedInstruments = useAppSelector(selectValuedInstruments);
  const storedCostOfEquityView = useAppSelector(
    (state) => state.uiValues.userSelections.costOfEquity.selectedTab
  );
  const { generatePath } = usePwcNavigate();

  const isStoredCostOfEquityViewInInstruments = valuedInstruments.some(
    (instrument) => instrument.instrumentId === storedCostOfEquityView
  );

  if (
    !storedCostOfEquityView ||
    (!isStoredCostOfEquityViewInInstruments &&
      storedCostOfEquityView !== RouteConstants.TotalEquityCrossCheckLegacy)
  ) {
    return <PwcNavigate route={generatePath(RouteConstants.SummaryComparator)} />;
  } else if (
    storedCostOfEquityView &&
    storedCostOfEquityView === RouteConstants.TotalEquityCrossCheckLegacy
  ) {
    return <PwcNavigate route={generatePath(RouteConstants.TotalEquityCrossCheckComparator)} />;
  }

  if (!isPwermValid) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <PwcNavigate
      route={generatePath(RouteConstants.InstrumentByIdComparator, {
        instrumentId: storedCostOfEquityView,
      })}
    />
  );
};
