import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { getRequiredValidator } from '@app/core/validations/hook-forms/validators';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { numericBaseFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { useLocale } from '@app/core/hooks/useLocale';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import PlusSvg from '@app/shared/icons/legacy/PlusSvg';
import { v4 as uuidv4 } from 'uuid';
import { useFieldArray, useFormContext } from 'react-hook-form';
import MinusRoundedSvg from '@app/shared/icons/MinusRounded';
import styles from '../../../deal-thesis/deal-thesis-page.module.scss';
import FieldRadio from '@app/shared/components/form-controls/field-radio/FieldRadio';
import { KeyFinancialMetrics as KeyFinancialMetricsDto } from '../../DealThesisPage2';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import store from '@app/core/store/store';
import { cloneDeep } from '@app/shared/helpers';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';
import DealThesisEmptyHistoricDataColumns from '../../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';

interface KeyFinancialMetricsProps {
  handleDataSubmit: () => void;
}

const KeyFinancialMetrics: FC<KeyFinancialMetricsProps> = ({ handleDataSubmit }): JSX.Element => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const numberOfInputForecastYears = caseData?.forecasts.length;
  const numberOfInputHistoricForecastYears = Object.keys(
    project.pwermInput.historicForecasts
  ).length;
  const historicDataForecastYearsInputs = cloneDeep(project.pwermInput.historicForecasts).reverse();
  const { control } = useFormContext();
  const fieldsConfiguration = store.getState().uiValues.probabilityWeighted;
  const caseResults = results.cases[caseId!];
  // results data sliced such that a decrease in forecast years is immediately rendered without depending on results
  const caseForecasts = Object.entries(caseResults.forecastDates)
    .map(([forecastId, value]) => ({
      forecastId,
      ...value,
    }))
    .slice(0, numberOfInputForecastYears);
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicDataForecastYearsResults = Object.keys(
    caseResults.historicDates.historicForecastYears ?? {}
  )
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicDataEventsYearsResults = Object.keys(
    results.cases[caseId!].historicDates.historicEventsYears ?? {}
  ).reverse();
  const maxHistoricalDataLength = Math.max(
    historicDataForecastYearsResults.length,
    historicDataEventsYearsResults.length
  );
  const maxColspanCount =
    (numberOfInputForecastYears ? numberOfInputForecastYears : 0) +
    maxHistoricalDataLength +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;
  const maxKeyFinancialFieldLength = fieldsConfiguration?.maxFinancialMetricNameLength ?? 64;
  const maxKeyFinancialCount = fieldsConfiguration?.maxFinancialMetrics ?? 5;
  const metricDefinition = Object.entries(project.pwermInput.metricDefinitions);
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );

  const { append: appendKeyFinancialMetrics, remove: removeMetric } = useFieldArray({
    name: 'keyFinancialMetrics',
    control: control,
  });

  const addKeyFinancialMetric = () => (): void => {
    const newKeyId = uuidv4();
    appendKeyFinancialMetrics(
      {
        metricId: newKeyId,
        narrative: '',
        forecast: [0].concat(metricDefinition.map(() => 0)),
      } as KeyFinancialMetricsDto,
      { shouldFocus: false }
    );
    handleDataSubmit();
  };
  const removeKeyFinancialMetric = (metricIndex: number) => (): void => {
    removeMetric(metricIndex);
    handleDataSubmit();
  };

  return (
    <>
      <tbody>
        <tr>
          <th className="table-primary__cell--header" colSpan={maxColspanCount}>
            {l('_KeyFinancialMetricsTitle')}
          </th>
        </tr>
        {Object.entries(project.pwermInput.metricDefinitions).map(([metricId], metricIndex) => {
          return (
            <React.Fragment key={`key-financial-metrics-${metricId}`}>
              <tr>
                <th className="table-primary__cell--vertical-separator">
                  <div className={styles['deal-thesis-th-radio-wrapper']}>
                    <GridTextField
                      name={`keyFinancialMetrics.${metricIndex}.narrative`}
                      placeholder={l('_KeyFinancialMetric')}
                      isInputMasked={false}
                      tooltipVariation={GridFieldTooltipVariation.Right}
                      rules={{
                        ...getRequiredValidator(),
                        validate: {
                          maxLength: (value) =>
                            value.length <= maxKeyFinancialFieldLength ||
                            l('_KeyFinancialMetricLengthValidation', {
                              characters: maxKeyFinancialFieldLength,
                            }),
                          isUnique: (value) =>
                            Object.values(project.pwermInput.metricDefinitions).filter(
                              (metric) => metric.narrative === value
                            ).length <= 1 ||
                            l('_PropertyNameMustBeUnique', {
                              PropertyName: l('_KeyFinancialMetric'),
                            }),
                        },
                      }}
                    />
                    <FieldRadio
                      name="activeMetric"
                      option={{ value: metricId }}
                      onChange={handleDataSubmit}
                    />
                  </div>
                </th>
                <DealThesisEmptyHistoricDataColumns isHistoricEventsSection={false} />
                {showHistoricDataColumns &&
                  historicDataForecastYearsResults.map((data, index) => (
                    <td key={data}>
                      <GridTextField
                        name={`historicForecasts.${
                          historicDataForecastYearsInputs.length - 1 - index
                        }.metricValues.${metricId}`}
                        alignment={Alignment.Right}
                        {...numericBaseFieldFormattingProps}
                        placeholder="0"
                      />
                    </td>
                  ))}
                <td>
                  <GridTextField
                    name={`keyFinancialMetrics.${metricIndex}.forecast.0`}
                    alignment={Alignment.Right}
                    {...numericBaseFieldFormattingProps}
                    placeholder="0"
                  />
                </td>
                {caseForecasts.map((_, index) => (
                  <td key={index + 'exit-driver'}>
                    <GridTextField
                      name={`keyFinancialMetrics.${metricIndex}.forecast.${index + 1}`}
                      alignment={Alignment.Right}
                      {...numericBaseFieldFormattingProps}
                      placeholder="0"
                      rules={{
                        ...getRequiredValidator(),
                      }}
                    />
                  </td>
                ))}
                <td className="table-primary__cell--actions">
                  {metricId !== project.pwermInput.valueDriverActiveMetric && (
                    <div className="hide-on-screenshot">
                      <Button
                        appearance={ButtonAppearance.CLEAN}
                        size={ButtonSize.TABLE_ACTION}
                        title={l('_DeleteItem')}
                        onClick={removeKeyFinancialMetric(metricIndex)}>
                        <MinusRoundedSvg />
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            </React.Fragment>
          );
        })}
        <tr>
          <td className="table-primary__cell--vertical-separator">
            <div className="hide-on-screenshot">
              <Button
                appearance={ButtonAppearance.DEFAULT_TERTIARY}
                size={ButtonSize.TABLE_ACTION}
                startIcon={<PlusSvg />}
                disabled={
                  Object.keys(project.pwermInput.metricDefinitions).length >= maxKeyFinancialCount
                }
                onClick={addKeyFinancialMetric()}
                autoIconSize>
                {l('_AddMetricTitle')}
              </Button>
            </div>
          </td>
          <td colSpan={maxColspanCount - 1} />
        </tr>
        <DealThesisSpacerRow2 />
      </tbody>
    </>
  );
};

export default KeyFinancialMetrics;
