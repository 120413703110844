import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './button.module.scss';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { Link, LinkProps } from 'react-router-dom';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';

type ButtonProps = {
  appearance: ButtonAppearance;
  className?: string;
  size?: ButtonSize;
  type?: string;
  autoIconSize?: boolean;
  to?: string;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  active?: boolean;
  cursorDefault?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement & LinkProps>;

const Button: React.FC<ButtonProps> = ({
  appearance,
  className,
  size,
  endIcon,
  startIcon,
  autoIconSize,
  type = 'button',
  children,
  to = '',
  ignoreReadOnly = false,
  disabled = false,
  active,
  cursorDefault = false,
  ...restProps
}): ReactElement => {
  const buttonClasses = classNames(
    styles['button'],
    {
      [styles[`button--${appearance}`]]: appearance,
      [styles[`button--${size}`]]: size,
      [styles[`button--active`]]: active,
      [styles[`button--cursor-default`]]: cursorDefault,
    },
    className
  );

  const isReadOnly = useIsReadOnly();

  const ElementTag = to ? Link : 'button';

  return (
    <ElementTag
      className={buttonClasses}
      type={type}
      {...restProps}
      to={to}
      disabled={disabled || (!ignoreReadOnly && isReadOnly)}>
      <>
        {startIcon && (
          <span
            className={classNames({
              [styles['button__start-icon-wrapper']]: !autoIconSize,
            })}>
            {startIcon}
          </span>
        )}
        {children}
        {endIcon && endIcon}
      </>
    </ElementTag>
  );
};

export default React.memo(Button);
