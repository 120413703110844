import ProjectNavigationSidebar from '@app/shared/layout/project-navigation-sidebar/ProjectNavigationSidebar';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './project-routes-wrapper.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { ContentHeaderLayout } from '@app/modules/projects/project-routes-wrapper/layout-enums';
import classNames from 'classnames';
import SidebarCollapseButton from '@app/shared/components/sidebar/SidebarCollapseButton';
import useCollapsableSidebarState from '@app/core/hooks/useLocalStoreSidebarCollapse';
import { setPageScrollPosition } from '@core/store/ui-values-slice';
import { useDispatch } from 'react-redux';
import { RouteConstants } from '../RouteConstants';
import { ContainerTestId } from '@app/shared/models/contracts/enums/shared-enums';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';

const PARENT_CLASSNAME = 'project-routes-wrapper';

type PageLayoutProps = {
  children: ReactNode;
  contentHeader?: ReactNode;
  contentHeaderLayout?: ContentHeaderLayout;
  rightSidebar?: ReactNode;
  pageName: string;
};

const PageLayout: FC<PageLayoutProps> = ({
  children,
  contentHeader,
  contentHeaderLayout,
  rightSidebar,
  pageName,
}): JSX.Element => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const mainContainer = useRef<HTMLElement>(null);
  const scrollTopRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const projectId = useAppSelector((state) => state.project.projectDraft.id);
  const pageScrollPositionList = useAppSelector(
    (state) => state.uiValues.pageScrollPositionList[projectId]
  );
  const dispatch = useDispatch();

  const handleScroll = () => {
    setScrollTop(mainContainer.current?.scrollTop ?? 0);
    setScrollLeft(mainContainer.current?.scrollLeft ?? 0);
  };

  useEffect(() => {
    scrollTopRef.current = scrollTop;
    scrollLeftRef.current = scrollLeft;
  }, [scrollTop, scrollLeft]);

  useEffect(() => {
    if (pageScrollPositionList) {
      const pageScrollPosition = pageScrollPositionList.find((page) => page.pageName === pageName);
      mainContainer.current &&
        pageScrollPosition &&
        mainContainer.current.scrollTo(pageScrollPosition.scrollLeft, pageScrollPosition.scrollTop);
    }

    return () => {
      if (pageName !== RouteConstants.PayoutLogicLegacy) {
        const actionPayload = {
          key: projectId,
          value: {
            pageName: pageName,
            scrollTop: scrollTopRef.current,
            scrollLeft: scrollLeftRef.current,
          },
        };
        dispatch(setPageScrollPosition(actionPayload));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentSection = (
    <>
      {contentHeader && (
        <div
          className={classNames(styles[`${PARENT_CLASSNAME}__header-container`], {
            [styles[`${PARENT_CLASSNAME}__header-container--${contentHeaderLayout}`]]:
              contentHeaderLayout,
          })}>
          {contentHeader}
        </div>
      )}
      {children}
    </>
  );

  return (
    <>
      <main
        className={styles[`${PARENT_CLASSNAME}__main`]}
        data-testid={ContainerTestId.Main}
        ref={mainContainer}
        onScroll={handleScroll}>
        {contentSection}
      </main>
      {rightSidebar}
    </>
  );
};

type ProjectRoutesWrapperProps = {
  children: ReactNode;
};

const ProjectRoutesWrapper: FC<ProjectRoutesWrapperProps> & {
  PageLayout: FC<PageLayoutProps>;
  // eslint-disable-next-line react/prop-types
} = ({ children }): JSX.Element => {
  const { projectId } = useParams();
  const project = useAppSelector((state) => state.project);
  const legacyCalculatedResults = useAppSelector((state) => state.pwermCalculation);
  const { isDevFeatureEnabled } = useDevFeatures();
  const isLegacyPwermEnabled = isDevFeatureEnabled(DevFeature.ShowLegacyPwerm);

  const [isCollapsed, toggleSidebar] = useCollapsableSidebarState('collapsed-sidebar');

  useEffect(() => {
    document.title = `${
      !project.projectDraft.name ? '' : project.projectDraft.name + ' - '
    }Equity Reward Analytics`;
  }, [project]);

  if (
    projectId === project?.projectDraft?.id?.toString() &&
    (isLegacyPwermEnabled
      ? Boolean(legacyCalculatedResults?.values?.calculatedVariables?.result)
      : true)
  ) {
    return (
      <div className={classNames(styles[PARENT_CLASSNAME])}>
        <div
          className={classNames(styles[`${PARENT_CLASSNAME}__sidebar-container`], {
            [styles['collapsed']]: isCollapsed,
          })}
          data-testid="sidebar-container">
          <ProjectNavigationSidebar />
          <SidebarCollapseButton toggleSidebar={toggleSidebar} isSidebarCollapsed={isCollapsed} />
        </div>
        {children}
      </div>
    );
  } else {
    return <></>;
  }
};

ProjectRoutesWrapper.PageLayout = PageLayout;

export default ProjectRoutesWrapper;
