import { useLocale } from '@app/core/hooks/useLocale';
import { Toggle } from '@app/shared/components/toggle/Toggle';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './expand.module.scss';
import {
  CapitalStructureCapTableEventDto,
  CapitalStructureEventDto,
  CapitalStructureInstrumentDefinitionDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ExpandCapTable } from './ExpandCapTable';
import { ExpandInstrumentTable } from './ExpandInstrumentTable';
import { EventKey } from '@app/shared/models/contracts/enums/shared-enums';

interface ExpandStructureProps {
  capTableEvent: CapitalStructureCapTableEventDto;
  buildStructureEvent: CapitalStructureEventDto;
  instrumentDefinitions: Record<string, CapitalStructureInstrumentDefinitionDto>;
}

enum ExpandType {
  CarryingValues = 'values',
  ChangesByInstrument = 'instrument',
}

export const ExpandStructure: FC<ExpandStructureProps> = ({
  capTableEvent,
  buildStructureEvent,
  instrumentDefinitions,
}) => {
  const { l } = useLocale();
  const [toggleExpanded, setToggleExpanded] = useState({
    ignoreExpandRows: false,
    toggleExpanded: false,
  });
  const [selectedRadio, setSelectedRadio] = useState(ExpandType.CarryingValues);

  const defaultExpandedInstruments: Dictionary<boolean> = {};

  capTableEvent &&
    Object.keys(capTableEvent.instruments).map((key) => {
      defaultExpandedInstruments[key] = false;
    });
  const [expandedInstruments, setExpandedInstruments] = useState<Dictionary<boolean>>(
    defaultExpandedInstruments
  );

  const expandAllRows = (isExpandAllRows: boolean) => {
    if (!capTableEvent) {
      return;
    }
    const expandedInstruments: Dictionary<boolean> = {};

    Object.keys(capTableEvent.instruments).forEach((key) => {
      expandedInstruments[key] = isExpandAllRows;
    });
    setExpandedInstruments(expandedInstruments);
  };

  useEffect(() => {
    const allTrue = Object.values(expandedInstruments).every((value) => value);
    const allFalse = Object.values(expandedInstruments).every((value) => !value);
    if (!allTrue && !allFalse) {
      setToggleExpanded((prevState) => {
        if (!prevState.toggleExpanded && prevState.ignoreExpandRows) {
          return prevState;
        }
        return { toggleExpanded: false, ignoreExpandRows: true };
      });
    }
  }, [expandedInstruments]);

  useEffect(() => {
    if (!toggleExpanded.ignoreExpandRows) {
      expandAllRows(toggleExpanded.toggleExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleExpanded]);

  const formMethods = useForm<any>({
    ...formConfigBase,
  });
  if (!capTableEvent) {
    return null;
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <div className={styles['expanded-wrapper']}>
          {buildStructureEvent.id !== EventKey.InitialCapitalStructure && (
            <div className={styles['radio-wrapper']}>
              <RadioGroup row key="expandType" name="expandType" value={selectedRadio}>
                <FormControlLabel
                  value={ExpandType.CarryingValues}
                  control={<Radio />}
                  label={l('_CarryingValues')}
                  onChange={() => {
                    setToggleExpanded({ toggleExpanded: false, ignoreExpandRows: false });
                    setSelectedRadio(ExpandType.CarryingValues);
                  }}
                />
                <FormControlLabel
                  value={ExpandType.ChangesByInstrument}
                  control={<Radio />}
                  label={l('_ChangesByInstrument')}
                  onChange={() => {
                    setToggleExpanded({ toggleExpanded: false, ignoreExpandRows: false });
                    setSelectedRadio(ExpandType.ChangesByInstrument);
                  }}
                />
              </RadioGroup>
            </div>
          )}
          {(selectedRadio !== ExpandType.ChangesByInstrument ||
            buildStructureEvent.movements.adjustments.total.amount !== 0 ||
            buildStructureEvent.movements.adjustments.total.numberOfShares !== 0) && (
            <div className={styles['toggle-wrapper']}>
              <Toggle
                name="expandAllRows"
                label={l('_ExpandAllRows')}
                isLabelHidden
                onChange={() =>
                  setToggleExpanded({
                    toggleExpanded: !toggleExpanded.toggleExpanded,
                    ignoreExpandRows: false,
                  })
                }
                forceChecked={toggleExpanded.toggleExpanded}
              />
              <div>
                <span className={styles['toggle-text']}>{l('_ExpandAllRows')}</span>
              </div>
            </div>
          )}

          <div className={styles['table-wrapper']}>
            {selectedRadio === ExpandType.CarryingValues && (
              <ExpandCapTable
                capTableEvent={capTableEvent}
                expandedInstruments={expandedInstruments}
                setExpandedInstruments={setExpandedInstruments}
              />
            )}
            {selectedRadio === ExpandType.ChangesByInstrument && (
              <ExpandInstrumentTable
                expandedInstruments={expandedInstruments}
                setExpandedInstruments={setExpandedInstruments}
                buildStructureEvent={buildStructureEvent}
                instrumentDefinitions={instrumentDefinitions}
              />
            )}
          </div>
        </div>
      </FormProvider>
    </>
  );
};
