import { FC, Fragment, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { selectCasesStructure } from '@core/store/project-slice-selectors';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';
import { instrumentsSortFn } from '@app/shared/helpers';
import { CostOfEquityTableHeader } from '@app/modules/projects/outputs/cost-of-equity/components/CostOfEquityTableHeader';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { Navigate, generatePath } from 'react-router';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';
import { setCostOfEquitySelectedTab } from '@app/core/store/ui-values-slice';
import { CAPMAssumptionsDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { ExitMultipleWeightedEVSection2 } from '../components/ExitMultipleWeightedEVSection2';
import {
  getCaseSummariesCaseInitial,
  getCaseSummariesCaseInitialDebtInstrument,
  getCaseSummariesCaseYear,
  getCaseSummariesCaseYearDebtInstrument,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { translationKeys } from '@locale/setupI18n';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { selectSlnAndPrefSharesInstrumentsWithInitialValues } from '@app/core/store/capital-structure-slice-selectors';
import styles from '../cost-of-equity.module.scss';

interface GetRefProp {
  getRef?: (ref: HTMLTableElement) => void;
}

const SummaryAndHighLevelData2: FC<GetRefProp> = ({ getRef }): JSX.Element => {
  const dispatch = useAppDispatch();
  const casesStructure = useAppSelector(selectCasesStructure);
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const slnAndPrefSharesInstruments = useAppSelector(
    selectSlnAndPrefSharesInstrumentsWithInitialValues
  );
  const { l } = useLocale();
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setCostOfEquitySelectedTab(RouteConstants.SummaryLegacy));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  const valuationInputsList: {
    fieldName: keyof CAPMAssumptionsDto;
    label: translationKeys;
  }[] = [
    { fieldName: 'riskFreeRate', label: '_RiskFreeRate' },
    { fieldName: 'assetBeta', label: '_AssetBeta' },
    { fieldName: 'marketRiskPremium', label: '_EMRP' },
    { fieldName: 'marketRiskAdjustment', label: '_CEMRA' },
    { fieldName: 'countryRiskPremium', label: '_CountrySpecificRiskPremium' },
    { fieldName: 'inflationDifferential', label: '_InflationDifferential' },
    { fieldName: 'sizePremium', label: '_SizePremium' },
    { fieldName: 'companyRiskPremium', label: '_CompanyRiskPremium' },
  ];

  return (
    <div className="main-container-padding-remove">
      <table
        ref={tableRef}
        className="table-primary table-primary--framed table-primary--zebra table-primary--title-column-nowrap table-primary--sticky-first-column">
        <CostOfEquityTableHeader />
        <tbody>
          <tr>
            <th className="table-primary__cell--header">{l('_CapmAssumptions')}</th>
            <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
          </tr>
          {valuationInputsList.map((item, index) => {
            const renderCell = (
              caseItem: CaseDto,
              additionalCell?: boolean,
              forecast?: ForecastDto
            ) => {
              const isFormattedAsNumber = item.fieldName === 'assetBeta';
              const unscaledValue = forecast
                ? getCaseSummariesCaseYear(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    (year) => year[item.fieldName]
                  )
                : getCaseSummariesCaseInitial(
                    results,
                    caseItem.caseId,
                    (caseItem) => caseItem[item.fieldName]
                  );
              const value =
                item.fieldName === 'assetBeta'
                  ? unscaledValue
                  : getCalculatedProjectValueInPercent(unscaledValue);

              return (
                <CellValue
                  value={value}
                  theme={additionalCell ? CellValueTheme.Tertiary : undefined}
                  {...(isFormattedAsNumber
                    ? numberCalculatedViewRules
                    : enteredPercentageViewRules)}
                  normalize
                />
              );
            };

            return (
              <Fragment key={index}>
                <TableSectionValuesPerForecast
                  key={index}
                  title={item.label}
                  renderSpacer={false}
                  renderCell={({ caseItem, forecast }) => renderCell(caseItem, false, forecast)}
                  renderAdditionalCell={({ caseItem }) => renderCell(caseItem, true)}
                />
              </Fragment>
            );
          })}
          <TableSpacerRow />
          <ExitMultipleWeightedEVSection2 />
          <tr>
            <th className="table-primary__cell--header">
              {l('_HighLevelTotalDebtForecastInOrderOfSeniority')}
            </th>
            <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
          </tr>
          <TableSectionValuesPerForecast
            title="Net Debt"
            renderSpacer={false}
            renderCell={({ forecast, caseItem }) => (
              <CellValue
                {...amountRules}
                value={getCaseSummariesCaseYear(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  (year) => year.totalNetDebt
                )}
              />
            )}
            renderAdditionalCell={({ caseItem }) => (
              <CellValue
                {...amountRules}
                value={getCaseSummariesCaseInitial(
                  results,
                  caseItem.caseId,
                  (caseItem) => caseItem.totalNetDebt
                )}
              />
            )}
          />
          {slnAndPrefSharesInstruments.sort(instrumentsSortFn).map((instrument) => (
            <TableSectionValuesPerForecast
              renderSpacer={false}
              key={instrument.instrumentId}
              title={instrument.instrumentNarrative}
              renderCell={({ caseItem, forecast }) => {
                const value = getCaseSummariesCaseYearDebtInstrument(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  instrument.instrumentId,
                  (instrument) => instrument.valueCarriedForward
                );
                return value !== null ? <CellValue {...amountRules} value={value} /> : <></>;
              }}
              renderAdditionalCell={({ caseItem }) => {
                const value = getCaseSummariesCaseInitialDebtInstrument(
                  results,
                  caseItem.caseId,
                  instrument.instrumentId,
                  (instrument) => instrument.valueCarriedForward
                );
                return value !== null ? (
                  <CellValue {...amountRules} value={value} theme={CellValueTheme.Tertiary} />
                ) : (
                  <></>
                );
              }}
              computedCellClassNames={({ caseItem, forecast }) => {
                const value = getCaseSummariesCaseYearDebtInstrument(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  instrument.instrumentId,
                  (instrument) => instrument.valueCarriedForward
                );

                return value === null ? styles['non-existent-instrument-value'] : '';
              }}
              computedAdditionalCellClassNames={({ caseItem }) => {
                const value = getCaseSummariesCaseInitialDebtInstrument(
                  results,
                  caseItem.caseId,
                  instrument.instrumentId,
                  (instrument) => instrument.valueCarriedForward
                );

                return value === null ? styles['non-existent-instrument-value'] : '';
              }}
            />
          ))}
          <TableSectionValuesPerForecast
            title="Total Net Debt and Loan Notes"
            renderSpacer={false}
            renderCell={({ forecast, caseItem }) => (
              <CellValue
                {...amountRules}
                strong
                value={getCaseSummariesCaseYear(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  (year) => year.totalIndebtedness
                )}
              />
            )}
            renderAdditionalCell={({ caseItem }) => (
              <CellValue
                {...amountRules}
                value={getCaseSummariesCaseInitial(
                  results,
                  caseItem.caseId,
                  (caseItem) => caseItem.totalIndebtedness
                )}
                theme={CellValueTheme.Tertiary}
              />
            )}
            additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
            additionalCellClassNames="table-primary__cell--section-start-separator"
          />
          <TableSpacerRow />
        </tbody>
      </table>
    </div>
  );
};

export default SummaryAndHighLevelData2;
