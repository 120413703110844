import { FC, Fragment } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { selectCasesStructure } from '@core/store/project-slice-selectors';
import classNames from 'classnames';
import { multiplierRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useShouldHighlight } from '../custom-hooks/useShouldHighlight';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';

type TableSectionValuesPerMultipleProps = {
  title: string;
  renderCell: (props: {
    caseItem: CaseDto;
    caseItemIndex: number;
    forecast: ForecastDto;
    multiple: number; // todo replace usage of this with multipleId
    multipleId?: string;
  }) => JSX.Element;
  computedCellClassNames?: (props: {
    caseItem: CaseDto;
    caseItemIndex: number;
    forecast: ForecastDto;
    multiple: number;
    multipleId?: string;
  }) => string;
  subTitle?: string;
  renderSpacer?: boolean;
  testId?: string;
};

const TableSectionValuesPerMultiple: FC<TableSectionValuesPerMultipleProps> = ({
  title,
  renderCell,
  computedCellClassNames,
  subTitle: subHeader,
  renderSpacer = true,
  testId,
}) => {
  const casesStructure = useAppSelector(selectCasesStructure);
  const shouldHighlightCell = useShouldHighlight();
  const maxMultiples = Math.max(...casesStructure.map((x) => x.multiples.length));
  return (
    <>
      {Array.from(Array(maxMultiples).keys()).map((multipleIndex) => (
        <tr key={multipleIndex}>
          <th className={classNames('table-primary__cell--vertical-separator')}>
            {multipleIndex === 0 ? title : ''}
            {multipleIndex === 1 ? subHeader : ''}
          </th>
          {casesStructure.map((caseItem, caseItemIndex) => {
            const multiple = caseItem.multiples[multipleIndex];
            const multipleId = multiple?.multipleId;
            return (
              <Fragment key={caseItemIndex}>
                <td className="table-primary__cell--vertical-separator">
                  {multiple && (
                    <CellValue
                      {...multiplierRules}
                      value={multiple.multiple}
                      theme={CellValueTheme.Tertiary}
                    />
                  )}
                </td>
                {caseItem.forecasts.map((forecast, forecastIndex) => (
                  <td
                    key={forecastIndex}
                    data-testid={
                      testId
                        ? `${testId}-c${caseItemIndex}-f${forecastIndex}-m${multipleIndex}`
                        : undefined
                    }
                    className={classNames(
                      multiple?.multiple &&
                        computedCellClassNames?.({
                          caseItem,
                          caseItemIndex,
                          forecast: forecast,
                          multiple: multiple.multiple,
                          multipleId,
                        }),
                      {
                        'table-primary__cell--vertical-separator':
                          caseItem.forecasts.length - 1 === forecastIndex,
                        'table-primary__cell--highlight':
                          multiple?.multiple &&
                          shouldHighlightCell(
                            caseItem.caseId,
                            forecast.forecastId,
                            multiple?.multiple
                          ),
                      }
                    )}>
                    {multiple?.multiple &&
                      renderCell({
                        caseItem,
                        caseItemIndex,
                        forecast: forecast,
                        multiple: multiple.multiple,
                        multipleId,
                      })}
                  </td>
                ))}
              </Fragment>
            );
          })}
        </tr>
      ))}
      {renderSpacer && <TableSpacerRow />}
    </>
  );
};
export default TableSectionValuesPerMultiple;
