import { FormProvider, useForm } from 'react-hook-form';
import { CapmAssumptionsDto } from '@app/shared/models/contracts/project-dto';
import * as projectActions from '@core/store/project-slice';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import {
  positiveValueValidator,
  getRequiredValidator,
} from '@core/validations/hook-forms/validators';
import {
  numericBasicFieldFormattingProps,
  percentageFieldFormattingProps,
  textFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { Card } from '@app/shared/components/card/Card';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { useEffect } from 'react';
import { Navigate, generatePath } from 'react-router';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { setValuationInputsSelectedTab } from '@app/core/store/ui-values-slice';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';

const PARENT_CLASSNAME = 'form';

export const CapmAssumptions = () => {
  useSetSelectedTab(RouteConstants.CapmAssumptions, setValuationInputsSelectedTab);

  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const { capmAssumptions } = project.pwermInput;

  const formMethods = useForm<CapmAssumptionsDto>({
    ...formConfigBase,
    defaultValues: {
      riskFreeRate: capmAssumptions.riskFreeRate,
      assetBeta: capmAssumptions.assetBeta,
      emrp: capmAssumptions.emrp,
      cemra: capmAssumptions.cemra,
      countrySpecificRiskPremium: capmAssumptions?.countrySpecificRiskPremium ?? null,
      inflationDifferential: capmAssumptions?.inflationDifferential ?? null,
      sizePremium: capmAssumptions?.sizePremium ?? null,
      companyRiskPremium: capmAssumptions?.companyRiskPremium ?? null,
      riskFreeRateCountry: capmAssumptions?.riskFreeRateCountry ?? '',
      sourceAndRunDate: capmAssumptions?.sourceAndRunDate ?? '',
    },
  });
  const { handleSubmit, getValues, trigger } = formMethods;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const submitData = async () => {
    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          pwermInput: { ...project.pwermInput, capmAssumptions: { ...getValues() } },
        },
      })
    ).unwrap();
  };

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <FormProvider {...formMethods}>
      <form onBlur={handleSubmit(submitData, submitData)}>
        <Card>
          <div className={`${PARENT_CLASSNAME}__row`}>
            <FormField
              name="riskFreeRate"
              label="Long term risk free rate (PWERM)"
              required
              rules={{
                ...getRequiredValidator(),
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
            <FormField
              name="assetBeta"
              label="Asset beta"
              required
              rules={{
                ...getRequiredValidator(),
                ...positiveValueValidator(true),
              }}
              placeholder="0.00"
              {...numericBasicFieldFormattingProps}
              isGapless
            />
            <FormField
              name="emrp"
              label="EMRP"
              required
              rules={{
                ...getRequiredValidator(),
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
            <FormField
              name="cemra"
              label="CEMRA"
              required
              rules={{
                ...getRequiredValidator(),
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
          </div>
          <div className={`${PARENT_CLASSNAME}__row`}>
            <FormField
              name="countrySpecificRiskPremium"
              label="Country specific risk premium"
              {...percentageFieldFormattingProps}
              isGapless
            />
            <FormField
              name="inflationDifferential"
              label="Inflation differential"
              {...percentageFieldFormattingProps}
              isGapless
            />
            <FormField
              name="sizePremium"
              label="Size premium"
              {...percentageFieldFormattingProps}
              isGapless
            />
            <FormField
              name="companyRiskPremium"
              label="Company risk premium"
              {...percentageFieldFormattingProps}
              isGapless
            />
          </div>
          <div className={`${PARENT_CLASSNAME}__row`}>
            <FormField
              name="riskFreeRateCountry"
              label="Risk free rate country"
              {...textFieldFormattingProps}
              isGapless
            />
            <FormField
              name="sourceAndRunDate"
              label="Source and run date"
              {...textFieldFormattingProps}
              isGapless
            />
          </div>
        </Card>
      </form>
    </FormProvider>
  );
};
