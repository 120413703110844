import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import * as projectActions from '@app/core/store/project-slice';
import {
  MapUpdatedProjectAdminToProjectDto,
  ProjectFormModel,
} from '@app/modules/projects/inputs/project-admin/project-form/project-form-types';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation } from '@app/shared/components/card/card-enums';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import {
  Currency,
  MultipleType,
  Denomination,
  CalcMethod,
  OpmSimulatedValue,
} from '@app/shared/models/contracts/enums/shared-enums';
import { ProjectStatusSelectionWidget } from '@app/shared/components/project-status-selection-widget/ProjectStatusSelectionWidget';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { enumKeyByValue } from '@app/shared/helpers';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import {
  greaterDateThanValidator,
  lesserOrEqualDateThanValidator,
  maxLengthValidator,
  getRequiredValidator,
  eventsBeforeDateNotAppliedToAllCasesValidator,
} from '@core/validations/hook-forms/validators';
import { CURRENCY_OPTIONS } from '@app/shared/constants/dropdown-values/currency-options';
import { MIP_EVENT_OPTIONS } from '@app/shared/constants/dropdown-values/mip-event-options';
import { SECTOR_OPTIONS } from '@app/shared/constants/dropdown-values/sector-options';
import { SUB_SECTOR_OPTIONS } from '@app/shared/constants/dropdown-values/sub-sector-options';
import { REGION_OPTIONS } from '@app/shared/constants/dropdown-values/region-options';
import { COUNTRY_OPTIONS } from '@app/shared/constants/dropdown-values/country-options';
import styles from './edit-project-form.module.scss';
import { Toggle, ToggleSize } from '@app/shared/components/toggle/Toggle';
import { Tooltip } from '@app/shared/components/tooltip/Tooltip';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import {
  BENCHMARKING_DATA_TOOLTIP_WIDTH,
  PROJECT_NAME_TOOLTIP_WIDTH,
} from '@app/shared/components/tooltip/constants';
import { DEAL_THESIS_EXIT_MULTIPLE_OPTIONS } from '@app/shared/constants/dropdown-values/deal-thesis-exit-multiple-options';
import { OPM_SIMULATED_VALUE_OPTIONS } from '@app/shared/constants/dropdown-values/ev_or_equity_method';
import { DENOMINATION_OPTIONS } from '@app/shared/constants/dropdown-values/denomination-options';
// import ProjectSectionStatus from '../project-input-progress/ProjectSectionStatus';
import Tabs from '@app/shared/components/tabs/Tabs';
import TabPane from '@app/shared/components/tabs/TabPane/TabPane';
import SvgInformation from '@app/shared/icons/Information';
import store from '@app/core/store/store';
import * as notificationActions from '@app/core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { useLocale } from '@app/core/hooks/useLocale';
import numeral from 'numeral';

const EditProjectFormNew: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const opmSimulationCountOptions = useAppSelector(
    (state) => state.uiValues.validOpmIterationCounts
  ).map((it) => ({
    value: it,
    viewValue: numeral(it).format('0,0'),
  }));
  const dispatch = useAppDispatch();
  const { maxYearsBetweenEventsAndValuation, maxMonthsBetweenValuationAndFYED } = useAppSelector(
    (state) => state.uiValues
  );
  const earliestEventDate = useMemo(() => {
    const eventDates = Object.values(project.capitalStructures).flatMap((cs) =>
      Object.values(cs.events).map((e) => new Date(e.eventDate))
    );
    return eventDates.length > 0 ? eventDates.sort((a, b) => a.getTime() - b.getTime())[0] : null;
  }, [project.capitalStructures]);
  const latestAllowedValuationDate = useMemo(() => {
    if (!earliestEventDate) {
      return null;
    }
    // copy of date required as addYears mutates the date, and earliestEventDate is used elsewhere
    const earliestEventDateCopy = new Date(earliestEventDate);
    return earliestEventDateCopy.addYears(maxYearsBetweenEventsAndValuation);
  }, [earliestEventDate, maxYearsBetweenEventsAndValuation]);
  const eventsNotAppliedToAllCasesDates = useMemo(() => {
    const allEventIds = new Set(
      Object.values(project.capitalStructures).flatMap((cs) => Object.keys(cs.events))
    );
    const eventsNotAppliedToAllCases = Array.from(allEventIds).filter((eventId) => {
      return !Object.values(project.capitalStructures).every((cs) =>
        Object.values(cs.eventSets).every((es) => es.events.includes(eventId))
      );
    });
    return eventsNotAppliedToAllCases
      .map((eventId) =>
        Object.values(project.capitalStructures).map((cs) => cs.events[eventId].eventDate)
      )
      .flat();
  }, [project.capitalStructures]);
  const getValuationDate = () => {
    const valuationDate = new Date(project.valuationDate);
    return isFinite(valuationDate as unknown as number) ? valuationDate : null;
  };
  const valuationDate = getValuationDate();
  const latestAllowedFYEDDate = valuationDate?.addMonths(maxMonthsBetweenValuationAndFYED);

  const { details: projectDetails } = project;
  /* const defaultSectionValue = {
    GeneralSettings: false,
    Methodology: false,
    EngagementDetails: false,
    ClientInformation: false,
    Geography: false,
    Sector: false,
  }; */

  const GeneralSettings = useRef(null);
  const Methodology = useRef(null);
  const EngagementDetails = useRef(null);
  const ClientInformation = useRef(null);
  const Geography = useRef(null);
  const Sector = useRef(null);
  const { l } = useLocale();

  /* const [statusSection, setStatusSection] = useState(defaultSectionValue); */

  const formMethods = useForm<ProjectFormModel>({
    ...formConfigBase,
    defaultValues: {
      ...projectDetails,
      projectName: project.name ?? '',
      status: project.status,
      currency: projectDetails.currency ?? enumKeyByValue(Currency, Currency.PoundSterling),
      denomination:
        projectDetails.denomination ?? enumKeyByValue(Denomination, Denomination.Thousands),
      calcMethod: projectDetails.calcMethod ?? enumKeyByValue(CalcMethod, CalcMethod.PWERM),
      opmSimulatedValue:
        project.opmInput.opmSimulatedValue ??
        enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue),

      valuationDate: project.valuationDate,
      yearEndDate: project.yearEndDate,
      investmentDate: project.investmentDate,

      client: projectDetails.client ?? '',
      targetTradeName: projectDetails.targetTradeName ?? '',
      sponsor: projectDetails.sponsor ?? '',
      coInvestor: projectDetails.coInvestor ?? '',

      engagementLetterDate: projectDetails.engagementLetterDate,
      valuationProjectLead: projectDetails.valuationProjectLead ?? '',
      dealsTaxProjectLead: projectDetails.dealsTaxProjectLead ?? '',
      salesforceId: projectDetails.salesforceId ?? '',
      qcSignOff: projectDetails.qcSignOff ?? false,
      qvpSignOff: projectDetails.qvpSignOff ?? false,
      qcSignOffUsername: projectDetails.qcSignOffUsername ?? '',
      qvpSignOffUsername: projectDetails.qvpSignOffUsername ?? '',
      randomiserActive: project.opmInput.randomiserActive ?? false,
      iterationCount: project.opmInput.iterationCount ?? 20000,
      storeBenchmarkingData: project.storeBenchmarkingData,
      dealThesisExitMultipleType:
        projectDetails.dealThesisExitMultipleType ?? enumKeyByValue(MultipleType, MultipleType.Ltm),
    },
  });

  const { watch, trigger } = formMethods;

  useEffect(() => {
    // re-trigger the form validation once the config values have loaded (in case the user comes direct to this page)
    trigger();
  }, [trigger, maxYearsBetweenEventsAndValuation, maxMonthsBetweenValuationAndFYED]);

  // Code for side project sectyion status
  /* const calcStatus = () => {
    let GeneralSettings = 'incomplete';
    if (
      project.name &&
      project.investmentDate &&
      project.valuationDate &&
      project.yearEndDate &&
      project.details.currency &&
      project.details.denomination &&
      project.details.mipEvent
    ) {
      GeneralSettings = 'complete';
    } else if (
      project.name &&
      project.investmentDate &&
      project.valuationDate &&
      project.yearEndDate &&
      project.details.currency &&
      project.details.denomination
    ) {
      GeneralSettings = 'required complete';
    } else if (
      !project.name ||
      !project.investmentDate ||
      !project.valuationDate ||
      !project.yearEndDate ||
      !project.details.currency ||
      !project.details.denomination
    ) {
      GeneralSettings = 'required not complete';
    } else {
      GeneralSettings = 'incomplete';
    }

    let Methodology = 'incomplete';
    if (
      project.details.dealsTaxProjectLead &&
      project.details.engagementLetterDate &&
      project.details.salesforceId &&
      project.details.valuationProjectLead
    ) {
      Methodology = 'complete';
    } else if (
      project.details.dealsTaxProjectLead ||
      project.details.engagementLetterDate ||
      project.details.salesforceId ||
      project.details.valuationProjectLead
    ) {
      Methodology = 'required complete';
    } else {
      Methodology = 'incomplete';
    }

    let EngagementDetails = 'incomplete';
    if (
      project.details.dealsTaxProjectLead &&
      project.details.engagementLetterDate &&
      project.details.salesforceId &&
      project.details.valuationProjectLead
    ) {
      EngagementDetails = 'complete';
    } else if (
      project.details.dealsTaxProjectLead ||
      project.details.engagementLetterDate ||
      project.details.salesforceId ||
      project.details.valuationProjectLead
    ) {
      EngagementDetails = 'required complete';
    } else {
      EngagementDetails = 'incomplete';
    }

    let ClientInformation = 'incomplete';
    if (
      project.details.client &&
      project.details.targetTradeName &&
      project.details.sponsor &&
      project.details.coInvestor
    ) {
      ClientInformation = 'complete';
    } else if (
      project.details.client ||
      project.details.targetTradeName ||
      project.details.sponsor ||
      project.details.coInvestor
    ) {
      ClientInformation = 'required complete';
    } else {
      ClientInformation = 'incomplete';
    }

    let Geography = 'incomplete';
    if (
      project.details.regionOfOperations &&
      project.details.countryOfMainOperations &&
      project.details.mainTaxJurisdiction &&
      project.details.secondaryTaxJurisdiction
    ) {
      Geography = 'complete';
    } else if (
      project.details.regionOfOperations ||
      project.details.countryOfMainOperations ||
      project.details.mainTaxJurisdiction ||
      project.details.secondaryTaxJurisdiction
    ) {
      Geography = 'required complete';
    } else {
      Geography = 'incomplete';
    }

    let Sector = 'incomplete';
    if (project.details.sector && project.details.subSector) {
      Sector = 'complete';
    } else if (project.details.sector || project.details.subSector) {
      Sector = 'required complete';
    } else {
      Sector = 'incomplete';
    }

    setStatusSection({
      ...statusSection,
      EngagementDetails: EngagementDetails,
      GeneralSettings: GeneralSettings,
      ClientInformation: ClientInformation,
      Geography: Geography,
      Sector: Sector,
      Methodology: Methodology,
    });
  };

  useEffect(() => {
    calcStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]); */

  const getIndex = (key: string) => {
    switch (key) {
      case 'PWERM':
        return 0;
      case 'OPM':
        return 1;
      case 'PWERM_AND_OPM':
        return 2;
      default:
        return 0;
    }
  };

  const formSubmitHandler = useCallback(async () => {
    await dispatch(
      projectActions.updateProjectDraft({
        project: MapUpdatedProjectAdminToProjectDto(formMethods.getValues(), project),
      })
    ).unwrap();
  }, [dispatch, formMethods, project]);

  useEffect(() => {
    formMethods.setValue(
      `qcSignOff`,
      projectDetails.qcSignOffUsername === '' ? false : projectDetails.qcSignOff
    );
    formMethods.setValue(
      `qvpSignOff`,
      projectDetails.qvpSignOffUsername === '' ? false : projectDetails.qvpSignOff
    );
    formMethods.setValue(`randomiserActive`, project.opmInput.randomiserActive);
    formSubmitHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectDetails.qcSignOff,
    projectDetails.qcSignOffUsername,
    projectDetails.qvpSignOff,
    projectDetails.qvpSignOffUsername,
    project.opmInput.randomiserActive,
  ]);

  return (
    <FormProvider {...formMethods}>
      <form
        data-cy="editProjectForm"
        onBlur={formMethods.handleSubmit(
          formSubmitHandler as SubmitHandler<ProjectFormModel>,
          formSubmitHandler as SubmitErrorHandler<ProjectFormModel>
        )}>
        <div ref={GeneralSettings} className={styles['flex-layout']}>
          <div className={styles['heading-size']}>{l('_GeneralSettings')}</div>
          <div className={styles['information-and-status']}>
            <Card isFullHeight cornersVariation={CardCornersVariation.Secondary}>
              <div className={styles['information-and-status__card-content']}>
                <div className={styles['row-span']}>
                  <div
                    className={`${styles['project-details-toggle']} ${styles['position-relative']} ${styles['flex-grow']} ${styles['margin-right-30']}`}>
                    <FormField
                      name="projectName"
                      label={l('_ProjectName')}
                      placeholder={l('_Name')}
                      {...textFieldFormattingProps}
                      rules={{
                        ...getRequiredValidator(),
                      }}
                      isGapless
                    />
                    <Tooltip
                      content={
                        <>
                          {l('_ProjectNameFormat')}
                          <br />
                          <br />
                          {l('_ProjectNameCannotIncludeProject')}
                        </>
                      }
                      width={PROJECT_NAME_TOOLTIP_WIDTH}
                      className={styles['project-tooltip-position']}>
                      <Button
                        appearance={ButtonAppearance.CLEAN}
                        size={ButtonSize.FLUID}
                        aria-label={l('_MoreInformation')}>
                        <SvgInformation
                          className={styles['project-details-toggle--warning-icon']}
                        />
                      </Button>
                    </Tooltip>
                  </div>

                  <div>
                    <div className={styles['margin-bottom-5']}>
                      <ProjectStatusSelectionWidget />
                    </div>
                    <div className={styles['project-details-toggle']}>
                      <Toggle
                        name="storeBenchmarkingData"
                        label={l('_StoreBenchmarkingData')}
                        toggleSize={ToggleSize.Medium}
                        labelTextClassName={styles['project-details-toggle-label']}
                      />
                      <div className={styles['margin-left-10']}>
                        <Tooltip
                          content={l('_BenchmarkingDataWarning')}
                          width={BENCHMARKING_DATA_TOOLTIP_WIDTH}>
                          <Button
                            appearance={ButtonAppearance.CLEAN}
                            size={ButtonSize.FLUID}
                            aria-label={l('_MoreInformation')}>
                            <SvgInformation
                              className={styles['project-details-toggle--warning-icon']}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <FormDatePicker
                  name="valuationDate"
                  label={l('_ValuationDate')}
                  data-testid="valuationDate"
                  rules={{
                    ...getRequiredValidator(),
                    validate: {
                      notTooFarAfterEventDate:
                        latestAllowedValuationDate && maxYearsBetweenEventsAndValuation
                          ? lesserOrEqualDateThanValidator(
                              latestAllowedValuationDate?.toISOString(),
                              l('_ValuationDateCannotBeMoreThanXYearsAfterEventDate', {
                                maxYearsBetweenEventsAndValuation,
                              })
                            ).validate
                          : () => true,
                      notAllowingEventsNotAppliedToAllCasesBeforeValuationDate:
                        eventsNotAppliedToAllCasesDates.length > 0
                          ? eventsBeforeDateNotAppliedToAllCasesValidator(
                              eventsNotAppliedToAllCasesDates,
                              l('_EventsBeforeDateNotAppliedToAllCases', {
                                dateType: l('_ValuationDate'),
                              })
                            ).validate
                          : () => true,
                    },
                  }}
                  onBlur={async () => {
                    await trigger(['investmentDate', 'yearEndDate']);
                  }}
                  formSubmitHandler={async () => {
                    await formSubmitHandler();
                    await trigger(['investmentDate', 'yearEndDate']);
                    store.dispatch(
                      notificationActions.showNotificationSnackbar({
                        severity: SeverityType.warning,
                        message: l('_ValuationDateUpdatedPleaseCheckValuationInputs'),
                        autoHide: false,
                      })
                    );
                  }}
                />
                <FormDatePicker
                  name="investmentDate"
                  label={l('_InvestmentDateTitle')}
                  rules={{
                    ...getRequiredValidator(),
                    validate: {
                      lesserDateThanEarliestEventDate: earliestEventDate
                        ? lesserOrEqualDateThanValidator(
                            earliestEventDate?.toISOString(),
                            l('_InvestmentDateCannotBeLaterThanEventDate')
                          ).validate
                        : () => true,
                    },
                  }}
                  onBlur={async () => {
                    await trigger(['yearEndDate']);
                  }}
                  formSubmitHandler={async () => {
                    await formSubmitHandler();
                    await trigger(['investmentDate', 'yearEndDate']);
                  }}
                />
                <FormDatePicker
                  name="yearEndDate"
                  label={l('_FirstYearEndDate')}
                  rules={{
                    ...getRequiredValidator(),
                    validate: {
                      greaterDateThanValuationDate: greaterDateThanValidator(
                        watch('valuationDate'),
                        l('_Field1MustBeGreaterThanField2', {
                          field1: l('_FirstYearEndDate'),
                          field2: l('_ValuationDate'),
                        })
                      ).validate,
                      greaterDateThanInvestmentDate: greaterDateThanValidator(
                        watch('investmentDate'),
                        l('_Field1MustBeGreaterThanField2', {
                          field1: l('_FirstYearEndDate'),
                          field2: l('_InvestmentDateTitle'),
                        })
                      ).validate,
                      notTooFarAfterValuationDate: maxMonthsBetweenValuationAndFYED
                        ? lesserOrEqualDateThanValidator(
                            latestAllowedFYEDDate?.toISOString() ?? null,
                            l('_FirstYearEndDateCannotBeMoreThanXMonthsAfterValuationDate', {
                              maxMonthsBetweenValuationAndFYED,
                            })
                          ).validate
                        : () => true,
                    },
                  }}
                  isGapless
                  formSubmitHandler={formSubmitHandler}
                />
                <FormSelect
                  name="currency"
                  label={l('_Currency')}
                  options={CURRENCY_OPTIONS}
                  rules={{ ...getRequiredValidator() }}
                  isGapless
                />
                <FormSelect
                  name="mipEvent"
                  label={l('_MIPEvent')}
                  options={MIP_EVENT_OPTIONS}
                  isGapless
                />
                <FormSelect
                  name="denomination"
                  label={l('_Denomination')}
                  options={DENOMINATION_OPTIONS}
                  rules={{ ...getRequiredValidator() }}
                  isGapless
                />
              </div>
            </Card>
          </div>
        </div>
        <div ref={Methodology} className={styles['flex-layout']}>
          <div className={styles['heading-size']}>{l('_Methodology')}</div>
          <div className={styles['information-and-status']}>
            <Card
              isFullHeight
              cornersVariation={CardCornersVariation.Secondary}
              className={styles['tabs-minimum-height']}>
              <Tabs
                formSubmitHandler={formSubmitHandler}
                formMethods={formMethods}
                setName="calcMethod"
                preSelectedTabIndex={getIndex(projectDetails.calcMethod)}
                enumName={CalcMethod}>
                <TabPane title={l('_Pwerm')} name="calcMethod" key="PWERM">
                  <div className={styles['padding-top-20']}>
                    <FormSelect
                      name="dealThesisExitMultipleType"
                      data-testid="dealThesisExitMultipleType"
                      label={l('_DealThesisExitMultipleType')}
                      options={DEAL_THESIS_EXIT_MULTIPLE_OPTIONS}
                      isGapless
                    />
                  </div>
                </TabPane>
                <TabPane title={l('_Opm')} name="calcMethod" key="OPM">
                  <div className={styles['padding-top-20']}>
                    <FormSelect
                      name="opmSimulatedValue"
                      data-testid="opmSimulatedValue"
                      label={l('_EnterpriseValueOrEquity')}
                      options={OPM_SIMULATED_VALUE_OPTIONS}
                      isGapless
                    />
                    <div className={styles['project-details-toggle']}>
                      <Toggle
                        name="randomiserActive"
                        label={l('_RandomiserActive')}
                        toggleSize={ToggleSize.Medium}
                        onChange={() => formSubmitHandler()}
                        labelTextClassName={styles['project-details-toggle-label']}
                        data-testid="project-details-radomiser-active-toggle"
                      />
                      <div className={styles['margin-left-10']}>
                        <Tooltip
                          content={l(
                            '_WhenToggleIsActiveValuesWillBeRandomisedEachTimeYouRunCalculations'
                          )}
                          width={BENCHMARKING_DATA_TOOLTIP_WIDTH}>
                          <Button
                            appearance={ButtonAppearance.CLEAN}
                            size={ButtonSize.FLUID}
                            aria-label={l('_MoreInformation')}>
                            <SvgInformation
                              className={styles['project-details-toggle--warning-icon']}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className={styles['padding-top-20']}>
                    <FormSelect
                      name="iterationCount"
                      data-testid="iterationCount"
                      label={l('_NumberOfSimulations')}
                      options={opmSimulationCountOptions}
                      isGapless
                    />
                  </div>
                </TabPane>

                <TabPane title={l('_PwermAndOpm')} name="calcMethod" key="PWERMOPM">
                  <div className={styles['border-line']}>
                    <FormSelect
                      name="dealThesisExitMultipleType"
                      data-testid="dealThesisExitMultipleType"
                      label={l('_DealThesisExitMultipleType')}
                      options={DEAL_THESIS_EXIT_MULTIPLE_OPTIONS}
                      isGapless
                    />
                  </div>
                  <div className={styles['padding-top-20']}>
                    <FormSelect
                      name="opmSimulatedValue"
                      data-testid="opmSimulatedValue"
                      label={l('_EnterpriseValueOrEquity')}
                      options={OPM_SIMULATED_VALUE_OPTIONS}
                      isGapless
                    />
                    <div className={styles['project-details-toggle']}>
                      <Toggle
                        name="randomiserActive"
                        label={l('_RandomiserActive')}
                        toggleSize={ToggleSize.Medium}
                        onChange={() => formSubmitHandler()}
                        labelTextClassName={styles['project-details-toggle-label']}
                        data-testid="project-details-radomiser-active-toggle"
                      />
                      <div className={styles['margin-left-10']}>
                        <Tooltip
                          content={l(
                            '_WhenToggleIsActiveValuesWillBeRandomisedEachTimeYouRunCalculations'
                          )}
                          width={BENCHMARKING_DATA_TOOLTIP_WIDTH}>
                          <Button
                            appearance={ButtonAppearance.CLEAN}
                            size={ButtonSize.FLUID}
                            aria-label={l('_MoreInformation')}>
                            <SvgInformation
                              className={styles['project-details-toggle--warning-icon']}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className={styles['padding-top-20']}>
                    <FormSelect
                      name="iterationCount"
                      data-testid="iterationCount"
                      label={l('_NumberOfSimulations')}
                      options={opmSimulationCountOptions}
                      isGapless
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </div>
        <div ref={EngagementDetails} className={styles['flex-layout']}>
          <div className={styles['heading-size']}>{l('_EngagementDetails')}</div>
          <div className={styles['information-and-status']}>
            <Card isFullHeight cornersVariation={CardCornersVariation.Secondary}>
              <div className={styles['information-and-status__card-content']}>
                <FormField
                  name="dealsTaxProjectLead"
                  label={l('_DealsTaxProjectLead')}
                  placeholder={l('_AddDetails')}
                  {...textFieldFormattingProps}
                />
                <FormDatePicker name="engagementLetterDate" label={l('_EngagementLetterDate')} />
                <FormField
                  name="salesforceId"
                  label={l('_SalesforceID')}
                  rules={{
                    ...maxLengthValidator(18),
                  }}
                  placeholder={l('_AddDetails')}
                  {...textFieldFormattingProps}
                  isGapless
                />
                <FormField
                  name="valuationProjectLead"
                  label={l('_ValuationProjectLead')}
                  placeholder={l('_AddDetails')}
                  {...textFieldFormattingProps}
                />
                <div>
                  <FormField
                    name="qcSignOffUsername"
                    label={l('_QC')}
                    rules={{
                      ...maxLengthValidator(100),
                    }}
                    placeholder={l('_InsertName')}
                    {...textFieldFormattingProps}
                    isGapless
                  />
                  {projectDetails.qcSignOffUsername && (
                    <Toggle
                      name="qcSignOff"
                      label={l('_SignedOff')}
                      toggleSize={ToggleSize.Medium}
                      labelTextClassName={styles['project-details-sign-off-label']}
                    />
                  )}
                </div>
                <div>
                  <FormField
                    name="qvpSignOffUsername"
                    label={l('_QVP')}
                    rules={{
                      ...maxLengthValidator(100),
                    }}
                    placeholder={l('_InsertName')}
                    {...textFieldFormattingProps}
                    isGapless
                  />
                  {projectDetails.qvpSignOffUsername && (
                    <Toggle
                      name="qvpSignOff"
                      label={l('_SignedOff')}
                      toggleSize={ToggleSize.Medium}
                      labelTextClassName={styles['project-details-sign-off-label']}
                    />
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div ref={ClientInformation} className={styles['flex-layout']}>
          <div className={styles['heading-size']}>{l('_ClientInformation')}</div>
          <div className={styles['information-and-status']}>
            <Card isFullHeight cornersVariation={CardCornersVariation.Secondary}>
              <div className={styles['information-and-status__card-content']}>
                <FormField
                  name="client"
                  label={l('_ClientLegalEntity')}
                  placeholder={l('_AddDetails')}
                  {...textFieldFormattingProps}
                />
                <FormField
                  name="targetTradeName"
                  label={l('_TargetTradeName')}
                  placeholder={l('_AddDetails')}
                  {...textFieldFormattingProps}
                />
                <FormField
                  name="sponsor"
                  label={l('_Sponsor')}
                  placeholder={l('_AddDetails')}
                  {...textFieldFormattingProps}
                />
                <FormField
                  name="coInvestor"
                  label={l('_CoInvestor')}
                  placeholder={l('_AddDetails')}
                  {...textFieldFormattingProps}
                  isGapless
                />
              </div>
            </Card>
          </div>
        </div>
        <div ref={Geography} className={styles['flex-layout']}>
          <div className={styles['heading-size']}>{l('_Geography')}</div>
          <div className={styles['information-and-status']}>
            <Card isFullHeight cornersVariation={CardCornersVariation.Secondary}>
              <div className={styles['information-and-status__card-content']}>
                <FormSelect
                  name="regionOfOperations"
                  label={l('_RegionOfOperations')}
                  options={REGION_OPTIONS}
                />
                <FormSelect
                  name="countryOfMainOperations"
                  label={l('_CountryOfMainOperations')}
                  options={COUNTRY_OPTIONS}
                />
                <FormSelect
                  name="mainTaxJurisdiction"
                  label={l('_MainTaxJurisdiction')}
                  options={COUNTRY_OPTIONS}
                />
                <FormSelect
                  name="secondaryTaxJurisdiction"
                  label={l('_SecondaryTaxJurisdiction')}
                  options={COUNTRY_OPTIONS}
                  isGapless
                />
              </div>
            </Card>
          </div>
        </div>
        <div ref={Sector} className={styles['flex-layout']}>
          <div className={styles['heading-size']}>{l('_Sector')}</div>
          <div className={styles['information-and-status']}>
            <Card isFullHeight cornersVariation={CardCornersVariation.Secondary}>
              <div className={styles['information-and-status__card-content']}>
                <FormSelect name="sector" label={l('_Sector')} options={SECTOR_OPTIONS} />
                <FormSelect
                  name="subSector"
                  label={l('_SubSector')}
                  options={SUB_SECTOR_OPTIONS}
                  isGapless
                />
              </div>
            </Card>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditProjectFormNew;
