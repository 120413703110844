import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import {
  amountBasicFieldFormattingProps,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { NUMBER_OF_PARTICIPANTS_IN_THE_MIP_OPTIONS } from '@app/shared/constants/dropdown-values/number-of-participats-in-mip-options';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useLocale } from '@app/core/hooks/useLocale';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';

const ManagementParticipation: FC = (): JSX.Element => {
  const {
    status,
    storeBenchmarkingData,
    benchmarking: { benchmarkingOnly },
  } = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();
  const { benchmarkingInputs } = useAppSelector((state) => state.benchmarking);

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>{l('_ManagementParticipation')}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name="managementParticipation.rolloverOfManagementNetProceeds"
            label={l('_RolloverOfManagementsNetProceeds', {
              interpolation: { escapeValue: false },
            })}
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="managementParticipation.numberOfParticipantsInTheMIP"
            label={l('_NumberOfParticipantsInTheMIP')}
            options={NUMBER_OF_PARTICIPANTS_IN_THE_MIP_OPTIONS}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="managementParticipation.ceoPercentOfSweetEquityParticipation"
            label={l('_CEOPercentageOfSweetEquityParticipation')}
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_ManagementStripPercentage')}
              value={getCalculatedProjectValueInPercent(benchmarkingInputs?.managementStripPc)}
              {...enteredPercentageViewRules}
              isGapless
            />
          ) : (
            <FormField
              name="managementParticipation.managementStripPercentage"
              label={l('_ManagementStripPercentage')}
              defaultValue={
                getCalculatedProjectValueInPercent(benchmarkingInputs?.managementStripPc) ?? 0
              }
              rules={{
                ...(shouldFieldsBeRequired && getRequiredValidator()),
                ...percentageValidator,
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column">
          <FormField
            name="managementParticipation.sweetSubscriptionCostPlusFMVLoans"
            label={l('_SweetSubscriptionCostPlusFMVLoans')}
            placeholder="0"
            {...amountBasicFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="managementParticipation.cfoPercentOfSweetEquityParticipation"
            label={l('_CFOPercentageOfSweetEquityParticipation')}
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_ManagementStripTotalSubscriptionCost')}
              value={benchmarkingInputs?.managementStripTotalSubscriptionCost ?? 0}
              {...amountRules}
              isGapless
            />
          ) : (
            <FormField
              name="managementParticipation.managementStripTotalSubscriptionCost"
              label={l('_ManagementStripTotalSubscriptionCost')}
              defaultValue={benchmarkingInputs?.managementStripTotalSubscriptionCost ?? 0}
              placeholder="0"
              rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
              {...amountBasicFieldFormattingProps}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column" />
        <div className="form-row__column">
          <FormField
            name="managementParticipation.chairmanPercentOfSweetEquityParticipation"
            label={l('_ChairmanPercentageOfSweetEquityParticipation')}
            required
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
      </div>
    </Card>
  );
};

export default ManagementParticipation;
