import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { enumKeyByValue, instrumentOwnersSortFn } from '@app/shared/helpers';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import classNames from 'classnames';
import {
  getDealThesisInputCaseForecastDate,
  getDealThesisInputCaseForecastDateShareholderDebtTotalByType,
  getDealThesisInputCaseHistoricEventsDate,
  getDealThesisInputCaseHistoricEventsDateShareholderDebtTotalByType,
  getDealThesisInputCaseValuationDate,
  getDealThesisInputCaseValuationDateShareholderDebtTotalByType,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';
import DealThesisEmptyHistoricDataColumns from '../../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';

const SlnAndPrefSharesForecastSummarySection2: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const numberOfResultsForecastYears = Object.keys(results.cases[caseId!].forecastDates).length;
  const caseResults = results.cases[caseId!];
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId)!;
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseForecasts = caseData.forecasts.slice(0, numberOfResultsForecastYears);
  const numberOfInputHistoricForecastYears = project.pwermInput.historicForecasts.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicForecasts = Object.entries(caseResults.historicDates.historicForecastYears ?? {})
    .map(([historicYears, value]) => ({
      historicYears,
      ...value,
    }))
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicEvents = Object.entries(caseResults.historicDates.historicEventsYears ?? {})
    .map(([historicEventsYear, value]) => ({
      historicEventsYear,
      ...value,
    }))
    .reverse();
  const maxHistoricalDataLength = Math.max(historicForecasts.length, historicEvents.length);

  const firstForecast = Object.values(results.cases[caseId!].forecastDates)[0];

  const sectionColCount =
    (caseData?.forecasts ? caseData?.forecasts.length : 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
    maxHistoricalDataLength;

  const renderSummarySection = (instrumentType: InstrumentType): JSX.Element => {
    const instrumentTypeKey = enumKeyByValue(InstrumentType, instrumentType);
    return (
      <>
        <tr className="table-primary__row--header">
          <th
            className="table-primary__cell--section-start-separator table-primary__cell--header-secondary"
            colSpan={sectionColCount}>
            {instrumentType}
          </th>
        </tr>
        {Object.keys(results.cases[caseId!].valuationDate.shareholderDebtSummary[instrumentTypeKey])
          .sort((a, b) =>
            instrumentOwnersSortFn(a as keyof typeof OwnerType, b as keyof typeof OwnerType)
          )
          .map((ownerType, index) => {
            return (
              <tr key={ownerType}>
                <th
                  className={classNames('table-primary__cell--vertical-separator', {
                    'table-primary__cell--section-start-separator': index === 0,
                  })}>
                  {OwnerType[ownerType as keyof typeof OwnerType]} {l('_Total')}
                </th>
                <DealThesisEmptyHistoricDataColumns
                  isHistoricEventsSection
                  isStartSeparator={index === 0}
                />
                {showHistoricDataColumns &&
                  historicEvents.map((historicEventYear) => (
                    <td
                      key={historicEventYear.historicEventsYear}
                      className={classNames({
                        'table-primary__cell--section-start-separator': index === 0,
                      })}>
                      <CellValue
                        {...amountRules}
                        value={getDealThesisInputCaseHistoricEventsDate(
                          results,
                          caseId!,
                          historicEventYear.historicEventsYear,
                          (historicForecast) =>
                            historicForecast.shareholderDebtSummary[instrumentTypeKey][ownerType]
                        )}
                      />
                    </td>
                  ))}
                <td
                  className={classNames({
                    'table-primary__cell--section-start-separator': index === 0,
                  })}>
                  <CellValue
                    {...amountRules}
                    value={getDealThesisInputCaseValuationDate(
                      results,
                      caseId!,
                      (caseItem) => caseItem.shareholderDebtSummary[instrumentTypeKey][ownerType]
                    )}
                  />
                </td>
                {caseForecasts?.map((forecast) => (
                  <td
                    key={forecast.forecastId}
                    className={classNames({
                      'table-primary__cell--section-start-separator': index === 0,
                    })}>
                    <CellValue
                      {...amountRules}
                      value={getDealThesisInputCaseForecastDate(
                        results,
                        caseId!,
                        forecast.forecastYear,
                        (forecast) => forecast?.shareholderDebtSummary[instrumentTypeKey][ownerType]
                      )}
                    />
                  </td>
                ))}
                <td
                  className={classNames('table-primary__cell--vertical-separator', {
                    'table-primary__cell--section-start-separator': index === 0,
                  })}
                />
              </tr>
            );
          })}

        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
            <strong>
              {instrumentType} {l('_Total')}
            </strong>
          </th>
          <DealThesisEmptyHistoricDataColumns
            isHistoricEventsSection
            isEndSeparator
            isStartSeparator
          />
          {showHistoricDataColumns &&
            historicEvents.map((historicEventYear) => (
              <td
                key={historicEventYear.historicEventsYear}
                className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                <CellValue
                  {...amountRules}
                  value={getDealThesisInputCaseHistoricEventsDateShareholderDebtTotalByType(
                    results,
                    caseId!,
                    historicEventYear.historicEventsYear,
                    instrumentTypeKey
                  )}
                  strong
                />
              </td>
            ))}
          <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
            <CellValue
              {...amountRules}
              value={getDealThesisInputCaseValuationDateShareholderDebtTotalByType(
                results,
                caseId!,
                instrumentTypeKey
              )}
              strong
            />
          </td>
          {caseForecasts?.map((forecast) => (
            <td
              className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator"
              key={forecast.forecastId + 'sln-totals-sum'}>
              {/* REQUIRES UPDATING AFTER TOTAL IS ADDED TO BACKEND */}
              <CellValue
                {...amountRules}
                value={getDealThesisInputCaseForecastDateShareholderDebtTotalByType(
                  results,
                  caseId!,
                  forecast.forecastYear,
                  instrumentTypeKey
                )}
                strong
              />
            </td>
          ))}
          <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator" />
        </tr>
        <DealThesisSpacerRow2 />
      </>
    );
  };

  const showSlnSection = Object.keys(firstForecast.shareholderDebtSummary).some(
    (i) => i === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );

  const showPrefSharesSection = Object.keys(firstForecast.shareholderDebtSummary).some(
    (i) => i === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  );

  const showShareholderDebtSection = showSlnSection || showPrefSharesSection;

  return (
    <tbody>
      {showShareholderDebtSection && (
        <tr>
          <th
            className="table-primary__cell--header table-primary__cell--header-alternate"
            colSpan={sectionColCount}>
            {l('_SummaryShareholderDebt')}
          </th>
        </tr>
      )}
      {showSlnSection && renderSummarySection(InstrumentType.ShareholderLoanNotes)}
      {showPrefSharesSection && renderSummarySection(InstrumentType.PreferredShares)}
    </tbody>
  );
};

export default SlnAndPrefSharesForecastSummarySection2;
