import { FC } from 'react';
import styles from './dashboard.module.scss';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { Navigate, generatePath } from 'react-router';
import { RouteConstants } from '../RouteConstants';
import { Pwerm2ValuationEstimatesPwerm } from './pwerm2-widgets/valuation-estimates/Pwerm2ValuationEstimatesPwerm';
import { Pwerm2ValuationEstimatesByInstrument } from './pwerm2-widgets/valuation-estimates-by-instrument/Pwerm2ValuationEstimatesByInstrument';
import { Pwerm2KeyMetrics } from './pwerm2-widgets/key-metrics/Pwerm2KeyMetrics';
import { Pwerm2EvEvolution } from './pwerm2-widgets/ev-evolution/Pwerm2EvEvolution';
import Pwerm2TotalEquityPwerm from './pwerm2-widgets/total-equity/Pwerm2TotalEquityPwerm';

const PARENT_CLASSNAME = 'dashboard-grid';
const Pwerm2Dashboard: FC = (): JSX.Element => {
  const { isPwermValid, projectDraft: project } = useAppSelector((state) => state.project);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  if (!isPwermValid) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <Pwerm2ValuationEstimatesPwerm />
      <Pwerm2EvEvolution />
      <Pwerm2ValuationEstimatesByInstrument />
      <Pwerm2TotalEquityPwerm />
      <Pwerm2KeyMetrics />
    </div>
  );
};

export default Pwerm2Dashboard;
