import EmptyStateNoListSvg from '@app/shared/icons/legacy/EmptyStateNoListSvg';
import { FC } from 'react';
import styles from './capital-structure.module.scss';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import CapitalStructureItemForm from '@app/modules/projects/inputs/capital-structure/CapitalStructureItemForm';
import Button from '@app/shared/components/button/Button';
import * as projectActions from '@core/store/project-slice';
import Sidebar, { SidebarVariation } from '@app/shared/components/sidebar/Sidebar';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { getProjectDraftWithRemovedInstrumentLegacy } from '@app/shared/helpers';
import SvgTrash from '@app/shared/icons/Trash';
import { OwnerType } from '@app/shared/models/contracts/enums/shared-enums';

const PARENT_CLASSNAME = 'capital-structure';

const CapitalStructureSidebar: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const activeInstrumentId = useAppSelector((state) => state.uiValues.activeInstrument);
  const equityInstruments = useAppSelector((state) => state.project.projectDraft.equityInstruments);

  const getEquityItem = () => {
    const equityItem = equityInstruments.find(
      (x) => x.instrumentId === activeInstrumentId?.instrumentId
    );

    if (!equityItem) {
      return null;
    }

    return {
      ...equityItem,
      ownership: Object.keys(OwnerType).map((owner) => {
        // In some cases we do not have an entry for an owner type. Create null entries for the owners so it can be displayed in the UI
        return {
          owner: owner as keyof typeof OwnerType,
          amount:
            equityItem?.ownership.find((ownership) => ownership.owner === owner)?.amount ?? null,
          numberOfShares:
            equityItem?.ownership.find((ownership) => ownership.owner === owner)?.numberOfShares ??
            null,
        };
      }),
    };
  };

  const equityItem = getEquityItem();

  const dispatch = useAppDispatch();

  const removeInstrument = async (instrumentId: string | undefined) => {
    if (instrumentId) {
      await dispatch(
        projectActions.updateProjectDraft({
          project: getProjectDraftWithRemovedInstrumentLegacy({ draft: project, instrumentId }),
        })
      );

      dispatch(uiValuesSlice.actions.setActiveInstrument(undefined));
    }
  };

  return (
    <>
      {equityItem && (
        <Sidebar
          data-testid="instrument-details-sidebar"
          actions={
            <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
              <Button
                onClick={() => removeInstrument(activeInstrumentId?.instrumentId)}
                size={ButtonSize.FULL_WIDTH}
                startIcon={<SvgTrash />}
                appearance={ButtonAppearance.DEFAULT_SECONDARY}>
                Delete Instrument
              </Button>
            </div>
          }>
          <CapitalStructureItemForm equityItem={equityItem} />
        </Sidebar>
      )}
      {!activeInstrumentId && (
        <Sidebar sidebarVariation={SidebarVariation.Empty} data-testid="instrument-details-sidebar">
          <EmptyStateNoListSvg className={styles[`${PARENT_CLASSNAME}__icon`]} />
          <span>You haven’t selected anything yet</span>
        </Sidebar>
      )}
    </>
  );
};

export default CapitalStructureSidebar;
