import { useAppSelector } from '@app/core/hooks/redux-hooks';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import BuildStructureControls from './build-structure-erf/controls/BuildStructureControls';
import { CaseNavigationControls } from '../controls/CaseNavigationControls';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import styles from '../tabs/build-structure-tab/build-structure.module.scss';

interface CapitalStructureTabContentProps {
  children: ReactNode;
  isBuildStructure?: boolean;
}

export const CapitalStructureTabContent: FC<CapitalStructureTabContentProps> = ({
  children,
  isBuildStructure = false,
}): JSX.Element | null => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const capitalStructureId = project.pwermInput.cases[0].capitalStructureId;
  const isErfProject = buildStructures && buildStructures?.[capitalStructureId]?.isErf;
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;

  if (isErfProject && !caseId && !isOpmOnly) {
    return (
      <PwcNavigate
        route=":caseId"
        params={{
          caseId: project.pwermInput.cases[0].caseId,
        }}
      />
    );
  } else if (isErfProject && isOpmOnly && caseId) {
    return <PwcNavigate route="" />;
  } else if (!isErfProject && caseId) {
    return <PwcNavigate route="" />;
  }

  const navigationControls = isBuildStructure ? (
    <BuildStructureControls />
  ) : (
    <div className={styles['controls']}>
      <CaseNavigationControls />
    </div>
  );

  return (
    <>
      {isErfProject && !isOpmOnly && navigationControls}
      {children}
    </>
  );
};
