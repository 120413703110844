import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { sumBy } from '@app/shared/helpers';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { ForecastDto } from '@app/shared/models/contracts/project-dto';
import {
  GridFieldTooltipVariation,
  GridFieldVariation,
} from '@app/shared/components/grid-controls/grid-field-options';
import { TABLE_COL_WIDTH, TABLE_LABELS_COL_WIDTH } from '@app/shared/constants/table-config';
import {
  numberCalculatedViewRules,
  shortDateViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { selectTimesToExit } from '@app/core/store/pwerm-calculation-slice-selectors';
import { EmptyValues } from '@app/shared/constants/empty-values';
import Button from '@app/shared/components/button/Button';
import SvgFilledRightArrow from '@app/shared/icons/FilledRightArrow';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis/DealThesisPage';
import styles from '../deal-thesis-page.module.scss';

interface DealThesisCaseTableHeaderProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseTableHeader: FC<DealThesisCaseTableHeaderProps> = ({
  handleDataSubmit,
}): JSX.Element => {
  const { caseId } = useParams();
  const formMethods = useFormContext<DealThesisFormModel>();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedTimesToExit = useAppSelector(selectTimesToExit);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const forecastYears = caseData?.forecasts.length ?? 0;

  const populate = (inputField: string, cellIndex: number) => {
    const { ...updatedDealThesisInput } = formMethods.getValues();
    updatedDealThesisInput.forecasts.map((_, index: number) => {
      index >= cellIndex &&
        formMethods.setValue(
          `forecasts.${index}.${inputField as keyof ForecastDto}`,
          updatedDealThesisInput.forecasts[cellIndex][inputField as keyof ForecastDto]
        );
    });

    handleDataSubmit();
  };

  const shouldAutoPopulateFromCell = (index: number): boolean | undefined =>
    index !== forecastYears - 1;

  const forecastProbabilitiesTotal = caseData
    ? sumBy(caseData?.forecasts, (forecast) => Number(forecast.probability))
    : 0;

  const forecastProbabilitiesTotalWarningMessage =
    forecastProbabilitiesTotal !== 100
      ? `All forecast probabilities add up to ${
          !Number.isNaN(forecastProbabilitiesTotal)
            ? numberValueFormatter({
                value: forecastProbabilitiesTotal,
                ...percentageValueFormatterProps,
              })
            : EmptyValues.EnDash
        }, this must be 100%.`
      : undefined;

  return (
    <>
      <colgroup>
        <col style={{ minWidth: `${TABLE_LABELS_COL_WIDTH}px` }} />
        <col style={{ minWidth: `${TABLE_COL_WIDTH}px` }} />
        {caseData?.forecasts.map((_, index) => (
          <col key={index} />
        ))}
        <col className="table-primary__col-action" />
      </colgroup>
      <thead className="table-primary__sticky-section table-primary__sticky-section--deal-thesis-header">
        <tr>
          <th className="table-primary__cell--header-tertiary" />
          <th className="table-primary__cell--header-tertiary">
            <GridTextField
              name="historicalYear"
              placeholder="LTM"
              isInputMasked={false}
              alignment={Alignment.Right}
              variation={GridFieldVariation.TableHeader}
              rules={{
                ...getRequiredValidator(),
              }}
            />
          </th>
          {caseData?.forecasts.map((forecast, index) => (
            <th key={index} className="table-primary__cell--header-tertiary">
              <CellValue value={forecast.forecastYear} {...shortDateViewRules} />
            </th>
          ))}
          <th className="table-primary__cell--header-tertiary" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th className="table-primary__cell--vertical-separator">Time to exit</th>
          <th />
          {caseData?.forecasts.map((forecast, index) => (
            <td key={'time-to-exit' + forecast.forecastId + index}>
              <CellValue
                value={calculatedTimesToExit?.[forecast.forecastYear]}
                {...numberCalculatedViewRules}
                normalize
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">
            <CellValue
              value="Exit probability"
              tooltipVariation={GridFieldTooltipVariation.FitContainer}
              errorMessage={forecastProbabilitiesTotalWarningMessage}
            />
          </th>
          <th />
          {caseData?.forecasts.map((_, index) => (
            <td key={index + 'exit-probability'} className={styles['hoverable-cell']}>
              <GridTextField
                className={[shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '']}
                name={`forecasts.${index}.probability`}
                {...percentageFieldFormattingProps}
                rules={{
                  ...getRequiredValidator(),
                  ...percentageValidator,
                }}
                alignment={Alignment.Right}
              />
              {shouldAutoPopulateFromCell(index) && (
                <Button
                  startIcon={<SvgFilledRightArrow />}
                  className={styles['deal-thesis-populate-button']}
                  appearance={ButtonAppearance.CLEAN}
                  size={ButtonSize.FLUID}
                  // stop the onBlur event occurring so we can control when the data is submitted
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => populate('probability', index)}
                  data-testid={`populate-probability-${index}`}
                  autoIconSize
                />
              )}
            </td>
          ))}
          <td />
        </tr>
      </tbody>
    </>
  );
};
