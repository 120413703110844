import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import styles from './secondary-navigation-item.module.scss';
import classNames from 'classnames';
import PwcNavLink from '@app/shared/routing/PwcNavLink';
import { SecondaryNavigationItemVariation } from '@app/shared/components/secondary-navigation/secondary-navigation-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { CapitalStructureRoutes } from '@app/modules/projects/inputs/capital-structure/CapitalStructureNavigation';
import { Tooltip, TooltipPlacement } from '../tooltip/Tooltip';
import { useLocale } from '@app/core/hooks/useLocale';

interface SecondaryNavigationItemProps
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  isActive?: boolean;
  isHighlighted?: boolean;
  route?: string;
  params?: Record<string, string>;
  additionalData?: string | number;
  variation?: SecondaryNavigationItemVariation;
}

export const SecondaryNavigationItem = ({
  children,
  isActive = false,
  isHighlighted = false,
  route,
  additionalData,
  params,
  variation,
  ...rest
}: SecondaryNavigationItemProps) => {
  const PARENT_CLASSNAME = 'navigation-item';
  const { isCapitalStructureValid } = useAppSelector((state) => state.project);
  const { capitalStructureErrorMessageList } = useAppSelector((state) => state.notification);
  const { l } = useLocale();
  let tooltipPrefix = '';
  let errorList: string[] = [];
  let enableTooltip = false;
  let disabled = false;

  const capitalStructureOutputRoutes: string[] = [
    CapitalStructureRoutes.CapTable,
    CapitalStructureRoutes.ParticipationTable,
  ];

  // disable capital structure outputs if there are validation errors
  if (route && capitalStructureOutputRoutes.includes(route) && !isCapitalStructureValid) {
    disabled = true;
    enableTooltip = true;
    tooltipPrefix = l('_ThisTabIsDisabledDueToTheFollowingErrors');
    errorList = capitalStructureErrorMessageList ?? [];
  }

  const getTabClasses = (isLinkActive: boolean | undefined = undefined) => {
    return classNames(styles[PARENT_CLASSNAME], {
      [styles[`${PARENT_CLASSNAME}--active`]]: isActive || isLinkActive,
      [styles[`${PARENT_CLASSNAME}--highlighted`]]: isHighlighted,
      [styles[`${PARENT_CLASSNAME}--${variation}`]]: variation,
      disabled: disabled,
    });
  };

  const content = (
    <>
      <span className={styles[`${PARENT_CLASSNAME}__additional-data`]}>{additionalData}</span>{' '}
      {children}
    </>
  );

  const navLink = (route: string) => (
    <span>
      <PwcNavLink
        route={route}
        params={params}
        className={({ isActive }) => getTabClasses(isActive)}
        disabled={disabled}>
        {content}
      </PwcNavLink>
    </span>
  );

  const generateTooltipContent = (prefixMessage: string, errorList: string[]) => {
    return (
      <>
        <div>{prefixMessage}</div>
        {errorList.length > 0 && (
          <ul>
            {errorList.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
      </>
    );
  };

  return route ? (
    <>
      {enableTooltip && (
        <Tooltip
          content={generateTooltipContent(tooltipPrefix, errorList)}
          placement={TooltipPlacement.Bottom}
          useFixedPositioning
          setMinWidthToFitContent>
          {navLink(route)}
        </Tooltip>
      )}
      {!enableTooltip && navLink(route)}
    </>
  ) : (
    <button type="button" className={getTabClasses()} {...rest}>
      {content}
    </button>
  );
};
