export {};

declare global {
  interface Date {
    /** Adds the specified amount of years to a date */
    addYears(years: number): Date;
    /** Subtracts the specified amount of years from a date */
    subtractYears(years: number): Date;
    /** Add the specified amount of days to a date */
    addDays(days: number): Date;
    /** Add the specified amount of months to a date */
    addMonths(months: number): Date;
    /** Gets the date string in ISO format (yyyy-mm-dd) */
    toISODateString(): string;
  }
}

Date.prototype.addYears = function (years: number): Date {
  if (!years) {
    return this;
  }

  return new Date(this.setFullYear(this.getFullYear() + years));
};

Date.prototype.subtractYears = function (years: number): Date {
  if (!years) {
    return this;
  }

  return new Date(this.setFullYear(this.getFullYear() - years));
};

Date.prototype.addDays = function (days: number): Date {
  const dateCopy = new Date(this);
  return new Date(dateCopy.setDate(dateCopy.getDate() + days));
};

Date.prototype.addMonths = function (months: number): Date {
  const dateCopy = new Date(this);
  return new Date(dateCopy.setMonth(dateCopy.getMonth() + months));
};

Date.prototype.toISODateString = function (): string {
  const dateCopy = new Date(this);
  dateCopy.setMinutes(this.getMinutes() - dateCopy.getTimezoneOffset());
  return dateCopy.toISOString().split('T')[0];
};
