import {
  EquityInstrumentDto,
  InstrumentInitialValuesDto,
} from '@app/shared/models/contracts/project-dto';

export const isInstrumentOwnerAmountEnteredLegacy = (instrument: EquityInstrumentDto) =>
  instrument.ownership.some((owner) => Boolean(owner?.amount));

export const isInstrumentOwnerAmountEntered = (instrument: InstrumentInitialValuesDto) =>
  instrument.ownership
    ? Object.values(instrument.ownership).some((owner) => Boolean(owner?.amount))
    : false;
