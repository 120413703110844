import { sumBy } from '@app/shared/helpers';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import styles from '../../../widgets/total-equity/total-equity-chart.module.scss';
import { Pwerm2CalculationDashboardTotalEquityChartYearDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import TotalEquityChartModalBlock from './TotalEquityChartModalBlock';
import { InstrumentBlockKey } from '@app/shared/models/contracts/enums/shared-enums';

const PARENT_CLASSNAME = 'modal-equity-chart';

interface TotalEquityChartProps {
  structure: Pwerm2CalculationDashboardTotalEquityChartYearDto | null;
  selectedBlock: Nullable<string>;
  setSelectedBlock: (block: string | null) => void;
}

const TotalEquityModalChart: FC<TotalEquityChartProps> = ({
  structure,
  selectedBlock,
  setSelectedBlock,
}): JSX.Element | null => {
  if (!structure) {
    return null;
  }

  const netDebt = Number(structure?.totalProceeds - structure?.netExitProceeds);
  const absoluteNetDebt = Math.abs(netDebt);

  // this will be false if there is no net debt, or there is net cash (-ve debt)
  const hasPositiveNetDebt = netDebt > 0;

  const renderRanks = (structure: Pwerm2CalculationDashboardTotalEquityChartYearDto) => {
    return structure.ranks
      .filter((rank) => rank.rank !== -1)
      .sort((a, b) => b.rank - a.rank)
      .map((rank, index) => {
        return (
          <Fragment key={`instrument-${index}`}>
            <div
              key={'rank-block-' + index}
              style={{ height: rank.proportionOfProceeds * 100 + '%' }}
              className={classNames(
                styles['graph__rank-block'],
                styles['graph__rank-block--expanded']
              )}>
              {rank.instruments.map((instrument, index) => {
                return (
                  <TotalEquityChartModalBlock
                    key={'instrument-block-component' + index + instrument.id}
                    instrument={instrument}
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                  />
                );
              })}
            </div>
          </Fragment>
        );
      });
  };

  const totalEquity = sumBy(
    structure.ranks.flatMap((x) => x.instruments),
    (x) => x.totalProceeds
  );

  const equityHeight = (totalEquity / (totalEquity + absoluteNetDebt)) * 100;
  const debtHeight = (absoluteNetDebt / (totalEquity + absoluteNetDebt)) * 100;

  /* 
    If net debt is negative (i.e. net cash), then display below the axis.
    Positive net debt displays above the axis
    The --with-positive-debt and --for-positive-debt modifiers move the bottom border
    from the base of the instrument block to the base of the net debt block
  */
  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__chart`], {
          [styles[`${PARENT_CLASSNAME}__chart--with-positive-debt`]]: hasPositiveNetDebt,
        })}
        style={{
          height: equityHeight + '%',
        }}>
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(
              styles[`${PARENT_CLASSNAME}__column`],
              styles[`${PARENT_CLASSNAME}__column--expanded`]
            )}>
            {renderRanks(structure)}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles[`${PARENT_CLASSNAME}__chart`],
          styles[`${PARENT_CLASSNAME}__chart--alternate`]
        )}
        style={{
          height: debtHeight + '%',
        }}>
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(
              styles[`${PARENT_CLASSNAME}__column`],
              styles[`${PARENT_CLASSNAME}__column--expanded`],
              styles[`${PARENT_CLASSNAME}__column--within-modal`]
            )}
            tabIndex={0}
            onClick={(event) => {
              event.stopPropagation();
              setSelectedBlock(InstrumentBlockKey.NetDebtId);
            }}>
            <div
              className={classNames(
                styles[`${PARENT_CLASSNAME}__debt-column`],
                styles[`${PARENT_CLASSNAME}__debt-column--expanded`],
                {
                  [styles[`${PARENT_CLASSNAME}__debt-column--for-positive-debt`]]:
                    hasPositiveNetDebt,
                  [styles['modal-instrument']]:
                    !selectedBlock || selectedBlock === InstrumentBlockKey.NetDebtId,
                  [styles['modal-instrument--faded']]:
                    selectedBlock && selectedBlock !== InstrumentBlockKey.NetDebtId,
                }
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalEquityModalChart;
