import { useLocale } from '@app/core/hooks/useLocale';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { RouteConstants } from '../../RouteConstants';

// These routes are used for navigating between the sub-pages of the Capital Structure page.
export const CapitalStructureRoutes = {
  BuildStructureLegacy: `../${RouteConstants.BuildStructureLegacy}`,
  BuildStructure: `../${RouteConstants.BuildStructure}`,
  PayoutLogicLegacy: `../${RouteConstants.PayoutLogicLegacy}`,
  PayoutLogic: `../${RouteConstants.PayoutLogic}`,
  CapTableLegacy: `../${RouteConstants.CapTableLegacy}`,
  CapTable: `../${RouteConstants.CapTable}`,
  ParticipationTableLegacy: `../${RouteConstants.ParticipationTableLegacy}`,
  ParticipationTable: `../${RouteConstants.ParticipationTable}`,
  SourcesAndUses: `../${RouteConstants.SourcesAndUses}`,
};

export const CapitalStructureNavigation = () => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isLegacyPwermEnabled = isDevFeatureEnabled(DevFeature.ShowLegacyPwerm);
  const { l } = useLocale();

  const navigationItems: NavigationItem[] = [
    {
      label: l('_BuildStructure'),
      route: CapitalStructureRoutes.BuildStructure,
    },
    {
      label: l('_BuildStructure') + ' Legacy',
      route: CapitalStructureRoutes.BuildStructureLegacy,
    },
    {
      label: l('_PayoutLogic'),
      route: CapitalStructureRoutes.PayoutLogic,
    },
    {
      label: l('_PayoutLogic') + ' Legacy',
      route: CapitalStructureRoutes.PayoutLogicLegacy,
    },
    {
      label: l('_CapTable'),
      route: CapitalStructureRoutes.CapTable,
    },
    {
      label: l('_CapTable') + ' Legacy',
      route: CapitalStructureRoutes.CapTableLegacy,
    },
    {
      label: l('_ParticipationTable'),
      route: CapitalStructureRoutes.ParticipationTable,
    },
    {
      label: l('_ParticipationTable') + ' Legacy',
      route: CapitalStructureRoutes.ParticipationTableLegacy,
    },
    {
      label: l('_SourcesAndUses'),
      route: CapitalStructureRoutes.SourcesAndUses,
    },
  ];

  let routesToHide: string[] = [];
  if (!isLegacyPwermEnabled) {
    routesToHide = [
      ...routesToHide,
      CapitalStructureRoutes.PayoutLogicLegacy,
      CapitalStructureRoutes.BuildStructureLegacy,
      CapitalStructureRoutes.CapTableLegacy,
      CapitalStructureRoutes.ParticipationTableLegacy,
    ];
  }

  routesToHide.forEach((route) => {
    const index = navigationItems.findIndex((item) => item.route === route);
    if (index !== -1) {
      navigationItems.splice(index, 1);
    }
  });

  return <SecondaryNavigation navigationItems={navigationItems} />;
};
