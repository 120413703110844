import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import {
  amountBasicFieldFormattingProps,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { getYesNoOptions } from '@app/shared/constants/dropdown-values/yes-no-options';
import { getInvestmentMetricOptions } from '@app/shared/constants/dropdown-values/benchmark/investment-metric-options';
import { getInvestmentHorizonOptions } from '@app/shared/constants/dropdown-values/benchmark/investment-horizon-options';
import { useFormContext } from 'react-hook-form';
import { BenchmarkingDto } from '@app/shared/models/contracts/project-dto';
import { InvestmentMetric, ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  currencyDenominationPair,
  enumKeyByValue,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import {
  amountRules,
  calculatedMultiplierRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useLocale } from '@app/core/hooks/useLocale';

const DealMetrics: FC = (): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const { watch } = useFormContext<BenchmarkingDto>();
  const { l } = useLocale();
  const investmentMetric = watch('dealMetrics.investmentMetric');
  const {
    storeBenchmarkingData,
    status,
    details: { currency, denomination },
    sourcesAndUses: { dayOneTransactionPurchasePrice },
  } = useAppSelector((state) => state.project.projectDraft);
  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;
  const investmentMetricLabel =
    investmentMetric !== null ? l(InvestmentMetric[investmentMetric]) : '{metric}';

  return (
    <Card>
      <h3>Deal Metrics</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label={`Entry Enterprise Value (${currencyDenominationPair(currency, denomination)})`}
            value={dayOneTransactionPurchasePrice}
            {...amountRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={`LTM ${investmentMetricLabel} Multiple`}
            value={getCalculatedProjectValue('LTMMetricMultiple')}
            {...calculatedMultiplierRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormDatePicker
            name="dealMetrics.debtMaturityDate"
            label="Debt Maturity Date"
            isGapless
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="dealMetrics.investmentMetric"
            label="Investment Metric"
            options={getInvestmentMetricOptions()}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={`Total Equity Funding (${currencyDenominationPair(currency, denomination)})`}
            value={getCalculatedProjectValue('TotalAmountInvested')}
            {...amountRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="dealMetrics.investmentHorizonYears"
            label="Investment Horizon (Years)"
            options={getInvestmentHorizonOptions()}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name="dealMetrics.entryOfTarget"
            label={`Entry ${investmentMetricLabel} of Target (${currencyDenominationPair(
              currency,
              denomination
            )})`}
            placeholder="0"
            {...amountBasicFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="dealMetrics.thirdPartyLeveragePercentOfEV"
            label="Third Party Leverage (% of EV)"
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="dealMetrics.investorMonitoringExitFees"
            label="Investor / Monitoring / Exit Fees?"
            options={getYesNoOptions()}
            isGapless
          />
        </div>
      </div>
    </Card>
  );
};

export default DealMetrics;
