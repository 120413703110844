import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { getYesNoUnknownOptions } from '@app/shared/constants/dropdown-values/yes-no-unknown-options';
import { getMipDesignOptions } from '@app/shared/constants/dropdown-values/mip-design-options';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { useLocale } from '@app/core/hooks/useLocale';

const SchemeDesignAndManagementAdvice: FC = (): JSX.Element => {
  const { dealsTaxProjectLead, valuationProjectLead } = useAppSelector(
    (state) => state.project.projectDraft.details
  );
  const { l } = useLocale();

  return (
    <Card>
      <h3>{l('_SchemeDesignAndManagementAdvice')}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.mipDesign"
            label={l('_MIPDesign')}
            options={getMipDesignOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.didManagementReceiveCommercialAdvice"
            label={l('_DidManagementReceiveCommercialAdvice')}
            options={getYesNoUnknownOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue label="Deals Tax Project Lead" value={dealsTaxProjectLead} isGapless />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name="schemeDesignAndManagementAdvice.mipDesignComments"
            label={l('_MIPDesignComments')}
            placeholder={l('_AddDetails')}
            {...textFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.didManagementReceiveValuationAdvice"
            label={l('_DidManagementReceiveValuationAdvice')}
            options={getYesNoUnknownOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="schemeDesignAndManagementAdvice.broaderComments"
            label={l('_BroaderComments')}
            placeholder={l('_AddDetails')}
            {...textFieldFormattingProps}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.didManagementReceiveTaxAdvice"
            label={l('_DidManagementReceiveTaxAdvice')}
            options={getYesNoUnknownOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue label={l('_ValuationProjectLead')} value={valuationProjectLead} isGapless />
        </div>
        <div className="form-row__column" />
      </div>
    </Card>
  );
};

export default SchemeDesignAndManagementAdvice;
