import {
  Children,
  cloneElement,
  FC,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  useRef,
  useState,
} from 'react';
import styles from './tooltip.module.scss';
import classNames from 'classnames';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';

export enum TooltipPlacement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

interface TooltipContentProps extends HTMLAttributes<HTMLElement> {
  content?: string | JSX.Element;
  placement?: TooltipPlacement;
  width?: string;
  tooltipVariation?: GridFieldTooltipVariation;
  useFixedPositioning?: boolean;
  setMinWidthToFitContent?: boolean;
}

interface ChildWithProps {
  props: ChildProps;
}

interface ChildProps {
  className: string;
  onMouseEnter: (e: any) => void;
}

export const Tooltip: FC<PropsWithChildren<TooltipContentProps>> = ({
  content,
  children,
  placement,
  className,
  style,
  tooltipVariation,
  width,
  useFixedPositioning,
  setMinWidthToFitContent,
  ...rest
}): JSX.Element => {
  const [topPosition, setTopPosition] = useState<number | undefined>(undefined);
  const [bottomPosition, setBottomPosition] = useState<number | undefined>(undefined);
  const [leftPosition, setLeftPosition] = useState<number | undefined>(undefined);
  const [rightPosition, setRightPosition] = useState<number | undefined>(undefined);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const getPosition = (e: MouseEvent) => {
    const { target } = e;

    const targetElement = (target as HTMLElement).getBoundingClientRect();
    const targetElementHeight = targetElement.height;
    const targetElementWidth = targetElement.width;

    const tooltipHeight = tooltipRef.current?.clientHeight ?? 0;

    switch (placement) {
      case TooltipPlacement.Left:
        setTopPosition(targetElement.top + targetElementHeight / 2 - tooltipHeight / 2);
        setRightPosition(window.innerWidth - targetElement.left);
        break;
      case TooltipPlacement.Right:
        setTopPosition(targetElement.top + targetElementHeight / 2 - tooltipHeight / 2);
        setLeftPosition(targetElement.right);
        break;
      case TooltipPlacement.Bottom:
        setTopPosition(targetElement.bottom + 10);
        setLeftPosition(targetElement.left + targetElementWidth / 2);
        break;
      case TooltipPlacement.Top:
        setBottomPosition(window.innerHeight - targetElement.top + 10);
        setLeftPosition(targetElement.left + targetElementWidth / 2);
        break;
      default:
        break;
    }
  };

  const childrenWithClassnames = Children.map(children as ChildWithProps[], (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        className: classNames(child.props.className, styles['tooltip__trigger']),
        onMouseEnter: useFixedPositioning ? getPosition : undefined,
      });
    }
    return child;
  });

  const getStyles = () => {
    let styles = {};
    if (width) {
      styles = { ...styles, maxWidth: width, width: width, minWidth: 0 };
    }

    if (useFixedPositioning) {
      styles = {
        ...styles,
        left: leftPosition,
        right: rightPosition,
        top: topPosition,
        bottom: bottomPosition,
      };
    }

    return styles;
  };

  return (
    <div className={classNames(className, styles['tooltip'])} style={style}>
      <>
        {childrenWithClassnames}
        {content === undefined ? (
          <></>
        ) : (
          <div
            style={getStyles()}
            className={classNames(styles['tooltip__content'], {
              [styles[`tooltip__content--${placement}`]]: placement,
              [styles[`tooltip__content--${tooltipVariation}`]]: tooltipVariation,
              [styles['tooltip__content--fixed']]: useFixedPositioning,
              [styles['tooltip__content--fit-content-min-width']]: setMinWidthToFitContent,
            })}
            ref={tooltipRef}
            {...rest}>
            {content}
          </div>
        )}
      </>
    </div>
  );
};
