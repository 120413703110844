import { useLocale } from '@app/core/hooks/useLocale';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import BaseModal from '@app/shared/components/modal/BaseModal';
import styles from '../../../widgets/total-equity/total-equity-chart.module.scss';
import { abbreviatedValueFormatter } from '@app/shared/formatters';
import { enumKeyByValue } from '@app/shared/helpers';
import {
  DenominationMultiplier,
  InstrumentType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { Pwerm2CalculationDashboardTotalEquityChartYearDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import TotalEquityModalChart from './TotalEquityModalChart';
import { TotalEquityInstrumentTableSection } from './TotalEquityInstrumentTableSection';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { Toggle, ToggleSize } from '@app/shared/components/toggle/Toggle';

interface PwermTotalEquityModalProps {
  structure: Pwerm2CalculationDashboardTotalEquityChartYearDto | null;
  isOpen: boolean;
  onClose: () => void;
  isEntryStack: boolean;
}

export const PwermTotalEquityModal: FC<PwermTotalEquityModalProps> = ({
  structure,
  isOpen,
  onClose,
  isEntryStack = false,
}) => {
  const { l } = useLocale();

  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];

  const [toggleExpanded, setToggleExpanded] = useState({
    ignoreExpandRows: false,
    toggleExpanded: false,
  });
  const defaultExpandedInstruments: Dictionary<boolean> = {};
  const allInstruments = structure?.ranks.flatMap((rank) => rank.instruments) ?? [];
  allInstruments.forEach((instrument) => {
    defaultExpandedInstruments[instrument.id] = false;
  });
  const [expandedInstruments, setExpandedInstruments] = useState<Dictionary<boolean>>(
    defaultExpandedInstruments
  );

  const expandAllRows = (isExpandAllRows: boolean) => {
    if (!structure) {
      return;
    }
    const expandedInstruments: Dictionary<boolean> = {};

    allInstruments.forEach((instrument) => {
      expandedInstruments[instrument.id] = isExpandAllRows;
    });
    setExpandedInstruments(expandedInstruments);
  };

  useEffect(() => {
    const allTrue = Object.values(expandedInstruments).every((value) => value);
    const allFalse = Object.values(expandedInstruments).every((value) => !value);
    if (!allTrue && !allFalse) {
      setToggleExpanded((prevState) => {
        if (!prevState.toggleExpanded && prevState.ignoreExpandRows) {
          return prevState;
        }
        return { toggleExpanded: false, ignoreExpandRows: true };
      });
    }
  }, [expandedInstruments]);

  useEffect(() => {
    if (!toggleExpanded.ignoreExpandRows) {
      expandAllRows(toggleExpanded.toggleExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleExpanded]);

  const formMethods = useForm<any>({
    ...formConfigBase,
  });

  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);

  const debtInstruments = structure?.ranks.find((rank) => rank.rank === -1)?.instruments ?? [];
  const ordinaryEquityInstruments = structure?.ranks.flatMap((rank) =>
    rank.instruments
      .filter(
        (instrument) =>
          instrument.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
      )
      .map((instrument) => ({ ...instrument, rank: rank.rank }))
  );
  const shareholderLoanNotesInstruments = structure?.ranks.flatMap((rank) =>
    rank.instruments
      .filter(
        (instrument) =>
          instrument.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
      )
      .map((instrument) => ({ ...instrument, rank: rank.rank }))
  );
  const preferredSharesInstruments = structure?.ranks.flatMap((rank) =>
    rank.instruments
      .filter(
        (instrument) =>
          instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
      )
      .map((instrument) => ({ ...instrument, rank: rank.rank }))
  );

  const isDebtPresent = debtInstruments.length > 0;
  const isOrdinaryEquityPresent = ordinaryEquityInstruments && ordinaryEquityInstruments.length > 0;
  const isShareholderLoanNotesPresent =
    shareholderLoanNotesInstruments && shareholderLoanNotesInstruments.length > 0;
  const isPreferredSharesPresent =
    preferredSharesInstruments && preferredSharesInstruments.length > 0;

  const isNetCash = (structure?.ranks?.find((rank) => rank.rank === -1)?.totalProceeds ?? 0) < 0;

  const handleClose = () => {
    setExpandedInstruments({});
    setToggleExpanded({ ignoreExpandRows: false, toggleExpanded: false });
    setSelectedBlock(null);
    onClose();
  };

  return (
    <BaseModal
      size={ModalSize.Large}
      isOpen={isOpen}
      onCloseButtonClick={handleClose}
      shouldCloseOnOverlayClick
      onClick={() => setSelectedBlock(null)}
      onRequestClose={handleClose}>
      <>
        <div>
          <h2>{isEntryStack ? l('_EntryCapitalStructure') : l('_ExitCapitalStructure')}</h2>
          <div className={styles['table-flex']}>
            <div>
              {structure && (
                <TotalEquityModalChart
                  structure={structure}
                  selectedBlock={selectedBlock}
                  setSelectedBlock={setSelectedBlock}
                />
              )}
            </div>
            <div className={styles['table-content']}>
              <div className={styles['header-flex']}>
                <h3>
                  {isEntryStack ? l('_Investment') : l('_EquityNetOfExitCosts')}:{' '}
                  {abbreviatedValueFormatter({
                    value: (structure?.netExitProceeds ?? 0) * multiplier,
                  })}
                </h3>
                <div
                  className={styles['toggle-wrapper']}
                  onClick={(event) => event.stopPropagation()}>
                  <FormProvider {...formMethods}>
                    <Toggle
                      name="expandAllRows"
                      label={l('_ExpandAllRows')}
                      onChange={() => {
                        setToggleExpanded({
                          toggleExpanded: !toggleExpanded.toggleExpanded,
                          ignoreExpandRows: false,
                        });
                      }}
                      toggleSize={ToggleSize.Medium}
                      forceChecked={toggleExpanded.toggleExpanded}
                    />
                  </FormProvider>
                </div>
              </div>
              <table
                className="table-primary"
                style={{ borderCollapse: 'collapse', fontSize: '1.4rem' }}>
                <thead>
                  <tr>
                    <th
                      data-testid="component-header"
                      className="table-primary__cell--header-quinary table-primary"
                      style={{ minWidth: '120px', paddingLeft: '0' }}>
                      {l('_Component')}
                    </th>
                    <th
                      data-testid="weighted-ev-header"
                      className="table-primary__cell--header-quinary table-primary__cell--right"
                      style={{ minWidth: '120px' }}>
                      {isEntryStack ? l('_Amount') : l('_WeightedProceedsTitle')}
                    </th>
                    <th
                      data-testid="net-ev-header"
                      className="table-primary__cell--header-quinary table-primary__cell--right"
                      style={{ minWidth: '120px' }}>
                      {isNetCash ? l('_NetEquityPercentage') : l('_NetEvPercentage')}
                    </th>
                    {!isEntryStack && (
                      <>
                        <th
                          data-testid="mom-header"
                          className="table-primary__cell--header-quinary table-primary__cell--right"
                          style={{ minWidth: '120px' }}>
                          {l('_MoM')}
                        </th>
                        <th
                          data-testid="irr-header"
                          className="table-primary__cell--header-quinary table-primary__cell--right"
                          style={{ minWidth: '120px' }}>
                          {l('_IRR')}
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {isDebtPresent && (
                    <TotalEquityInstrumentTableSection
                      instrumentsType={null}
                      instrumentsData={debtInstruments}
                      isEntryStack={isEntryStack}
                      setExpandedInstruments={setExpandedInstruments}
                      expandedInstruments={expandedInstruments}
                      selectedBlock={selectedBlock}
                    />
                  )}
                  {isShareholderLoanNotesPresent && (
                    <TotalEquityInstrumentTableSection
                      instrumentsType={InstrumentType.ShareholderLoanNotes}
                      instrumentsData={shareholderLoanNotesInstruments}
                      isEntryStack={isEntryStack}
                      setExpandedInstruments={setExpandedInstruments}
                      expandedInstruments={expandedInstruments}
                      selectedBlock={selectedBlock}
                    />
                  )}
                  {isPreferredSharesPresent && (
                    <TotalEquityInstrumentTableSection
                      instrumentsType={InstrumentType.PreferredShares}
                      instrumentsData={preferredSharesInstruments}
                      isEntryStack={isEntryStack}
                      setExpandedInstruments={setExpandedInstruments}
                      expandedInstruments={expandedInstruments}
                      selectedBlock={selectedBlock}
                    />
                  )}
                  {isOrdinaryEquityPresent && (
                    <TotalEquityInstrumentTableSection
                      instrumentsType={InstrumentType.OrdinaryEquity}
                      instrumentsData={ordinaryEquityInstruments}
                      isEntryStack={isEntryStack}
                      setExpandedInstruments={setExpandedInstruments}
                      expandedInstruments={expandedInstruments}
                      selectedBlock={selectedBlock}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </BaseModal>
  );
};
