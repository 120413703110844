import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import { getYesNoOptions } from '@app/shared/constants/dropdown-values/yes-no-options';
import { getVestingConditionOptions } from '@app/shared/constants/dropdown-values/vesting-condition-options';
import { getEsgTermsOptions } from '@app/shared/constants/dropdown-values/esg-term-options';
import { useLocale } from '@app/core/hooks/useLocale';

const VestingAndLiquidity: FC = (): JSX.Element => {
  const { l } = useLocale();

  return (
    <Card>
      <h3>{l('_VestingAndLiquidity')}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.vestingCondition"
            label={l('_VestingCondition')}
            options={getVestingConditionOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.anyLiquidityProvisions"
            label={l('_AnyLiquidityProvisionsPutCall')}
            options={getYesNoOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.esgTermsAttachedToSweetEquity"
            label={l('_ESGTermsAttachedToSweetEquity')}
            options={getYesNoOptions()}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.doesVestingAccelerateOnExitOrChangeOfControl"
            label={l('_DoesVestingAccelerateOnExitOrChangeOfControl')}
            options={getYesNoOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormDatePicker
            name="vestingAndLiquidity.dateOfLiquidityProvisions"
            label={l('_DateOfLiquidityProvisions')}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.esgTermsInherentOrVestingRelated"
            label={l('_ESGTermsInherentOrVestingRelated')}
            options={getEsgTermsOptions()}
            isGapless
          />
        </div>
      </div>
    </Card>
  );
};

export default VestingAndLiquidity;
