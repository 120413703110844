import {
  amountRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import styles from '../../opm-summary-waterfall.module.scss';
import { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { OpmSampleWaterfallDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import { useLocale } from '@app/core/hooks/useLocale';
import { translationKeys } from '@locale/setupI18n';
import SampleWaterfallTranches from './SampleWaterfallOutputsTranches';
import { enumKeyByValue } from '@app/shared/helpers';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';

type ValueKeys = Omit<OpmSampleWaterfallDto, 'tranches' | 'hasPayoutLogic'>;

interface SampleWaterfallOutputsSummaryProps {
  sampleWaterfallResults: OpmSampleWaterfallDto;
}

const SampleWaterfallOutputsSummary: FC<SampleWaterfallOutputsSummaryProps> = ({
  sampleWaterfallResults,
}): JSX.Element => {
  const { l } = useLocale();
  const opmCalc = useAppSelector((state) => state.opmCalculation);

  const isEquitySimulation =
    opmCalc?.riskFreeValues?.inputs?.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue);

  const hasTranches = sampleWaterfallResults.hasPayoutLogic;

  const createRow = (
    label: translationKeys,
    valueKey: keyof ValueKeys,
    isPercentage = false,
    className = '',
    decimalPoint: number | null = null
  ) => {
    return (
      <tr>
        <td className={className} data-testid={`${valueKey}-title`}>
          {l(label)}
        </td>
        <td className={className} data-testid={`${valueKey}-value`}>
          <CellValue
            value={
              isPercentage && typeof sampleWaterfallResults[valueKey] === 'number'
                ? (sampleWaterfallResults[valueKey] ?? 0) * 100
                : sampleWaterfallResults[valueKey]
            }
            {...(isPercentage ? enteredPercentageViewRules : amountRules)}
            {...(decimalPoint !== null && numberCalculatedViewRules)}
            normalize
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <tbody>
        {createRow('_TimeToExit', 'yearsToExit', false, styles['border-cell-bottom'], 2)}
        {createRow('_TransactionCost', 'transactionCosts', true)}
        {createRow('_IpoDiscount', 'ipoDiscount', true)}
        {createRow('_NetExitProceeds', 'netExitProceeds')}
        {isEquitySimulation && createRow('_NetDebtNegativeOrNetCash', 'netCash')}
        {createRow('_TotalEquityTitle', 'totalEquity')}
        {createRow('_TotalContractualShareholderDebt', 'shareholderDebtDistributions')}
        {createRow('_OrdinaryEquity', 'totalOrdinaryEquity')}
        {hasTranches ? (
          <SampleWaterfallTranches sampleWaterfallResults={sampleWaterfallResults} />
        ) : (
          <>
            <tr>
              <td data-testid="participationPc-title">{l('_PcParticipationTitle')}</td>
              <td data-testid="participationPc-value">
                <CellValue
                  value={sampleWaterfallResults.tranches[0].trancheParticipationPc * 100}
                  {...enteredPercentageViewRules}
                />
              </td>
            </tr>
            <tr>
              <td data-testid="distributionsPaid-title">{l('_DistributionsPaid')}</td>
              <td data-testid="distributionsPaid-value">
                <CellValue
                  value={sampleWaterfallResults.tranches[0].trancheDistribution}
                  {...amountRules}
                />
              </td>
            </tr>
            <tr>
              <td data-testid="postIpoDlom-title">{l('_PostIPOLockInDiscount')}</td>
              <td data-testid="postIpoDlom-value">
                <CellValue
                  value={sampleWaterfallResults.tranches[0].postIpoDlomPc * 100}
                  {...enteredPercentageViewRules}
                />
              </td>
            </tr>
            <tr>
              <td data-testid="netDistribution-title">{l('_NetProceeds')}</td>
              <td data-testid="netDistribution-value">
                <CellValue
                  value={sampleWaterfallResults.tranches[0].netDistribution}
                  {...amountRules}
                />
              </td>
            </tr>
          </>
        )}
      </tbody>
    </>
  );
};

export default SampleWaterfallOutputsSummary;
