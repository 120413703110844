import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { calculateCapitalStructureDimensions } from '@app/modules/projects/inputs/capital-structure/capital-structure-block-size-calculator';
import {
  CapitalStructure,
  Instrument,
} from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import { groupBy, sumBy } from '@app/shared/helpers';
import { OpmStackedDistributionPlotDataPointDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import OpmInstrumentBlockComponent from '@app/modules/projects/dashboard/widgets/total-equity/opm/OpmInstrumentBlock';
import styles from '../opm-charts.module.scss';
import {
  InstrumentDefinitionAndInitialValues,
  selectInstrumentsWithInitialValues,
} from '@app/core/store/capital-structure-slice-selectors';

const PARENT_CLASSNAME = 'exit-equity-chart';

interface ExitEquityChartProps {
  selectedPoint: OpmStackedDistributionPlotDataPointDto;
}

const ExitEquityChart: FC<ExitEquityChartProps> = ({ selectedPoint }): JSX.Element => {
  const instruments = useAppSelector(selectInstrumentsWithInitialValues);

  const netDebt = Number(selectedPoint.netDebt);
  const absoluteNetDebt = Math.abs(netDebt);

  // this will be false if there is no net debt, or there is net cash (-ve debt)
  const hasPositiveNetDebt = netDebt > 0;

  const createStructureForInstruments = <T extends Instrument>(instruments: T[]) =>
    calculateCapitalStructureDimensions(instruments, 100, 100, 0, 0, false);

  const getStructure = () => {
    const distributions = selectedPoint.instrumentDistributions;
    const distributionsByInstrument = groupBy(distributions, (x) => x.instrumentId!);

    return createStructureForInstruments(
      Object.values(distributionsByInstrument).map((ins) => {
        const definition = instruments.find((item) => item.instrumentId === ins[0].instrumentId);
        return {
          ...definition!,
          amount: ins[0].distributionAmount,
        };
      })
    );
  };

  const renderRanks = (structure: CapitalStructure<InstrumentDefinitionAndInitialValues>) => {
    return structure.ranks.map((rank, index) => {
      return (
        <Fragment key={`instrument-${index}`}>
          <div
            key={'rank-block-' + index}
            style={{ height: rank.height + '%' }}
            className={classNames(
              styles['graph__rank-block'],
              styles['graph__rank-block--expanded']
            )}>
            {rank.instrumentBlocks.map((instrument, index) => {
              return (
                <OpmInstrumentBlockComponent
                  key={'instrument-block-component' + index + instrument.instrumentId}
                  instrument={instrument}
                  chartForecastsCount={1}
                  enableTooltip={false}
                />
              );
            })}
          </div>
        </Fragment>
      );
    });
  };

  const structure = getStructure();

  const totalEquity = sumBy(
    structure.ranks.flatMap((x) => x.instrumentBlocks),
    (x) => x.amount
  );

  const equityHeight = (totalEquity / (totalEquity + absoluteNetDebt)) * 100;
  const debtHeight = (absoluteNetDebt / (totalEquity + absoluteNetDebt)) * 100;

  /* 
    If net debt is negative (i.e. net cash), then display below the axis.
    Positive net debt displays above the axis
    The --with-positive-debt and --for-positive-debt modifiers move the bottom border
    from the base of the instrument block to the base of the net debt block
  */
  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__chart`], {
          [styles[`${PARENT_CLASSNAME}__chart--with-positive-debt`]]: hasPositiveNetDebt,
        })}
        style={{
          height: equityHeight + '%',
        }}>
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(
              styles[`${PARENT_CLASSNAME}__column`],
              styles[`${PARENT_CLASSNAME}__column--expanded`]
            )}>
            {renderRanks(structure)}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles[`${PARENT_CLASSNAME}__chart`],
          styles[`${PARENT_CLASSNAME}__chart--alternate`]
        )}
        style={{
          height: debtHeight + '%',
        }}>
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(
              styles[`${PARENT_CLASSNAME}__column`],
              styles[`${PARENT_CLASSNAME}__column--expanded`]
            )}>
            <div
              className={classNames(
                styles[`${PARENT_CLASSNAME}__debt-column`],
                styles[`${PARENT_CLASSNAME}__debt-column--expanded`],
                {
                  [styles[`${PARENT_CLASSNAME}__debt-column--for-positive-debt`]]:
                    hasPositiveNetDebt,
                }
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitEquityChart;
