import {
  PerYearValuesDto,
  MarketValueDlomDto,
  ProjectDto,
} from '@app/shared/models/contracts/project-dto';
import { enumKeyByValue } from '@app/shared/helpers';
import {
  CalcMethod,
  OpmInputDataSource,
  OpmSimulatedValue,
  CollaringMode,
} from '@app/shared/models/contracts/enums/shared-enums';
import { dateFormatter } from '@app/shared/formatters';
import { getOpmInputsFromCapitalStructure } from './getOpmInputsFromCapitalStructure';
import { getOpmInputsFromPwerm } from './getOpmInputsFromPwerm';
import { Pwerm2CalculationResultsDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';

export const updateProjectOpmInputs = (
  project: ProjectDto,
  calculationResults: Pwerm2CalculationResultsDto
) => {
  const opmInput = project.opmInput;
  const capitalStructureKey = Object.keys(project.capitalStructures)?.[0];

  const {
    getMaxForecastYearsFromPwerm,
    getInitialCashValueFromPwerm,
    getOpmValuesFromPwerm,
    getNetDebtItemsFromPwerm,
    getControlPremiumForDlocFromPwerm,
    getInitialTotalNetDebtFromPwerm,
    getMarketVolatilityFromPwerm,
    getPeerSetVolatilityFromPwerm,
  } = getOpmInputsFromPwerm(project, calculationResults);

  const { getEvOrEquityValueFromCapitalStructure } = getOpmInputsFromCapitalStructure(project);

  const shouldGetValuesFromPwerm = project.details.calcMethod !== CalcMethod.OPM;
  const isEquitySimulation =
    opmInput.opmSimulatedValue === enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.Equity);

  const addOrUpdateMarketValueDlomsFromPwerm = (
    yearInput: PerYearValuesDto | null,
    pwermValues: MarketValueDlomDto[],
    isNewForecast: boolean
  ): MarketValueDlomDto[] => {
    const valuedInstruments = Object.entries(
      project.capitalStructures[capitalStructureKey].instrumentDefinitions
    ).filter(([_, instrument]) => instrument.shouldBeValued);

    const currentValues = yearInput?.marketValueDloms;

    const getDlomValue = (
      existingValue: Nullable<number> | undefined,
      existingSource: keyof typeof OpmInputDataSource | undefined,
      pwermValue: Nullable<number> | undefined
    ): number => {
      return isNewForecast
        ? getNewDlomValue(existingValue, pwermValue)
        : getUpdatedDlomValue(existingValue, existingSource, pwermValue);
    };

    const getUpdatedDlomValue = (
      existingValue: Nullable<number> | undefined,
      existingSource: keyof typeof OpmInputDataSource | undefined,
      pwermValue: Nullable<number> | undefined
    ): number => {
      if (!existingSource || existingSource === OpmInputDataSource.FromPWERM) {
        return shouldGetValuesFromPwerm ? pwermValue ?? 0 : existingValue ?? 0;
      }

      return existingSource === OpmInputDataSource.Override ? existingValue ?? 0 : pwermValue ?? 0;
    };

    const getNewDlomValue = (
      lastValue: Nullable<number> | undefined,
      pwermValue: Nullable<number> | undefined
    ) => {
      return shouldGetValuesFromPwerm ? pwermValue ?? 0 : lastValue ?? 0;
    };

    return valuedInstruments.map(([instrumentId, instrument]) => {
      const currentInstrument = currentValues?.find((mvd) => mvd.instrumentId === instrumentId);
      const pwermInstrument = pwermValues.find((mvd) => mvd.instrumentId === instrumentId);

      return {
        instrumentId,
        instrumentDlom:
          (instrument.payoutLogic?.length ?? 0) > 0
            ? null
            : getDlomValue(
                currentInstrument?.instrumentDlom,
                currentInstrument?.instrumentDlomSource,
                pwermInstrument?.instrumentDlom
              ),
        instrumentDlomSource:
          currentInstrument?.instrumentDlomSource ?? OpmInputDataSource.FromPWERM,
        trancheDloms:
          instrument.payoutLogic?.map((tr) => {
            const currentTranche = currentInstrument?.trancheDloms.find(
              (td) => td.trancheId === tr.id
            );
            const pwermTranche = pwermInstrument?.trancheDloms.find((td) => td.trancheId === tr.id);
            return {
              trancheId: tr.id,
              dlom: getDlomValue(
                currentTranche?.dlom,
                currentTranche?.dlomSource,
                pwermTranche?.dlom
              ),
              dlomSource: currentTranche?.dlomSource ?? OpmInputDataSource.FromPWERM,
            };
          }) ?? [],
      };
    });
  };

  const updatePerYearInput = (
    yearInput: PerYearValuesDto,
    yearIndex: number,
    shouldUpdateExitProbabilities: boolean
  ): PerYearValuesDto => {
    const forecastDate = new Date(project.yearEndDate).addYears(yearIndex).toISODateString();
    const isLastYear = yearIndex === forecastYears - 1;
    const opmValuesFromPwerm = getOpmValuesFromPwerm(forecastDate);

    const exitHorizonProbability = shouldUpdateExitProbabilities
      ? isLastYear
        ? 100
        : 0
      : yearInput.exitHorizonProbability;

    const financingCosts =
      yearInput.financingCostsSource !== OpmInputDataSource.Override &&
      !isEquitySimulation &&
      shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.financingCosts
        : yearInput.financingCosts;

    const ipoProbability =
      yearInput.ipoProbabilitySource !== OpmInputDataSource.Override && shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.ipoProbability
        : yearInput.ipoProbability;

    const ipoDiscount =
      yearInput.ipoDiscountSource !== OpmInputDataSource.Override &&
      !isEquitySimulation &&
      shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.ipoDiscount
        : yearInput.ipoDiscount;

    const hypotheticalDebtWhenIpo =
      yearInput.hypotheticalDebtWhenIpoSource !== OpmInputDataSource.Override &&
      shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.hypotheticalDebtWhenIpo
        : yearInput.hypotheticalDebtWhenIpo;

    const equitySoldInIpo =
      yearInput.equitySoldInIpoSource !== OpmInputDataSource.Override && shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.equitySoldInIpo
        : yearInput.equitySoldInIpo;

    const postIpoSaleDlom =
      yearInput.postIpoSaleDlomSource !== OpmInputDataSource.Override && shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.postIpoSaleDlom
        : yearInput.postIpoSaleDlom;

    const ipoCostsOfEv =
      yearInput.ipoCostsOfEvSource !== OpmInputDataSource.Override &&
      !isEquitySimulation &&
      shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.ipoCostsOfEv
        : yearInput.ipoCostsOfEv;

    const secondarySaleCostsOfEv =
      yearInput.secondarySaleCostsOfEvSource !== OpmInputDataSource.Override &&
      !isEquitySimulation &&
      shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.secondarySaleCostsOfEv
        : yearInput.secondarySaleCostsOfEv;

    const totalNetDebt =
      yearInput.totalNetDebtSource !== OpmInputDataSource.Override && shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.totalNetDebt
        : yearInput.totalNetDebt;

    const operationalFreeCashFlow =
      yearInput.operationalFreeCashFlowSource !== OpmInputDataSource.Override &&
      shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.operationalFreeCashFlow
        : yearInput.operationalFreeCashFlow;

    const operationalEv =
      yearInput.operationalEvSource !== OpmInputDataSource.Override && shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.operationalEv
        : yearInput.operationalEv;

    return {
      ...yearInput,
      forecastDate: forecastDate,
      exitHorizonProbability: exitHorizonProbability,
      financingCosts: financingCosts,
      ipoProbability: ipoProbability,
      ipoDiscount: ipoDiscount,
      hypotheticalDebtWhenIpo: hypotheticalDebtWhenIpo,
      equitySoldInIpo: equitySoldInIpo,
      postIpoSaleDlom: postIpoSaleDlom,
      ipoCostsOfEv: ipoCostsOfEv,
      secondarySaleCostsOfEv: secondarySaleCostsOfEv,
      cashValue: shouldGetValuesFromPwerm ? opmValuesFromPwerm.cashValue : yearInput.cashValue,
      netDebtItems: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.netDebtItems
        : yearInput.netDebtItems,
      marketValueDloms: addOrUpdateMarketValueDlomsFromPwerm(
        yearInput,
        opmValuesFromPwerm.marketValueDloms,
        false
      ),
      totalNetDebt: totalNetDebt,
      operationalFreeCashFlow: operationalFreeCashFlow,
      operationalEv: operationalEv,
      collaring: {
        inputMode: (yearInput.collaring && yearInput.collaring.inputMode) ?? CollaringMode.Off,
        percentage: (yearInput.collaring && yearInput.collaring.percentage) ?? null,
        fixedValue: (yearInput.collaring && yearInput.collaring.fixedValue) ?? null,
      },
    };
  };

  const generateNewPerYearInput = (
    lastForecast: PerYearValuesDto | null,
    yearIndex: number,
    currentYearsCount: number,
    shouldUpdateExitProbabilities: boolean
  ): PerYearValuesDto => {
    // if we have an existing forecast, the next one is 1 year later
    // if we only have the year end date, the 1st forecast is on this date
    const forecastDate = lastForecast?.forecastDate
      ? new Date(lastForecast.forecastDate).addYears(yearIndex + 1).toISODateString()
      : new Date(project.yearEndDate).addYears(yearIndex).toISODateString();
    const opmValuesFromPwerm = getOpmValuesFromPwerm(forecastDate);
    const isLastYear = yearIndex + currentYearsCount === forecastYears - 1;

    return {
      forecastDate: forecastDate,
      exitHorizonProbability: shouldUpdateExitProbabilities
        ? isLastYear
          ? 100
          : 0
        : lastForecast?.exitHorizonProbability ?? 0,
      riskFreeRate: lastForecast?.riskFreeRate ?? 0,
      financingCosts:
        shouldGetValuesFromPwerm && !isEquitySimulation
          ? opmValuesFromPwerm.financingCosts
          : lastForecast?.financingCosts ?? 0,
      financingCostsSource: OpmInputDataSource.FromPWERM,
      ipoProbability: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.ipoProbability
        : lastForecast?.ipoProbability ?? 0,
      ipoProbabilitySource: OpmInputDataSource.FromPWERM,
      ipoDiscount:
        shouldGetValuesFromPwerm && !isEquitySimulation
          ? opmValuesFromPwerm.ipoDiscount
          : lastForecast?.ipoDiscount ?? 0,
      ipoDiscountSource: OpmInputDataSource.FromPWERM,
      secondarySaleCostsOfEv:
        shouldGetValuesFromPwerm && !isEquitySimulation
          ? opmValuesFromPwerm.secondarySaleCostsOfEv
          : lastForecast?.secondarySaleCostsOfEv ?? 0,
      secondarySaleCostsOfEvSource: OpmInputDataSource.FromPWERM,
      ipoCostsOfEv:
        shouldGetValuesFromPwerm && !isEquitySimulation
          ? opmValuesFromPwerm.ipoCostsOfEv
          : lastForecast?.ipoCostsOfEv ?? 0,
      ipoCostsOfEvSource: OpmInputDataSource.FromPWERM,
      hypotheticalDebtWhenIpo: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.hypotheticalDebtWhenIpo
        : lastForecast?.hypotheticalDebtWhenIpo ?? 0,
      hypotheticalDebtWhenIpoSource: OpmInputDataSource.FromPWERM,
      equitySoldInIpo: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.equitySoldInIpo
        : lastForecast?.equitySoldInIpo ?? 0,
      equitySoldInIpoSource: OpmInputDataSource.FromPWERM,
      postIpoSaleDlom: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.postIpoSaleDlom
        : lastForecast?.postIpoSaleDlom ?? 0,
      postIpoSaleDlomSource: OpmInputDataSource.FromPWERM,
      cashValue: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.cashValue
        : lastForecast?.cashValue ?? 0,
      netDebtItems: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.netDebtItems
        : lastForecast?.netDebtItems ?? [],
      marketValueDloms: addOrUpdateMarketValueDlomsFromPwerm(
        lastForecast,
        opmValuesFromPwerm.marketValueDloms,
        true
      ),
      totalNetDebtSource: OpmInputDataSource.FromPWERM,
      totalNetDebt: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.totalNetDebt
        : lastForecast?.totalNetDebt ?? 0,
      operationalFreeCashFlowSource: OpmInputDataSource.FromPWERM,
      operationalFreeCashFlow: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.operationalFreeCashFlow
        : lastForecast?.operationalFreeCashFlow ?? 0,
      operationalEvSource: OpmInputDataSource.FromPWERM,
      operationalEv: shouldGetValuesFromPwerm
        ? opmValuesFromPwerm.operationalEv
        : lastForecast?.operationalEv ?? 0,
      collaring: { inputMode: CollaringMode.Off, percentage: null, fixedValue: null },
    } as PerYearValuesDto;
  };

  const generatePerYearInputs = () => {
    const currentPerYearInputs = opmInput.perYearInputs ?? [];
    const currentYearsCount = currentPerYearInputs.length;
    const requiredYearsCount = Number(forecastYears);
    const lastForecast =
      currentYearsCount > 0 ? currentPerYearInputs[currentPerYearInputs.length - 1] : null;

    // we will update the exit probabilities if all the existing probabilities are 0%
    // OR there are no existing years
    const shouldUpdateExitProbabilities =
      currentPerYearInputs.every((yi) => yi.exitHorizonProbability === 0) ||
      currentPerYearInputs.length === 0;

    // update the inputs with any values calculated from the capital structure or PWERM
    let updatedPerYearInputs = currentPerYearInputs.map((yearInput, yearIndex) =>
      updatePerYearInput(yearInput, yearIndex, shouldUpdateExitProbabilities)
    );

    if (
      opmInput.perYearInputs &&
      currentYearsCount > 0 &&
      currentYearsCount === requiredYearsCount
    ) {
      return updatedPerYearInputs;
    }

    // add any missing years
    if (requiredYearsCount > currentYearsCount) {
      [...Array(requiredYearsCount - currentYearsCount).keys()].map((yearIndex) => {
        const yearInput = generateNewPerYearInput(
          lastForecast,
          yearIndex,
          currentYearsCount,
          shouldUpdateExitProbabilities
        );
        updatedPerYearInputs.push(yearInput);
      });
    } else {
      // remove the unneccessary years
      updatedPerYearInputs = updatedPerYearInputs.slice(0, requiredYearsCount);
    }

    return updatedPerYearInputs;
  };

  const evValueFromCapitalStructure = getEvOrEquityValueFromCapitalStructure();
  const evValue =
    opmInput.evValueSource !== OpmInputDataSource.Override
      ? evValueFromCapitalStructure
      : opmInput.evValue ?? 0;

  const maxForecastYearsFromPwerm = getMaxForecastYearsFromPwerm();
  const forecastYears =
    opmInput.forecastYearsSource !== OpmInputDataSource.Override &&
    shouldGetValuesFromPwerm &&
    maxForecastYearsFromPwerm !== 0
      ? maxForecastYearsFromPwerm
      : opmInput.forecastYears ?? 3;

  const initialCashValueFromPwerm = getInitialCashValueFromPwerm();
  const initialCashValue = shouldGetValuesFromPwerm
    ? initialCashValueFromPwerm
    : opmInput.initialCashValue ?? 0;

  const netDebtItemsFromPwerm = getNetDebtItemsFromPwerm();
  const netDebtItems = shouldGetValuesFromPwerm
    ? netDebtItemsFromPwerm
    : opmInput.netDebtItems ?? [];

  const controlPremiumForDlocFromPwerm = getControlPremiumForDlocFromPwerm();
  const controlPremiumForDloc =
    opmInput.controlPremiumForDlocSource !== OpmInputDataSource.Override && shouldGetValuesFromPwerm
      ? controlPremiumForDlocFromPwerm
      : opmInput.controlPremiumForDloc ?? 0;

  const marketVolatilityFromPwerm = getMarketVolatilityFromPwerm();
  const marketVolatility = shouldGetValuesFromPwerm
    ? marketVolatilityFromPwerm
    : opmInput.marketVolatility;

  const peerSetVolatilityFromPwerm = getPeerSetVolatilityFromPwerm();
  const peerSetVolatility = shouldGetValuesFromPwerm
    ? peerSetVolatilityFromPwerm
    : opmInput.peerSetVolatility;

  const valuationDateNarrative =
    !project.opmInput.valuationDateNarrative || project.opmInput.valuationDateNarrative === ''
      ? dateFormatter(project.valuationDate.toString(), { dateStyle: 'short' })
      : project.opmInput.valuationDateNarrative;

  const initialTotalNetDebtFromPwerm = getInitialTotalNetDebtFromPwerm();
  const initialTotalNetDebt =
    opmInput.initialTotalNetDebtSource !== OpmInputDataSource.Override && shouldGetValuesFromPwerm
      ? initialTotalNetDebtFromPwerm
      : opmInput.initialTotalNetDebt ?? 0;

  return {
    ...project,
    opmInput: {
      ...project.opmInput,
      evValue: evValue,
      evVolatility: opmInput.evVolatility ?? 0,
      forecastYears: forecastYears,
      initialCashValue: initialCashValue,
      perYearInputs: generatePerYearInputs(),
      netDebtItems: netDebtItems,
      valuationDateNarrative: valuationDateNarrative,
      controlPremiumForDloc: controlPremiumForDloc,
      initialTotalNetDebt: initialTotalNetDebt,
      marketVolatility: marketVolatility,
      peerSetVolatility: peerSetVolatility,
    },
  };
};
