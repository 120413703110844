import { SelectOption } from '@app/shared/models/option';
import styles from '../opm-summary-waterfall.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { dateFormatter } from '@app/shared/formatters';
import { Navigate, generatePath } from 'react-router-dom';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { calculateOpmSampleWaterfall } from '@app/core/store/opm-calculation-slice';
import { sortDictionaryPerInstrumentFn } from '@app/shared/helpers';
import {
  setOpmWaterfallSampleWaterfallSelectedEnterpriseValue,
  setOpmWaterfallSelectedTab,
} from '@core/store/ui-values-slice';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';
import SampleWaterfallInputs from '../sample-waterfall/sample-waterfall-inputs/SampleWaterfallInputs';
import SampleWaterfallOutputsSummaryErf from './sample-waterfall-outputs-erf/SampleWaterfallOutputsSummaryErf';

const SampleWaterfallTableErf = (): JSX.Element => {
  useSetSelectedTab(RouteConstants.SampleWaterfallErf, setOpmWaterfallSelectedTab);

  const dispatch = useAppDispatch();
  const storedSampleYear = useAppSelector(
    (state) => state.uiValues.userSelections.opmWaterfall.sampleWaterfall.selectedYear
  );
  const storedSampleInstrument = useAppSelector(
    (state) => state.uiValues.userSelections.opmWaterfall.sampleWaterfall.selectedInstrument
  );
  const storedSampleEnterpriseValue = useAppSelector(
    (state) => state.uiValues.userSelections.opmWaterfall.sampleWaterfall.selectedEnterpriseValue
  );
  const opmCalculation = useAppSelector((state) => state.opmCalculation);
  const opmWaterfall = opmCalculation?.riskFreeValues.waterfalls;
  const riskFreeResults = useAppSelector((state) => state.opmCalculation.riskFreeValues);
  const sampleWaterfallResults = riskFreeResults?.sampleWaterfall;

  let firstKey: string;
  let instrumentOptions: SelectOption[] = [];
  let yearOptions: SelectOption[] = [];

  if (opmWaterfall && Object.keys(opmWaterfall).length > 0) {
    firstKey = Object.keys(opmWaterfall)[0];
    const sortedInstruments = sortDictionaryPerInstrumentFn(
      opmWaterfall[firstKey].first[1].instruments
    );
    instrumentOptions = Object.keys(sortedInstruments)
      .filter((key) => sortedInstruments[key].shouldBeValued)
      .map((key) => ({
        value: key,
        viewValue: sortedInstruments[key].instrumentName,
      }));

    yearOptions = Object.keys(opmWaterfall).map((key) => ({
      value: key,
      viewValue: dateFormatter(key, { month: 'short', year: 'numeric' }),
    }));
  }

  const isStoredInstrumentValid =
    storedSampleInstrument &&
    instrumentOptions.some((option) => option.value === storedSampleInstrument);
  const isStoredYearValid =
    storedSampleYear && yearOptions.some((option) => option.value === storedSampleYear);

  const defaultInstrument = isStoredInstrumentValid
    ? storedSampleInstrument
    : String(instrumentOptions[0]?.value);
  const defaultYear = isStoredYearValid ? storedSampleYear : String(yearOptions[0]?.value);

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: {
      instrumentSelect: defaultInstrument,
      yearSelect: defaultYear,
      enterpriseValue: String(storedSampleEnterpriseValue) ?? '',
    },
  });

  if (!riskFreeResults || Object.keys(riskFreeResults).length === 0) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const { watch, handleSubmit, setValue } = formMethods;

  const selectedInstrument = watch('instrumentSelect');
  const selectedYear = watch('yearSelect');
  const exitOrEquityEvValue = watch('enterpriseValue');

  // measure taken to prevent the exit calc api from failing if selectedYear
  // is removed, and the user re-runs the calc whilst viewing the table
  if (!yearOptions.find((y) => y.value === selectedYear)) {
    setValue('yearSelect', String(yearOptions[0].value ?? 0));
  }

  // measure taken to prevent the exit calc api from failing if selectedInstrument
  // is removed, and the user re-runs the calc whilst viewing the table
  if (!instrumentOptions.find((v) => v.value === selectedInstrument)) {
    setValue('instrumentSelect', String(instrumentOptions[0].value ?? 0));
  }

  const shouldDisplayOutputs =
    sampleWaterfallResults && Object.keys(sampleWaterfallResults).length > 0;

  const titleWidth = '370px';
  const cellWidth = '270px';

  const submitData = async () => {
    if (formMethods.getFieldState('enterpriseValue').invalid) {
      return;
    }
    dispatch(setOpmWaterfallSampleWaterfallSelectedEnterpriseValue(exitOrEquityEvValue));
    await dispatch(
      calculateOpmSampleWaterfall({
        yearDate: selectedYear,
        instrumentId: selectedInstrument,
        enterpriseValue: exitOrEquityEvValue,
      })
    ).unwrap();
  };

  return (
    <div className="main-container-padding-remove">
      <FormProvider {...formMethods}>
        <table
          onBlur={handleSubmit(submitData, submitData)}
          className={`table-primary table-primary--zebra table-primary--framed table-primary--framed-bottom table-primary--title-column-nowrap ${styles['opm-sample-waterfall-table']}`}
          data-testid="opm-sample-waterfall-table">
          <colgroup>
            <col style={{ width: titleWidth }} />
            <col style={{ width: cellWidth }} />
          </colgroup>
          <SampleWaterfallInputs
            instrumentOptions={instrumentOptions}
            yearOptions={yearOptions}
            shouldDisplayOutputs={shouldDisplayOutputs}
          />
          {shouldDisplayOutputs && (
            <SampleWaterfallOutputsSummaryErf
              sampleWaterfallResults={sampleWaterfallResults}
              selectedInstrument={
                opmWaterfall[Object.keys(opmWaterfall)[0]].first[1].instruments[selectedInstrument]
                  .instrumentName
              }
            />
          )}
        </table>
      </FormProvider>
    </div>
  );
};

export default SampleWaterfallTableErf;
