import api from '@server/api-config';
import env from '@environment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Pwerm2CalculationResultsDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { AppDispatch, RootState } from './store';
import { WithValidationResult } from '@app/shared/interfaces/with-validation-result';
import {
  Pwerm2InputCalculationResultsDto,
  Pwerm2InputsHistoricEventsCalculationResultsDto,
  Pwerm2InputYearDebtInstrumentCalculationResultsDto,
  Pwerm2InputYearDebtInstrumentCalculationResultsEventsDto,
  Pwerm2InputYearOrdinaryEquityInstrumentCalculationResultsDto,
} from '@app/shared/models/contracts/pwerm2-input-calculation-results-dto';
import { isDevFeatureEnabled, DevFeature } from '../utils/dev-feature';
import { EndpointConstants } from './EndpointConstants';
import { CouponType, InterestPaymentType } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';

export interface Pwerm2CalculationState {
  projectId?: number;
  calculatedResults: Pwerm2CalculationResultsDto;
  calculatedInputs: Pwerm2InputCalculationResultsDto;
}

export const pwerm2CalculateInputs = createAsyncThunk<
  { calculations: Pwerm2InputCalculationResultsDto; projectId: number },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculatePwerm2Inputs', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;
  const apiURL = `${env.apiUrl}${EndpointConstants.CalculatePwermInputs}`;
  const results = await api.post<WithValidationResult<Pwerm2InputCalculationResultsDto>>(
    apiURL,
    JSON.stringify(project)
  );

  const projectCases = Object.keys(results.data.result.cases);
  const projectInstruments = Object.keys(
    results.data.result.cases[projectCases[0]].valuationDate.shareholderDebtInstruments
  );

  const firstForecastDate = Object.keys(
    results.data.result.cases[projectCases[0]].forecastDates
  )[0];
  const ordinaryProjectInstruments = Object.keys(
    results.data.result.cases[projectCases[0]].forecastDates[firstForecastDate].ordinaryInstruments
  );

  const firstForecast = new Date(firstForecastDate);
  const day = firstForecast.getDate();
  const month = firstForecast.getMonth() + 1;
  const year = firstForecast.getFullYear();

  const historicDates = [
    `${year - 1}-${month}-${day}`,
    `${year - 2}-${month}-${day}`,
    `${year - 3}-${month}-${day}`,
  ];

  const createHistoricEventData = (
    num: number
  ): Pwerm2InputsHistoricEventsCalculationResultsDto => {
    const shareholderDebtInstruments: Dictionary<Pwerm2InputYearDebtInstrumentCalculationResultsDto> =
      {};
    const shareholderDebtInstrumentsEvents: Dictionary<Pwerm2InputYearDebtInstrumentCalculationResultsEventsDto> =
      {};
    const ordinaryInstruments: Dictionary<Pwerm2InputYearOrdinaryEquityInstrumentCalculationResultsDto> =
      {};

    projectInstruments.forEach((instrument) => {
      shareholderDebtInstruments[instrument] = {
        closingBalanceByOwner: {
          Institution: 1000,
          Management: 2000,
        },
      };
    });

    projectInstruments.forEach((instrument) => {
      shareholderDebtInstrumentsEvents[instrument] = {
        closingBalance: 999,
        periodTotal: {
          openingBalance: 5000,
          additions: 1000,
          accruedInterest: 200,
          closingBalance: 6200,
          couponPc: 5,
          couponType: enumKeyByValue(CouponType, CouponType.CompoundInterest),
          cashPayProportionPc: 50,
          cashPayInterest: 100,
        },
        periodByEvent: [
          {
            eventNarrative: 'Event 1',
            periodStart: '2021-01-01',
            periodEnd: '2021-12-31',
            openingBalance: 5000 + num,
            additions: 1000,
            accruedInterest: 200,
            closingBalance: 6200,
            couponPc: 5,
            interestPaymentType: enumKeyByValue(InterestPaymentType, InterestPaymentType.CashPay),
            couponType: enumKeyByValue(CouponType, CouponType.CompoundInterest),
            cashPayProportionPc: 50,
            cashPayInterest: 100,
          },
          {
            eventNarrative: 'Event 2',
            periodStart: '2021-02-01',
            periodEnd: '2021-12-31',
            openingBalance: 6000,
            additions: 2000,
            accruedInterest: 300,
            closingBalance: 7200,
            couponPc: 6,
            interestPaymentType: enumKeyByValue(InterestPaymentType, InterestPaymentType.CashPay),
            couponType: enumKeyByValue(CouponType, CouponType.CompoundInterest),
            cashPayProportionPc: 60,
            cashPayInterest: 90,
          },
        ],
      };
    });

    ordinaryProjectInstruments.forEach((instrument) => {
      ordinaryInstruments[instrument] = {
        periodTotal: { additions: 7004 },
        periodByEvent: [
          {
            date: '01/01/2021',
            additions: 3002,
          },
          {
            date: '01/02/2021',
            additions: 4002,
          },
        ],
      };
    });

    return {
      ordinaryInstruments,
      shareholderDebtInstruments,
      shareholderDebtInstrumentsEvents,
      shareholderDebtSummary: {
        PreferredShares: { Institution: 1000, Management: 2000 },
        ShareholderLoanNotes: { Management: 1000 },
      },
      shareholderDebtTotalByType: {
        PreferredShares: 1000,
        ShareholderLoanNotes: 2000,
      },
      totalNetDebt: 3000,
    };
  };

  const historicEventsYears: Dictionary<Pwerm2InputsHistoricEventsCalculationResultsDto> = {};

  historicDates.forEach((date, index) => {
    historicEventsYears[date] = createHistoricEventData(index);
  });

  const casesWithHistoricData = projectCases.reduce((acc, caseId) => {
    acc[caseId] = {
      ...results.data.result.cases[caseId],
      historicDates: {
        ...results.data.result.cases[caseId].historicDates,
        historicEventsYears,
      },
    };
    return acc;
  }, {} as Record<string, any>);

  const resultsWithDummyHistoricData = {
    ...results.data.result,
    cases: casesWithHistoricData,
  };

  return {
    calculations: isDevFeatureEnabled(DevFeature.DummyHistoricEventsData)
      ? resultsWithDummyHistoricData
      : results.data.result,
    projectId: project.id,
  };
});

export const pwerm2Calculate = createAsyncThunk<
  { calculations: Pwerm2CalculationResultsDto; projectId: number },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculatePwerm2', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;

  const results = await api.post<WithValidationResult<Pwerm2CalculationResultsDto>>(
    `${env.apiUrl}${EndpointConstants.CalculatePwerm}`,
    JSON.stringify(project)
  );
  return { calculations: results.data.result, projectId: project.id };
});

export const pwerm2CalculationSlice = createSlice({
  name: 'pwerm2calculation',
  initialState: { calculatedResults: {}, calculatedInputs: {} } as Pwerm2CalculationState,
  reducers: {
    clearPwerm2Results: (state) => {
      state.calculatedResults = {} as Pwerm2CalculationResultsDto;
      state.calculatedInputs = {} as Pwerm2InputCalculationResultsDto;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pwerm2Calculate.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      state.calculatedResults = {
        ...action.payload.calculations,
      };
    });
    builder.addCase(pwerm2CalculateInputs.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      state.calculatedInputs = {
        ...action.payload.calculations,
      };
    });
  },
});

export const { clearPwerm2Results } = pwerm2CalculationSlice.actions;

export default pwerm2CalculationSlice.reducer;
