import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { BenchmarkingInputsCalculationResultsDto } from '@app/shared/models/contracts/benchmarking-input-calculation-results-dto';
import { useMemo } from 'react';

export interface BenchmarkingCalculatedValues2 {
  fixedReturnInstrumentWithinStripPc: Nullable<number>;
  volumeWeightedPreferredInstrumentCouponPc: Nullable<number>;
  managementStripPc: Nullable<number>;
  managementStripTotalSubscriptionCost: Nullable<number>;
  returnsAnalysis: {
    low: {
      managementMoMSweetOnly: Nullable<number>;
      managementStripReturn: Nullable<number>;
      totalManagementMom: Nullable<number>;
      envyRatio: Nullable<number>;
      envyRatioSweetOnly: Nullable<number>;
    };
    mid: {
      managementMoMSweetOnly: Nullable<number>;
      managementStripReturn: Nullable<number>;
      totalManagementMom: Nullable<number>;
      envyRatio: Nullable<number>;
      envyRatioSweetOnly: Nullable<number>;
    };
    high: {
      managementMoMSweetOnly: Nullable<number>;
      managementStripReturn: Nullable<number>;
      totalManagementMom: Nullable<number>;
      envyRatio: Nullable<number>;
      envyRatioSweetOnly: Nullable<number>;
    };
  };
}

export const useDefaultCalculatedBenchmarking2 = (
  results: BenchmarkingInputsCalculationResultsDto
): BenchmarkingCalculatedValues2 => {
  const calculatedValue = useMemo(() => {
    return {
      fixedReturnInstrumentWithinStripPc:
        getCalculatedProjectValueInPercent(results.fixedReturnInstrumentWithinStripPc) ?? null,
      volumeWeightedPreferredInstrumentCouponPc:
        getCalculatedProjectValueInPercent(results.volumeWeightedPreferredInstrumentCouponPc) ??
        null,
      managementStripPc: getCalculatedProjectValueInPercent(results.managementStripPc) ?? null,
      managementStripTotalSubscriptionCost: results.managementStripTotalSubscriptionCost ?? null,
      returnsAnalysis: {
        low: {
          managementMoMSweetOnly: results.returnsAnalysis.low.managementMoMSweetOnly ?? null,
          managementStripReturn: results.returnsAnalysis.low.managementStripReturn ?? null,
          totalManagementMom: results.returnsAnalysis.low.totalManagementMoM ?? null,
          envyRatio: results.returnsAnalysis.low.envyRatio ?? null,
          envyRatioSweetOnly: results.returnsAnalysis.low.envyRatioSweetOnly ?? null,
        },
        mid: {
          managementMoMSweetOnly: results.returnsAnalysis.mid.managementMoMSweetOnly ?? null,
          managementStripReturn: results.returnsAnalysis.mid.managementStripReturn ?? null,
          totalManagementMom: results.returnsAnalysis.mid.totalManagementMoM ?? null,
          envyRatio: results.returnsAnalysis.mid.envyRatio ?? null,
          envyRatioSweetOnly: results.returnsAnalysis.mid.envyRatioSweetOnly ?? null,
        },
        high: {
          managementMoMSweetOnly: results.returnsAnalysis.high.managementMoMSweetOnly ?? null,
          managementStripReturn: results.returnsAnalysis.high.managementStripReturn ?? null,
          totalManagementMom: results.returnsAnalysis.high.totalManagementMoM ?? null,
          envyRatio: results.returnsAnalysis.high.envyRatio ?? null,
          envyRatioSweetOnly: results.returnsAnalysis.high.envyRatioSweetOnly ?? null,
        },
      },
    };
  }, [results]);
  return calculatedValue;
};
