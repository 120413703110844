import {
  ForecastDto,
  PerYearValuesDto,
  MarketValueDlomDto,
  DlomDto,
  PayoutLogicDto,
  TrancheDto,
} from '@app/shared/models/contracts/project-dto';

export const getDlomIndexByTrancheIdLegacy = (forecast: ForecastDto, trancheItem: TrancheDto) => {
  return forecast.dlom?.findIndex((dlomItem: DlomDto) => {
    return dlomItem.trancheId === trancheItem.id;
  });
};

export const getDlomIndexByInstrumentId = (forecast: ForecastDto, instrumentId: string) => {
  return forecast.dlom?.findIndex((dlomItem: DlomDto) => {
    return dlomItem.instrumentId === instrumentId;
  });
};

export const getDlomIndexByInstrumentIdForOpm = (
  perYearInput: PerYearValuesDto,
  instrumentId: string
) => {
  return perYearInput.marketValueDloms?.findIndex((dlomItem: MarketValueDlomDto) => {
    return dlomItem.instrumentId === instrumentId;
  });
};

export const getDlomIndexByTrancheId = (forecast: ForecastDto, trancheItem: PayoutLogicDto) => {
  return forecast.dlom?.findIndex((dlomItem: DlomDto) => {
    return dlomItem.trancheId === trancheItem.id;
  });
};

export const getDlomIndexByTrancheIdForOpm = (
  perYearInput: PerYearValuesDto,
  trancheItem: PayoutLogicDto,
  instrumentId: string
) => {
  const instrumentIndex = getDlomIndexByInstrumentIdForOpm(perYearInput, instrumentId);
  return perYearInput.marketValueDloms[instrumentIndex].trancheDloms?.findIndex((dlomItem) => {
    return dlomItem.trancheId === trancheItem.id;
  });
};
