import { EquityItem } from '@app/modules/projects/inputs/capital-structure/CapitalStructureItemFormErf';
import {
  CapitalStructureBuildStructureDto,
  CapitalStructureEventDto,
  CapitalStructureEventSetDto,
  CapitalStructureInstrumentDefinitionDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { createSelector } from '@reduxjs/toolkit';
import { enumKeyByValue } from '@app/shared/helpers';
import {
  InstrumentDefinitionDto,
  InstrumentInitialValuesDto,
} from '@app/shared/models/contracts/project-dto';
import { RootState } from './store';

export type InstrumentDefinitionWithId = InstrumentDefinitionDto & { instrumentId: string };
export type InstrumentDefinitionAndInitialValues = InstrumentDefinitionWithId & {
  initialValues: Nullable<InstrumentInitialValuesDto>;
};

const selectProjectDraft = (state: RootState) => state.project.projectDraft;

export const selectCapitalStructures = createSelector(
  [selectProjectDraft],
  (projectDraft) => projectDraft.capitalStructures
);

export const selectCapitalStructure = createSelector(
  [selectCapitalStructures],
  (capitalStructures) => Object.values(capitalStructures)[0]
);

export const selectInstruments = createSelector(
  [selectCapitalStructure],
  (capitalStructure): InstrumentDefinitionWithId[] => {
    return Object.entries(capitalStructure.instrumentDefinitions).map(
      ([instrumentId, instrument]) => ({
        instrumentId,
        ...instrument,
      })
    );
  }
);

/**
 * Selects instruments with their initial values from the capital structure.
 * @returns An array of `InstrumentDefinitionAndInitialValues` objects.
 *          The `initialValues` property of each object may be `null` if the instrument
 *          was not added in the initial capital structure.
 */
export const selectInstrumentsWithInitialValues = createSelector(
  [selectCapitalStructure, selectInstruments],
  (capitalStructure, instrumentDefinitions): InstrumentDefinitionAndInitialValues[] => {
    return instrumentDefinitions.map((instrument) => {
      const initialValues =
        instrument.instrumentId in capitalStructure.initialValues
          ? capitalStructure.initialValues[instrument.instrumentId]
          : null;
      return {
        ...instrument,
        initialValues: initialValues,
      };
    });
  }
);

/**
 * Selects debt instruments with their initial values from the capital structure.
 * @returns An array of `InstrumentDefinitionAndInitialValues` objects.
 *          The `initialValues` property of each object may be `null` if the instrument
 *          was not added in the initial capital structure.
 */
export const selectSlnAndPrefSharesInstrumentsWithInitialValues = createSelector(
  [selectInstrumentsWithInitialValues],
  (allInstruments): InstrumentDefinitionAndInitialValues[] => {
    return allInstruments.filter(
      (instrument) =>
        instrument.type !== enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
    );
  }
);

export const selectOrdinaryEquityInstruments = createSelector(
  [selectInstruments],
  (instruments): InstrumentDefinitionWithId[] => {
    return instruments.filter(
      (instrument) =>
        instrument.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
    );
  }
);

export const selectPrefShareInstruments = createSelector(
  [selectInstruments],
  (instruments): InstrumentDefinitionWithId[] => {
    return instruments.filter(
      (instrument) =>
        instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
    );
  }
);

export const selectSlnInstruments = createSelector(
  [selectInstruments],
  (instruments): InstrumentDefinitionWithId[] => {
    return instruments.filter(
      (instrument) =>
        instrument.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
    );
  }
);

export const selectSlnAndPrefSharesInstruments = createSelector(
  [selectInstruments],
  (instruments): InstrumentDefinitionWithId[] => {
    return instruments.filter(
      (instrument) =>
        instrument.type !== enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
    );
  }
);

export const selectValuedInstruments = createSelector(
  [selectInstruments],
  (instruments): InstrumentDefinitionWithId[] => {
    return instruments.filter((instrument) => instrument.shouldBeValued);
  }
);

export const getInitialTotalEquityByInstrumentTypeAndOwnership = (
  instruments: InstrumentDefinitionAndInitialValues[],
  instrumentTypeFilter?: keyof typeof InstrumentType,
  ownershipFilter?: keyof typeof OwnerType
): number | null => {
  let amount: null | number = null;
  const filteredInstruments = instrumentTypeFilter
    ? instruments.filter((ins) => ins.type === instrumentTypeFilter)
    : instruments;

  filteredInstruments.map((ins) => {
    const ownership = ins.initialValues?.ownership;

    if (ownership) {
      Object.entries(ownership).map(([ownerType, ownershipItem]) => {
        if (!ownershipFilter || ownerType === ownershipFilter) {
          if (ownershipItem.amount) {
            const currentValue = Number(ownershipItem.amount);
            amount ? (amount += currentValue) : (amount = currentValue);
          }
        }
      });
    }
  });

  return amount;
};

export const getEventInstrumentsFromBuildStructure = (
  buildStructure: Dictionary<CapitalStructureBuildStructureDto>,
  eventSetId: string,
  eventId: string
): EquityItem[] => {
  const eventSet = Object.values(buildStructure)[0].eventSets[eventSetId];
  const instrumentDefinitions = Object.values(buildStructure)[0].instrumentDefinitions;
  const event = eventSet.events.find((event) => event.id === eventId);

  if (!event) {
    return [];
  }

  const instruments = event.ranks.flatMap((rank) =>
    Object.entries(rank.instruments).map(([instrumentId, instrument]) => ({
      ...instrument,
      instrumentId,
    }))
  );

  return instruments.map((instrument) => {
    return {
      ...instrument,
      ...instrumentDefinitions[instrument.instrumentId],
      ownership: Object.keys(OwnerType).map((owner) => {
        return {
          owner: owner as keyof typeof OwnerType,
          amount: instrument.ownershipAfterMovements[owner]?.amount ?? null,
          numberOfShares: instrument.ownershipAfterMovements[owner]?.numberOfShares ?? null,
        };
      }),
    };
  });
};

export const getEventInstrumentsFromEvent = (
  event: CapitalStructureEventDto,
  instrumentDefinitions: Dictionary<CapitalStructureInstrumentDefinitionDto>
): EquityItem[] => {
  const instruments = event.ranks.flatMap((rank) =>
    Object.entries(rank.instruments).map(([instrumentId, instrument]) => ({
      ...instrument,
      instrumentId,
    }))
  );

  return instruments.map((instrument) => {
    return {
      ...instrument,
      ...instrumentDefinitions[instrument.instrumentId],
      ownership: Object.keys(OwnerType).map((owner) => {
        return {
          owner: owner as keyof typeof OwnerType,
          amount: instrument.ownershipAfterMovements[owner]?.amount ?? null,
          numberOfShares: instrument.ownershipAfterMovements[owner]?.numberOfShares ?? null,
        };
      }),
    };
  });
};

export const getDuplicateGroups = (eventSets: [string, CapitalStructureEventSetDto][]) => {
  const processedEventSetIds = new Set<string>();
  const groups: string[][] = [];

  const eventSetMap = Object.fromEntries(eventSets);

  const traverseEventSet = (eventSetId: string, currentGroup: string[]) => {
    processedEventSetIds.add(eventSetId);
    currentGroup.push(eventSetId);

    for (const duplicateId of eventSetMap[eventSetId].duplicates) {
      if (!processedEventSetIds.has(duplicateId)) {
        traverseEventSet(duplicateId, currentGroup);
      }
    }
  };

  for (const [eventSetId] of eventSets) {
    if (!processedEventSetIds.has(eventSetId)) {
      const currentGroup: string[] = [];
      traverseEventSet(eventSetId, currentGroup);
      groups.push(currentGroup);
    }
  }

  return groups;
};
