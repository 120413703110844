import React, { FC } from 'react';
import classNames from 'classnames';
import styles from '../../widgets/total-equity/total-equity-chart.module.scss';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { dateFormatter } from '@app/shared/formatters';
import { MAX_LABEL_ITEMS } from '../../widgets/total-equity/constants';
import { Pwerm2CalculationDashboardTotalEquityChartYearDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';

const PARENT_CLASSNAME = 'total-equity-chart';

interface NetDebtColumnProps {
  isExpanded: boolean;
  labelTopValue: number;
  forecastYear: string;
  maxNetDebtAxisCount: number;
  columnIndex: number;
  netDebt: number;
  chartForecastsCount: number;
  tooltipContent: string | JSX.Element;
  setSelectedStructure: (
    structure: Pwerm2CalculationDashboardTotalEquityChartYearDto | null
  ) => void;
  setIsTotalEquityChartModalVisible: (isVisible: boolean) => void;
  getSelectedStructure: (
    isEntry: boolean,
    forecastIndex?: number
  ) => Pwerm2CalculationDashboardTotalEquityChartYearDto | null;
}

const Pwerm2NetDebtColumn: FC<NetDebtColumnProps> = ({
  isExpanded,
  labelTopValue,
  forecastYear,
  columnIndex,
  chartForecastsCount,
  netDebt,
  maxNetDebtAxisCount,
  tooltipContent,
  setSelectedStructure,
  setIsTotalEquityChartModalVisible,
  getSelectedStructure,
}): JSX.Element => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isTotalEquityChartWithModalEnabled = isDevFeatureEnabled(
    DevFeature.TotalEquityChartWithModal
  );

  const handleClick = () => {
    if (isTotalEquityChartWithModalEnabled) {
      setSelectedStructure(getSelectedStructure(false, columnIndex));
      setIsTotalEquityChartModalVisible(true);
    }
  };

  return (
    <div
      className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
        [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
      })}>
      <div
        onClick={handleClick}
        className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
          [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
          [styles['cursor-pointer']]: isTotalEquityChartWithModalEnabled,
        })}
        style={{
          height: (100 / ((labelTopValue / MAX_LABEL_ITEMS) * maxNetDebtAxisCount)) * netDebt + '%',
        }}>
        <Tooltip
          key={'debt-tooltip' + columnIndex}
          placement={
            chartForecastsCount - 1 === columnIndex ? TooltipPlacement.Left : TooltipPlacement.Top
          }
          content={tooltipContent}
          className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
            [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
          })}>
          <div className={styles[`${PARENT_CLASSNAME}__tooltip-placeholder`]} />
        </Tooltip>
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__column-legend`]}>
        {dateFormatter(forecastYear, { year: 'numeric' })}
      </div>
    </div>
  );
};

export default React.memo(Pwerm2NetDebtColumn);
