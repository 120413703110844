import { cloneDeep } from '@app/shared/helpers';
import { DlomDto, PayoutLogicDto, ProjectDto } from '@app/shared/models/contracts/project-dto';

export const missingDlomElements = (project: ProjectDto) => {
  const capitalStructureKey = Object.keys(project.capitalStructures)?.[0];
  if (capitalStructureKey === undefined) {
    return [];
  }

  const tranches = Object.values(
    project.capitalStructures[capitalStructureKey].instrumentDefinitions
  )
    .filter((instrument) => instrument.payoutLogic && instrument.payoutLogic.length > 0)
    .flatMap((instrument) => instrument.payoutLogic);

  if (tranches.length === 0) {
    return [];
  }

  const missingTranchesFromDlom = tranches.filter((tranche) => {
    return project.pwermInput.cases.some((caseItem) => {
      return caseItem.forecasts.some((forecast) => {
        return !forecast.dlom.some((dlomItem) => dlomItem.trancheId === tranche?.id);
      });
    });
  });

  const filteredMissingTranchesFromDlom = missingTranchesFromDlom.filter(
    (payoutLogic): payoutLogic is PayoutLogicDto =>
      payoutLogic !== null && payoutLogic !== undefined
  );

  return filteredMissingTranchesFromDlom;
};

export const setDlomElementsToNull = (missingTranches: PayoutLogicDto[], project: ProjectDto) => {
  const capitalStructureKey = Object.keys(project.capitalStructures)?.[0];
  const updatedProject = cloneDeep(project);

  const additionalDlomItems: DlomDto[] = missingTranches.map((tranche) => {
    return {
      instrumentId:
        Object.entries(project.capitalStructures[capitalStructureKey].instrumentDefinitions).find(
          ([_, instrument]) => instrument.payoutLogic?.includes(tranche)
        )?.[0] ?? '',
      value: null,
      trancheId: tranche.id,
    };
  });

  updatedProject.pwermInput.cases = project.pwermInput.cases.map((caseItem) => {
    return {
      ...caseItem,
      forecasts: caseItem.forecasts.map((forecastItem) => {
        return {
          ...forecastItem,
          dlom: [...forecastItem.dlom, ...additionalDlomItems],
        };
      }),
    };
  });

  return updatedProject;
};
