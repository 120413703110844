import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { FC } from 'react';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import * as projectActions from '@core/store/project-slice';
import {
  enumKeyByValue,
  getProjectDraftWithAddedInstrumentLegacy,
  getNewInstrumentLegacy,
  sumBy,
} from '@app/shared/helpers';
import styles from './capital-structure.module.scss';
import classNames from 'classnames';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import Button from '@app/shared/components/button/Button';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { calculateCapitalStructureDimensions } from '../../capital-structure-block-size-calculator';
import { InstrumentBlock } from '../../capital-structure-types';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import {
  amountValueFormatterProps,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import {
  calculatePercentage,
  getTotalOfEquityByInstrumentTypeAndOwnershipLegacy,
} from '@core/store/project-slice-selectors';

const CapitalStructure: FC = () => {
  const activeInstrumentId = useAppSelector((state) => state.uiValues.activeInstrument);
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);

  const [{ isActive }, drop] = useDrop(
    () => ({
      accept: [
        InstrumentType.ShareholderLoanNotes,
        InstrumentType.PreferredShares,
        InstrumentType.OrdinaryEquity,
      ],
      drop(_item: string, monitor) {
        const draggedInstrumentType = monitor.getItemType() as InstrumentType;
        addInstrument(draggedInstrumentType);
      },
      collect: (monitor) => ({
        isActive: monitor.canDrop() && monitor.isOver(),
      }),
    }),
    [project]
  );

  const handleInstrumentSelection = (instrumentId: string): void => {
    dispatch(uiValuesSlice.actions.setActiveInstrument({ instrumentId }));
  };

  const addInstrument = async (instrumentType: InstrumentType): Promise<void> => {
    const instrumentId = uuidv4();
    const newInstrument = getNewInstrumentLegacy(project, instrumentType, instrumentId);

    await dispatch(
      projectActions.updateProjectDraft({
        project: getProjectDraftWithAddedInstrumentLegacy({ draft: project, newInstrument }),
      })
    );

    dispatch(uiValuesSlice.actions.setActiveInstrument({ instrumentId }));
  };

  const structure = calculateCapitalStructureDimensions(
    project.equityInstruments.map((x) => ({
      ...x,
      amount: sumBy(x.ownership, (c) => Number(c.amount) || 0) ?? 0,
    })),
    380,
    362,
    32,
    24
  );

  const getInstrumentPercentageOfTotalPerInstrumentType = (instrument: EquityInstrumentDto) =>
    calculatePercentage(
      getTotalOfEquityByInstrumentTypeAndOwnershipLegacy(
        project.equityInstruments,
        enumKeyByValue(InstrumentType, InstrumentType[instrument.type])
      ),
      getTotalOfEquityByInstrumentTypeAndOwnershipLegacy(
        [instrument],
        enumKeyByValue(InstrumentType, InstrumentType[instrument.type])
      )
    );

  const getPercentageOfTotalInvestedAmount = (instrument: EquityInstrumentDto) =>
    calculatePercentage(
      getTotalOfEquityByInstrumentTypeAndOwnershipLegacy(project.equityInstruments),
      getTotalOfEquityByInstrumentTypeAndOwnershipLegacy([instrument])
    );

  const createInstrumentBlockTooltip = (instrument: InstrumentBlock<EquityInstrumentDto>) => {
    return (
      <div className={styles['instrument-details']}>
        <h2 className={styles['instrument-details__title']}>
          {instrument.instrumentNarrative} investment
        </h2>
        <p className={styles['instrument-details__subtitle']}>{InstrumentType[instrument.type]}</p>
        <ul className={styles['instrument-details__owners-list']}>
          {instrument.ownership
            .filter((item) => Boolean(item.amount) || item.amount === 0)
            .map((item, index) => (
              <li key={index} className={styles['instrument-details__owners-list-item']}>
                {OwnerType[item.owner]}{' '}
                <strong>
                  {numberValueFormatter({
                    value: Number(item.amount),
                    ...amountValueFormatterProps,
                  })}
                </strong>
              </li>
            ))}
        </ul>
        <div className={styles['instrument-details__percentages-container']}>
          {getInstrumentPercentageOfTotalPerInstrumentType(instrument) !== null && (
            <div>
              {`${numberValueFormatter({
                value: getInstrumentPercentageOfTotalPerInstrumentType(instrument)!,
                ...percentageValueFormatterProps,
              })} of ${InstrumentType[instrument.type]}`}
            </div>
          )}
          {getPercentageOfTotalInvestedAmount(instrument) !== null && (
            <div>
              {`${numberValueFormatter({
                value: getPercentageOfTotalInvestedAmount(instrument)!,
                ...percentageValueFormatterProps,
              })} of Total Equity`}
            </div>
          )}
        </div>
      </div>
    );
  };

  const createInstrumentBlock = (instrument: InstrumentBlock<EquityInstrumentDto>, key: number) => {
    return (
      <Tooltip
        key={key}
        content={createInstrumentBlockTooltip(instrument)}
        placement={TooltipPlacement.Right}
        className={styles['graph__item-container']}
        style={{ width: instrument.width }}>
        <Button
          data-testid="instrument-block"
          appearance={ButtonAppearance.CLEAN}
          onClick={() => handleInstrumentSelection(instrument.instrumentId)}
          key={key}
          ignoreReadOnly
          className={classNames(styles['graph__item'], {
            [styles['graph__item--sweet-equity']]:
              instrument.isSweetEquity &&
              instrument.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity),
            [styles['graph__item--shareholder-loan']]:
              instrument.type ===
              enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
            [styles['graph__item--preferred-shares']]:
              instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
            [styles['graph__item--active']]:
              instrument.instrumentId === activeInstrumentId?.instrumentId,
          })}>
          <div className={styles['graph__item-text']}>{instrument.instrumentNarrative}</div>
        </Button>
      </Tooltip>
    );
  };

  const renderRanks = () => {
    return structure.ranks.map((rank) => {
      return (
        <div
          key={rank.rank}
          style={{ height: rank.height }}
          className={styles['graph__rank-block']}>
          {rank.instrumentBlocks.map((instrument, i) => {
            return createInstrumentBlock(instrument, i);
          })}
        </div>
      );
    });
  };

  return (
    <div
      ref={drop}
      data-testid="build-structure-drag-area"
      className={classNames(styles['graph'], {
        [styles['graph--active']]: isActive,
      })}>
      {structure.ranks.length > 0 && (
        <div style={{ width: structure.maxWidth }} className={styles['graph__wrapper']}>
          {renderRanks()}
        </div>
      )}
    </div>
  );
};

export default CapitalStructure;
