import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import {
  multipleFieldFormattingProps,
  percentageFieldFormattingProps,
  textFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { getNatureOfSweetEquityOptions } from '@app/shared/constants/dropdown-values/benchmark/nature-of-sweet-equity-options';
import { getBasisOfHurdleOptions } from '@app/shared/constants/dropdown-values/benchmark/basis-of-hurdle-options';
import { getStructureOfRatchetMechanismOptions } from '@app/shared/constants/dropdown-values/benchmark/structure-of-ratchet-mechanism-options';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';

const SweetEquityDetails: FC = (): JSX.Element => {
  const { status, storeBenchmarkingData } = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();
  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>{l('_SweetEquityDetails')}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="sweetEquityDetails.natureOfSweetEquityParticipationReturn"
            label={l('_NatureOfSweetEquityParticipationReturn')}
            options={getNatureOfSweetEquityOptions()}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="sweetEquityDetails.hurdleRateIRR"
            label={l('_HurdleRateIRR')}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="sweetEquityDetails.sweetEquityPercentOfTotalOrdinaryEquity"
            label={l('_SweetEquityPercentageOfTotalOrdinaryEquity')}
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="sweetEquityDetails.basisOfHurdle"
            label={l('_BasisOfHurdle')}
            options={getBasisOfHurdleOptions()}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="sweetEquityDetails.structureOfRatchetMechanism"
            label={l('_StructureOfRatchetMechanism')}
            options={getStructureOfRatchetMechanismOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="sweetEquityDetails.maxSweetEquityPercentPostRatchet"
            label={l('_MaxSweetEquityPercentagePostRatchet')}
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name="sweetEquityDetails.hurdleRateMoM"
            label={l('_HurdleRateMoM')}
            {...multipleFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="sweetEquityDetails.detailsOfAdditionalRatchets"
            label={l('_DetailsOfAdditionalRatchets')}
            placeholder="Add details"
            {...textFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column" />
      </div>
    </Card>
  );
};

export default SweetEquityDetails;
