import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import {
  amountBasicFieldFormattingProps,
  multipleFieldFormattingPropsSecondary,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { currencyDenominationPair, enumKeyByValue } from '@app/shared/helpers';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import {
  amountRules,
  calculatedMultiplierRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useLocale } from '@app/core/hooks/useLocale';
import { BenchmarkingInputsReturnsAnalysisDetailsDto } from '@app/shared/models/contracts/benchmarking-input-calculation-results-dto';

export enum AnalysisType {
  High = '_High',
  Mid = '_Mid',
  Low = '_Low',
}

interface ReturnAnalysisProps {
  analysisType: AnalysisType;
}

const ReturnAnalysis: FC<ReturnAnalysisProps> = ({ analysisType }) => {
  const {
    status,
    storeBenchmarkingData,
    details: { currency, denomination },
    benchmarking: { benchmarkingOnly },
  } = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();
  const { returnsAnalysis: returnAnalysisResults } = useAppSelector(
    (state) => state.benchmarking.benchmarkingInputs
  );
  const analysisTypeKey = enumKeyByValue(AnalysisType, analysisType).toLowerCase();

  let results: BenchmarkingInputsReturnsAnalysisDetailsDto =
    {} as BenchmarkingInputsReturnsAnalysisDetailsDto;
  switch (analysisType) {
    case AnalysisType.Low: {
      results = returnAnalysisResults.low;
      break;
    }
    case AnalysisType.Mid: {
      results = returnAnalysisResults.mid;
      break;
    }
    case AnalysisType.High: {
      results = returnAnalysisResults.high;
      break;
    }
    default:
      break;
  }

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>
        {l('_ReturnAnalysis', {
          analysisType: `$t(${analysisType})`,
          interpolation: { skipOnVariables: false },
        })}
      </h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label={l('_SponsorMoM')}
            value={results.sponsorMoM}
            {...calculatedMultiplierRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisTypeKey}.sweetReturnAsPercentOfTotalIncreaseInAttributableEquity`}
            label={l('_SweetReturnAsAPercentageOfTotalIncreaseInAttributableEquity')}
            rules={{
              ...(shouldFieldsBeRequired && getRequiredValidator()),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_ManagementMoMSweetOnly')}
              value={results.managementMoMSweetOnly}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisTypeKey}.managementMoMSweetOnly`}
              label={l('_ManagementMoMSweetOnly')}
              defaultValue={results.managementMoMSweetOnly ?? undefined}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisTypeKey}.impliedSponsorIRR`}
            label={l('_ImpliedSponsorIRR')}
            {...percentageFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={l('_TotalManagementReturnAbsolute')}
            value={results.totalManagementReturn}
            {...amountRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisTypeKey}.managementIRRSweetOnly`}
            label={l('_ManagementIRRSweetOnly')}
            {...percentageFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_ManagementStripReturnAbsolute', {
                currencyAndDenomination: currencyDenominationPair(currency, denomination),
                interpolation: { escapeValue: false },
              })}
              value={results.managementStripReturn}
              {...amountRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisTypeKey}.managementStripReturn`}
              label={l('_ManagementStripReturnAbsolute', {
                currencyAndDenomination: currencyDenominationPair(currency, denomination),
                interpolation: { escapeValue: false },
              })}
              defaultValue={results.managementStripReturn ?? undefined}
              placeholder="0"
              rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
              {...amountBasicFieldFormattingProps}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_TotalManagementMoM')}
              value={results.totalManagementMoM}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisTypeKey}.totalManagementMom`}
              label={l('_TotalManagementMoM')}
              defaultValue={results.totalManagementMoM ?? undefined}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_EnvyRatioAbsoluteReturns')}
              value={results.envyRatio}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisTypeKey}.envyRatio`}
              label={l('_EnvyRatioAbsoluteReturns')}
              defaultValue={results.envyRatio ?? undefined}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisTypeKey}.sweetReturnAbsolute`}
            label={l('_SweetReturnAbsolute', {
              currencyAndDenomination: currencyDenominationPair(currency, denomination),
              interpolation: { escapeValue: false },
            })}
            placeholder="0"
            {...amountBasicFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisTypeKey}.totalManagementIRR`}
            label={l('_TotalManagementIRR')}
            {...percentageFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_EnvyRatioSweetOnly')}
              value={results.envyRatioSweetOnly}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisTypeKey}.envyRatioSweetOnly`}
              label={l('_EnvyRatioSweetOnly')}
              defaultValue={results.envyRatioSweetOnly ?? undefined}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ReturnAnalysis;
