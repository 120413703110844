import { enumKeyByValue } from '@app/shared/helpers';
import { OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import { getInitialTotalEquityByInstrumentTypeAndOwnership } from '../store/capital-structure-slice-selectors';

export const getOpmInputsFromCapitalStructure = (project: ProjectDto) => {
  const getEvOrEquityValueFromCapitalStructure = () => {
    const isEquitySimulation =
      project.opmInput.opmSimulatedValue ===
      enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.Equity);

    if (isEquitySimulation) {
      if (
        project.sourcesAndUses.updatedSpotEquity &&
        project.sourcesAndUses.updatedSpotEquity > 0
      ) {
        return project.sourcesAndUses.updatedSpotEquity;
      }

      const capitalStructure = Object.values(project.capitalStructures)[0];
      const instrumentsAndInitialValues = Object.entries(
        capitalStructure.instrumentDefinitions
      ).map(([instrumentId, instrument]) => {
        const initialValues =
          instrumentId in capitalStructure.initialValues
            ? capitalStructure.initialValues[instrumentId]
            : null;
        return {
          instrumentId,
          ...instrument,
          initialValues: initialValues,
        };
      });

      return getInitialTotalEquityByInstrumentTypeAndOwnership(instrumentsAndInitialValues) ?? 0;
    }

    if (project.sourcesAndUses.currentValuation && project.sourcesAndUses.currentValuation > 0) {
      return project.sourcesAndUses.currentValuation;
    }

    return project.sourcesAndUses.dayOneTransactionPurchasePrice ?? 0;
  };

  return {
    getEvOrEquityValueFromCapitalStructure,
  };
};
