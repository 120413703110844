import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { RouteConstants } from '../../RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';

export const ValuationInputsNavigation = () => {
  const { l } = useLocale();
  const { isDevFeatureEnabled } = useDevFeatures();
  const isLegacyPwermEnabled = isDevFeatureEnabled(DevFeature.ShowLegacyPwerm);

  const navigationItems: NavigationItem[] = [
    {
      label: l('_CapmAssumptions'),
      route: `../${RouteConstants.CapmAssumptions}`,
    },
    {
      label: l('_MinorityDiscounts'),
      route: `../${RouteConstants.MinorityDiscounts}`,
    },
  ];

  if (isLegacyPwermEnabled) {
    navigationItems.push({
      label: l('_MinorityDiscounts') + ' Legacy',
      route: `../${RouteConstants.MinorityDiscountsLegacy}`,
    });
  }

  return <SecondaryNavigation navigationItems={navigationItems} />;
};
