import {
  getCalculatedPayoutValue,
  getMultipleValue,
  getTotalOfEquityByInstrumentTypeAndOwnershipLegacy,
  selectOrdinaryEquityInstrumentsLegacy,
  selectSlnAndPrefSharesInstrumentsLegacy,
} from '@core/store/project-slice-selectors';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { useAppSelector } from '@core/hooks/redux-hooks';
import {
  getCaseProbabilityWeightedValue,
  getForecastProbabilityWeightedValue,
  getMultipleProbabilityWeightedValue,
} from '@app/shared/helpers/get-weighted-values';
import {
  selectCalculatedPayoutsResults,
  selectCalculatedVariablesResults,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { sumBy } from '@app/shared/helpers';
import { getMultipleWeightedTotalEquityProceeds } from '@app/shared/helpers/predefined-variables/get-multiple-weighted-total-equity-proceeds';

export const useTotalEquityGeneralMetrics = (caseItem?: CaseDto) => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const calculatedPayouts = useAppSelector(selectCalculatedPayoutsResults);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstrumentsLegacy);
  const ordinaryEquityInstruments = useAppSelector(selectOrdinaryEquityInstrumentsLegacy);
  const equityItems = useAppSelector((state) => state.project.projectDraft.equityInstruments);
  const sweetEquityInstruments = ordinaryEquityInstruments.filter(
    (equityInstrument) => equityInstrument.isSweetEquity === true
  );

  const getForecastProbabilityWeightedTotalEquityProceeds = ({
    caseItem,
  }: {
    caseItem: CaseDto;
  }) => {
    return getForecastProbabilityWeightedValue({
      caseItem,
      getValue: ({ forecast }) =>
        getMultipleWeightedTotalEquityProceeds(project, calculatedVariables, caseItem, forecast),
    });
  };
  const getTotalEquityProceedsWeightedValue = getCaseProbabilityWeightedValue({
    project,
    getValue: getForecastProbabilityWeightedTotalEquityProceeds,
  });
  const getForecastProbabilityWeightedExitSlnDebtProceeds = ({
    caseItem,
  }: {
    caseItem: CaseDto;
  }) => {
    return getForecastProbabilityWeightedValue({
      caseItem,
      getValue: getMultipleProbabilityWeightedExitSlnDebtProceeds,
    });
  };
  const getMultipleProbabilityWeightedExitSlnDebtProceeds = ({
    caseItem,
    forecast,
  }: {
    caseItem: CaseDto;
    forecast: ForecastDto;
  }) => {
    return getMultipleProbabilityWeightedValue({
      caseItem,
      forecast,
      getValue: ({ multipleId }) => {
        const multipleValue = getMultipleValue({ project, caseItem, multipleId });
        if (!multipleValue) {
          return 0;
        }
        return sumBy(slnAndPrefSharesInstruments, (instrument) => {
          return Number(
            getCalculatedPayoutValue(
              project,
              calculatedPayouts,
              caseItem.caseId,
              forecast.forecastId,
              multipleValue,
              instrument.instrumentId
            )?.payout
          );
        });
      },
    });
  };
  const getMultipleProbabilityWeightedExitOrdinaryProceeds = ({
    caseItem,
    forecast,
  }: {
    caseItem: CaseDto;
    forecast: ForecastDto;
  }) => {
    return getMultipleProbabilityWeightedValue({
      caseItem,
      forecast,
      getValue: ({ multipleId }) => {
        const multipleValue = getMultipleValue({ project, caseItem, multipleId });
        if (!multipleValue) {
          return 0;
        }
        return sumBy(ordinaryEquityInstruments, (instrument) => {
          return Number(
            getCalculatedPayoutValue(
              project,
              calculatedPayouts,
              caseItem.caseId,
              forecast.forecastId,
              multipleValue,
              instrument.instrumentId
            )?.payout
          );
        });
      },
    });
  };
  const getExitSlnDebtProceedsWeightedValue = getCaseProbabilityWeightedValue({
    project,
    getValue: getForecastProbabilityWeightedExitSlnDebtProceeds,
  });
  const getForecastProbabilityWeightedExitOrdinaryProceeds = ({
    caseItem,
  }: {
    caseItem: CaseDto;
  }) => {
    return getForecastProbabilityWeightedValue({
      caseItem,
      getValue: getMultipleProbabilityWeightedExitOrdinaryProceeds,
    });
  };
  const getExitOrdinaryProceedsWeightedValue = getCaseProbabilityWeightedValue({
    project,
    getValue: getForecastProbabilityWeightedExitOrdinaryProceeds,
  });
  const getMultipleProbabilityWeightedExitSweetOrdinaryProceeds = ({
    caseItem,
    forecast,
  }: {
    caseItem: CaseDto;
    forecast: ForecastDto;
  }) => {
    return getMultipleProbabilityWeightedValue({
      caseItem,
      forecast,
      getValue: ({ multipleId }) => {
        const multipleValue = getMultipleValue({ project, caseItem, multipleId });
        if (!multipleValue) {
          return 0;
        }
        return sumBy(sweetEquityInstruments, (instrument) => {
          return Number(
            getCalculatedPayoutValue(
              project,
              calculatedPayouts,
              caseItem.caseId,
              forecast.forecastId,
              multipleValue,
              instrument.instrumentId
            )?.payout
          );
        });
      },
    });
  };
  const getForecastProbabilityWeightedExitSweetOrdinaryProceeds = ({
    caseItem,
  }: {
    caseItem: CaseDto;
  }) => {
    return getForecastProbabilityWeightedValue({
      caseItem,
      getValue: getMultipleProbabilityWeightedExitSweetOrdinaryProceeds,
    });
  };
  const getExitSweetOrdinaryProceedsWeightedValue = getCaseProbabilityWeightedValue({
    project,
    getValue: getForecastProbabilityWeightedExitSweetOrdinaryProceeds,
  });

  const entryTotalEquityValue = getTotalOfEquityByInstrumentTypeAndOwnershipLegacy(equityItems);

  const equityProceedsWeightedValue = caseItem
    ? getForecastProbabilityWeightedTotalEquityProceeds({ caseItem })
    : getTotalEquityProceedsWeightedValue;

  const exitSlnDebtProceeds = caseItem
    ? getForecastProbabilityWeightedExitSlnDebtProceeds({ caseItem })
    : getExitSlnDebtProceedsWeightedValue;

  const exitOrdinaryEquityProceeds = caseItem
    ? getForecastProbabilityWeightedExitOrdinaryProceeds({ caseItem })
    : getExitOrdinaryProceedsWeightedValue;

  const sweetEquityProceedsWeightedValue = caseItem
    ? getForecastProbabilityWeightedExitSweetOrdinaryProceeds({ caseItem })
    : getExitSweetOrdinaryProceedsWeightedValue;

  const sweetEquityPercentageOfOrdinaryEquityProceeds =
    (100 / exitOrdinaryEquityProceeds) * sweetEquityProceedsWeightedValue;

  return {
    entryTotalEquityValue,
    equityProceedsWeightedValue,
    exitSlnDebtProceeds,
    exitOrdinaryEquityProceeds,
    sweetEquityPercentageOfOrdinaryEquityProceeds,
  };
};
