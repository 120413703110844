import { FC, Fragment } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  decimalCalculatedViewRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { enumKeyByValue, instrumentByTypeOrTranchesOrDefaultSortFn } from '@app/shared/helpers';
import { InstrumentType, WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import { PayoutLogicDto } from '@app/shared/models/contracts/project-dto';
import { Alignment } from '@app/shared/enums/alignment.enum';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { WaterfallPartialTableProps } from '../../pwerm-summary-waterfall/waterfall-table/waterfall-shared';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import {
  getWaterfallCaseInstrumentTrancheValue,
  getWaterfallCaseInstrumentValue,
  getWaterfallCaseYearInstrumentFirstTrancheValue,
  getWaterfallCaseYearInstrumentTrancheValue,
  getWaterfallCaseYearInstrumentValue,
  getWaterfallCaseYearValue,
  getWaterfallInstrumentTrancheValue,
  getWaterfallInstrumentValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import TableSectionValuesPerCase from '../../components/TableSectionValuesPerCase';
import {
  InstrumentDefinitionWithId,
  selectValuedInstruments,
} from '@app/core/store/capital-structure-slice-selectors';

export const Pwerm2WaterfallValuationPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const valuedInstruments = useAppSelector(selectValuedInstruments);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();
  const interpolationOption = {
    escapeValue: false,
  };

  const ordinaryEquityKey = enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);
  const [isValuationOpen, setIsValuationOpen] = useToggler();

  const renderValuationSection = (
    instrument: InstrumentDefinitionWithId,
    tranche: PayoutLogicDto | undefined,
    trancheIndex: number | undefined
  ) => (
    <>
      <TableSectionValuesPerForecast
        title={l('_WeightedProceeds')}
        additionalTitleClassNames="table-primary__cell--strong"
        renderSpacer={false}
        highlight
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            className="table-primary__cell--strong"
            {...amountRules}
            value={
              tranche
                ? getWaterfallCaseYearInstrumentTrancheValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    tranche.id,
                    (tranche) => tranche.weightedTotalDistributions
                  )
                : getWaterfallCaseYearInstrumentValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (instrument) => instrument.weightedTotalDistributions
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_PostIPODLOM')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              Number(
                getWaterfallCaseYearValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  (forecast) => forecast.weightedPostIpoSaleRestrictionDlomPc
                )
              )
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_NetExitWeightedProceeds')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={
              tranche
                ? getWaterfallCaseYearInstrumentTrancheValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    tranche.id,
                    (tranche) => tranche.netDistributions
                  )
                : getWaterfallCaseYearInstrumentValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (instrument) => instrument.netDistributions
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={instrument.type === ordinaryEquityKey ? l('_CostOfEquity') : l('_DiscountRate')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={
              instrument.type === ordinaryEquityKey && tranche
                ? getCalculatedProjectValueInPercent(
                    getWaterfallCaseYearInstrumentTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      tranche.id,
                      (tranche) => tranche.cumulativeCostOfEquityPc
                    )
                  )
                : // cumulativeCostOfEquityPc property is not present in CaseYearInstrument,
                  // and so it needs to be obtained from the generated tranche in the results
                  getCalculatedProjectValueInPercent(
                    getWaterfallCaseYearInstrumentFirstTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (tranche) => tranche.cumulativeCostOfEquityPc
                    )
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_DiscountFactor')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...decimalCalculatedViewRules}
            value={
              tranche
                ? getWaterfallCaseYearInstrumentTrancheValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    tranche.id,
                    (tranche) => tranche.cumulativeDiscountFactor
                  )
                : // cumulativeDiscountFactor property is not present in CaseYearInstrument,
                  // and so it needs to be obtained from the generated tranche in the results
                  getWaterfallCaseYearInstrumentFirstTrancheValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (tranche) => tranche.cumulativeDiscountFactor
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={
          tranche
            ? l('_PvOfInstrumentTrancheN', {
                instrument: instrument.instrumentNarrative,
                n: trancheIndex,
                interpolation: interpolationOption,
              })
            : l('_PvOfInstrumentName', {
                instrument: instrument.instrumentNarrative,
                interpolation: interpolationOption,
              })
        }
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={
              tranche
                ? getWaterfallCaseYearInstrumentTrancheValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    tranche.id,
                    (tranche) => tranche.fairValue
                  )
                : getWaterfallCaseYearInstrumentValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (instrument) => instrument.fairValue
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_MinorityDiscounts')}
        renderSpacer={false}
        additionalTitleClassNames="table-primary__cell--strong"
        renderCell={() => <CellValue value={' '} />}
      />
      {instrument.type === ordinaryEquityKey && (
        <TableSectionValuesPerForecast
          title={l('_DLOC')}
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getWaterfallCaseYearInstrumentValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  instrument.instrumentId,
                  (instrument) => instrument.dlocPc
                )
              )}
            />
          )}
        />
      )}
      <TableSectionValuesPerForecast
        title={l('_DLOM')}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={
              tranche
                ? getCalculatedProjectValueInPercent(
                    getWaterfallCaseYearInstrumentTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      tranche.id,
                      (tranche) => tranche.dlomPc
                    )
                  )
                : getCalculatedProjectValueInPercent(
                    getWaterfallCaseYearInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (instrument) => instrument.dlomPc
                    )
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_PostDiscountPV')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={
              tranche
                ? getWaterfallCaseYearInstrumentTrancheValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    tranche.id,
                    (tranche) => tranche.discountedFairValue
                  )
                : getWaterfallCaseYearInstrumentValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (instrument) => instrument.discountedFairValue
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerCase
        title={
          tranche
            ? l('_MvOfInstrumentTrancheN', {
                instrument: instrument.instrumentNarrative,
                n: trancheIndex,
                interpolation: interpolationOption,
              })
            : l('_MvOfInstrumentName', {
                instrument: instrument.instrumentNarrative,
                interpolation: interpolationOption,
              })
        }
        renderSpacer={false}
        renderCell={({ caseItem }) => {
          return (
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={
                tranche
                  ? getWaterfallCaseInstrumentTrancheValue(
                      results,
                      caseItem.caseId,
                      instrument.instrumentId,
                      tranche.id,
                      (tranche) => tranche.weightedDiscountedFairValue
                    )
                  : getWaterfallCaseInstrumentValue(
                      results,
                      caseItem.caseId,
                      instrument.instrumentId,
                      (instrument) => instrument.weightedDiscountedFairValue
                    )
              }
            />
          );
        }}
        additionalTitleClassNames="table-primary__cell--strong"
        additionalCellClassNames="table-primary__cell--strong"
      />
      <tr>
        <th className="table-primary__cell--vertical-separator table-primary__cell--background-secondary table-primary__cell--strong">
          {l('_WeightedAverageMarketValue')}
        </th>
        <td className="table-primary__cell--vertical-separator table-primary__cell--background-secondary" />
        <td colSpan={columnsCount - 2} className="table-primary__cell--background-secondary">
          <CellValue
            {...amountRules}
            alignment={Alignment.Center}
            strong
            value={
              tranche
                ? getWaterfallInstrumentTrancheValue(
                    results,
                    instrument.instrumentId,
                    tranche.id,
                    (tranche) => tranche.weightedDiscountedFairValue
                  )
                : getWaterfallInstrumentValue(
                    results,
                    instrument.instrumentId,
                    (instrument) => instrument.weightedDiscountedFairValue
                  )
            }
          />
        </td>
      </tr>
    </>
  );

  const renderValuationInstrumentTotalSection = (instrument: InstrumentDefinitionWithId) => (
    <>
      <tr>
        <td colSpan={columnsCount} className="table-primary__cell--section-separator" />
      </tr>
      <tr>
        <th className="table-primary__cell--header-tertiary">
          {l('_TotalValueOfName', {
            name: instrument.instrumentNarrative,
            interpolation: interpolationOption,
          })}
        </th>
        <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
      </tr>
      <TableSectionValuesPerForecast
        title={l('_TotalProceedsAcrossTranches')}
        additionalTitleClassNames="table-primary__cell--strong"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            className="table-primary__cell--strong"
            {...amountRules}
            value={getWaterfallCaseYearInstrumentValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              instrument.instrumentId,
              (instrument) => instrument.weightedTotalDistributions
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_PostIPODLOM')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              getWaterfallCaseYearInstrumentValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                instrument.instrumentId,
                (instrument) => instrument.weightedPostIpoSaleRestrictionDlom
              )
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_NetExitWeightedProceeds')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={getWaterfallCaseYearInstrumentValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              instrument.instrumentId,
              (instrument) => instrument.netDistributions
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_ImpliedDiscountRateAcrossTranches')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              getWaterfallCaseYearInstrumentValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                instrument.instrumentId,
                (instrument) => instrument.impliedDiscountRate
              )
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_PVOf', {
          Instrument: instrument.instrumentNarrative,
          interpolation: interpolationOption,
        })}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={getWaterfallCaseYearInstrumentValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              instrument.instrumentId,
              (instrument) => instrument.fairValue
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_ImpliedMinorityDiscounts')}
        renderSpacer={false}
        additionalTitleClassNames="table-primary__cell--strong"
        renderCell={() => <CellValue value={' '} />}
      />
      <TableSectionValuesPerForecast
        title={l('_DLOC')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              getWaterfallCaseYearInstrumentValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                instrument.instrumentId,
                (instrument) => instrument.dlocPc
              )
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_DLOM')}
        renderCell={({ forecast, caseItem }) => {
          return (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getWaterfallCaseYearInstrumentValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  instrument.instrumentId,
                  (instrument) => instrument.dlomPc
                )
              )}
            />
          );
        }}
      />
      <TableSectionValuesPerCase
        renderSpacer={false}
        title={l('_MVOf', {
          Instrument: instrument.instrumentNarrative,
          interpolation: interpolationOption,
        })}
        renderCell={({ caseItem }) => {
          return (
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={getWaterfallCaseInstrumentValue(
                results,
                caseItem.caseId,
                instrument.instrumentId,
                (instrument) => instrument.weightedDiscountedFairValue
              )}
            />
          );
        }}
        additionalTitleClassNames="table-primary__cell--strong"
        additionalCellClassNames="table-primary__cell--strong"
      />
      <tr>
        <th className="table-primary__cell--vertical-separator table-primary__cell--background-secondary table-primary__cell--strong">
          {l('_WeightedAverageMarketValue')}
        </th>
        <td className="table-primary__cell--vertical-separator table-primary__cell--background-secondary" />
        <td colSpan={columnsCount - 2} className="table-primary__cell--background-secondary">
          <CellValue
            {...amountRules}
            strong
            alignment={Alignment.Center}
            value={getWaterfallInstrumentValue(
              results,
              instrument.instrumentId,
              (instrument) => instrument.weightedDiscountedFairValue
            )}
          />
        </td>
      </tr>
    </>
  );

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_Valuation')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.Valuation)}
          isOpen={isValuationOpen}
          setIsOpen={setIsValuationOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isValuationOpen,
        })}>
        {valuedInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn).map((instrument) => (
          <Fragment key={`valuation-item-instrument-${instrument.instrumentId}`}>
            <tr>
              <th className="table-primary__cell--header-quaternary">
                {instrument.instrumentNarrative}
              </th>
              <th className="table-primary__cell--header-quaternary" colSpan={columnsCount - 1} />
            </tr>

            {(instrument.type !== ordinaryEquityKey ||
              (instrument.type === ordinaryEquityKey && !instrument.payoutLogic?.length)) && (
              <>{renderValuationSection(instrument, undefined, undefined)}</>
            )}

            {Boolean(instrument.payoutLogic?.length) && (
              <>
                {instrument.payoutLogic!.map((tranche, index) => {
                  return (
                    <Fragment key={`valuation-item-tranche-${tranche.id}`}>
                      {index !== 0 && <TableSpacerRow />}
                      <tr>
                        <th className="table-primary__cell--header-tertiary">
                          {l('_TrancheN', { n: index + 1 })}
                        </th>
                        <th
                          className="table-primary__cell--header-tertiary"
                          colSpan={columnsCount - 1}
                        />
                      </tr>
                      {renderValuationSection(instrument, tranche, index + 1)}
                    </Fragment>
                  );
                })}
                {Boolean(instrument.payoutLogic?.length && instrument.payoutLogic?.length > 1) && (
                  <>{renderValuationInstrumentTotalSection(instrument)}</>
                )}
              </>
            )}
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--background-tertiary table-primary__cell--strong">
                {l('_ValuePerShare')}
              </th>
              <td className="table-primary__cell--vertical-separator table-primary__cell--background-tertiary" />
              <td
                colSpan={columnsCount - 2}
                className="table-primary__cell--background-tertiary table-primary__cell--strong">
                <CellValue
                  {...decimalCalculatedViewRules}
                  alignment={Alignment.Center}
                  value={getWaterfallInstrumentValue(
                    results,
                    instrument.instrumentId,
                    (instrument) => instrument.weightedDiscountedFairValuePerShare
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={columnsCount} className="table-primary__cell--section-separator" />
            </tr>
          </Fragment>
        ))}
      </tbody>
    </>
  );
};
