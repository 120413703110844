import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { useAppSelector } from '@core/hooks/redux-hooks';
import {
  amountRules,
  calculatedMultiplierRules,
  enteredPercentageViewRules,
  shortDateViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { mapAsRecord } from '@app/shared/helpers';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import classNames from 'classnames';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import styles from '../../widgets/key-metrics/key-metrics.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ToggleWidget } from '@app/shared/enums/expand-collapse';
import { FC, useState } from 'react';
import MinusIcon from '@app/shared/icons/MinusIcon';
import SvgPlus from '@app/shared/icons/Plus';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { useLocale } from '@app/core/hooks/useLocale';
import { translationKeys } from '@locale/setupI18n';
import { useLocalStateCollapse } from '@app/core/hooks/useLocalStateCollapse';
import { setPwermDashboard2KeyMetricsAndCrossChecksTableIsExpanded } from '@app/core/store/ui-values-slice';

const allTableTitlesList: translationKeys[] = [
  '_Case',
  '_CaseWeighting',
  '_WeightedExitDate',
  '_ExitEV',
  '_TotalEquityReturns',
  '_SponsorMoM',
  '_SponsorIRR',
  '_TotalEquityDCFCrossCheck',
];

const EXPANDED_TABLE_COL_COUNT = 8;
const COLLAPSED_TABLE_COL_COUNT = 5;
const EXPANDER_COLUMN_NUMBER_FROM_ROW_END = 3;

export const Pwerm2KeyMetrics: FC = () => {
  const { l } = useLocale();
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.keyMetrics
  );
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const storedExpandedState = useAppSelector(
    (state) =>
      state.uiValues.userSelections.pwerm2Dashboard.keyMetricsAndCrossChecksTable.isExpanded
  );

  const defaultExpandedState = storedExpandedState ?? false;

  const [isExpanded, setIsExpanded] = useState(defaultExpandedState);

  useLocalStateCollapse(isExpanded, setPwermDashboard2KeyMetricsAndCrossChecksTableIsExpanded);

  const { cases } = sortedProjectByCase.pwermInput;

  const columnCount = isExpanded ? EXPANDED_TABLE_COL_COUNT : COLLAPSED_TABLE_COL_COUNT;

  const getCaseData = (caseItem: CaseDto) => {
    const caseWeighting = results.cases[caseItem.caseId].probability;

    const forecastWeightedExitDate = results.cases[caseItem.caseId].exitDate;

    const forecastWeightedExitEV = results.cases[caseItem.caseId].exitEv;

    const forecastWeightedTotalEquityProceeds = results.cases[caseItem.caseId].totalEquityReturns;

    const forecastWeightedInstitutionalMoM = results.cases[caseItem.caseId].institutionalMom;

    const forecastWeightedInstitutionalIRR = results.cases[caseItem.caseId].institutionalIrr;

    const forecastWeightedMarketValueOfTotalEquity =
      results.cases[caseItem.caseId].totalEquityCrosscheck;

    return [
      {
        value: getCalculatedProjectValueInPercent(caseWeighting),
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: forecastWeightedExitDate,
        formattingRules: shortDateViewRules,
      },
      {
        value: forecastWeightedExitEV,
        formattingRules: amountRules,
      },
      {
        value: forecastWeightedTotalEquityProceeds,
        formattingRules: amountRules,
      },
      {
        value: forecastWeightedInstitutionalMoM,
        formattingRules: calculatedMultiplierRules,
      },
      {
        value: getCalculatedProjectValueInPercent(forecastWeightedInstitutionalIRR),
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: forecastWeightedMarketValueOfTotalEquity,
        formattingRules: amountRules,
      },
    ];
  };

  const dataByCaseId = mapAsRecord(
    cases,
    (item) => getCaseData(item),
    (item) => item.caseId
  );

  const weightedAverageData = [
    {
      value: getCalculatedProjectValueInPercent(results.weightedProbability),
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: results.weightedExitDate,
      formattingRules: shortDateViewRules,
    },
    {
      value: results.weightedExitEv,
      formattingRules: amountRules,
    },
    {
      value: results.weightedTotalEquityReturns,
      formattingRules: amountRules,
    },
    {
      value: results.weightedInstitutionalMom,
      formattingRules: calculatedMultiplierRules,
    },
    {
      value: getCalculatedProjectValueInPercent(results.weightedInstitutionalIrr),
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: results.weightedTotalEquityCrosscheck,
      formattingRules: amountRules,
    },
  ];

  const weightedAverageRowData = isExpanded
    ? weightedAverageData
    : [
        ...weightedAverageData.slice(0, 1),
        ...weightedAverageData.slice(-EXPANDER_COLUMN_NUMBER_FROM_ROW_END),
      ];

  const varianceToCurrentMarketValueInPercent = getCalculatedProjectValueInPercent(
    results.discountedFairValueVariance
  );

  const tableColumnTitles = isExpanded
    ? allTableTitlesList
    : [
        ...allTableTitlesList.slice(0, 2),
        ...allTableTitlesList.slice(-EXPANDER_COLUMN_NUMBER_FROM_ROW_END),
      ];

  return (
    <DashboardWidgetContainer
      isExpanded={isExpanded}
      title={l('_KeyMetricsAndCrossChecks')}
      actions={
        <div className="dashboard-widget-controls">
          <Button
            data-testid="expansion-toggle"
            appearance={ButtonAppearance.DEFAULT_SECONDARY}
            onClick={() => setIsExpanded(!isExpanded)}
            className="dashboard-widget-controls__button"
            ignoreReadOnly>
            {isExpanded ? ToggleWidget.Collapse : ToggleWidget.Expand}
          </Button>
        </div>
      }>
      <table className="table-primary table-primary--fixed table-primary--secondary-theme">
        <thead>
          <tr>
            {tableColumnTitles.map((title, index) => (
              <th
                key={index}
                className={classNames('table-primary__cell--header-quinary', {
                  'table-primary__cell--right': index !== 0,
                  [styles['cell--expander']]:
                    index === tableColumnTitles.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END,
                })}>
                {index === tableColumnTitles.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className={styles['expander-button']}>
                    {isExpanded ? <MinusIcon /> : <SvgPlus />}
                  </button>
                )}
                {l(title)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getCasesWithNonZeroProbability(cases).map((caseItem, index) => {
            const caseData = isExpanded
              ? dataByCaseId[caseItem.caseId]
              : [
                  ...dataByCaseId[caseItem.caseId].slice(0, 1),
                  ...dataByCaseId[caseItem.caseId].slice(-EXPANDER_COLUMN_NUMBER_FROM_ROW_END),
                ];

            return (
              <tr key={index} data-testid={`key-metrics-row-${index}`}>
                <td>
                  <span className="heading-2 heading-2--alternate">{caseItem.narrative}</span>
                </td>

                {caseData.map((item, valueIndex) => (
                  <td
                    key={valueIndex}
                    className={classNames({
                      [styles['cell--expander']]:
                        valueIndex === caseData.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END,
                    })}>
                    <CellValue
                      value={item.value}
                      theme={CellValueTheme.Secondary}
                      {...item.formattingRules}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr data-testid="key-metrics-summary-row">
            <th className="table-primary__cell--header-quinary">{l('_CaseWeightedAverage')}</th>
            {weightedAverageRowData.map((item, index) => (
              <td
                key={index}
                className={classNames(
                  {
                    [styles['cell--expander']]:
                      index === weightedAverageRowData.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END,
                  },
                  styles['cell--weighted-value']
                )}>
                <CellValue
                  value={item.value}
                  theme={CellValueTheme.Secondary}
                  {...item.formattingRules}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th className="table-primary__cell--header-quinary">
              {l('_PcVarianceToTotalEquityValue')}
            </th>
            <th colSpan={columnCount - 4} />
            <td
              colSpan={EXPANDER_COLUMN_NUMBER_FROM_ROW_END}
              data-testid="variance-to-current-market-value-in-percent"
              className={classNames(styles['cell--expander'], styles['cell--weighted-value'])}>
              <CellValue
                value={varianceToCurrentMarketValueInPercent}
                theme={CellValueTheme.Secondary}
                {...enteredPercentageViewRules}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </DashboardWidgetContainer>
  );
};
