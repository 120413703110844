import { FC } from 'react';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  decimalCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@core/hooks/redux-hooks';
import {
  getTotalOfEquityByInstrumentTypeAndOwnershipLegacy,
  selectValuedInstrumentsLegacy,
} from '@core/store/project-slice-selectors';
import {
  instrumentByTypeOrTranchesOrDefaultSortFnLegacy,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { Alignment } from '@app/shared/enums/alignment.enum';

export const ValuationEstimatesPwerm: FC = (): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const valuedInstruments = useAppSelector(selectValuedInstrumentsLegacy);
  const sortedValuedInstruments = valuedInstruments.sort(
    instrumentByTypeOrTranchesOrDefaultSortFnLegacy
  );

  const columnsCount = 5;

  const getRowData = (instrument: EquityInstrumentDto) => {
    const aggMarketValue = getCalculatedProjectValue(
      'CaseWeightedAverageValuationMarketValuePerInstrument',
      {
        instrumentId: instrument.instrumentId,
      }
    );

    const aggCost = getTotalOfEquityByInstrumentTypeAndOwnershipLegacy([instrument]);

    const numberOfShares = getTotalOfEquityByInstrumentTypeAndOwnershipLegacy(
      [instrument],
      undefined,
      undefined,
      'numberOfShares'
    );

    const marketValuePerShare = getCalculatedProjectValue('ValuationValuePerShare', {
      instrumentId: instrument.instrumentId,
    });

    const subscriptionPricePerShare =
      aggCost !== null && numberOfShares !== null ? aggCost / numberOfShares : NaN;

    return [
      { value: aggMarketValue, formattingRules: amountRules },
      { value: aggCost, formattingRules: amountRules },
      { value: marketValuePerShare, formattingRules: decimalCalculatedViewRules },
      { value: subscriptionPricePerShare, formattingRules: decimalCalculatedViewRules },
    ];
  };

  return (
    <DashboardWidgetContainer title="Valuation Estimates">
      <table className="table-primary table-primary--fixed table-primary--secondary-theme">
        <thead>
          <tr>
            <th className="table-primary__cell--header-quinary">Instrument</th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Aggregate Market Value
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Aggregate Cost
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Market Value per Share
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Subscription Price per Share
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedValuedInstruments.length === 0 && (
            <tr>
              <td>{EmptyValues.EnDash}</td>
              {[...Array(columnsCount - 1)].map((_, index) => (
                <td key={index}>
                  <CellValue
                    value={undefined}
                    theme={CellValueTheme.Secondary}
                    alignment={Alignment.Right}
                  />
                </td>
              ))}
            </tr>
          )}
          {sortedValuedInstruments.map((instrument, index) => {
            const rowData = getRowData(instrument);

            return (
              <tr key={index} data-testid={`valuation-estimates-row-${index}`}>
                <td>
                  <span className="heading-2 heading-2--alternate">
                    {instrument.instrumentNarrative}
                  </span>
                </td>
                {rowData.map((item, valueIndex) => (
                  <td key={valueIndex}>
                    <CellValue
                      value={item.value}
                      theme={CellValueTheme.Secondary}
                      {...item.formattingRules}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </DashboardWidgetContainer>
  );
};
