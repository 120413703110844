import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { multipleFieldFormattingProps } from '@app/shared/components/grid-controls/grid-text-field/grid-field-formating-config';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import {
  ForecastDto,
  MultipleDto,
  MultipleProbabilityDto,
} from '@app/shared/models/contracts/project-dto';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@app/shared/components/button/Button';
import PlusSvg from '@app/shared/icons/Plus';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import MinusRoundedSvg from '@app/shared/icons/MinusRounded';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  calculatedMultiplierRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getMultipleIndex,
  getTotalMultipleProbabilityPerForecast,
} from '@app/core/store/project-slice-selectors';
import {
  percentageValidator,
  positiveValueValidator,
  getRequiredValidator,
  uniqueMultipleValidator,
} from '@core/validations/hook-forms/validators';
import {
  multipleValueFormatterProps,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { MAX_MULTIPLE_COUNT } from '@app/shared/constants/deal-thesis';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { MultipleType } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';
import styles from '../../../deal-thesis/deal-thesis-page.module.scss';
import SvgFilledRightArrow from '@app/shared/icons/FilledRightArrow';
import { DealThesisFormModel } from '../../DealThesisPage2';
import { StepSelector } from '../../../deal-thesis/deal-thesis-case-table/exit-multiples/StepSelector';
import { getDealThesisInputCaseForecastDate } from '@app/core/store/pwerm2-calculation-slice-selectors';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';
import DealThesisEmptyHistoricDataColumns from '../../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';

interface DealThesisCaseExitMultiplesSectionProps {
  onRowsChange: () => void;
}

const DealThesisCaseExitMultiplesSection2: FC<DealThesisCaseExitMultiplesSectionProps> = ({
  onRowsChange,
}): JSX.Element => {
  const { caseId } = useParams();
  const { control, getValues, trigger, setValue } = useFormContext();
  const formMethods = useFormContext<DealThesisFormModel>();
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const numberOfInputForecastYears = caseData?.forecasts.length;
  const numberOfInputHistoricForecastYears = project.pwermInput.historicForecasts.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicDataForecastYearsResults = Object.values(
    results.cases[caseId!].historicDates.historicForecastYears ?? {}
  ).slice(0, numberOfInputHistoricForecastYears);
  const historicDataEventsYearsResults = Object.keys(
    results.cases[caseId!].historicDates.historicEventsYears ?? {}
  ).reverse();
  const maxHistoricalDataLength = Math.max(
    historicDataForecastYearsResults.length,
    historicDataEventsYearsResults.length
  );
  const numberOfResultsForecastYears = Object.keys(results.cases[caseId!]?.forecastDates).length;
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseForecasts = caseData?.forecasts.slice(0, numberOfResultsForecastYears);

  const columnCount =
    (numberOfInputForecastYears ? numberOfInputForecastYears : 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
    maxHistoricalDataLength;
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );

  const shouldAutoPopulateFromCell = (index: number): boolean | undefined =>
    index !== numberOfResultsForecastYears - 1;

  const populate = (cellIndex: number, multipleItem: MultipleDto) => {
    const { ...updatedDealThesisInput } = formMethods.getValues();
    updatedDealThesisInput.forecasts.map((forecast, index: number) => {
      index > cellIndex &&
        formMethods.setValue(
          `forecasts.${index}.multipleProbabilities.${getMultipleIndex(
            forecast,
            multipleItem
          )}.probability`,
          updatedDealThesisInput.forecasts[cellIndex].multipleProbabilities[
            getMultipleIndex(updatedDealThesisInput.forecasts[cellIndex], multipleItem)
          ].probability
        );
    });

    onRowsChange();
  };

  const createCurrentMultipleText = () => {
    const initialEV =
      project.sourcesAndUses.currentValuation ??
      project.sourcesAndUses.dayOneTransactionPurchasePrice;

    const valuationValueDriver = getPwermValuationValueDriver(project);

    const exitValueDriverMultiple =
      initialEV && valuationValueDriver.value
        ? numberValueFormatter({
            ...multipleValueFormatterProps,
            value: initialEV / valuationValueDriver.value,
          })
        : EmptyValues.EnDash;

    const currentMultipleText = `${project.pwermInput.valuationDateNarrative} ${
      valuationValueDriver.narrative
    } ${l('_Multiple')}: ${exitValueDriverMultiple}`;

    return currentMultipleText;
  };

  const { replace: replaceForecastsField } = useFieldArray({
    name: 'forecasts',
    control: control,
  });

  const { append: appendMultiplesField, remove: removeMultiplesField } = useFieldArray({
    name: 'multiples',
    control: control,
  });

  const addExitMultiple = () => (): void => {
    const newMultipleId = uuidv4();
    appendMultiplesField({ multipleId: newMultipleId, multiple: 0 } as MultipleDto, {
      shouldFocus: false,
    });
    replaceForecastsField([
      ...(caseData?.forecasts.map((forecast: ForecastDto) => {
        return {
          ...forecast,
          multipleProbabilities: [
            ...forecast.multipleProbabilities,
            { multipleId: newMultipleId, probability: 0 } as MultipleProbabilityDto,
          ],
        };
      }) ?? []),
    ]);

    onRowsChange();
  };

  const removeExitMultiple = (multipleToDelete: MultipleDto, multipleIndex: number) => (): void => {
    removeMultiplesField(multipleIndex);
    replaceForecastsField([
      ...(caseData?.forecasts.map((forecast: ForecastDto) => {
        return {
          ...forecast,
          multipleProbabilities: [
            ...forecast.multipleProbabilities.filter(
              (multipleProbability) =>
                multipleProbability.multipleId !== multipleToDelete.multipleId
            ),
          ],
        };
      }) ?? []),
    ]);

    onRowsChange();
    trigger(); // triggers form validation in order to remove duplicated error message on Multiple field.
  };

  const updateExitMultiple = (newStep: number) => {
    if (!caseData?.multiples || caseData?.multiples.length < 1) {
      return;
    }

    const firstMultiple = caseData.multiples[0].multiple ?? 0;

    for (let i = 0; i < caseData.multiples.length; i++) {
      const newValue = firstMultiple + i * newStep;
      setValue(`multiples.${i}.multiple`, newValue);
    }
    onRowsChange();
    trigger(); // triggers form validation in order to remove duplicated error message on Multiple field.
  };

  // required to update the form values when the caseData changes, which ensure the form values is up to date after a project is saved.
  useEffect(() => {
    setValue(`multiples`, caseData?.multiples);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseData]);

  return (
    <>
      <tbody>
        {caseId && (
          <>
            <tr>
              <th colSpan={columnCount} className="table-primary__cell--header">
                {l('_ExitMultipleSpreadOfExitMultipleType', {
                  exitMultipleType: MultipleType[project.details.dealThesisExitMultipleType],
                })}
              </th>
            </tr>
            <tr>
              <th className="table-primary__cell--header-tertiary" colSpan={columnCount}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <p data-testid="currentMultipleText">{createCurrentMultipleText()}</p>
                  <StepSelector autoFillClick={updateExitMultiple} />
                </div>
              </th>
            </tr>
            {caseData?.multiples.map((multipleItem, multipleIndex) => (
              <tr key={multipleItem.multipleId}>
                <td className="table-primary__cell--vertical-separator">
                  <GridTextField
                    name={`multiples.${multipleIndex}.multiple`}
                    rules={{
                      ...getRequiredValidator(),
                      validate: {
                        isUnique: uniqueMultipleValidator(getValues().multiples || [], multipleItem)
                          .validate,
                        isPositive: positiveValueValidator(false).validate,
                      },
                    }}
                    {...multipleFieldFormattingProps}
                  />
                </td>
                <td />
                <DealThesisEmptyHistoricDataColumns isHistoricEventsSection={false} />
                {showHistoricDataColumns &&
                  historicDataForecastYearsResults.map((_, index) => <td key={index} />)}
                {caseForecasts?.map((forecast, forecastIndex) => {
                  return (
                    <td
                      className={`table-primary__cell ${styles['hoverable-cell']}`}
                      key={forecast.forecastId + 'input'}>
                      <GridTextField
                        className={[
                          shouldAutoPopulateFromCell(forecastIndex)
                            ? styles[`padding-right-hover`]
                            : '',
                        ]}
                        name={`forecasts.${forecastIndex}.multipleProbabilities.${getMultipleIndex(
                          forecast,
                          multipleItem
                        )}.probability`}
                        data-testid={`forecasts.${forecastIndex}.multipleProbabilities.${getMultipleIndex(
                          forecast,
                          multipleItem
                        )}.probability`}
                        tooltipVariation={GridFieldTooltipVariation.FitContainer}
                        rules={{ ...getRequiredValidator(), ...percentageValidator }}
                        {...percentageFieldFormattingProps}
                      />
                      {shouldAutoPopulateFromCell(forecastIndex) && (
                        <Button
                          startIcon={<SvgFilledRightArrow />}
                          className={styles['deal-thesis-populate-button']}
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.FLUID}
                          // stop the onBlur event occurring so we can control when the data is submitted
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => populate(forecastIndex, multipleItem)}
                          data-testid={`populate-forecasts.${forecastIndex}.multipleProbabilities.${getMultipleIndex(
                            forecast,
                            multipleItem
                          )}.probability`}
                          autoIconSize
                        />
                      )}
                    </td>
                  );
                })}
                <td className="table-primary__cell--actions">
                  {multipleIndex !== 0 && (
                    <div className="hide-on-screenshot">
                      <Button
                        appearance={ButtonAppearance.CLEAN}
                        size={ButtonSize.TABLE_ACTION}
                        title={l('_DeleteItem')}
                        onClick={removeExitMultiple(multipleItem, multipleIndex)}>
                        <MinusRoundedSvg />
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td className="table-primary__cell--vertical-separator">
                <div className="hide-on-screenshot">
                  <Button
                    appearance={ButtonAppearance.DEFAULT_TERTIARY}
                    size={ButtonSize.TABLE_ACTION}
                    startIcon={<PlusSvg />}
                    disabled={caseData?.multiples.length === MAX_MULTIPLE_COUNT}
                    onClick={addExitMultiple()}>
                    {l('_AddExitMultipleTitle')}
                  </Button>
                </div>
              </td>
              <td colSpan={columnCount - 1} />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                {l('_TotalPercentage')}
              </th>
              <DealThesisEmptyHistoricDataColumns
                isHistoricEventsSection={false}
                isPrimaryCell
                isStartSeparator
              />
              {showHistoricDataColumns &&
                historicDataForecastYearsResults.map((_, index) => (
                  <td className="table-primary__cell--section-start-separator" key={index} />
                ))}
              <td className="table-primary__cell--section-start-separator" />
              {caseForecasts?.map((forecast) => {
                const totalMultiplePercentage = getTotalMultipleProbabilityPerForecast(
                  project,
                  caseId,
                  forecast.forecastId
                );
                const totalMultiplePercentageError = `All multiple probabilities add up to ${
                  !Number.isNaN(totalMultiplePercentage)
                    ? numberValueFormatter({
                        value: totalMultiplePercentage!,
                        ...percentageValueFormatterProps,
                      })
                    : EmptyValues.EnDash
                }, this must be 100%.`;

                return (
                  <td
                    className="table-primary__cell table-primary__cell--section-start-separator"
                    key={forecast.forecastId + 'total'}>
                    <CellValue
                      errorMessage={
                        totalMultiplePercentage !== 100 ? totalMultiplePercentageError : undefined
                      }
                      tooltipVariation={GridFieldTooltipVariation.FitContainer}
                      {...enteredPercentageViewRules}
                      value={totalMultiplePercentage}
                    />
                  </td>
                );
              })}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                <strong>{l('_WeightedAverageMultiple')}</strong>
              </th>
              <DealThesisEmptyHistoricDataColumns
                isHistoricEventsSection={false}
                isStartSeparator
              />
              {showHistoricDataColumns &&
                historicDataForecastYearsResults.map((_, index) => (
                  <td className="table-primary__cell--section-start-separator" key={index} />
                ))}
              <td className="table-primary__cell--section-start-separator" />
              {caseForecasts?.map((forecast) => (
                <td
                  key={forecast.forecastId + 'weighted-average'}
                  className="table-primary__cell--section-start-separator">
                  <CellValue
                    {...calculatedMultiplierRules}
                    strong
                    value={getDealThesisInputCaseForecastDate(
                      results,
                      caseId!,
                      forecast.forecastYear,
                      (forecast) => forecast?.weightedAverageMultiple
                    )}
                  />
                </td>
              ))}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <DealThesisSpacerRow2 />
          </>
        )}
      </tbody>
    </>
  );
};

export default DealThesisCaseExitMultiplesSection2;
