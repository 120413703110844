import {
  EquityInstrumentDto,
  OwnershipDto,
  ProjectDto,
} from '@app/shared/models/contracts/project-dto';
import {
  InstrumentType,
  OwnerType,
  OpmInputDataSource,
  InstrumentRank,
  EquityRefinancingMovementType,
  OwnershipSplitType,
  CouponType,
} from '@app/shared/models/contracts/enums/shared-enums';
import {
  cloneDeep,
  enumKeyByValue,
  generateUniqueName,
  instrumentTypeToName,
} from '@app/shared/helpers';
import { EquityItem } from '@app/modules/projects/inputs/capital-structure/CapitalStructureItemFormErf';
import { InstrumentDefinitionAndInitialValues } from '@app/core/store/capital-structure-slice-selectors';

export const getEmptyOwnershipStructureLegacy = (): OwnershipDto[] => {
  return (Object.keys(OwnerType) as Array<keyof typeof OwnerType>).map(
    (key: keyof typeof OwnerType) => ({
      owner: key,
      amount: null,
      numberOfShares: null,
    })
  );
};

export const getNewInstrumentLegacy = (
  project: ProjectDto,
  instrumentType: InstrumentType,
  instrumentId: string
): EquityInstrumentDto => {
  const startingRank =
    instrumentType === InstrumentType.OrdinaryEquity
      ? 0
      : Math.max(...project.equityInstruments.map((i) => i.rank), -1) + 1;

  const instrumentTypeEnumKey = enumKeyByValue(InstrumentType, instrumentType);
  const ordinaryEquityEnumKey = enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);

  const instrumentNewName = generateUniqueName(
    instrumentTypeToName(instrumentType),
    new Set(
      project.equityInstruments
        .filter((e) => e.type === instrumentTypeEnumKey)
        .map((e) => e.instrumentNarrative)
    )
  );

  return {
    instrumentNarrative: instrumentNewName,
    instrumentId: instrumentId,
    ownership: getEmptyOwnershipStructureLegacy(),
    tranches: instrumentTypeEnumKey === ordinaryEquityEnumKey ? [] : undefined,
    type: instrumentTypeEnumKey,
    rank: startingRank,
    shouldBeValued: false,
    isSweetEquity: instrumentTypeEnumKey === ordinaryEquityEnumKey ? false : null,
    couponStartDate: instrumentTypeEnumKey === ordinaryEquityEnumKey ? null : project.valuationDate,
    couponValue: instrumentTypeEnumKey === ordinaryEquityEnumKey ? null : 0,
  };
};

export const getNewInstrument = (
  project: ProjectDto,
  eventInstruments: EquityItem[],
  instrumentType: InstrumentType,
  instrumentId: string
): InstrumentDefinitionAndInitialValues => {
  const startingRank =
    instrumentType === InstrumentType.OrdinaryEquity
      ? 0
      : Math.max(
          ...eventInstruments
            .filter((i) => i.rank !== InstrumentRank.OrdinaryEquity)
            .map((i) => i.rank),
          -1
        ) + 1;

  const instrumentTypeEnumKey = enumKeyByValue(InstrumentType, instrumentType);
  const ordinaryEquityEnumKey = enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);

  const instrumentNewName = generateUniqueName(
    instrumentTypeToName(instrumentType),
    new Set(
      eventInstruments
        .filter((e) => e.type === instrumentTypeEnumKey)
        .map((e) => e.instrumentNarrative)
    )
  );

  return {
    instrumentNarrative: instrumentNewName,
    instrumentId: instrumentId,
    initialValues: {
      ownership: {},
      coupon:
        instrumentTypeEnumKey === ordinaryEquityEnumKey
          ? undefined
          : {
              value: 0,
              date: project.valuationDate,
              type: enumKeyByValue(CouponType, CouponType.CompoundInterest),
              cashPayProportion: 0,
            },
    },
    payoutLogic: instrumentTypeEnumKey === ordinaryEquityEnumKey ? [] : null,
    type: instrumentTypeEnumKey,
    rank: startingRank,
    shouldBeValued: false,
    isSweetEquity: instrumentTypeEnumKey === ordinaryEquityEnumKey ? false : null,
  };
};

export const getProjectDraftWithInstrumentAddedToEvent = ({
  draft,
  eventId,
  newInstrument,
}: {
  draft: ProjectDto;
  eventId: string;
  newInstrument: InstrumentDefinitionAndInitialValues;
}): ProjectDto => {
  const capitalStructures = draft.capitalStructures ? { ...draft.capitalStructures } : {};
  const newInstrumentId = newInstrument.instrumentId;
  const capitalStructureId = Object.keys(capitalStructures)[0];
  const instrumentDefinitions = capitalStructures[capitalStructureId].instrumentDefinitions
    ? { ...capitalStructures[capitalStructureId]?.instrumentDefinitions }
    : {};
  instrumentDefinitions[newInstrumentId] = {
    instrumentNarrative: newInstrument.instrumentNarrative,
    type: newInstrument.type,
    rank: newInstrument.rank,
    payoutLogic: [],
    shouldBeValued: newInstrument.shouldBeValued,
    isSweetEquity: newInstrument.isSweetEquity,
  };
  const events = capitalStructures[capitalStructureId].events
    ? { ...capitalStructures[capitalStructureId]?.events }
    : {};
  events[eventId] = {
    ...events[eventId],
    movements: {
      ...events[eventId].movements,
      [newInstrumentId]: {
        movementType: EquityRefinancingMovementType.NewInstrument,
        ownerMovements: {},
        ownershipSplit: OwnershipSplitType.ProRata,
        coupon:
          newInstrument.type !== enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
            ? {
                value: newInstrument.initialValues?.coupon?.value ?? null,
                date: newInstrument.initialValues?.coupon?.date ?? draft.investmentDate,
                type: enumKeyByValue(CouponType, CouponType.CompoundInterest),
                cashPayProportion: 0,
              }
            : undefined,
      },
    },
  };

  return {
    ...draft,
    opmInput: {
      ...draft.opmInput,
      perYearInputs: [
        ...(draft.opmInput.perYearInputs?.map((perYearInput) => {
          return {
            ...perYearInput,
            marketValueDloms: [
              ...perYearInput.marketValueDloms.filter(
                (dlomItem) => !(dlomItem.instrumentId === newInstrument.instrumentId)
              ),
              {
                instrumentId: newInstrument.instrumentId,
                instrumentDlom: null,
                instrumentDlomSource: OpmInputDataSource.FromPWERM,
                trancheDloms: [],
              },
            ],
          };
        }) ?? []),
      ],
    },
    pwermInput: {
      ...draft.pwermInput,
      cases: [
        ...draft.pwermInput.cases.map((caseItem) => ({
          ...caseItem,
          forecasts: caseItem.forecasts.map((forecast) => {
            return {
              ...forecast,
              dlom: [
                ...forecast.dlom.filter(
                  (dlomItem) =>
                    !(
                      dlomItem.instrumentId === newInstrument.instrumentId &&
                      dlomItem.trancheId === null
                    )
                ),
                {
                  instrumentId: newInstrument.instrumentId,
                  value: null,
                  trancheId: null,
                },
              ],
              allInDiscountRates: [
                ...forecast.allInDiscountRates,
                ...(newInstrument.type !==
                enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
                  ? [{ instrumentId: newInstrument.instrumentId, value: null }]
                  : []),
              ],
            };
          }),
        })),
      ],
    },
    capitalStructures: {
      ...draft.capitalStructures,
      [capitalStructureId]: {
        ...draft.capitalStructures[capitalStructureId],
        instrumentDefinitions: instrumentDefinitions,
        events: events,
      },
    },
  };
};

export const getProjectDraftWithAddedInstrumentLegacy = ({
  draft,
  newInstrument,
}: {
  draft: ProjectDto;
  newInstrument: EquityInstrumentDto;
}): ProjectDto => {
  return {
    ...draft,
    equityInstruments: [...draft.equityInstruments, { ...newInstrument }],
    opmInput: {
      ...draft.opmInput,
      perYearInputs: [
        ...(draft.opmInput.perYearInputs?.map((perYearInput) => {
          return {
            ...perYearInput,
            marketValueDloms: [
              ...perYearInput.marketValueDloms.filter(
                (dlomItem) => !(dlomItem.instrumentId === newInstrument.instrumentId)
              ),
              {
                instrumentId: newInstrument.instrumentId,
                instrumentDlom: null,
                instrumentDlomSource: OpmInputDataSource.FromPWERM,
                trancheDloms: [],
              },
            ],
          };
        }) ?? []),
      ],
    },
    pwermInput: {
      ...draft.pwermInput,
      cases: [
        ...draft.pwermInput.cases.map((caseItem) => ({
          ...caseItem,
          forecasts: caseItem.forecasts.map((forecast) => {
            return {
              ...forecast,
              dlom: [
                ...forecast.dlom.filter(
                  (dlomItem) =>
                    !(
                      dlomItem.instrumentId === newInstrument.instrumentId &&
                      dlomItem.trancheId === null
                    )
                ),
                {
                  instrumentId: newInstrument.instrumentId,
                  value: null,
                  trancheId: null,
                },
              ],
              allInDiscountRates: [
                ...forecast.allInDiscountRates,
                ...(newInstrument.type !==
                enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
                  ? [{ instrumentId: newInstrument.instrumentId, value: null }]
                  : []),
              ],
            };
          }),
        })),
      ],
    },
  };
};

export const getProjectDraftWithAddedInstrument = ({
  draft,
  newInstrument,
}: {
  draft: ProjectDto;
  newInstrument: InstrumentDefinitionAndInitialValues;
}): ProjectDto => {
  const capitalStructures = draft.capitalStructures ? { ...draft.capitalStructures } : {};
  const newInstrumentId = newInstrument.instrumentId;
  const capitalStructureId = Object.keys(capitalStructures)[0];
  const instrumentDefinitions = capitalStructures[capitalStructureId].instrumentDefinitions
    ? { ...capitalStructures[capitalStructureId]?.instrumentDefinitions }
    : {};
  instrumentDefinitions[newInstrumentId] = {
    instrumentNarrative: newInstrument.instrumentNarrative,
    type: newInstrument.type,
    rank: newInstrument.rank,
    payoutLogic: [],
    shouldBeValued: newInstrument.shouldBeValued,
    isSweetEquity: newInstrument.isSweetEquity,
  };
  const initialValues = capitalStructures[capitalStructureId].initialValues
    ? { ...capitalStructures[capitalStructureId]?.initialValues }
    : {};
  initialValues[newInstrumentId] = {
    coupon:
      newInstrument.type !== enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
        ? {
            value: newInstrument.initialValues?.coupon?.value ?? 0,
            date: newInstrument.initialValues?.coupon?.date ?? draft.investmentDate,
            type: enumKeyByValue(CouponType, CouponType.CompoundInterest),
            cashPayProportion: 0,
          }
        : undefined,
    ownership: {},
  };

  return {
    ...draft,
    capitalStructures: {
      ...draft.capitalStructures,
      [capitalStructureId]: {
        ...draft.capitalStructures[capitalStructureId],
        instrumentDefinitions: instrumentDefinitions,
        initialValues: initialValues,
      },
    },
    opmInput: {
      ...draft.opmInput,
      perYearInputs: [
        ...(draft.opmInput.perYearInputs?.map((perYearInput) => {
          return {
            ...perYearInput,
            marketValueDloms: [
              ...perYearInput.marketValueDloms.filter(
                (dlomItem) => !(dlomItem.instrumentId === newInstrument.instrumentId)
              ),
              {
                instrumentId: newInstrument.instrumentId,
                instrumentDlom: null,
                instrumentDlomSource: OpmInputDataSource.FromPWERM,
                trancheDloms: [],
              },
            ],
          };
        }) ?? []),
      ],
    },
    pwermInput: {
      ...draft.pwermInput,
      cases: [
        ...draft.pwermInput.cases.map((caseItem) => ({
          ...caseItem,
          forecasts: caseItem.forecasts.map((forecast) => {
            return {
              ...forecast,
              dlom: [
                ...forecast.dlom.filter(
                  (dlomItem) =>
                    !(
                      dlomItem.instrumentId === newInstrument.instrumentId &&
                      dlomItem.trancheId === null
                    )
                ),
                {
                  instrumentId: newInstrument.instrumentId,
                  value: null,
                  trancheId: null,
                },
              ],
              allInDiscountRates: [
                ...forecast.allInDiscountRates,
                ...(newInstrument.type !==
                enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
                  ? [{ instrumentId: newInstrument.instrumentId, value: null }]
                  : []),
              ],
            };
          }),
        })),
      ],
    },
  };
};

export const getProjectDraftWithRemovedInstrument = ({
  draft,
  instrumentId,
}: {
  draft: ProjectDto;
  instrumentId: string;
}): ProjectDto => {
  const capitalStructures = draft.capitalStructures ? { ...draft.capitalStructures } : {};
  const capitalStructureId = Object.keys(capitalStructures)[0];
  const instrumentDefinitions = cloneDeep(
    capitalStructures[capitalStructureId].instrumentDefinitions
  );
  const events = cloneDeep(capitalStructures[capitalStructureId].events);
  const initialValues = cloneDeep(capitalStructures[capitalStructureId].initialValues);

  for (const eventId in events) {
    delete events[eventId].movements?.[instrumentId];
  }

  delete instrumentDefinitions[instrumentId];
  delete initialValues[instrumentId];

  const isInstrumentDefinitionsEmpty = Object.keys(instrumentDefinitions).length === 0;

  return {
    ...draft,
    capitalStructures: {
      ...draft.capitalStructures,
      [capitalStructureId]: {
        ...draft.capitalStructures[capitalStructureId],
        instrumentDefinitions: instrumentDefinitions,
        initialValues: initialValues,
        events: isInstrumentDefinitionsEmpty ? {} : events,
        eventSets: isInstrumentDefinitionsEmpty
          ? {}
          : draft.capitalStructures[capitalStructureId].eventSets,
      },
    },
    pwermInput: {
      ...draft.pwermInput,
      cases: [
        ...draft.pwermInput.cases.map((caseItem) => {
          return {
            ...caseItem,
            forecasts: caseItem.forecasts.map((forecastItem) => ({
              ...forecastItem,
              dlom: forecastItem.dlom.filter((dlomItem) => dlomItem.instrumentId !== instrumentId),
              allInDiscountRates: forecastItem.allInDiscountRates.filter(
                (item) => item.instrumentId !== instrumentId
              ),
            })),
          };
        }),
      ],
    },
  };
};

export const getProjectDraftWithRemovedInstrumentLegacy = ({
  draft,
  instrumentId,
}: {
  draft: ProjectDto;
  instrumentId: string;
}): ProjectDto => {
  return {
    ...draft,
    equityInstruments: [
      ...draft.equityInstruments.filter((equityItem) => equityItem.instrumentId !== instrumentId),
    ],
    pwermInput: {
      ...draft.pwermInput,
      cases: [
        ...draft.pwermInput.cases.map((caseItem) => {
          return {
            ...caseItem,
            forecasts: caseItem.forecasts.map((forecastItem) => ({
              ...forecastItem,
              dlom: forecastItem.dlom.filter((dlomItem) => dlomItem.instrumentId !== instrumentId),
              allInDiscountRates: forecastItem.allInDiscountRates.filter(
                (item) => item.instrumentId !== instrumentId
              ),
            })),
          };
        }),
      ],
    },
  };
};

export const getProjectDraftWithAddedDlom = ({
  draft,
  instrumentId,
  trancheId,
}: {
  draft: ProjectDto;
  instrumentId: string;
  trancheId?: string;
}): ProjectDto => {
  if (!trancheId) {
    return draft;
  }
  return {
    ...draft,
    pwermInput: {
      ...draft.pwermInput,
      cases: [
        ...draft.pwermInput.cases.map((_case) => ({
          ..._case,
          forecasts: _case.forecasts.map((forecast) => {
            return {
              ...forecast,
              dlom: [
                ...forecast.dlom.filter(
                  (dlomItem) =>
                    !(dlomItem.instrumentId === instrumentId && dlomItem.trancheId === null)
                ),
                {
                  instrumentId: instrumentId,
                  value: null,
                  trancheId: trancheId,
                },
              ],
            };
          }),
        })),
      ],
    },
  };
};

// TODO: Remove this function when we switch over to the CapitalStructures model everywhere
export const getProjectDraftWithRemovedDlomLegacy = ({
  draft,
  instrumentId,
  trancheId,
}: {
  draft: ProjectDto;
  instrumentId: string;
  trancheId?: string;
}): ProjectDto => {
  if (!trancheId) {
    return draft;
  }
  const updatedCases = draft.pwermInput.cases.map((caseItem) => {
    return {
      ...caseItem,
      forecasts: caseItem.forecasts.map((forecastItem) => {
        return {
          ...forecastItem,
          dlom: [
            ...forecastItem.dlom.filter(
              (dlomItem) =>
                !(dlomItem.instrumentId === instrumentId && dlomItem.trancheId === trancheId)
            ),
            ...(draft.equityInstruments.find(
              (instrument) => instrument.instrumentId === instrumentId
            )?.tranches?.length === 0
              ? [
                  {
                    instrumentId: instrumentId,
                    value: null,
                    trancheId: null,
                  },
                ]
              : []),
          ],
        };
      }),
    };
  });

  return {
    ...draft,
    pwermInput: {
      ...draft.pwermInput,
      cases: updatedCases,
    },
  };
};

export const getProjectDraftWithRemovedDlom = ({
  draft,
  instrumentId,
  trancheId,
}: {
  draft: ProjectDto;
  instrumentId: string;
  trancheId?: string;
}): ProjectDto => {
  if (!trancheId) {
    return draft;
  }

  const updatedCases = draft.pwermInput.cases.map((caseItem) => {
    return {
      ...caseItem,
      forecasts: caseItem.forecasts.map((forecastItem) => {
        return {
          ...forecastItem,
          dlom: [
            ...forecastItem.dlom.filter(
              (dlomItem) =>
                !(dlomItem.instrumentId === instrumentId && dlomItem.trancheId === trancheId)
            ),
            ...(Object.values(draft.capitalStructures)[0].instrumentDefinitions[instrumentId]
              ?.payoutLogic?.length === 0
              ? [
                  {
                    instrumentId: instrumentId,
                    value: null,
                    trancheId: null,
                  },
                ]
              : []),
          ],
        };
      }),
    };
  });

  const updatedInstrumentDefinitions = {
    ...draft.capitalStructures,
    [Object.keys(draft.capitalStructures)[0]]: {
      ...draft.capitalStructures[Object.keys(draft.capitalStructures)[0]],
      instrumentDefinitions: {
        ...draft.capitalStructures[Object.keys(draft.capitalStructures)[0]].instrumentDefinitions,
        [instrumentId]: {
          ...draft.capitalStructures[Object.keys(draft.capitalStructures)[0]].instrumentDefinitions[
            instrumentId
          ],
          tranches: draft.capitalStructures[
            Object.keys(draft.capitalStructures)[0]
          ].instrumentDefinitions[instrumentId].payoutLogic?.filter(
            (tranche) => tranche.id !== trancheId
          ),
        },
      },
    },
  };

  return {
    ...draft,
    pwermInput: {
      ...draft.pwermInput,
      cases: updatedCases,
    },
    capitalStructures: updatedInstrumentDefinitions,
  };
};
