import React, { FC } from 'react';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import classNames from 'classnames';
import styles from '@app/modules/projects/dashboard/widgets/total-equity/total-equity-chart.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';

interface TotalEquityChartModalBlockProps {
  instrument: Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockDto;
  selectedBlock: Nullable<string>;
  setSelectedBlock: (block: string | null) => void;
}

const TotalEquityChartModalBlock: FC<TotalEquityChartModalBlockProps> = ({
  instrument,
  selectedBlock,
  setSelectedBlock,
}): JSX.Element => {
  const noBlocksSelected = !selectedBlock;
  const thisBlockSelected = selectedBlock && selectedBlock === instrument.id;
  return (
    <div
      key={'instrument-tooltip-wrapper' + instrument.id}
      style={{ width: instrument.proportionOfRank * 100 + '%' }}>
      <Button
        data-testid="instrument-block"
        appearance={ButtonAppearance.CLEAN}
        ignoreReadOnly
        className={classNames(styles['graph__item'], {
          [styles['modal-instrument']]: noBlocksSelected || thisBlockSelected,
          [styles['modal-instrument--faded']]: !noBlocksSelected && !thisBlockSelected,
          [styles['graph__item--shareholder-loan']]:
            instrument.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
          [styles['graph__item--sweet']]: instrument.isSweet === true,
          [styles['graph__item--preferred-shares']]:
            instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
        })}
        onClick={(event) => {
          event.stopPropagation();
          setSelectedBlock(instrument.id);
        }}
      />
    </div>
  );
};

export default React.memo(TotalEquityChartModalBlock);
