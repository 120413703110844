import { FC, Fragment, useEffect } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { selectOrdinaryEquityInstrumentsLegacy } from '@core/store/project-slice-selectors';
import {
  enumKeyByValue,
  instrumentByTypeOrTranchesOrDefaultSortFnLegacy,
  sumBy,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import {
  ConjunctionType,
  Else,
  Operator,
  OwnerType,
  TargetMetric,
} from '@app/shared/models/contracts/enums/shared-enums';
import {
  ConditionalStatementDto,
  EquityInstrumentDto,
  TrancheDto,
} from '@app/shared/models/contracts/project-dto';
import {
  getCalculatedProjectResults,
  getCalculatedProjectValueInPercent,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import styles from './participation-table.module.scss';
import { EmptyValues } from '@app/shared/constants/empty-values';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import {
  amountValueFormatterProps,
  calculatedMultipleValueFormatterProps,
  dateFormatter,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { getEquityInstrumentOwnershipsLegacy } from '@app/shared/helpers/equity-instruments/get-equity-instrument-ownerships';
import { GridSelectField } from '@app/shared/components/grid-controls/grid-select-field/GridSelectField';
import { ParticipationTableRow } from '@app/modules/projects/inputs/capital-structure/tabs/participation-table/ParticipationTableRow';
import {
  setCapitalStructureParticipationTableSelectedYear,
  setCapitalStructureSelectedTab,
} from '@app/core/store/ui-values-slice';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';
import { useLocale } from '@app/core/hooks/useLocale';

const PARENT_CLASSNAME = 'participation-table';

interface ParticipationTableProps {
  shouldRenderStandaloneTable?: boolean;
  injectedForecastId?: string;
}

export const ParticipationTable: FC<ParticipationTableProps> = ({
  shouldRenderStandaloneTable = true,
  injectedForecastId,
}) => {
  useSetSelectedTab(RouteConstants.ParticipationTableLegacy, setCapitalStructureSelectedTab);

  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const projectDraft = useAppSelector((state) => state.project.projectDraft);
  const calculatedProjectResults = useAppSelector(getCalculatedProjectResults);
  const ordinaryEquityInstruments = useAppSelector(selectOrdinaryEquityInstrumentsLegacy);
  const caseItemWithMaxForecasts = getCaseWithMostForecasts(projectDraft.pwermInput.cases, true);
  const storedYear = useAppSelector(
    (state) => state.uiValues.userSelections.capitalStructure.participationTable.selectedYear
  );

  const forecastOptions = caseItemWithMaxForecasts.forecasts.map((forecast) => ({
    value: forecast.forecastId,
    viewValue: dateFormatter(forecast.forecastYear, { year: 'numeric' }),
  }));

  const isStoredYearValid = forecastOptions.some((item) => item.value === storedYear);

  const defaultYear = isStoredYearValid
    ? storedYear
    : forecastOptions[forecastOptions.length - 1].value;

  const formMethods = useForm<{ selectedForecastId: string }>({
    ...formConfigBase,
    defaultValues: {
      selectedForecastId: defaultYear,
    },
  });

  const { selectedForecastId } = formMethods.watch();

  useEffect(() => {
    if (selectedForecastId !== storedYear) {
      dispatch(setCapitalStructureParticipationTableSelectedYear(selectedForecastId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForecastId]);

  const activeForecast = injectedForecastId ?? selectedForecastId;

  const isBasedOnOrdinaryEquity = ordinaryEquityInstruments.some((instrument) =>
    instrument.tranches?.some((t) =>
      t.statements[0].conditions.some(
        (c) => enumKeyByValue(TargetMetric, TargetMetric.TotalOrdinaryEquity) === c.targetMetric
      )
    )
  );

  const hurdlesForSelectedYear = isBasedOnOrdinaryEquity
    ? calculatedProjectResults.filter(
        (result) => result.name === 'OrdinaryEquityHurdle' && result.forecastId === activeForecast
      )
    : calculatedProjectResults.filter(
        (result) =>
          result.name === 'InstitutionHurdleActive' && result.forecastId === activeForecast
      );

  const allSortedTranches = ordinaryEquityInstruments
    .reduce(
      (acc, instrument) => {
        return [
          ...acc,
          ...(instrument.tranches?.map(({ id, ...rest }) => ({
            trancheId: id,
            instrumentId: instrument.instrumentId,
            instrumentNarrative: instrument.instrumentNarrative,
            ordinaryEquityHurdleValue: hurdlesForSelectedYear.find(
              (item) => item.instrumentId === instrument.instrumentId && item.trancheId === id
            )?.value,
            ...rest,
          })) ?? []),
        ];
      },
      [] as {
        trancheId: string;
        instrumentId: string;
        instrumentNarrative: string;
        ordinaryEquityHurdleValue: number | undefined;
        order: number;
        statements: ConditionalStatementDto[];
        else: keyof typeof Else;
      }[]
    )
    .sort((a, b) =>
      a.ordinaryEquityHurdleValue !== undefined && b.ordinaryEquityHurdleValue !== undefined
        ? a.ordinaryEquityHurdleValue - b.ordinaryEquityHurdleValue
        : 0
    );

  const columnsCount = 2 + allSortedTranches.length;

  const getConditionString = (statement: ConditionalStatementDto) => {
    const getValueFormat = (targetMetric: TargetMetric) => {
      switch (targetMetric) {
        case TargetMetric.InstitutionalIRR:
          return percentageValueFormatterProps;
        case TargetMetric.InstitutionalMoM:
          return calculatedMultipleValueFormatterProps;
        default:
          return amountValueFormatterProps;
      }
    };

    return statement.conditions.map((condition, conditionIndex) => {
      const formattedValue =
        condition.targetMetricTestValue !== null
          ? numberValueFormatter({
              value: condition.targetMetricTestValue,
              ...getValueFormat(TargetMetric[condition.targetMetric]),
            })
          : EmptyValues.EnDash;

      return (
        <Fragment key={conditionIndex}>
          {`${TargetMetric[condition.targetMetric]} ${
            Operator[condition.operator]
          } ${formattedValue}`}
          {statement.conditions.length - 1 !== conditionIndex && statement.conjunction && (
            <span className={styles['conjunction']}>{` ${
              ConjunctionType[statement.conjunction]
            } `}</span>
          )}
        </Fragment>
      );
    });
  };

  const getPreHurdleResultKey = (owner: OwnerType) => {
    switch (owner) {
      case OwnerType.Institution:
        return 'ParticipationTableBeforeTrancheSponsorPercent';
      case OwnerType.Management:
        return 'ParticipationTableBeforeTrancheManagementPercent';
      case OwnerType.CoInvestor:
        return 'ParticipationTableBeforeTrancheCoInvestorPercent';
      case OwnerType.Other:
        return 'ParticipationTableBeforeTrancheOtherPercent';
    }
  };
  const getParticipationResultKey = (owner: OwnerType) => {
    switch (owner) {
      case OwnerType.Institution:
        return 'ParticipationTableSponsorPercent';
      case OwnerType.Management:
        return 'ParticipationTableManagementPercent';
      case OwnerType.CoInvestor:
        return 'ParticipationTableCoInvestorPercent';
      case OwnerType.Other:
        return 'ParticipationTableOtherPercent';
    }
  };

  const preHurdleParticipationTotal = ordinaryEquityInstruments.length
    ? sumBy(ordinaryEquityInstruments, (instrument) =>
        getCalculatedProjectValueInPercent(
          getCalculatedProjectValue('ParticipationTableBeforeTrancheTotalInstrumentPercent', {
            forecastId: activeForecast,
            instrumentId: instrument.instrumentId,
          })
        )
      )
    : undefined;

  const getParticipationTotal = (trancheId: string) =>
    sumBy(ordinaryEquityInstruments, (instrument) =>
      getCalculatedProjectValueInPercent(
        getCalculatedProjectValue('ParticipationTableTotalInstrumentPercent', {
          forecastId: activeForecast,
          instrumentId: instrument.instrumentId,
          trancheId: trancheId,
        })
      )
    );

  const renderOwnerships = (instrument: EquityInstrumentDto, tranche?: TrancheDto) =>
    getEquityInstrumentOwnershipsLegacy(instrument, (x) => x.numberOfShares).map((owner) => (
      <ParticipationTableRow
        key={instrument.instrumentId + owner.owner}
        allSortedTranches={allSortedTranches}
        renderLabelCellValue={() => <>{OwnerType[owner.owner]}</>}
        renderPreHurdleCellValue={() => (
          <CellValue
            value={getCalculatedProjectValueInPercent(
              getCalculatedProjectValue(getPreHurdleResultKey(OwnerType[owner.owner]), {
                forecastId: activeForecast,
                instrumentId: instrument.instrumentId,
                trancheId2: tranche?.id,
              })
            )}
            {...enteredPercentageViewRules}
          />
        )}
        renderCellValue={({ item }) => (
          <CellValue
            value={getCalculatedProjectValueInPercent(
              getCalculatedProjectValue(getParticipationResultKey(OwnerType[owner.owner]), {
                forecastId: activeForecast,
                instrumentId: instrument.instrumentId,
                trancheId: item.trancheId,
                trancheId2: tranche?.id,
              })
            )}
            {...enteredPercentageViewRules}
          />
        )}
        labelCellClassnames={classNames({
          ['table-primary__cell--indentation']: Boolean(tranche),
        })}
      />
    ));

  const renderTableContent = () => {
    return (
      <>
        <colgroup>
          <col className={styles[`${PARENT_CLASSNAME}__labels-col`]} />
          <col className={styles[`${PARENT_CLASSNAME}__pre-hurdle-col`]} />
          {allSortedTranches.map((tranche) => (
            <col className={styles[`${PARENT_CLASSNAME}__tranches-col`]} key={tranche.trancheId} />
          ))}
        </colgroup>
        <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
          <tr>
            <th
              className={classNames(
                'table-primary__cell--header-tertiary table-primary__cell--vertical-separator',
                styles[`${PARENT_CLASSNAME}__header-cell`]
              )}>
              <strong>Forecast year</strong>
            </th>
            <th
              className={classNames(
                'table-primary__cell--header-tertiary table-primary__cell--vertical-separator table-primary__cell--right',
                styles[`${PARENT_CLASSNAME}__header-cell`]
              )}>
              <strong>Pre-hurdle participation</strong>
            </th>
            {allSortedTranches.map((item, index) => (
              <Fragment key={item.trancheId}>
                <th
                  className={classNames(
                    'table-primary__cell--header-tertiary table-primary__cell--right table-primary__cell--vertical-separator table-primary__cell--wrap',
                    styles[`${PARENT_CLASSNAME}__header-cell`]
                  )}>
                  <div className={styles[`${PARENT_CLASSNAME}__header-pre-title`]}>
                    Hurdle {index + 1}
                  </div>
                  <strong className={styles[`${PARENT_CLASSNAME}__header-title`]}>
                    {item.instrumentNarrative} Tranche {item.order + 1}
                  </strong>
                </th>
              </Fragment>
            ))}
          </tr>
          <tr>
            <th className="table-primary__cell--vertical-separator">
              {shouldRenderStandaloneTable ? (
                <GridSelectField
                  aria-label={l('_SelectorAriaLabel', { label: l('_Year') })}
                  name="selectedForecastId"
                  options={forecastOptions}
                  disabled={forecastOptions.length === 0}
                  ignoreReadOnly
                />
              ) : (
                <>{forecastOptions?.find((item) => item.value === activeForecast)?.viewValue}</>
              )}
            </th>
            <th className="table-primary__cell--vertical-separator" />
            {allSortedTranches.map((item) => (
              <td
                key={item.trancheId}
                className="table-primary__cell--vertical-separator table-primary__cell--right">
                {item.statements.map(getConditionString)}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {ordinaryEquityInstruments
            .sort(instrumentByTypeOrTranchesOrDefaultSortFnLegacy)
            .map((instrument) => (
              <Fragment key={instrument.instrumentId}>
                <tr>
                  <th colSpan={columnsCount} className="table-primary__cell--header">
                    {instrument.instrumentNarrative}
                  </th>
                </tr>
                {instrument.tranches && instrument.tranches?.length > 0
                  ? instrument.tranches?.map((tranche, index) => (
                      <Fragment key={tranche.id}>
                        <ParticipationTableRow
                          allSortedTranches={allSortedTranches}
                          renderLabelCellValue={() => <>Tranche {tranche.order + 1}</>}
                          renderPreHurdleCellValue={() =>
                            getEquityInstrumentOwnershipsLegacy(instrument, (x) => x.numberOfShares)
                              .length > 1 ? (
                              <CellValue
                                strong
                                value={getCalculatedProjectValueInPercent(
                                  getCalculatedProjectValue(
                                    'ParticipationTableBeforeTrancheTotalTranchePercent',
                                    {
                                      forecastId: activeForecast,
                                      instrumentId: instrument.instrumentId,
                                      trancheId2: tranche.id,
                                    }
                                  )
                                )}
                                {...enteredPercentageViewRules}
                              />
                            ) : (
                              <></>
                            )
                          }
                          renderCellValue={({ item }) =>
                            getEquityInstrumentOwnershipsLegacy(instrument, (x) => x.numberOfShares)
                              .length > 1 ? (
                              <CellValue
                                strong
                                value={getCalculatedProjectValueInPercent(
                                  getCalculatedProjectValue(
                                    'ParticipationTableTotalTranchePercent',
                                    {
                                      forecastId: activeForecast,
                                      instrumentId: instrument.instrumentId,
                                      trancheId: item.trancheId,
                                      trancheId2: tranche.id,
                                    }
                                  )
                                )}
                                {...enteredPercentageViewRules}
                              />
                            ) : (
                              <></>
                            )
                          }
                          labelCellClassnames="table-primary__cell--strong"
                          cellClassnames={classNames({
                            ['table-primary__cell--section-start-separator']: index > 0,
                          })}
                        />
                        {renderOwnerships(instrument, tranche)}
                      </Fragment>
                    ))
                  : renderOwnerships(instrument)}
                {((instrument.tranches && instrument.tranches.length > 0) ||
                  getEquityInstrumentOwnershipsLegacy(instrument, (x) => x.numberOfShares).length >
                    1) && (
                  <ParticipationTableRow
                    allSortedTranches={allSortedTranches}
                    renderLabelCellValue={() => <>Total</>}
                    renderPreHurdleCellValue={() => (
                      <CellValue
                        strong
                        value={getCalculatedProjectValueInPercent(
                          getCalculatedProjectValue(
                            'ParticipationTableBeforeTrancheTotalInstrumentPercent',
                            {
                              forecastId: activeForecast,
                              instrumentId: instrument.instrumentId,
                            }
                          )
                        )}
                        {...enteredPercentageViewRules}
                      />
                    )}
                    renderCellValue={({ item }) => (
                      <CellValue
                        strong
                        value={getCalculatedProjectValueInPercent(
                          getCalculatedProjectValue('ParticipationTableTotalInstrumentPercent', {
                            forecastId: activeForecast,
                            instrumentId: instrument.instrumentId,
                            trancheId: item.trancheId,
                          })
                        )}
                        {...enteredPercentageViewRules}
                      />
                    )}
                    labelCellClassnames="table-primary__cell--strong"
                    cellClassnames="table-primary__cell--section-start-separator"
                    rowClassnames="table-primary__row--header"
                  />
                )}
                <tr>
                  {[...Array(columnsCount)].map((_, i) => (
                    <th
                      key={i}
                      className="table-primary__cell--vertical-separator table-primary__cell--section-separator"
                    />
                  ))}
                </tr>
              </Fragment>
            ))}
          <ParticipationTableRow
            allSortedTranches={allSortedTranches}
            renderLabelCellValue={() => <>Total participation</>}
            renderPreHurdleCellValue={() => (
              <CellValue
                value={preHurdleParticipationTotal}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            renderCellValue={({ item }) => (
              <CellValue
                value={getParticipationTotal(item.trancheId)}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            rowClassnames="table-primary__row--header"
            labelCellClassnames="table-primary__cell--strong"
            cellClassnames="table-primary__cell--section-start-separator"
          />
          <ParticipationTableRow
            allSortedTranches={allSortedTranches}
            renderLabelCellValue={() => <>Sponsor gross up %</>}
            renderPreHurdleCellValue={() => (
              <CellValue
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('GrossUpPercentBeforeTranche', {
                    forecastId: activeForecast,
                  })
                )}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            renderCellValue={({ item }) => (
              <CellValue
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('GrossUpPercentOnTranche', {
                    forecastId: activeForecast,
                    trancheId: item.trancheId,
                  })
                )}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            labelCellClassnames="table-primary__cell--strong"
            cellClassnames="table-primary__cell--background-tertiary"
          />
          {!shouldRenderStandaloneTable && (
            <>
              <tr>
                <td />
                <td />
                {allSortedTranches.map((tranche) => (
                  <td key={tranche.trancheId} />
                ))}
              </tr>
              <tr>
                <td />
                <td />
                {allSortedTranches.map((tranche) => (
                  <td key={tranche.trancheId} />
                ))}
              </tr>
              <tr>
                <td />
                <td />
                {allSortedTranches.map((tranche) => (
                  <td key={tranche.trancheId} />
                ))}
              </tr>
            </>
          )}
        </tbody>
      </>
    );
  };

  return shouldRenderStandaloneTable ? (
    <>
      <div className="main-container-padding-remove">
        <FormProvider {...formMethods}>
          <form>
            <table className="table-primary table-primary--framed table-primary--zebra">
              {renderTableContent()}
            </table>
          </form>
        </FormProvider>
      </div>
    </>
  ) : (
    <>{renderTableContent()}</>
  );
};
