import { FC } from 'react';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { shortDateViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getRequiredValidator } from '@core/validations/hook-forms/validators';
import { GridFieldVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useParams } from 'react-router-dom';
import { useLocale } from '@app/core/hooks/useLocale';
import { cloneDeep } from '@app/shared/helpers';
import classNames from 'classnames';
import DealThesisEmptyHistoricDataColumns from '../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';

interface DealThesisSectionHeaderProps {
  isValuationDateHeader: boolean;
}

const DealThesisSectionHeader: FC<DealThesisSectionHeaderProps> = ({
  isValuationDateHeader,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();
  const valuationDate = project.valuationDate;
  const { caseId } = useParams();
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseResults = results.cases[caseId!];
  const numberOfInputForecastYears = caseData?.forecasts.length ?? 0;
  const numberOfInputHistoricForecastYears = Object.keys(
    project.pwermInput.historicForecasts
  ).length;
  // results data sliced such that a decrease in forecast years is immediately rendered without depending on results
  const caseForecasts = Object.entries(caseResults.forecastDates)
    .map(([forecastId, value]) => ({
      forecastId,
      ...value,
    }))
    .slice(0, numberOfInputForecastYears);
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );
  const historicDataForecastYearsInputs = cloneDeep(project.pwermInput.historicForecasts).reverse();
  const historicDataForecastYearsResults = Object.keys(
    caseResults.historicDates.historicForecastYears ?? {}
  )
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicEventsYearsResults = Object.keys(
    results.cases[caseId!].historicDates.historicEventsYears ?? {}
  ).reverse();

  const renderHistoricDataColumns = () => {
    const columns = [];
    if (showHistoricDataColumns) {
      if (isValuationDateHeader) {
        columns.push(
          ...historicEventsYearsResults.map((historicEventsYear) => (
            <th
              key={historicEventsYear}
              className="table-primary__cell--header-tertiary table-primary__cell--section-start-separator">
              <CellValue value={historicEventsYear} {...shortDateViewRules} />
            </th>
          ))
        );
      } else {
        columns.push(
          ...historicDataForecastYearsResults.map((historicalData, index) => (
            <th
              key={historicalData}
              className="table-primary__cell--header-tertiary table-primary__cell--section-start-separator">
              <CellValue
                value={historicDataForecastYearsInputs[index].date}
                {...shortDateViewRules}
              />
            </th>
          ))
        );
      }
    }
    return columns;
  };

  return (
    <thead className="table-primary__sticky-section table-primary__sticky-section--deal-thesis-header">
      <tr>
        <th className="table-primary__cell--header-tertiary table-primary__cell--section-start-separator" />
        <DealThesisEmptyHistoricDataColumns
          isHistoricEventsSection={isValuationDateHeader}
          isSectionHeader
          isHeaderTertiary
          isStartSeparator
        />
        {renderHistoricDataColumns()}
        <th
          className={classNames(
            'table-primary__cell--header-tertiary',
            'table-primary__cell--section-start-separator',
            { 'table-primary__cell--align-inherit': !isValuationDateHeader }
          )}>
          {isValuationDateHeader ? (
            <CellValue value={valuationDate} {...shortDateViewRules} />
          ) : (
            <GridTextField
              name="historicalYear"
              placeholder={l('_LTM')}
              isInputMasked={false}
              alignment={Alignment.Right}
              variation={GridFieldVariation.TableHeader}
              isDealThesisHeader
              rules={{
                ...getRequiredValidator(),
              }}
            />
          )}
        </th>
        {Object.values(caseForecasts).map((forecast, index) => (
          <th
            key={index}
            className="table-primary__cell--header-tertiary table-primary__cell--section-start-separator">
            <CellValue value={forecast.forecastId} {...shortDateViewRules} />
          </th>
        ))}
        <th className="table-primary__cell--header-tertiary table-primary__cell--section-start-separator" />
      </tr>
    </thead>
  );
};

export default DealThesisSectionHeader;
