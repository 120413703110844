import React, { FC, useState } from 'react';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import classNames from 'classnames';
import styles from '@app/modules/projects/dashboard/widgets/total-equity/total-equity-chart.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { InstrumentBlock } from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import OpmInstrumentTooltip from './OpmInstrumentTooltip';
import { OpmForecastDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import { InstrumentDefinitionAndInitialValues } from '@app/core/store/capital-structure-slice-selectors';

interface OpmInstrumentBlockProps {
  instrument: InstrumentBlock<InstrumentDefinitionAndInitialValues>;
  forecastIndex?: number;
  forecastData?: OpmForecastDto[];
  chartForecastsCount: number;
  enableTooltip?: boolean;
}

const OpmInstrumentBlockComponent: FC<OpmInstrumentBlockProps> = ({
  instrument,
  forecastIndex,
  forecastData,
  chartForecastsCount,
  enableTooltip = true,
}): JSX.Element => {
  const isLastForecast = chartForecastsCount - 2 <= (forecastIndex ?? 0);
  const isFirstForecast = forecastIndex === undefined;
  const [isHovering, setIsHovering] = useState(false);
  const tooltipPlacement = isFirstForecast
    ? TooltipPlacement.Right
    : isLastForecast
    ? TooltipPlacement.Left
    : TooltipPlacement.Top;
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      key={'instrument-tooltip-wrapper' + instrument.instrumentId}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{ width: instrument.width + '%' }}>
      {enableTooltip && (
        <Tooltip
          key={'instrument-tooltip' + instrument.instrumentId}
          placement={tooltipPlacement}
          className={classNames(styles['graph__item-container'])}
          content={
            <OpmInstrumentTooltip
              isHovering={isHovering}
              forecastData={forecastData ?? []}
              forecastIndex={forecastIndex}
              instrument={instrument}
              key={'instrument-tooltip-content' + instrument.instrumentId + forecastIndex}
            />
          }>
          <Button
            data-testid="instrument-block"
            appearance={ButtonAppearance.CLEAN}
            key={'instrument-block-' + forecastIndex}
            ignoreReadOnly
            className={classNames(styles['graph__item'], {
              [styles['graph__item--shareholder-loan']]:
                instrument.type ===
                enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
              [styles['graph__item--sweet']]: instrument.isSweetEquity === true,
              [styles['graph__item--preferred-shares']]:
                instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
            })}
          />
        </Tooltip>
      )}
      {!enableTooltip && (
        <Button
          data-testid="instrument-block"
          appearance={ButtonAppearance.CLEAN}
          key={'instrument-block-' + forecastIndex}
          ignoreReadOnly
          className={classNames(styles['graph__item'], {
            [styles['graph__item--shareholder-loan']]:
              instrument.type ===
              enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
            [styles['graph__item--sweet']]: instrument.isSweetEquity === true,
            [styles['graph__item--preferred-shares']]:
              instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
          })}
        />
      )}
    </div>
  );
};

export default React.memo(OpmInstrumentBlockComponent);
