import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { getSponsorInvestmentOptions } from '@app/shared/constants/dropdown-values/sponsor-investmenet-options';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { useLocale } from '@app/core/hooks/useLocale';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';

const SponsorInvestment: FC = (): JSX.Element => {
  const {
    storeBenchmarkingData,
    status,
    benchmarking: { benchmarkingOnly },
  } = useAppSelector((state) => state.project.projectDraft);
  const { benchmarkingInputs } = useAppSelector((state) => state.benchmarking);
  const { l } = useLocale();

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>{l('_SponsorInvestment')}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="sponsorInvestment.sponsorInvestmentStructureStrip"
            label={l('_SponsorInvestmentStructureStrip')}
            options={getSponsorInvestmentOptions()}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_PercentageOfFixedReturnInstrumentWithinTheStrip')}
              value={getCalculatedProjectValueInPercent(
                benchmarkingInputs?.fixedReturnInstrumentWithinStripPc
              )}
              {...enteredPercentageViewRules}
              isGapless
            />
          ) : (
            <FormField
              name="sponsorInvestment.percentageOfFixedReturnInstrumentWithinStrip"
              label={l('_PercentageOfFixedReturnInstrumentWithinTheStrip')}
              defaultValue={
                getCalculatedProjectValueInPercent(
                  benchmarkingInputs?.fixedReturnInstrumentWithinStripPc
                ) ?? 0
              }
              rules={{
                ...(shouldFieldsBeRequired && getRequiredValidator()),
                ...percentageValidator,
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={l('_CouponOnPreferredCapitalInstrumentVolumeWeighted')}
              value={getCalculatedProjectValueInPercent(
                benchmarkingInputs.volumeWeightedPreferredInstrumentCouponPc
              )}
              {...enteredPercentageViewRules}
              isGapless
            />
          ) : (
            <FormField
              name="sponsorInvestment.couponOnPreferredCapitalInstrumentPercentage"
              label={l('_CouponOnPreferredCapitalInstrumentVolumeWeighted')}
              defaultValue={
                getCalculatedProjectValueInPercent(
                  benchmarkingInputs?.volumeWeightedPreferredInstrumentCouponPc
                ) ?? 0
              }
              rules={{
                ...(shouldFieldsBeRequired && getRequiredValidator()),
                ...percentageValidator,
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default SponsorInvestment;
