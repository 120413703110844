import { FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import { swapMaskForValueDisplay } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import { Card } from '@app/shared/components/card/Card';
import styles from './deal-thesis-page.module.scss';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { Navigate, useParams } from 'react-router-dom';
import * as projectActions from '@core/store/project-slice';
import { CaseDto, EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { DealThesisCaseTable } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/DealThesisCaseTable';
import {
  cloneDeep,
  cloneForecast,
  groupBy,
  instrumentsSortFn,
  isInstrumentOwnerAmountEnteredLegacy,
  sumBy,
} from '@app/shared/helpers';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import BaseModal from '@app/shared/components/modal/BaseModal';
import Button from '@app/shared/components/button/Button';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { NotificationMessage } from '@app/shared/components/notification-message/NotificationMessage';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { SelectOption } from '@app/shared/models/option';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { NotificationMessageType } from '@app/shared/components/notification-message/notification-message-enums';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import { EmptyValues } from '@app/shared/constants/empty-values';
import SvgTrash from '@app/shared/icons/Trash';
import { selectSlnAndPrefSharesInstrumentsLegacy } from '@core/store/project-slice-selectors';
import useIsReadOnly from '@core/hooks/customUseIsReadOnly';
import { CalcMethod, ReduceYearsType } from '@app/shared/models/contracts/enums/shared-enums';
import { RouteConstants } from '../../RouteConstants';
import { setDealThesisSelectedTab } from '@core/store/ui-values-slice';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';
import { ReduceYearsModal } from '@app/shared/components/modal/reduce-years-modal/ReduceYearsModal';

export interface DealThesisFormModel extends CaseDto {
  filteredSlnAndPrefSharesInstruments: EquityInstrumentDto[];
  numberOfForecastYears: number;
  historicalYear: string;
  exitValueDriverLabel: string;
  historicalExitValueDriver: Nullable<number>;
}

export const DealThesisForm = () => {
  const PARENT_CLASSNAME = 'case-form-card';
  const dispatch = useAppDispatch();
  const { navigate, generatePath } = usePwcNavigate();
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstrumentsLegacy);
  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments
    .filter(isInstrumentOwnerAmountEnteredLegacy)
    .sort(instrumentsSortFn);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const activeCase = caseData?.narrative;
  const [isDeleteCaseModalVisible, setIsDeleteCaseModalVisible] = useState(false);
  const [isForecastYearRemovalModalVisible, setIsForecastYearRemovalModalVisible] = useState(false);
  const isReadOnly = useIsReadOnly();

  const caseExists = project.pwermInput.cases.some((x) => x.caseId === caseId);

  useEffect(() => {
    dispatch(setDealThesisSelectedTab(caseId));
  }, [caseId, dispatch]);

  useEffect(() => {
    if (!caseExists) {
      navigate('../');
    }
  }, [project.pwermInput.cases, caseExists, navigate]);

  const valuationValueDriver = getPwermValuationValueDriver(project);

  const defaultFormValues: DealThesisFormModel = {
    caseId: caseId ?? '',
    narrative: caseData?.narrative ?? '',
    probability: caseData?.probability ?? 0,
    capitalStructureId: caseData?.capitalStructureId ?? '',
    eventSetId: caseData?.eventSetId ?? null,
    multiples: caseData?.multiples ?? [],
    netDebtItems: caseData?.netDebtItems ?? [],
    forecasts: caseData?.forecasts ?? [],
    numberOfForecastYears: caseData?.forecasts.length ?? 3,
    historicalYear: project.pwermInput.valuationDateNarrative,
    historicalCashValue:
      caseData?.historicalCashValue || caseData?.historicalCashValue === 0
        ? caseData?.historicalCashValue
        : null,
    exitValueDriverLabel: valuationValueDriver.narrative,
    historicalExitValueDriver:
      valuationValueDriver.value || valuationValueDriver.value === 0
        ? valuationValueDriver.value
        : null,
    filteredSlnAndPrefSharesInstruments: filteredSlnAndPrefSharesInstruments ?? [],
  };

  const formMethods = useForm<DealThesisFormModel>({
    ...formConfigBase,
    defaultValues: defaultFormValues,
  });

  const { append: appendForecastsField, remove: removeForecastsField } = useFieldArray({
    name: 'forecasts',
    control: formMethods.control,
  });

  const { handleSubmit, reset, getValues, trigger } = formMethods;

  const [countOfForecastYears, setCountOfForecastYears] = useState<number>(
    defaultFormValues.numberOfForecastYears
  );

  const numberOfForecastYearsOptions: SelectOption[] = [...Array(10).keys()].map((item) => ({
    viewValue: item + 1,
    value: item + 1,
  }));

  const caseProbabilitiesTotal = sumBy(project.pwermInput.cases, (caseItem) =>
    Number(caseItem.probability)
  );
  const probabilitiesTotalWarningMessage = `All case probabilities add up to ${
    !Number.isNaN(caseProbabilitiesTotal)
      ? numberValueFormatter({ value: caseProbabilitiesTotal, ...percentageValueFormatterProps })
      : EmptyValues.EnDash
  }, this must be 100%.`;

  const isStructureTheSame =
    Object.keys(groupBy(project.pwermInput.cases, (c) => c.forecasts.length)).length === 1 &&
    Object.keys(groupBy(project.pwermInput.cases, (c) => c.multiples.length)).length === 1;
  const caseStruturesDiffersWarningMessage =
    'Number of Forecast Years and/or Exit Multiples aren’t the same across all cases.';

  useEffect(() => {
    reset(defaultFormValues);
    setCountOfForecastYears(defaultFormValues.numberOfForecastYears);
    trigger();
  }, [reset, caseId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleForecastYearQuantityChange = () => {
    if (Number(countOfForecastYears) > Number(getValues().numberOfForecastYears)) {
      setIsForecastYearRemovalModalVisible(true);
    } else if (Number(countOfForecastYears) < Number(getValues().numberOfForecastYears)) {
      addForecastYears();
      setCountOfForecastYears(getValues().numberOfForecastYears);
    }
  };

  const revertForecastYears = () => {
    setIsForecastYearRemovalModalVisible(false);
    formMethods.setValue('numberOfForecastYears', countOfForecastYears);
    submitData();
  };

  const removeForecastYears = () => {
    const forecasts = caseData?.forecasts ?? [];

    removeForecastsField(
      [
        ...Array(Number(countOfForecastYears) - Number(getValues().numberOfForecastYears)).keys(),
      ].map((index) => {
        return forecasts.length - index - 1;
      })
    );
    submitData();
    setIsForecastYearRemovalModalVisible(false);
    setCountOfForecastYears(getValues().numberOfForecastYears);
  };

  const addForecastYears = () => {
    const forecasts = caseData?.forecasts ?? [];
    const lastCaseItemForecast = forecasts?.[forecasts?.length - 1];
    [...Array(Number(getValues().numberOfForecastYears) - Number(countOfForecastYears)).keys()].map(
      (index) => {
        const clonedForecast = cloneForecast(lastCaseItemForecast);
        appendForecastsField({
          ...clonedForecast,
          forecastYear: new Date(clonedForecast.forecastYear).addYears(index + 1).toISODateString(),
        });
      }
    );
  };

  const submitData = async () => {
    const {
      historicalYear,
      exitValueDriverLabel,
      historicalExitValueDriver,
      filteredSlnAndPrefSharesInstruments,
      ...caseDtoValues
    } = getValues();
    if (!isReadOnly) {
      const updatedCasesData = project.pwermInput.cases.map((caseItem) => {
        if (caseItem.caseId !== caseId) {
          return caseItem;
        }

        return {
          ...caseItem,
          ...cloneDeep(caseDtoValues),
          caseId: caseItem.caseId,
        };
      });

      await dispatch(
        projectActions.updateProjectDraft({
          project: {
            ...project,
            equityInstruments: [
              ...project.equityInstruments.filter(
                (instrument) =>
                  !filteredSlnAndPrefSharesInstruments.some(
                    (slnOrPrefShareInstrument) =>
                      instrument.instrumentId === slnOrPrefShareInstrument.instrumentId
                  )
              ),
              ...cloneDeep(filteredSlnAndPrefSharesInstruments),
            ],
            pwermInput: {
              ...project.pwermInput,
              cases: [...updatedCasesData],
              valuationDateNarrative: historicalYear,
              metricDefinitions: {
                ...project.pwermInput.metricDefinitions,
                [project.pwermInput.valueDriverActiveMetric]: { narrative: exitValueDriverLabel },
              },
              valuationDateMetrics: {
                ...project.pwermInput.valuationDateMetrics,
                [project.pwermInput.valueDriverActiveMetric]: historicalExitValueDriver,
              },
            },
          },
        })
      ).unwrap();
    }
  };

  const handleCaseRemoval = (caseId: string | undefined) => {
    dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          pwermInput: {
            ...project.pwermInput,
            cases: [...project.pwermInput.cases.filter((i) => i.caseId !== caseId)],
          },
        },
      })
    );
    setIsDeleteCaseModalVisible(false);
    const index = project.pwermInput.cases.findIndex((item) => item.caseId === caseId);
    navigate(
      generatePath('../:caseId', {
        caseId: project.pwermInput.cases[index == 0 ? index + 1 : index - 1].caseId,
      })
    );
  };

  useEffect(() => {
    // resolve concurrency issue
    setTimeout(() => trigger(), 0);
  }, [trigger, caseId]);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <FormProvider {...formMethods}>
      <form onBlur={handleSubmit(submitData, submitData)}>
        {caseProbabilitiesTotal !== 100 && (
          <div className={styles['notification']}>
            <NotificationMessage>{probabilitiesTotalWarningMessage}</NotificationMessage>
          </div>
        )}
        {!isStructureTheSame && (
          <div className={styles['notification']}>
            <NotificationMessage type={NotificationMessageType.warning}>
              {caseStruturesDiffersWarningMessage}
            </NotificationMessage>
          </div>
        )}

        <Card className={styles[PARENT_CLASSNAME]}>
          <FormField
            name="narrative"
            label="Case Narrative"
            required
            rules={{
              ...getRequiredValidator(),
            }}
            isInputMasked={false}
            fieldValueMask={swapMaskForValueDisplay}
            isGapless
          />
          <FormField
            name="probability"
            label="Case Probability"
            required
            rules={{
              ...getRequiredValidator(),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
          <FormSelect
            name="numberOfForecastYears"
            label="Number of Forecast Years"
            onBlur={() => handleForecastYearQuantityChange()}
            required
            rules={{
              ...getRequiredValidator(),
            }}
            options={numberOfForecastYearsOptions}
            isGapless
          />
          <div className={styles[`${PARENT_CLASSNAME}__action`]}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              startIcon={<SvgTrash />}
              disabled={project.pwermInput.cases.length === 1}
              onClick={() => setIsDeleteCaseModalVisible(true)}>
              Delete Case
            </Button>
          </div>
          <BaseModal
            size={ModalSize.Small}
            title={`Delete ${activeCase}?`}
            buttonConfirmLabel="Delete Case"
            buttonCancelLabel="Cancel"
            onCloseButtonClick={() => setIsDeleteCaseModalVisible(false)}
            onConfirm={() => handleCaseRemoval(caseId)}
            onCancel={() => setIsDeleteCaseModalVisible(false)}
            isOpen={isDeleteCaseModalVisible}>
            {`This will delete ${activeCase} and any data you’ve entered.`}
          </BaseModal>
          <ReduceYearsModal
            onCloseButtonClick={() => revertForecastYears()}
            onCancel={() => revertForecastYears()}
            onConfirm={() => removeForecastYears()}
            isOpen={isForecastYearRemovalModalVisible}
            type={ReduceYearsType.Forecast}
          />
        </Card>
        <DealThesisCaseTable handleDataSubmit={submitData} />
      </form>
    </FormProvider>
  );
};

export const DealThesisPage: FC = (): JSX.Element => {
  const { cases } = useAppSelector((state) => state.project.projectDraft).pwermInput;
  const storedDealThesisCaseId = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.selectedTab
  );
  const { generatePath } = usePwcNavigate();

  return (
    <PwcNavigate
      route={generatePath(
        ':caseId',
        storedDealThesisCaseId ? { caseId: storedDealThesisCaseId } : { caseId: cases[0].caseId }
      )}
    />
  );
};
