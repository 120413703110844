import React from 'react';
import HideUnrelatedEvents from './HideUnrelatedEvents';
import styles from '../../build-structure-tab/build-structure.module.scss';
import { CaseNavigationControls } from '../../../controls/CaseNavigationControls';
import { useAppSelector } from '@app/core/hooks/redux-hooks';

const BuildStructureControls: React.FC = () => {
  const hideUnrelatedEventsToggleDisplay = useAppSelector(
    (state) => state.uiValues.hideUnrelatedEventsToggleDisplay
  );
  return (
    <div className={styles['controls']}>
      <CaseNavigationControls />
      {hideUnrelatedEventsToggleDisplay && <HideUnrelatedEvents />}
    </div>
  );
};

export default BuildStructureControls;
