import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useAppSelector } from '@core/hooks/redux-hooks';
import {
  TABLE_COL_WIDTH,
  TABLE_LABELS_COL_WIDTH_ALTERNATIVE,
} from '@app/shared/constants/table-config';
import { shortDateViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import styles from '../opm-inputs.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';
import { GridFieldVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { getRequiredValidator } from '@core/validations/hook-forms/validators';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';

export const OpmInputTableHeader = () => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();

  return (
    <>
      <colgroup>
        <col style={{ minWidth: `${TABLE_LABELS_COL_WIDTH_ALTERNATIVE}px` }} />
        <col style={{ minWidth: `${TABLE_COL_WIDTH}px` }} />
        {project.opmInput?.perYearInputs.map((_, index) => (
          <col key={index} />
        ))}
      </colgroup>
      <thead
        className={`table-primary__sticky-section table-primary__sticky-section--opm-input-header ${
          styles[`opm-table-header`]
        }`}>
        <tr>
          <th className={`table-primary__cell--header-tertiary ${styles[`table-title`]}`}>
            {l('_OtherInputs')}
          </th>
          <th className="table-primary__cell--header-tertiary">
            <GridTextField
              name="valuationDateNarrative"
              data-testid="historicalYear"
              isInputMasked={false}
              alignment={Alignment.Right}
              variation={GridFieldVariation.TableHeader}
              rules={{
                ...getRequiredValidator(),
              }}
            />
          </th>
          {project.opmInput?.perYearInputs.map((forecast, index) => (
            <th key={index} className="table-primary__cell--header-tertiary">
              <CellValue value={forecast.forecastDate} {...shortDateViewRules} />
            </th>
          ))}
          <th className="table-primary__cell--header-tertiary" />
        </tr>
      </thead>
    </>
  );
};
