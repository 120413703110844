import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { Pwerm2WaterfallPartialTableProps } from '../pwerm2-waterfall-shared';
import { FC } from 'react';
import { WaterfallTableExpandableRow } from '../../pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import useToggler from '@app/core/hooks/useToggler';
import { useLocale } from '@app/core/hooks/useLocale';
import { enumKeyByValue } from '@app/shared/helpers';
import { WaterfallSectionsErf } from '@app/shared/models/contracts/enums/shared-enums';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import classNames from 'classnames';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getWaterfallCaseYearValue } from '@app/core/store/pwerm2-calculation-slice-selectors';
import { DevFeature, isDevFeatureEnabled } from '@app/core/utils/dev-feature';

export const Pwerm2WaterfallOtherCashflowMovementPartialTable: FC<
  Pwerm2WaterfallPartialTableProps
> = ({ columnsCount }) => {
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const project = useAppSelector((state) => state.project.projectDraft);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const capitalStructureId = project.pwermInput.cases[0].capitalStructureId;
  const isErfProject = buildStructures?.[capitalStructureId]?.isErf;
  const [isOtherCashflowMovementOpen, setIsOtherCashflowMovementOpen] = useToggler();
  const { l } = useLocale();

  if (!isErfProject || !isDevFeatureEnabled(DevFeature.ERF)) {
    return null;
  }

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_OtherCashflowMovement')}
          id={enumKeyByValue(WaterfallSectionsErf, WaterfallSectionsErf.OtherCashflowMovement)}
          isOpen={isOtherCashflowMovementOpen}
          setIsOpen={setIsOtherCashflowMovementOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isOtherCashflowMovementOpen,
        })}>
        <TableSectionValuesPerForecast
          title={l('_Additions')}
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.shareholderDebtDistributionTotalAdditions
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_Redemption')}
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.shareholderDebtDistributionTotalRedemptions
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_CashPayInterest')}
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.shareholderDebtDistributionTotalCashPayInterest
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_NetCashflowMovement')}
          additionalTitleClassNames="table-primary__cell--strong"
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.shareholderDebtDistributionTotalCashflow
              )}
            />
          )}
        />
      </tbody>
    </>
  );
};
