export enum RouteConstants {
  BenchmarkingInputsLegacy = 'benchmarking-inputs-legacy',
  BenchmarkingInputs = 'benchmarking-inputs',
  BuildStructureLegacy = 'build-structure-legacy',
  BuildStructure = 'build-structure',
  CapitalStructure = 'capital-structure',
  CapmAssumptions = 'capm-assumptions',
  CapTableLegacy = 'cap-table-legacy',
  CapTable = 'cap-table',
  CostOfEquityLegacy = 'cost-of-equity-legacy',
  CostOfEquity = 'cost-of-equity',
  CostOfEquityComparator = 'cost-of-equity-comparator',
  DashboardOpm = 'dashboard-opm',
  DashboardPwermLegacy = 'dashboard-pwerm-legacy',
  DashboardPwerm = 'dashboard-pwerm',
  DealThesisLegacy = 'deal-thesis-legacy',
  DealThesis = 'deal-thesis',
  MinorityDiscountsLegacy = 'minority-discounts-legacy',
  MinorityDiscounts = 'minority-discounts',
  OpmInputs = 'opm-inputs',
  ParticipationTableLegacy = 'participation-table-legacy',
  ParticipationTable = 'participation-table',
  PayoutLogicLegacy = 'payout-logic-legacy',
  PayoutLogic = 'payout-logic',
  ProjectDetails = 'project-details',
  PwermWaterfallLegacy = 'waterfall-pwerm-legacy',
  PwermWaterfall = 'waterfall-pwerm',
  PwermComparator = 'waterfall-comparator',
  RealWorldDistributions = 'real-world-distributions',
  SourcesAndUses = 'sources-and-uses',
  SummaryLegacy = 'summary-legacy',
  Summary = 'summary',
  SummaryComparator = 'summary-comparator',
  TotalEquityCrossCheckLegacy = 'total-equity-cross-check-legacy',
  TotalEquityCrossCheck = 'total-equity-cross-check',
  TotalEquityCrossCheckComparator = 'total-equity-cross-check-comparator',
  ValuationInputs = 'valuation-inputs',
  ValuationSummary = 'valuation-summary',
  OpmWaterfall = 'waterfall-opm',
  OpmWaterfallErf = 'waterfall-opm',
  FirstLastSimulations = 'first-last-simulations',
  FirstLastSimulationsErf = 'first-last-simulations-erf',
  SampleWaterfall = 'sample-waterfall',
  SampleWaterfallErf = 'sample-waterfall-erf',
  InstrumentByIdLegacy = 'instrument-legacy/:instrumentId',
  InstrumentById = 'instrument/:instrumentId',
  InstrumentByIdComparator = 'instrument-comparator/:instrumentId',
  PwermOpmConclusions = 'pwerm-opm-overall-conclusions',
}
