import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useFormContext } from 'react-hook-form';
import {
  percentageValidator,
  getRequiredValidator,
} from '@app/core/validations/hook-forms/validators';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { Alignment } from '@app/shared/enums/alignment.enum';
import SvgChevron from '@app/shared/icons/Chevron';
import SvgRevert from '@app/shared/icons/Revert';
import { FC } from 'react';
import styles from '../../opm-inputs.module.scss';
import useLocalStorageCollapseState from '@app/core/hooks/useLocalStoreCollapse';
import { useLocale } from '@app/core/hooks/useLocale';
import Button from '@app/shared/components/button/Button';
import SvgFilledRightArrow from '@app/shared/icons/FilledRightArrow';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import {
  CalcMethod,
  OpmInputDataSource,
  OpmSimulatedValue,
} from '@app/shared/models/contracts/enums/shared-enums';
import { PerYearValuesDto } from '@app/shared/models/contracts/project-dto';
import { useOpmInputsFromPwerm } from '../../hooks/useOpmInputsFromPwerm';
import { enumKeyByValue } from '@app/shared/helpers';
import { OpmInputFormModel } from '@app/modules/projects/inputs/opm-inputs/OpmInputs';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import SvgInformation from '@app/shared/icons/Information';
import { PROJECT_NAME_TOOLTIP_WIDTH } from '@app/shared/components/tooltip/constants';
import { getOpmTransactionCostsCalculatedValues } from '@app/core/inputs/getOpmInputCalculatedValues';

interface TransactionCostSectionProps {
  submitData: () => void;
}

type RevertableFields = {
  hypotheticalDebtWhenIpo: number;
  ipoProbability: number;
  ipoCostsOfEv: number;
  secondarySaleCostsOfEv: number;
  ipoDiscount: number;
  equitySoldInIpo: number;
  postIpoSaleDlom: number;
};

const TransactionCostSection: FC<TransactionCostSectionProps> = ({ submitData }): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const isEquitySimulation =
    project.opmInput.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.Equity);
  const { l } = useLocale();
  const formMethods = useFormContext<OpmInputFormModel>();
  const [expanded, setExpanded] = useLocalStorageCollapseState(
    'expanded-transaction-cost',
    project.id
  );

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  const perYearInputs = project.opmInput.perYearInputs;
  const forecastDates = perYearInputs.map((yi) => yi.forecastDate);
  const opmCalculatedValues = getOpmTransactionCostsCalculatedValues(project, forecastDates);
  const opmPwermValues = useOpmInputsFromPwerm();
  const shouldGetValuesFromPwerm = project.details.calcMethod !== CalcMethod.OPM;
  const maxColspanCount =
    project.opmInput.forecastYears !== null
      ? project.opmInput.forecastYears + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
      : 0;
  const forecastYears = project.opmInput.forecastYears;
  const multipleForecastYears =
    project.opmInput.forecastYears !== null && project.opmInput.forecastYears > 1;

  const abbrevOpmValue =
    project.opmInput.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue)
      ? 'EV'
      : OpmSimulatedValue.Equity.toLowerCase();

  const populate = (inputField: string, cellIndex: number) => {
    const { ...updatedOpmInput } = formMethods.getValues();
    updatedOpmInput.perYearInputs.map((_, index: number) => {
      index >= cellIndex &&
        formMethods.setValue(
          `perYearInputs.${index}.${inputField as keyof PerYearValuesDto}`,
          updatedOpmInput.perYearInputs[cellIndex][inputField as keyof PerYearValuesDto]
        );
    });

    submitData();
  };

  const shouldAutoPopulateFromCell = (index: number): boolean =>
    (multipleForecastYears && forecastYears && index !== forecastYears - 1) ||
    (index === 0 && multipleForecastYears);

  const transactionCostsSource = perYearInputs.some((pyi) => {
    return (
      pyi.ipoProbabilitySource === OpmInputDataSource.Override ||
      pyi.secondarySaleCostsOfEvSource === OpmInputDataSource.Override ||
      pyi.ipoCostsOfEvSource === OpmInputDataSource.Override ||
      pyi.ipoDiscountSource === OpmInputDataSource.Override ||
      pyi.hypotheticalDebtWhenIpoSource === OpmInputDataSource.Override ||
      pyi.equitySoldInIpoSource === OpmInputDataSource.Override ||
      pyi.postIpoSaleDlomSource === OpmInputDataSource.Override
    );
  })
    ? OpmInputDataSource.Override
    : OpmInputDataSource.FromPWERM;

  const displayRevert = (rowSource: keyof PerYearValuesDto) => {
    const equityFieldSources: Array<keyof PerYearValuesDto> = [
      'ipoCostsOfEvSource',
      'secondarySaleCostsOfEvSource',
    ];
    if (
      shouldGetValuesFromPwerm &&
      // note — ipoCostsOfEvSource and secondarySaleCostsOfEvSource are NOT revertable if the
      // user has opted for an Equity Simulation
      (!isEquitySimulation || (isEquitySimulation && !equityFieldSources.includes(rowSource)))
    ) {
      return perYearInputs.some((yi) => yi[rowSource] === OpmInputDataSource.Override);
    }
  };

  const handleRevert = (propertyName: keyof RevertableFields) => {
    perYearInputs.forEach((yi, index) => {
      const valueToSet = opmPwermValues.getOpmValuesFromPwerm(yi.forecastDate)?.[propertyName];
      formMethods.setValue(`perYearInputs.${index}.${propertyName}`, valueToSet);
    });
    submitData();
  };

  return (
    <>
      <tbody>
        <tr>
          <th className={`table-primary__cell--header-tertiary ${styles[`opm-header`]}`}>
            <div className={styles['opm-collapsable-header']}>
              <button
                type="button"
                data-testid="tc-collapse-button"
                aria-label={l('_ToggleSectionCollapse', {
                  section: l('_ExitCostsTitle'),
                })}
                aria-expanded={expanded}
                style={{
                  border: 'none',
                  backgroundColor: 'none',
                  background: 'none',
                }}
                onClick={toggleCollapse}>
                {!expanded ? (
                  <SvgChevron
                    onClick={toggleCollapse}
                    className={styles[`opm-chevron-collapsed`]}
                  />
                ) : (
                  <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-expanded`]} />
                )}
              </button>
              {l('_ExitCostsTitle')}
            </div>
          </th>
          <th
            className={`table-primary__cell--header-tertiary ${styles[`opm-header-badge`]}`}
            colSpan={maxColspanCount - 1}>
            {project.details.calcMethod !== CalcMethod.OPM && (
              <div className={styles['opm-collapsable-header-badge']} data-testid="tc-badge">
                <Pill
                  size={PillSize.Xsmall}
                  noTextTransform
                  theme={
                    transactionCostsSource === OpmInputDataSource.Override
                      ? PillTheme.Orange
                      : PillTheme.White
                  }>
                  {transactionCostsSource === OpmInputDataSource.Override
                    ? l('_Overridden')
                    : l('_AutoPopulatedFromPWERM')}
                </Pill>
              </div>
            )}
          </th>
        </tr>
        {expanded && (
          <>
            <tr>
              <>
                <th
                  className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}
                  align="left">
                  {l('_ProbabilityOfIpo')}
                  {displayRevert('ipoProbabilitySource') && (
                    <SvgRevert
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => handleRevert('ipoProbability')}
                      className={styles['revert-button']}
                    />
                  )}
                </th>
                <td />
                {project.opmInput.perYearInputs?.map((_, index) => {
                  return (
                    <td key={`ipoProbability_${index}`} className={styles['hoverable-cell']}>
                      <GridTextField
                        className={[
                          shouldGetValuesFromPwerm &&
                          perYearInputs[index].ipoProbabilitySource === OpmInputDataSource.Override
                            ? styles['revertable-value']
                            : '',
                          shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                        ]}
                        name={`perYearInputs.${index}.ipoProbability`}
                        data-testid={`perYearInputs.${index}.ipoProbability`}
                        {...percentageFieldFormattingProps}
                        rules={{
                          ...percentageValidator,
                          ...getRequiredValidator(),
                        }}
                        alignment={Alignment.Right}
                      />
                      {shouldAutoPopulateFromCell(index) && (
                        <Button
                          startIcon={<SvgFilledRightArrow />}
                          className={styles['opm-populate-button']}
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.FLUID}
                          // stop the onBlur event occurring so we can control when the data is submitted
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => populate('ipoProbability', index)}
                          data-testid={`populate-ipoProbability-${index}`}
                          autoIconSize
                        />
                      )}
                    </td>
                  );
                })}
                <td />
              </>
            </tr>
            <tr>
              <th className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}>
                {l('_ProbabilityOfSecondaryTradeSaleTitle')}
              </th>
              <td />
              {project?.opmInput.perYearInputs.map((_, index) => (
                <td key={index + 'ProbabilityOfSecondaryTradeSale'}>
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={opmCalculatedValues[index].probabilityofSecondaryTradesale}
                  />
                </td>
              ))}
              <td />
            </tr>
            <tr data-testid="tc-row">
              <th
                className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}
                align="left">
                <div>
                  {l('_IPOTransactionCosts')}
                  {displayRevert('ipoCostsOfEvSource') && (
                    <SvgRevert
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => handleRevert('ipoCostsOfEv')}
                      className={styles['revert-button']}
                    />
                  )}
                </div>
                {isEquitySimulation && shouldGetValuesFromPwerm && (
                  <div>
                    <Tooltip
                      placement={TooltipPlacement.Right}
                      content={l('_OpmNoAutoPopulateBecauseEquityTooltip')}
                      width={PROJECT_NAME_TOOLTIP_WIDTH}
                      className={styles['opm-no-autopopulate-because-equity-tooltip']}>
                      <SvgInformation
                        className={styles['project-details-toggle--warning-icon']}
                        data-testid="autopopulated-from-pwerm-icon"
                      />
                    </Tooltip>
                  </div>
                )}
              </th>
              <td />
              {project.opmInput?.perYearInputs.map((_, index) => (
                <td key={index + 'ipoCostsOfEv'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldGetValuesFromPwerm &&
                      !isEquitySimulation &&
                      perYearInputs[index].ipoCostsOfEvSource === OpmInputDataSource.Override
                        ? styles['revertable-value']
                        : '',
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`perYearInputs.${index}.ipoCostsOfEv`}
                    data-testid={`perYearInputs.${index}.ipoCostsOfEv`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...getRequiredValidator(),
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['opm-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('ipoCostsOfEv', index)}
                      data-testid={`populate-ipoCostsOfEv-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr data-testid="tc-row">
              <th className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}>
                <div>
                  {l('_SecondaryOrTradeSaleTransactionCost')}
                  {displayRevert('secondarySaleCostsOfEvSource') && (
                    <SvgRevert
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => handleRevert('secondarySaleCostsOfEv')}
                      className={styles['revert-button']}
                    />
                  )}
                </div>
                {isEquitySimulation && shouldGetValuesFromPwerm && (
                  <div>
                    <Tooltip
                      placement={TooltipPlacement.Right}
                      content={l('_OpmNoAutoPopulateBecauseEquityTooltip')}
                      width={PROJECT_NAME_TOOLTIP_WIDTH}
                      className={styles['opm-no-autopopulate-because-equity-tooltip']}>
                      <SvgInformation className={styles['project-details-toggle--warning-icon']} />
                    </Tooltip>
                  </div>
                )}
              </th>
              <td />
              {project.opmInput?.perYearInputs.map((_, index) => (
                <td key={index + 'secondarySaleCostsOfEv'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldGetValuesFromPwerm &&
                      !isEquitySimulation &&
                      perYearInputs[index].secondarySaleCostsOfEvSource ===
                        OpmInputDataSource.Override
                        ? styles['revertable-value']
                        : '',
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`perYearInputs.${index}.secondarySaleCostsOfEv`}
                    data-testid={`perYearInputs.${index}.secondarySaleCostsOfEv`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...getRequiredValidator(),
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['opm-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('secondarySaleCostsOfEv', index)}
                      data-testid={`populate-secondarySaleCostsOfEv-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th
                className={`table-primary__cell--vertical-separator ${styles[`opm-table-outer`]}`}>
                <strong>{l('_TransactionCost')}</strong>
              </th>
              <td />
              {project?.opmInput.perYearInputs.map((_, index) => (
                <td key={index + 'TransactionCost'}>
                  <strong>
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={opmCalculatedValues[index].transactionCost}
                    />
                  </strong>
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <>
                <th
                  align="left"
                  data-testid="header-ipoDiscount"
                  className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}>
                  <div>
                    {l('_IpoDiscountTitle')}
                    {displayRevert('ipoDiscountSource') && (
                      <SvgRevert
                        // stop the onBlur event occurring so we can control when the data is submitted
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => handleRevert('ipoDiscount')}
                        className={styles['revert-button']}
                      />
                    )}
                  </div>
                  {isEquitySimulation && shouldGetValuesFromPwerm && (
                    <div>
                      <Tooltip
                        placement={TooltipPlacement.Right}
                        content={l('_OpmNoAutoPopulateBecauseEquityTooltip')}
                        width={PROJECT_NAME_TOOLTIP_WIDTH}
                        className={styles['opm-no-autopopulate-because-equity-tooltip']}>
                        <SvgInformation
                          className={styles['project-details-toggle--warning-icon']}
                        />
                      </Tooltip>
                    </div>
                  )}
                </th>
                <td />
                {project.opmInput.perYearInputs?.map((_, index) => {
                  return (
                    <td key={`ipoDiscount_${index}`} className={styles['hoverable-cell']}>
                      <GridTextField
                        className={[
                          shouldGetValuesFromPwerm &&
                          !isEquitySimulation &&
                          perYearInputs[index].ipoDiscountSource === OpmInputDataSource.Override
                            ? styles['revertable-value']
                            : '',
                          shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                        ]}
                        name={`perYearInputs.${index}.ipoDiscount`}
                        data-testid={`perYearInputs.${index}.ipoDiscount`}
                        {...percentageFieldFormattingProps}
                        rules={{
                          ...percentageValidator,
                          ...getRequiredValidator(),
                        }}
                        alignment={Alignment.Right}
                      />
                      {shouldAutoPopulateFromCell(index) && (
                        <Button
                          startIcon={<SvgFilledRightArrow />}
                          className={styles['opm-populate-button']}
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.FLUID}
                          // stop the onBlur event occurring so we can control when the data is submitted
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => populate('ipoDiscount', index)}
                          data-testid={`populate-ipoDiscount-${index}`}
                          autoIconSize
                        />
                      )}
                    </td>
                  );
                })}
                <td />
              </>
            </tr>
            <tr>
              <>
                <th
                  align="left"
                  className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}>
                  {l('_HypotheticalDebtAtIpoTitle')}
                  {displayRevert('hypotheticalDebtWhenIpoSource') && (
                    <SvgRevert
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => handleRevert('hypotheticalDebtWhenIpo')}
                      className={styles['revert-button']}
                    />
                  )}
                </th>
                <td />
                {project.opmInput.perYearInputs?.map((_, index) => {
                  return (
                    <td
                      key={`hypotheticalDebtWhenIpo_${index}`}
                      className={styles['hoverable-cell']}>
                      <GridTextField
                        className={[
                          shouldGetValuesFromPwerm &&
                          perYearInputs[index].hypotheticalDebtWhenIpoSource ===
                            OpmInputDataSource.Override
                            ? styles['revertable-value']
                            : '',
                          shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                        ]}
                        name={`perYearInputs.${index}.hypotheticalDebtWhenIpo`}
                        data-testid={`perYearInputs.${index}.hypotheticalDebtWhenIpo`}
                        {...percentageFieldFormattingProps}
                        rules={{
                          ...percentageValidator,
                          ...getRequiredValidator(),
                        }}
                        alignment={Alignment.Right}
                      />
                      {shouldAutoPopulateFromCell(index) && (
                        <Button
                          startIcon={<SvgFilledRightArrow />}
                          className={styles['opm-populate-button']}
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.FLUID}
                          // stop the onBlur event occurring so we can control when the data is submitted
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => populate('hypotheticalDebtWhenIpo', index)}
                          data-testid={`populate-hypotheticalDebtWhenIpo-${index}`}
                          autoIconSize
                        />
                      )}
                    </td>
                  );
                })}
                <td />
              </>
            </tr>
            <tr>
              <>
                <th
                  align="left"
                  data-testid="populate-equitySoldInIpo1"
                  className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}>
                  {l('_PercentageEquitySoldInIpo')}
                  {displayRevert('equitySoldInIpoSource') && (
                    <SvgRevert
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => handleRevert('equitySoldInIpo')}
                      className={styles['revert-button']}
                    />
                  )}
                </th>
                <td />
                {project.opmInput.perYearInputs?.map((_, index) => {
                  return (
                    <td key={`equitySoldInIpo_${index}`} className={styles['hoverable-cell']}>
                      <GridTextField
                        className={[
                          shouldGetValuesFromPwerm &&
                          perYearInputs[index].equitySoldInIpoSource === OpmInputDataSource.Override
                            ? styles['revertable-value']
                            : '',
                          shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                        ]}
                        name={`perYearInputs.${index}.equitySoldInIpo`}
                        data-testid={`perYearInputs.${index}.equitySoldInIpo`}
                        {...percentageFieldFormattingProps}
                        rules={{
                          ...percentageValidator,
                          ...getRequiredValidator(),
                        }}
                        alignment={Alignment.Right}
                      />
                      {shouldAutoPopulateFromCell(index) && (
                        <Button
                          startIcon={<SvgFilledRightArrow />}
                          className={styles['opm-populate-button']}
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.FLUID}
                          // stop the onBlur event occurring so we can control when the data is submitted
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => populate('equitySoldInIpo', index)}
                          data-testid={`populate-equitySoldInIpo-${index}`}
                          autoIconSize
                        />
                      )}
                    </td>
                  );
                })}
                <td />
              </>
            </tr>
            <tr>
              <th
                className={`table-primary__cell--vertical-separator ${styles[`opm-table-outer`]}`}
                data-testid="ipo-discount-percentage-title">
                <strong>
                  {l('_IPODiscountPercentOfValuationType', { valuationType: abbrevOpmValue })}
                </strong>
              </th>
              <td />
              {project?.opmInput.perYearInputs.map((_, index) => (
                <td
                  key={index + 'IPOdiscountPercentOfEV'}
                  className={index === 0 ? styles[`padding-right-hover`] : ''}>
                  <strong>
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={opmCalculatedValues[index].ipoDiscountPercentOfEV}
                    />
                  </strong>
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <>
                <th
                  align="left"
                  data-testid="populate-postIpoSaleDlom1"
                  className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}>
                  {l('_PostIPOLockInDiscount')}
                  {displayRevert('postIpoSaleDlomSource') && (
                    <SvgRevert
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => handleRevert('postIpoSaleDlom')}
                      className={styles['revert-button']}
                    />
                  )}
                </th>
                <td />
                {project.opmInput.perYearInputs?.map((_, index) => {
                  return (
                    <td key={`postIpoSaleDlom_${index}`} className={styles['hoverable-cell']}>
                      <GridTextField
                        className={[
                          shouldGetValuesFromPwerm &&
                          perYearInputs[index].postIpoSaleDlomSource === OpmInputDataSource.Override
                            ? styles['revertable-value']
                            : '',
                          shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                        ]}
                        name={`perYearInputs.${index}.postIpoSaleDlom`}
                        data-testid={`perYearInputs.${index}.postIpoSaleDlom`}
                        {...percentageFieldFormattingProps}
                        rules={{
                          ...percentageValidator,
                          ...getRequiredValidator(),
                        }}
                        alignment={Alignment.Right}
                      />
                      {shouldAutoPopulateFromCell(index) && (
                        <Button
                          startIcon={<SvgFilledRightArrow />}
                          className={styles['opm-populate-button']}
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.FLUID}
                          // stop the onBlur event occurring so we can control when the data is submitted
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => populate('postIpoSaleDlom', index)}
                          data-testid={`populate-postIpoSaleDlom-${index}`}
                          autoIconSize
                        />
                      )}
                    </td>
                  );
                })}
                <td />
              </>
            </tr>
            <tr>
              <th className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}>
                {l('_ImpliedEquityRetainedOnIPO')}
              </th>
              <td />
              {project?.opmInput.perYearInputs.map((_, index) => (
                <td
                  key={index + 'ImpliedEquityRetainedOnIPO'}
                  className={index === 0 ? styles[`padding-right-hover`] : ''}>
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={opmCalculatedValues[index].impliedEquityRetainedOnIPO}
                  />
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th
                className={`table-primary__cell--vertical-separator ${styles[`opm-table-outer`]}`}>
                <strong>{l('_ProbabilityWeightedPostIPOLockInDiscount')}</strong>
              </th>
              <td />
              {project?.opmInput.perYearInputs.map((_, index) => (
                <td key={index + 'ProbabilityWeightedDLOMforPostIPOSaleRestrictions'}>
                  <strong>
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={
                        opmCalculatedValues[index].probabilityWeightedDLOMforPostIPOSaleRestrictions
                      }
                    />
                  </strong>
                </td>
              ))}
              <td />
            </tr>
          </>
        )}
      </tbody>
    </>
  );
};

export default TransactionCostSection;
