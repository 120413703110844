import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { numericBasicFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { getThirdPartyTaxValuationOptions } from '@app/shared/constants/dropdown-values/benchmark/third-party-tax-valuation-options';
import { getTaxValuationMethodologyAdoptedOptions } from '@app/shared/constants/dropdown-values/benchmark/tax-valuation-methodology-adopted-options';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { shortDateViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getRequiredValidator } from '@core/validations/hook-forms/validators';
import { enumKeyByValue } from '@app/shared/helpers';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';

const TaxValuation: FC = (): JSX.Element => {
  const { status, storeBenchmarkingData, valuationDate } = useAppSelector(
    (state) => state.project.projectDraft
  );

  const { l } = useLocale();

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>{l('_TaxValuation')}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="taxValuation.thirdPartyTaxValuationUndertakenOnEntry"
            label={l('_ThirdPartyTaxValuationUndertakenOnEntry')}
            options={getThirdPartyTaxValuationOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={l('_ValuationDate')}
            value={valuationDate.toString()}
            {...shortDateViewRules}
            isGapless
          />
        </div>
        <div className="form-row__column" />
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="taxValuation.taxValuationMethodologyAdopted"
            label={l('_TaxValuationMethodologyAdopted')}
            options={getTaxValuationMethodologyAdoptedOptions()}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="taxValuation.exchangeRateAtValuationDateGBP"
            label={l('_ExchangeRateAtValuationDateInCurrency')}
            placeholder="0"
            {...numericBasicFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column" />
      </div>
    </Card>
  );
};

export default TaxValuation;
