import { FC, Fragment, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { selectSlnAndPrefSharesForecasts } from '@app/core/store/pwerm-calculation-slice-selectors';
import {
  getCaseValueNetDebtItemsTotal,
  getNetDebtAtExit,
  getSlnAndPrefSharesForecast,
  selectCasesStructure,
  selectSlnAndPrefSharesInstrumentsLegacy,
} from '@core/store/project-slice-selectors';
import { CapmAssumptionsDto } from '@app/shared/models/contracts/project-dto';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';
import {
  instrumentsSortFn,
  isInstrumentOwnerAmountEnteredLegacy,
  sumBy,
} from '@app/shared/helpers';
import { CostOfEquityTableHeader } from '@app/modules/projects/outputs/cost-of-equity/components/CostOfEquityTableHeader';
import { ExitMultipleWeightedEVSection } from '@app/modules/projects/outputs/cost-of-equity/components/ExitMultipleWeightedEVSection';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { Navigate, generatePath } from 'react-router';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';
import { setCostOfEquitySelectedTab } from '@app/core/store/ui-values-slice';

interface GetRefProp {
  getRef?: (ref: HTMLTableElement) => void;
}

const SummaryAndHighLevelData: FC<GetRefProp> = ({ getRef }): JSX.Element => {
  const dispatch = useAppDispatch();
  const casesStructure = useAppSelector(selectCasesStructure);
  const project = useAppSelector((state) => state.project.projectDraft);
  const {
    pwermInput: { capmAssumptions },
  } = project;
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstrumentsLegacy);
  const calculatedSlnAndPrefSharesForecasts = useAppSelector(selectSlnAndPrefSharesForecasts);
  const { l } = useLocale();
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setCostOfEquitySelectedTab(RouteConstants.SummaryLegacy));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    isInstrumentOwnerAmountEnteredLegacy
  );

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  const valuationInputsList: { fieldName: keyof CapmAssumptionsDto; label: string }[] = [
    { fieldName: 'riskFreeRate', label: 'Risk free rate' },
    { fieldName: 'assetBeta', label: 'Asset beta' },
    { fieldName: 'emrp', label: 'EMRP' },
    { fieldName: 'cemra', label: 'CEMRA' },
    { fieldName: 'countrySpecificRiskPremium', label: 'Country specific risk premium' },
    { fieldName: 'inflationDifferential', label: 'Inflation differential' },
    { fieldName: 'sizePremium', label: 'Size premium' },
    { fieldName: 'companyRiskPremium', label: 'Company risk premium' },
  ];

  return (
    <div className="main-container-padding-remove">
      <table
        ref={tableRef}
        className="table-primary table-primary--framed table-primary--zebra table-primary--title-column-nowrap table-primary--sticky-first-column">
        <CostOfEquityTableHeader />
        <tbody>
          <tr>
            <th className="table-primary__cell--header">{l('_CapmAssumptions')}</th>
            <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
          </tr>
          {valuationInputsList.map((item, index) => {
            const renderCell = (additionalCell?: boolean) => {
              const isFormattedAsNumber = item.fieldName === 'assetBeta';

              return (
                <CellValue
                  value={capmAssumptions?.[item.fieldName]}
                  theme={additionalCell ? CellValueTheme.Tertiary : undefined}
                  {...(isFormattedAsNumber
                    ? numberCalculatedViewRules
                    : enteredPercentageViewRules)}
                  normalize
                />
              );
            };

            return (
              <Fragment key={index}>
                <TableSectionValuesPerForecast
                  key={index}
                  title={item.label}
                  renderSpacer={false}
                  renderCell={() => renderCell(false)}
                  renderAdditionalCell={() => renderCell(true)}
                />
              </Fragment>
            );
          })}
          <TableSpacerRow />
          <ExitMultipleWeightedEVSection />
          <tr>
            <th className="table-primary__cell--header">
              {l('_HighLevelTotalDebtForecastInOrderOfSeniority')}
            </th>
            <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
          </tr>
          <TableSectionValuesPerForecast
            title="Net Debt"
            renderSpacer={false}
            renderCell={({ forecast }) => (
              <CellValue {...amountRules} value={getNetDebtAtExit(forecast)} />
            )}
            renderAdditionalCell={({ caseItem }) => (
              <CellValue {...amountRules} value={getCaseValueNetDebtItemsTotal(caseItem)} />
            )}
          />
          {filteredSlnAndPrefSharesInstruments.sort(instrumentsSortFn).map((instrument) => (
            <TableSectionValuesPerForecast
              renderSpacer={false}
              key={instrument.instrumentId}
              title={instrument.instrumentNarrative}
              renderCell={({ caseItem, forecast }) => (
                <CellValue
                  {...amountRules}
                  value={
                    getSlnAndPrefSharesForecast(
                      project,
                      calculatedSlnAndPrefSharesForecasts,
                      caseItem.caseId,
                      instrument.instrumentId,
                      forecast.forecastId
                    )?.closingBalance
                  }
                />
              )}
              renderAdditionalCell={() => {
                const investedAmountPerInstrument = sumBy(
                  instrument.ownership,
                  (owner) => Number(owner.amount) || 0
                );
                return (
                  <CellValue
                    {...amountRules}
                    value={investedAmountPerInstrument}
                    theme={CellValueTheme.Tertiary}
                  />
                );
              }}
            />
          ))}
          <TableSectionValuesPerForecast
            title="Total Net Debt and Loan Notes"
            renderSpacer={false}
            renderCell={({ caseItem, forecast }) => {
              const slnTotal = sumBy(
                filteredSlnAndPrefSharesInstruments,
                (instrument) =>
                  getSlnAndPrefSharesForecast(
                    project,
                    calculatedSlnAndPrefSharesForecasts,
                    caseItem.caseId,
                    instrument.instrumentId,
                    forecast.forecastId
                  )?.closingBalance ?? 0
              );
              const netDebtTotal = getNetDebtAtExit(forecast);
              const total = netDebtTotal + slnTotal;

              return <CellValue {...amountRules} strong value={total} />;
            }}
            renderAdditionalCell={({ caseItem }) => {
              const investedAmount = sumBy(filteredSlnAndPrefSharesInstruments, (instrument) =>
                sumBy(instrument.ownership, (owner) => Number(owner.amount))
              );

              const total = getCaseValueNetDebtItemsTotal(caseItem) + investedAmount;

              return <CellValue {...amountRules} value={total} theme={CellValueTheme.Tertiary} />;
            }}
            additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
            additionalCellClassNames="table-primary__cell--section-start-separator"
          />
          <TableSpacerRow />
        </tbody>
      </table>
    </div>
  );
};

export default SummaryAndHighLevelData;
