import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { InstrumentDefinitionDto, ProjectDto } from '@app/shared/models/contracts/project-dto';
import {
  cloneDeep,
  enumKeyByValue,
  getProjectDraftWithAddedDlom,
  getProjectDraftWithRemovedDlom,
} from '@app/shared/helpers';
import * as projectActions from '@core/store/project-slice';
import styles from './payout-logic.module.scss';
import classNames from 'classnames';
import Button from '@app/shared/components/button/Button';
import PlusSvg from '@app/shared/icons/Plus';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { createPayoutLogicItem } from '@app/shared/helpers/create-tranche';
import { v4 as uuidv4 } from 'uuid';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { MAX_TRANCHES_PER_INSTRUMENT } from '@app/shared/constants/payout-logic';
import SvgEdit from '@app/shared/icons/Edit';
import { ContainerTestId, Else } from '@app/shared/models/contracts/enums/shared-enums';
import SvgTrash from '@app/shared/icons/Trash';
import { useLocale } from '@app/core/hooks/useLocale';
import { RouteConstants } from '../../../../RouteConstants';
import { setPageScrollPosition } from '@app/core/store/ui-values-slice';
import PayoutStatementsErf from './payout-statements/PayoutStatementsErf';
import {
  selectCapitalStructures,
  selectInstruments,
} from '@app/core/store/capital-structure-slice-selectors';

const PARENT_CLASSNAME = 'payout-logic';

export const PayoutLogicErf = () => {
  const { instrumentId } = useParams();
  // required to force re-render when instrumentId changes, which is needed to dispatch scroll positions
  return <PayoutLogicComponentErf key={instrumentId} />;
};

const PayoutLogicComponentErf: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { instrumentId } = useParams();
  const dispatch = useAppDispatch();

  const projectDraft = useAppSelector((state) => state.project.projectDraft);
  const capitalStructures = useAppSelector(selectCapitalStructures);
  const trancheId = uuidv4();
  const pageScrollPositionList = useAppSelector(
    (state) => state.uiValues.pageScrollPositionList[projectDraft.id]
  );
  const mainContainer = document.querySelector<HTMLElement>(
    `[data-testid=${ContainerTestId.Main}]`
  );
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollTopRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const activeInstrument = cloneDeep(
    useAppSelector(selectInstruments).find((x) => x.instrumentId === instrumentId)
  );
  const [currentEquityId, setCurrentEquityId] = useState<string | undefined>(
    activeInstrument?.instrumentId
  );

  const formMethods = useForm<InstrumentDefinitionDto>({
    ...formConfigBase,
    defaultValues: {
      ...{ ...activeInstrument },
    },
  });

  const { trigger } = formMethods;

  useEffect(() => {
    scrollTopRef.current = scrollTop;
    scrollLeftRef.current = scrollLeft;
  }, [scrollTop, scrollLeft]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(mainContainer?.scrollTop ?? 0);
      setScrollLeft(mainContainer?.scrollLeft ?? 0);
    };

    if (mainContainer) {
      mainContainer.addEventListener('scroll', handleScroll);
    }

    if (pageScrollPositionList) {
      const pageScrollPosition = pageScrollPositionList.find(
        (page) => page.pageName === RouteConstants.PayoutLogicLegacy + instrumentId
      );
      mainContainer &&
        pageScrollPosition &&
        mainContainer.scrollTo(pageScrollPosition.scrollLeft, pageScrollPosition.scrollTop);
    }
    return () => {
      const actionPayload = {
        key: projectDraft.id,
        value: {
          pageName: RouteConstants.PayoutLogicLegacy + instrumentId,
          scrollTop: scrollTopRef.current ?? 0,
          scrollLeft: scrollLeftRef.current ?? 0,
        },
      };
      dispatch(setPageScrollPosition(actionPayload));

      if (mainContainer) {
        mainContainer.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (instrumentId !== currentEquityId) {
      setCurrentEquityId(instrumentId);
      formMethods.reset(activeInstrument);
    }
  }, [instrumentId, activeInstrument, currentEquityId, formMethods]);

  const submitForm = async (
    getDraftWithSideEffects: (data: {
      draft: ProjectDto;
      instrumentId: string;
      trancheId?: string;
    }) => ProjectDto = ({ draft }) => draft,
    trancheId?: string
  ) => {
    if (!instrumentId) {
      return;
    }

    const capitalStructuresClone = cloneDeep(capitalStructures);
    const capitalStructure = Object.values(capitalStructuresClone)[0];
    const instrumentDefinitions = capitalStructure.instrumentDefinitions;

    const formData = formMethods.getValues();

    instrumentDefinitions[instrumentId] = {
      ...formData,
      payoutLogic: formData.payoutLogic
        ? [
            ...formData.payoutLogic
              .sort((a, b) => a.order - b.order)
              .map((tranche, index) => {
                return { ...tranche, order: index };
              }),
          ]
        : [],
    };

    // need to clone the object otherwise we get some read-only properties when it's
    // used to set the form values - see https://github.com/orgs/react-hook-form/discussions/3715#discussioncomment-746931
    const updatedProjectDraft = {
      ...projectDraft,
      capitalStructures: cloneDeep(capitalStructuresClone),
    };

    const updatedProjectDraftWithDlom = getDraftWithSideEffects({
      draft: updatedProjectDraft,
      instrumentId,
      trancheId,
    });

    await dispatch(
      projectActions.updateProjectDraft({ project: updatedProjectDraftWithDlom })
    ).unwrap();
  };

  const formSubmitHandler = () => {
    submitForm();
  };

  const {
    append: appendTranche,
    remove: removeTranche,
    fields: tranchesFields,
  } = useFieldArray({
    name: 'payoutLogic',
    keyName: 'trancheKey',
    control: formMethods.control,
  });

  const createTranche = async () => {
    const lastTrancheItem = activeInstrument?.payoutLogic?.length
      ? activeInstrument.payoutLogic[activeInstrument.payoutLogic.length - 1]
      : undefined;

    const trancheItem = lastTrancheItem
      ? {
          ...lastTrancheItem,
          id: trancheId,
          else: enumKeyByValue(Else, Else.Zero),
        }
      : createPayoutLogicItem(0, trancheId);

    await appendTranche(trancheItem, { shouldFocus: false });
    await submitForm(getProjectDraftWithAddedDlom, trancheItem.id);
  };

  const removeTrancheItem = async (trancheIndex: number, trancheId: string) => {
    await removeTranche(trancheIndex);
    await submitForm(getProjectDraftWithRemovedDlom, trancheId);
  };

  // required to prevent leave site dialogue on enter key press
  const submitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
  };

  return (
    <FormProvider {...formMethods}>
      <form
        data-cy="payout-logic-form"
        className={styles[`${PARENT_CLASSNAME}`]}
        onBlur={formMethods.handleSubmit(
          formSubmitHandler as SubmitHandler<InstrumentDefinitionDto>,
          formSubmitHandler as SubmitErrorHandler<InstrumentDefinitionDto>
        )}
        onSubmit={submitHandler}>
        {tranchesFields.length ? (
          <>
            {tranchesFields.map((trancheItem, trancheIndex) => {
              return (
                <div
                  key={trancheItem.trancheKey + 'tranche'}
                  className={
                    trancheIndex === 0
                      ? styles[`${PARENT_CLASSNAME}`]
                      : classNames(styles[`${PARENT_CLASSNAME}`], styles['non-first-tranche'])
                  }>
                  <h2 className={classNames('heading-5', styles[`${PARENT_CLASSNAME}__title`])}>
                    {l('_TrancheN', { n: trancheIndex + 1 })}
                  </h2>
                  <div className={styles[`${PARENT_CLASSNAME}__tranche-action-remove`]}>
                    <div className={styles[`${PARENT_CLASSNAME}__action-circle`]}>
                      <Button
                        appearance={ButtonAppearance.DEFAULT_TERTIARY}
                        aria-label={l('_RemoveTranche')}
                        size={ButtonSize.TABLE_ACTION}
                        startIcon={<SvgTrash />}
                        onBlur={(event) => event.stopPropagation()} // this prevents form from submission it's not needed, since it is submitted with removeTrancheItem method
                        onClick={() => removeTrancheItem(trancheIndex, trancheItem.id)}
                      />
                    </div>
                  </div>
                  <div className={styles[`${PARENT_CLASSNAME}__wrapper`]}>
                    <PayoutStatementsErf
                      trancheIndex={trancheIndex}
                      activeEquityInstrument={activeInstrument}
                      formSubmitHandler={formSubmitHandler}
                    />
                  </div>
                </div>
              );
            })}
            {activeInstrument && activeInstrument.payoutLogic && (
              <div className={styles[`${PARENT_CLASSNAME}__tranche-action`]}>
                <Button
                  appearance={ButtonAppearance.DEFAULT_TERTIARY}
                  size={ButtonSize.TABLE_ACTION}
                  startIcon={<PlusSvg />}
                  disabled={activeInstrument.payoutLogic.length >= MAX_TRANCHES_PER_INSTRUMENT}
                  title={
                    activeInstrument.payoutLogic.length >= MAX_TRANCHES_PER_INSTRUMENT
                      ? l('_MaximumNumberOfTranchesIsReached')
                      : undefined
                  }
                  onBlur={(event) => event.stopPropagation()} // this prevents form from submission it's not needed, since it is submitted with createTranche method
                  onClick={createTranche}>
                  {l('_AddTranche')}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div>
            <h2 className={classNames('heading-5', styles[`${PARENT_CLASSNAME}__title`])}>
              {l('_TrancheN', { n: '1' })}
            </h2>
            <div className={styles[`${PARENT_CLASSNAME}__empty-state-wrapper`]}>
              <div className={styles[`${PARENT_CLASSNAME}__empty-state-message`]}>
                {l('_DistributionIsBasedOnPercentageShareholding')}
              </div>
              <div>
                <Button
                  data-testid="payout-customize"
                  appearance={ButtonAppearance.DEFAULT_PRIMARY}
                  startIcon={<SvgEdit />}
                  onBlur={(event) => event.stopPropagation()} // this prevents form from submission it's not needed, since it is submitted with createTranche method
                  onClick={createTranche}>
                  {l('_Customise')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
