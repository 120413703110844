import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { amountBasicFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import SvgChevron from '@app/shared/icons/Chevron';
import SvgRevert from '@app/shared/icons/Revert';
import { FC } from 'react';
import styles from '../../opm-inputs.module.scss';
import useLocalStorageCollapseState from '@app/core/hooks/useLocalStoreCollapse';
import { useLocale } from '@app/core/hooks/useLocale';
import { CalcMethod, OpmInputDataSource } from '@app/shared/models/contracts/enums/shared-enums';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import { numberPattern } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { PerYearValuesDto } from '@app/shared/models/contracts/project-dto';
import { useOpmInputsFromPwerm } from '../../hooks/useOpmInputsFromPwerm';
import { useFormContext } from 'react-hook-form';
import { OpmInputFormModel } from '../../OpmInputs';
import { OpmInputsFromPwerm } from '@app/core/inputs/getOpmInputsFromPwermLegacy';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getOperationalFreeCashFlowYield } from '@app/core/inputs/getOpmInputCalculatedValues';
import { greaterThanOrEqualToValidator } from '@app/core/validations/hook-forms/validators';

interface MemoOperationalFreeCashFlowSectionProps {
  submitData: () => void;
}

const MemoOperationalFreeCashFlowSection: FC<MemoOperationalFreeCashFlowSectionProps> = ({
  submitData,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const opmInput = project.opmInput;
  const { l } = useLocale();
  const opmPwermValues = useOpmInputsFromPwerm();
  const formMethods = useFormContext<OpmInputFormModel>();
  const [expanded, setExpanded] = useLocalStorageCollapseState('expanded-ofcf', project.id);

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  const perYearInputs = project.opmInput.perYearInputs;
  const shouldGetValuesFromPwerm = project.details.calcMethod !== CalcMethod.OPM;
  const maxColspanCount =
    project.opmInput.forecastYears !== null
      ? project.opmInput.forecastYears + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
      : 0;

  const displayRevert = (rowSource: keyof PerYearValuesDto) => {
    if (shouldGetValuesFromPwerm) {
      return opmInput.perYearInputs.some((yi) => yi[rowSource] === OpmInputDataSource.Override);
    }
  };

  const handleRevert = (propertyName: keyof OpmInputsFromPwerm) => {
    perYearInputs.forEach((yi, index) => {
      const valueToSet = opmPwermValues.getOpmValuesFromPwerm(yi.forecastDate)?.[propertyName];
      formMethods.setValue(`perYearInputs.${index}.${propertyName}`, valueToSet);
    });
    submitData();
  };

  const badgeSource = perYearInputs.some(
    (pyi) =>
      pyi.operationalEvSource === OpmInputDataSource.Override ||
      pyi.operationalFreeCashFlowSource === OpmInputDataSource.Override
  );

  return (
    <>
      <tbody>
        <tr>
          <th className={`table-primary__cell--header-tertiary ${styles[`opm-header`]}`}>
            <div className={styles['opm-collapsable-header']}>
              <button
                type="button"
                data-testid="ofcf-collapse-button"
                aria-label={l('_ToggleSectionCollapse', {
                  section: l('_MemoOperationalFreeCashFlow'),
                })}
                aria-expanded={expanded}
                style={{
                  border: 'none',
                  backgroundColor: 'none',
                  background: 'none',
                }}
                onClick={toggleCollapse}>
                {!expanded ? (
                  <SvgChevron
                    onClick={toggleCollapse}
                    className={styles[`opm-chevron-collapsed`]}
                  />
                ) : (
                  <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-expanded`]} />
                )}
              </button>
              {l('_MemoOperationalFreeCashFlow')}
            </div>
          </th>
          <th
            className={`table-primary__cell--header-tertiary ${styles[`opm-header-badge`]}`}
            colSpan={maxColspanCount - 1}>
            {project.details.calcMethod !== CalcMethod.OPM && (
              <div className={styles['opm-collapsable-header-badge']} data-testid="ofcf-badge">
                <Pill
                  size={PillSize.Xsmall}
                  noTextTransform
                  theme={badgeSource ? PillTheme.Orange : PillTheme.White}>
                  {badgeSource ? l('_Overridden') : l('_AutoPopulatedFromPWERM')}
                </Pill>
              </div>
            )}
          </th>
        </tr>
        {expanded && (
          <>
            <tr>
              <>
                <th
                  className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}
                  align="left">
                  {l('_OperationalFreeCashFlow')}
                  {displayRevert('operationalFreeCashFlowSource') && (
                    <SvgRevert
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => handleRevert('operationalFreeCashFlow')}
                      className={styles['revert-button']}
                    />
                  )}
                </th>
                <td />
                {project.opmInput.perYearInputs?.map((_, index) => {
                  return (
                    <td key={`operationalFreeCashFlow_${index}`}>
                      <GridTextField
                        className={[
                          shouldGetValuesFromPwerm &&
                          perYearInputs[index].operationalFreeCashFlowSource ===
                            OpmInputDataSource.Override
                            ? styles['revertable-value']
                            : '',
                        ]}
                        name={`perYearInputs.${index}.operationalFreeCashFlow`}
                        data-testid={`perYearInputs.${index}.operationalFreeCashFlow`}
                        {...amountBasicFieldFormattingProps}
                        fieldValueMask={numberPattern}
                        alignment={Alignment.Right}
                        placeholder="0"
                      />
                    </td>
                  );
                })}
                <td />
              </>
            </tr>
            <tr>
              <th
                className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}
                data-testid="memo-ev">
                {l('_EV')}
                {displayRevert('operationalEvSource') && (
                  <SvgRevert
                    // stop the onBlur event occurring so we can control when the data is submitted
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => handleRevert('operationalEv')}
                    className={styles['revert-button']}
                  />
                )}
              </th>
              <td>
                <CellValue
                  dataTestId="ev-starting-value"
                  alignment={Alignment.Right}
                  {...amountBasicFieldFormattingProps}
                  value={opmInput.evValue}
                />
              </td>
              {project.opmInput.perYearInputs?.map((_, index) => {
                return (
                  <td key={`operationalEv_${index}`}>
                    <GridTextField
                      className={[
                        shouldGetValuesFromPwerm &&
                        perYearInputs[index].operationalEvSource === OpmInputDataSource.Override
                          ? styles['revertable-value']
                          : '',
                      ]}
                      name={`perYearInputs.${index}.operationalEv`}
                      data-testid={`perYearInputs.${index}.operationalEv`}
                      {...amountBasicFieldFormattingProps}
                      fieldValueMask={numberPattern}
                      alignment={Alignment.Right}
                      rules={{ ...greaterThanOrEqualToValidator(0) }}
                      placeholder="0"
                    />
                  </td>
                );
              })}
              <td />
            </tr>
            <tr data-testid="ofcf-row">
              <th
                className={`table-primary__cell--vertical-separator ${styles[`header-padding`]}`}
                align="left">
                <div>{l('_FreeCashFlowYield')}</div>
              </th>
              <td />
              {project.opmInput.perYearInputs?.map((pyi, index) => {
                return (
                  <td key={`operationalFreeCashFlowYield_${index}`}>
                    <CellValue
                      className={
                        shouldGetValuesFromPwerm &&
                        getOperationalFreeCashFlowYield(project, pyi.forecastDate!) !==
                          opmPwermValues.getFinancingCostsFromPwerm(pyi.forecastDate!)
                          ? styles['revertable-value']
                          : ''
                      }
                      dataTestId={`operationalFreeCashFlowYield-year${index + 1}`}
                      {...enteredPercentageViewRules}
                      alignment={Alignment.Right}
                      value={getOperationalFreeCashFlowYield(project, pyi.forecastDate!)}
                    />
                  </td>
                );
              })}
              <td />
            </tr>
          </>
        )}
      </tbody>
    </>
  );
};

export default MemoOperationalFreeCashFlowSection;
