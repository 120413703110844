import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { EquityInstrumentDto, ProjectDto } from '@app/shared/models/contracts/project-dto';
import {
  cloneDeep,
  enumKeyByValue,
  getProjectDraftWithAddedDlom,
  getProjectDraftWithRemovedDlomLegacy,
} from '@app/shared/helpers';
import * as projectActions from '@core/store/project-slice';
import styles from './payout-logic.module.scss';
import classNames from 'classnames';
import Button from '@app/shared/components/button/Button';
import PlusSvg from '@app/shared/icons/Plus';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { createTrancheItem } from '@app/shared/helpers/create-tranche';
import { v4 as uuidv4 } from 'uuid';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { MAX_TRANCHES_PER_INSTRUMENT } from '@app/shared/constants/payout-logic';
import PayoutStatements from '@app/modules/projects/inputs/capital-structure/tabs/payout-logic/payout-statements/PayoutStatements';
import SvgEdit from '@app/shared/icons/Edit';
import { ContainerTestId, Else } from '@app/shared/models/contracts/enums/shared-enums';
import SvgTrash from '@app/shared/icons/Trash';
import { useLocale } from '@app/core/hooks/useLocale';
import { RouteConstants } from '../../../../RouteConstants';
import { setPageScrollPosition } from '@app/core/store/ui-values-slice';

const PARENT_CLASSNAME = 'payout-logic';

export const PayoutLogic = () => {
  const { instrumentId } = useParams();
  // required to force re-render when instrumentId changes, which is needed to dispatch scroll positions
  return <PayoutLogicComponent key={instrumentId} />;
};

const PayoutLogicComponent: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { instrumentId } = useParams();
  const dispatch = useAppDispatch();

  const projectDraft = useAppSelector((state) => state.project.projectDraft);
  const trancheId = uuidv4();
  const pageScrollPositionList = useAppSelector(
    (state) => state.uiValues.pageScrollPositionList[projectDraft.id]
  );

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollTopRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const activeEquityInstrument = projectDraft.equityInstruments.find(
    (x) => x.instrumentId === instrumentId
  );
  const [currentEquityId, setCurrentEquityId] = useState<string | undefined>(
    activeEquityInstrument?.instrumentId
  );

  const formMethods = useForm<EquityInstrumentDto>({
    ...formConfigBase,
    defaultValues: {
      ...{ ...activeEquityInstrument },
    },
  });

  const { trigger } = formMethods;

  useEffect(() => {
    scrollTopRef.current = scrollTop;
    scrollLeftRef.current = scrollLeft;
  }, [scrollTop, scrollLeft]);

  useEffect(() => {
    const mainContainer = document.querySelector<HTMLElement>(
      `[data-testid=${ContainerTestId.Main}]`
    );

    const handleScroll = () => {
      setScrollTop(mainContainer?.scrollTop ?? 0);
      setScrollLeft(mainContainer?.scrollLeft ?? 0);
    };

    if (mainContainer) {
      mainContainer.addEventListener('scroll', handleScroll);
    }

    if (pageScrollPositionList) {
      const pageScrollPosition = pageScrollPositionList.find(
        (page) => page.pageName === RouteConstants.PayoutLogicLegacy + instrumentId
      );

      if (pageScrollPosition) {
        mainContainer &&
          mainContainer.scrollTo(pageScrollPosition.scrollLeft, pageScrollPosition.scrollTop);
      } else {
        mainContainer && mainContainer.scrollTo(0, 0);
      }
    }

    return () => {
      const actionPayload = {
        key: projectDraft.id,
        value: {
          pageName: RouteConstants.PayoutLogicLegacy + instrumentId,
          scrollTop: scrollTopRef.current ?? 0,
          scrollLeft: scrollLeftRef.current ?? 0,
        },
      };
      dispatch(setPageScrollPosition(actionPayload));

      if (mainContainer) {
        mainContainer.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (instrumentId !== currentEquityId) {
      setCurrentEquityId(instrumentId);
      formMethods.reset(activeEquityInstrument);
    }
  }, [instrumentId, activeEquityInstrument, currentEquityId, formMethods]);

  const submitForm = async (
    getDraftWithSideEffects: (data: {
      draft: ProjectDto;
      instrumentId: string;
      trancheId?: string;
    }) => ProjectDto = ({ draft }) => draft,
    trancheId?: string
  ) => {
    if (!instrumentId) {
      return;
    }

    const equityItemArray = cloneDeep(projectDraft.equityInstruments);
    const equityItemMatchIndex = equityItemArray.findIndex(
      (equityItem: EquityInstrumentDto) => equityItem.instrumentId === instrumentId
    );

    const formData = formMethods.getValues();

    equityItemArray[equityItemMatchIndex] = {
      ...formData,
      tranches: formData.tranches
        ? [
            ...formData.tranches
              .sort((a, b) => a.order - b.order)
              .map((tranche, index) => {
                return { ...tranche, order: index };
              }),
          ]
        : [],
    };

    const updatedProjectDraft = {
      ...projectDraft,
      equityInstruments: [...cloneDeep(equityItemArray)],
    };

    await dispatch(
      projectActions.updateProjectDraft({
        project: getDraftWithSideEffects({ draft: updatedProjectDraft, instrumentId, trancheId }),
      })
    ).unwrap();
  };

  const formSubmitHandler = () => {
    submitForm();
  };

  const {
    append: appendTranche,
    remove: removeTranche,
    fields: tranchesFields,
  } = useFieldArray({
    name: 'tranches',
    keyName: 'trancheKey',
    control: formMethods.control,
  });

  const createTranche = async () => {
    const lastTrancheItem = activeEquityInstrument?.tranches?.length
      ? activeEquityInstrument.tranches[activeEquityInstrument.tranches.length - 1]
      : undefined;

    const trancheItem = lastTrancheItem
      ? {
          ...lastTrancheItem,
          id: trancheId,
          else: enumKeyByValue(Else, Else.Zero),
        }
      : createTrancheItem(0, trancheId);

    await appendTranche(trancheItem, { shouldFocus: false });
    await submitForm(getProjectDraftWithAddedDlom, trancheItem.id);
  };

  const removeTrancheItem = async (trancheIndex: number, trancheId: string) => {
    await removeTranche(trancheIndex);
    await submitForm(getProjectDraftWithRemovedDlomLegacy, trancheId);
  };

  // required to prevent leave site dialogue on enter key press
  const submitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
  };

  return (
    <FormProvider {...formMethods}>
      <form
        data-cy="payout-logic-form"
        className={styles[`${PARENT_CLASSNAME}`]}
        onBlur={formMethods.handleSubmit(
          formSubmitHandler as SubmitHandler<EquityInstrumentDto>,
          formSubmitHandler as SubmitErrorHandler<EquityInstrumentDto>
        )}
        onSubmit={submitHandler}>
        {tranchesFields.length ? (
          <>
            {tranchesFields.map((trancheItem, trancheIndex) => {
              return (
                <div
                  key={trancheItem.trancheKey + 'tranche'}
                  className={
                    trancheIndex === 0
                      ? styles[`${PARENT_CLASSNAME}`]
                      : classNames(styles[`${PARENT_CLASSNAME}`], styles['non-first-tranche'])
                  }>
                  <h2 className={classNames('heading-5', styles[`${PARENT_CLASSNAME}__title`])}>
                    Tranche {trancheIndex + 1}
                  </h2>
                  <div className={styles[`${PARENT_CLASSNAME}__tranche-action-remove`]}>
                    <div className={styles[`${PARENT_CLASSNAME}__action-circle`]}>
                      <Button
                        appearance={ButtonAppearance.DEFAULT_TERTIARY}
                        aria-label={l('_RemoveTranche')}
                        size={ButtonSize.TABLE_ACTION}
                        startIcon={<SvgTrash />}
                        onBlur={(event) => event.stopPropagation()} // this prevents form from submission it's not needed, since it is submitted with removeTrancheItem method
                        onClick={() => removeTrancheItem(trancheIndex, trancheItem.id)}
                      />
                    </div>
                  </div>
                  <div className={styles[`${PARENT_CLASSNAME}__wrapper`]}>
                    <PayoutStatements
                      trancheIndex={trancheIndex}
                      activeEquityInstrument={activeEquityInstrument}
                      formSubmitHandler={formSubmitHandler}
                    />
                  </div>
                </div>
              );
            })}
            {activeEquityInstrument && activeEquityInstrument.tranches && (
              <div className={styles[`${PARENT_CLASSNAME}__tranche-action`]}>
                <Button
                  appearance={ButtonAppearance.DEFAULT_TERTIARY}
                  size={ButtonSize.TABLE_ACTION}
                  startIcon={<PlusSvg />}
                  disabled={activeEquityInstrument.tranches.length >= MAX_TRANCHES_PER_INSTRUMENT}
                  title={
                    activeEquityInstrument.tranches.length >= MAX_TRANCHES_PER_INSTRUMENT
                      ? 'Maximum number of tranches is reached'
                      : undefined
                  }
                  onBlur={(event) => event.stopPropagation()} // this prevents form from submission it's not needed, since it is submitted with createTranche method
                  onClick={createTranche}>
                  {l('_AddTranche')}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div>
            <h2 className={classNames('heading-5', styles[`${PARENT_CLASSNAME}__title`])}>
              Tranche 1
            </h2>
            <div className={styles[`${PARENT_CLASSNAME}__empty-state-wrapper`]}>
              <div className={styles[`${PARENT_CLASSNAME}__empty-state-message`]}>
                Distribution is based on % shareholding
              </div>
              <div>
                <Button
                  data-testid="payout-customize"
                  appearance={ButtonAppearance.DEFAULT_PRIMARY}
                  startIcon={<SvgEdit />}
                  onBlur={(event) => event.stopPropagation()} // this prevents form from submission it's not needed, since it is submitted with createTranche method
                  onClick={createTranche}>
                  Customise
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
