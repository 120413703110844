import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { getDealEventOptions } from '@app/shared/constants/dropdown-values/benchmark/deal-event-options';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  Country,
  Currency,
  Denomination,
  ProjectStatus,
  RegionOfOperations,
  Sector,
  SubSector,
} from '@app/shared/models/contracts/enums/shared-enums';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { shortDateViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getRequiredValidator } from '@core/validations/hook-forms/validators';
import { enumKeyByValue } from '@app/shared/helpers';
import { useLocale } from '@app/core/hooks/useLocale';

const DealOverview: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);

  const { status, storeBenchmarkingData } = project;

  const { l } = useLocale();

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>{l('_DealOverview')}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue label={l('_PEHouseSponsor')} value={project.details.sponsor} isGapless />
        </div>
        <div className="form-row__column">
          <FormValue
            label={l('_RegionOfOperations')}
            value={
              project.details.regionOfOperations
                ? RegionOfOperations[project.details.regionOfOperations]
                : ''
            }
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={l('_Denomination')}
            value={Denomination[project.details.denomination]}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label={l('_TargetTradeName')}
            value={project.details.targetTradeName}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={l('_CountryOfMainOperations')}
            value={
              project.details.countryOfMainOperations
                ? Country[project.details.countryOfMainOperations]
                : ''
            }
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue label={l('_Currency')} value={Currency[project.details.currency]} isGapless />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label={l('_Sector')}
            value={project.details.sector ? Sector[project.details.sector] : ''}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="dealOverview.dealEvent"
            label={l('_DealEvent')}
            options={getDealEventOptions()}
            rules={{ ...(shouldFieldsBeRequired && getRequiredValidator()) }}
            isGapless
          />
        </div>
        <div className="form-row__column" />
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label={l('_SubSector')}
            value={project.details.subSector ? SubSector[project.details.subSector] : ''}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={l('_DateOfCompletion')}
            value={project.investmentDate}
            isGapless
            {...shortDateViewRules}
          />
        </div>
        <div className="form-row__column" />
      </div>
    </Card>
  );
};

export default DealOverview;
