import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { getOpmInputsFromPwerm } from '@app/core/inputs/getOpmInputsFromPwerm';
import { getOpmInputsFromPwermLegacy } from '@app/core/inputs/getOpmInputsFromPwermLegacy';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';

export const useOpmInputsFromPwerm = () => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isLegacyPwermEnabled = isDevFeatureEnabled(DevFeature.ShowLegacyPwerm);
  const project = useAppSelector((state) => state.project.projectDraft);
  const legacyCalculationResults = useAppSelector((state) => state.pwermCalculation.values);
  const calculationResults = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  return isLegacyPwermEnabled
    ? getOpmInputsFromPwermLegacy(project, legacyCalculationResults)
    : getOpmInputsFromPwerm(project, calculationResults);
};
