import { FC, useEffect, useState } from 'react';
import { getCaseById } from '@core/store/project-slice-selectors';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import {
  amountRules,
  enteredPercentageViewRules,
  multiplierRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { DefaultDashboardDropdownValues } from '@app/modules/projects/dashboard/widgets/default-dashboard-dropdown-values-enum';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ToggleWidget } from '@app/shared/enums/expand-collapse';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { Pwerm2EVEvolutionChart } from './Pwerm2EvEvolutionChart';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import { formatNumber } from '../../opm-charts/format-helpers';
import { useLocalStateCollapse } from '@app/core/hooks/useLocalStateCollapse';
import {
  setPwermDashboard2EvolutionChartIsExpanded,
  setPwermDashboard2EvolutionChartSelectedCase,
} from '@app/core/store/ui-values-slice';

export const Pwerm2EvEvolution: FC = (): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.evolution
  );

  const sortedProjectByCase = sortedProjectByCases(project);
  const sortedProjectByCaseWithNonZeroProbability = getCasesWithNonZeroProbability(
    sortedProjectByCase.pwermInput.cases
  );

  const storedCase = useAppSelector(
    (state) => state.uiValues.userSelections.pwerm2Dashboard.evEvolutionChart.selectedCase
  );
  const storedExpandedState = useAppSelector(
    (state) => state.uiValues.userSelections.pwerm2Dashboard.evEvolutionChart.isExpanded
  );

  const defaultExpandedState = storedExpandedState ?? false;
  const [isExpanded, setIsExpanded] = useState(defaultExpandedState);

  useLocalStateCollapse(isExpanded, setPwermDashboard2EvolutionChartIsExpanded);

  const caseOptions = [
    { value: DefaultDashboardDropdownValues.AllCases, viewValue: l('_AllCases') },
    ...sortedProjectByCaseWithNonZeroProbability.map((caseItem) => ({
      value: caseItem.caseId,
      viewValue: caseItem.narrative,
    })),
    { value: DefaultDashboardDropdownValues.WeightedAverage, viewValue: l('_WeightedAverage') },
  ];

  const isStoredCaseValid = storedCase && caseOptions.some((option) => option.value === storedCase);

  const defaultViewOption = isStoredCaseValid
    ? storedCase
    : DefaultDashboardDropdownValues.AllCases;

  const formMethods = useForm<{ viewOption: string }>({
    ...formConfigBase,
    defaultValues: {
      viewOption: defaultViewOption,
    },
  });

  const { watch, setValue } = formMethods;
  const selectedViewOption = watch('viewOption');

  useEffect(() => {
    if (selectedViewOption !== storedCase) {
      dispatch(setPwermDashboard2EvolutionChartSelectedCase(selectedViewOption));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewOption]);

  const selectedCase = getCaseById(project, selectedViewOption);
  const isSpecificCaseSelected = !(
    selectedViewOption === DefaultDashboardDropdownValues.AllCases ||
    selectedViewOption === DefaultDashboardDropdownValues.WeightedAverage
  );

  const caseResults = isSpecificCaseSelected
    ? results.cases[selectedCase!.caseId]
    : results.weighted;
  const exitValueDriverName = results.valueDriverName;

  const cagrYears = caseResults.growthRateYears;
  const cagrDisplayYears = formatNumber(cagrYears, 0);
  const cagrDisplayYearsTooltip = formatNumber(cagrYears, 6);

  const data = [
    {
      label: l('_CurrentEV'),
      value: caseResults.initialEv,
      format: amountRules,
    },
    {
      label: l('_CurrentDriverMultiple', { valueDriverName: exitValueDriverName }),
      value: caseResults.initialValueDriverMultiple,
      format: multiplierRules,
    },
    {
      label: l('_ExitDriverMultiple', { valueDriverName: exitValueDriverName }),
      value: caseResults.exitValueDriverMultiple,
      format: multiplierRules,
    },
    {
      label: l('_ExitEV'),
      value: caseResults.exitEv,
      format: amountRules,
    },
    {
      label: l('_YearEVCAGR', { noOfYears: cagrDisplayYears }),
      labelTooltip: l('_YearEVCAGR', { noOfYears: cagrDisplayYearsTooltip }),
      value: getCalculatedProjectValueInPercent(caseResults.growthRateValue),
      format: enteredPercentageViewRules,
    },
  ];

  return (
    <DashboardWidgetContainer
      title={l('_EVEvolution')}
      isExpanded={isExpanded}
      actions={
        <FormProvider {...formMethods}>
          <form className="dashboard-widget-controls">
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              onClick={() => setIsExpanded(!isExpanded)}
              className="dashboard-widget-controls__button"
              ignoreReadOnly>
              {isExpanded ? ToggleWidget.Collapse : ToggleWidget.Expand}
            </Button>
            <div className="dashboard-widget-controls__item">
              <FormSelect
                ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartView') })}
                name="viewOption"
                required
                options={caseOptions}
                isGapless
                ignoreReadOnly
              />
            </div>
          </form>
        </FormProvider>
      }
      secondaryContent={
        <table className="table-primary table-primary--fixed table-primary--secondary-theme">
          <thead>
            <tr>
              {data.map((item, index) => (
                <th
                  key={index}
                  title={item.labelTooltip}
                  className="table-primary__cell--header-quinary">
                  {item.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {data.map((item, index) => (
                <td key={index}>
                  <CellValue
                    value={item.value}
                    {...item.format}
                    className="heading-2 heading-2--alternate"
                    alignment={Alignment.Left}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      }>
      <Pwerm2EVEvolutionChart
        selectedCase={selectedCase}
        selectedViewOption={selectedViewOption}
        setSelectedViewOption={setValue}
        isExpanded={isExpanded}
      />
    </DashboardWidgetContainer>
  );
};
