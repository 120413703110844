import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { ForecastDto } from '@app/shared/models/contracts/project-dto';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { TABLE_COL_WIDTH, TABLE_LABELS_COL_WIDTH } from '@app/shared/constants/table-config';
import { numberCalculatedViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { EmptyValues } from '@app/shared/constants/empty-values';
import Button from '@app/shared/components/button/Button';
import SvgFilledRightArrow from '@app/shared/icons/FilledRightArrow';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis-2/DealThesisPage2';
import styles from '../../deal-thesis/deal-thesis-page.module.scss';
import { getDealThesisInputCaseForecastDate } from '@app/core/store/pwerm2-calculation-slice-selectors';
import { sumBy } from '@app/shared/helpers';
import { useLocale } from '@app/core/hooks/useLocale';
import DealThesisSectionHeader from './DealThesisSectionHeader';
import DealThesisEmptyHistoricDataColumns from '../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';

interface DealThesisCaseTableHeaderProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseTableHeader2: FC<DealThesisCaseTableHeaderProps> = ({
  handleDataSubmit,
}): JSX.Element => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const formMethods = useFormContext<DealThesisFormModel>();
  const project = useAppSelector((state) => state.project.projectDraft);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseResults = results.cases[caseId!];
  const numberOfInputForecastYears = caseData?.forecasts.length ?? 0;
  const numberOfInputHistoricForecastYears = Object.keys(
    project.pwermInput.historicForecasts
  ).length;
  // results data sliced such that a decrease in forecast years is immediately rendered without depending on results
  const caseForecasts = Object.entries(caseResults.forecastDates)
    .map(([forecastId, value]) => ({
      forecastId,
      ...value,
    }))
    .slice(0, numberOfInputForecastYears);
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );
  const historicDataForecastYearsResults = Object.keys(
    caseResults.historicDates.historicForecastYears ?? {}
  )
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicDataEventsYearsResults = Object.keys(
    results.cases[caseId!].historicDates.historicEventsYears ?? {}
  ).reverse();
  const maxHistoricalDataLength = Math.max(
    historicDataForecastYearsResults.length,
    historicDataEventsYearsResults.length
  );

  const populate = (inputField: string, cellIndex: number) => {
    const { ...updatedDealThesisInput } = formMethods.getValues();
    updatedDealThesisInput.forecasts.map((_, index: number) => {
      index >= cellIndex &&
        formMethods.setValue(
          `forecasts.${index}.${inputField as keyof ForecastDto}`,
          updatedDealThesisInput.forecasts[cellIndex][inputField as keyof ForecastDto]
        );
    });

    handleDataSubmit();
  };

  const shouldAutoPopulateFromCell = (index: number): boolean | undefined =>
    index !== numberOfInputForecastYears - 1;

  const forecastProbabilitiesTotal = caseData
    ? sumBy(caseData?.forecasts, (forecast) => Number(forecast.probability))
    : 0;

  const forecastProbabilitiesTotalWarningMessage =
    forecastProbabilitiesTotal !== 100
      ? l('_AllProbabilitiesMustBe100Error', {
          type: l('_ForecastLower'),
          amount: !Number.isNaN(forecastProbabilitiesTotal)
            ? numberValueFormatter({
                value: forecastProbabilitiesTotal!,
                ...percentageValueFormatterProps,
              })
            : EmptyValues.EnDash,
        })
      : undefined;

  return (
    <>
      <colgroup>
        <col style={{ minWidth: `${TABLE_LABELS_COL_WIDTH}px` }} />
        {showHistoricDataColumns &&
          Array.from({ length: maxHistoricalDataLength }).map((_, index) => (
            <col key={index} style={{ width: `${TABLE_COL_WIDTH}px` }} />
          ))}
        <col style={{ width: `${TABLE_COL_WIDTH}px` }} />
        {Object.keys(caseForecasts).map((_, index) => (
          <col key={index} style={{ width: `${TABLE_COL_WIDTH}px` }} />
        ))}
        <col className="table-primary__col-action" />
      </colgroup>
      <DealThesisSectionHeader isValuationDateHeader={false} />
      <tbody>
        <tr>
          <th className="table-primary__cell--vertical-separator">{l('_TimeToExit')}</th>
          <DealThesisEmptyHistoricDataColumns isHistoricEventsSection={false} />
          {showHistoricDataColumns &&
            historicDataForecastYearsResults.map((_, index) => <td key={index} />)}
          <th />
          {caseForecasts.map((forecast, index) => (
            <td key={'time-to-exit' + forecast.forecastId + index}>
              <CellValue
                value={getDealThesisInputCaseForecastDate(
                  results,
                  caseId!,
                  forecast.forecastId,
                  (forecast) => forecast.timeToExit
                )}
                {...numberCalculatedViewRules}
                normalize
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">
            <CellValue
              value={l('_ExitProbability')}
              tooltipVariation={GridFieldTooltipVariation.FitContainer}
              errorMessage={forecastProbabilitiesTotalWarningMessage}
            />
          </th>
          <DealThesisEmptyHistoricDataColumns isHistoricEventsSection={false} />
          {showHistoricDataColumns &&
            historicDataForecastYearsResults.map((_, index) => <td key={index} />)}
          <th />
          {caseForecasts.map((_, index) => (
            <td key={index + 'exit-probability'} className={styles['hoverable-cell']}>
              <GridTextField
                className={[shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '']}
                name={`forecasts.${index}.probability`}
                {...percentageFieldFormattingProps}
                rules={{
                  ...getRequiredValidator(),
                  ...percentageValidator,
                }}
                alignment={Alignment.Right}
              />
              {shouldAutoPopulateFromCell(index) && (
                <Button
                  startIcon={<SvgFilledRightArrow />}
                  className={styles['deal-thesis-populate-button']}
                  appearance={ButtonAppearance.CLEAN}
                  size={ButtonSize.FLUID}
                  // stop the onBlur event occurring so we can control when the data is submitted
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => populate('probability', index)}
                  data-testid={`populate-probability-${index}`}
                  autoIconSize
                />
              )}
            </td>
          ))}
          <td />
        </tr>
      </tbody>
    </>
  );
};
