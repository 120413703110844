import {
  EquityInstrumentDto,
  InstrumentDefinitionDto,
} from '@app/shared/models/contracts/project-dto';
import { enumKeyByValue } from '@app/shared/helpers';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import {
  OpmDlomPerInstrumentDictionary,
  OpmDlomPerInstrumentPerTranche,
  OpmInstrumentDistributionResultsDto,
  OpmValuationConclusionInstrumentResultDto,
  FirstLastInstrument,
  FirstLastInstrumentsDictionaryDto,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import { CapitalStructureCapTableInstrumentDto } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { Pwerm2CalculationCostOfEquityInstrumentCaseYearDebtInstrumentDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { OrdinaryEquityInstrument } from '@app/modules/projects/inputs/capital-structure/tabs/participation-table-erf/ParticipationTableErf';

const instrumentTypeToSortableNumber = (
  type:
    | EquityInstrumentDto['type']
    | OpmValuationConclusionInstrumentResultDto['type']
    | OpmDlomPerInstrumentPerTranche['instrumentType']
    | FirstLastInstrument['instrumentType']
    | InstrumentDefinitionDto['type']
) => (type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) ? 2 : 1);

export const instrumentsSortFn = (
  a: EquityInstrumentDto | OpmValuationConclusionInstrumentResultDto | InstrumentDefinitionDto,
  b: EquityInstrumentDto | OpmValuationConclusionInstrumentResultDto | InstrumentDefinitionDto
) =>
  instrumentTypeToSortableNumber(a.type) - instrumentTypeToSortableNumber(b.type) ||
  a.rank - b.rank ||
  a.instrumentNarrative.localeCompare(b.instrumentNarrative);

export const debtInstrumentsSortFn = (
  a: Pwerm2CalculationCostOfEquityInstrumentCaseYearDebtInstrumentDto,
  b: Pwerm2CalculationCostOfEquityInstrumentCaseYearDebtInstrumentDto
) => a.rank - b.rank || a.instrumentNarrative.localeCompare(b.instrumentNarrative);

export const equityInstrumentsSortWithTranchesFnLegacy = (
  a: EquityInstrumentDto,
  b: EquityInstrumentDto
) =>
  instrumentTypeToSortableNumber(a.type) - instrumentTypeToSortableNumber(b.type) ||
  a.rank - b.rank ||
  instrumentByTranchesSortFnLegacy(a, b) ||
  a.instrumentNarrative.localeCompare(b.instrumentNarrative);

export const instrumentsByTypeSortFn = (
  a: EquityInstrumentDto | CapitalStructureCapTableInstrumentDto,
  b: EquityInstrumentDto | CapitalStructureCapTableInstrumentDto
) => {
  const keySequence = Object.keys(InstrumentType);
  return keySequence.indexOf(a.type) - keySequence.indexOf(b.type);
};

export const instrumentOwnersSortFn = (a: keyof typeof OwnerType, b: keyof typeof OwnerType) => {
  const ownershipOrder = Object.keys(OwnerType);

  return ownershipOrder.indexOf(a) - ownershipOrder.indexOf(b);
};

export const instrumentKeysByTypeSortFn = (
  a: keyof typeof InstrumentType,
  b: keyof typeof InstrumentType
) => {
  const instrumentOrder = Object.keys(InstrumentType);

  return instrumentOrder.indexOf(a) - instrumentOrder.indexOf(b);
};

export const instrumentByTranchesSortFnLegacy = (a: EquityInstrumentDto, b: EquityInstrumentDto) =>
  (b.tranches?.length ?? 0) - (a.tranches?.length ?? 0);

export const opmInstrumentByTranchesSortFn = (
  a: OpmValuationConclusionInstrumentResultDto,
  b: OpmValuationConclusionInstrumentResultDto
) => (b.numberOfPayoutLogicTranches ?? 0) - (a.numberOfPayoutLogicTranches ?? 0);

export const instrumentByTypeOrTranchesOrDefaultSortFnLegacy = (
  a: EquityInstrumentDto,
  b: EquityInstrumentDto
) =>
  instrumentTypeToSortableNumber(a.type) - instrumentTypeToSortableNumber(b.type) ||
  instrumentByTranchesSortFnLegacy(a, b) ||
  instrumentsSortFn(a, b);

export const instrumentByTranchesSortFn = (
  a: InstrumentDefinitionDto,
  b: InstrumentDefinitionDto
) => (b.payoutLogic?.length ?? 0) - (a.payoutLogic?.length ?? 0);

export const instrumentsSortWithTranchesFn = (
  a: InstrumentDefinitionDto,
  b: InstrumentDefinitionDto
) =>
  instrumentTypeToSortableNumber(a.type) - instrumentTypeToSortableNumber(b.type) ||
  a.rank - b.rank ||
  instrumentByTranchesSortFn(a, b) ||
  a.instrumentNarrative.localeCompare(b.instrumentNarrative);

export const instrumentByTypeOrTranchesOrDefaultSortFn = (
  a: InstrumentDefinitionDto,
  b: InstrumentDefinitionDto
) =>
  instrumentTypeToSortableNumber(a.type) - instrumentTypeToSortableNumber(b.type) ||
  instrumentByTranchesSortFn(a, b) ||
  instrumentsSortFn(a, b);

export const opmInstrumentByTypeOrDefaultSortFn = (
  a: OpmValuationConclusionInstrumentResultDto,
  b: OpmValuationConclusionInstrumentResultDto
) =>
  instrumentTypeToSortableNumber(a.type) - instrumentTypeToSortableNumber(b.type) ||
  opmInstrumentByTranchesSortFn(a, b) ||
  instrumentsSortFn(a, b);

const plotInstrumentTypeToSortableNumber = (
  type: OpmInstrumentDistributionResultsDto['instrumentType']
) => (type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) ? 2 : 1);

const plotInstrumentByTranchesSortFn = (
  a: OpmInstrumentDistributionResultsDto,
  b: OpmInstrumentDistributionResultsDto
) =>
  (b.instrumentNumberOfPayoutLogicTranches ?? 0) - (a.instrumentNumberOfPayoutLogicTranches ?? 0);

export const plotInstrumentsSortFn = (
  a: OpmInstrumentDistributionResultsDto,
  b: OpmInstrumentDistributionResultsDto
) =>
  instrumentTypeToSortableNumber(a.instrumentType) -
    instrumentTypeToSortableNumber(b.instrumentType) ||
  a.instrumentRank - b.instrumentRank ||
  a.instrumentNarrative.localeCompare(b.instrumentNarrative);

export const plotInstrumentByTypeOrDefaultSortFn = (
  a: OpmInstrumentDistributionResultsDto,
  b: OpmInstrumentDistributionResultsDto
) =>
  plotInstrumentTypeToSortableNumber(a.instrumentType) -
    plotInstrumentTypeToSortableNumber(b.instrumentType) ||
  plotInstrumentByTranchesSortFn(a, b) ||
  plotInstrumentsSortFn(a, b);

export type InstrumentDictionary =
  | OpmDlomPerInstrumentDictionary
  | FirstLastInstrumentsDictionaryDto;

export function sortDictionaryPerInstrumentFn(entries: InstrumentDictionary) {
  return Object.fromEntries(
    Object.entries(entries).sort(([, objectA], [, objectB]) => {
      return (
        instrumentTypeToSortableNumber(objectA.instrumentType) -
          instrumentTypeToSortableNumber(objectB.instrumentType) ||
        objectA.instrumentRank - objectB.instrumentRank ||
        compareByPayoutLogicTranchesOrTrancheDlomsKeys(objectA, objectB) ||
        instrumentNameNarrativeStringComparison(objectA, objectB)
      );
    })
  );
}

export type IndividualInstrument = OpmDlomPerInstrumentPerTranche | FirstLastInstrument;

function instrumentNameNarrativeStringComparison(
  valA: IndividualInstrument,
  valB: IndividualInstrument
) {
  const keyA = 'instrumentNarrative' in valA ? valA.instrumentNarrative : valA.instrumentName;
  const keyB = 'instrumentNarrative' in valB ? valB.instrumentNarrative : valB.instrumentName;
  return keyA.toString().localeCompare(keyB.toString());
}

export function compareByPayoutLogicTranchesOrTrancheDlomsKeys(
  objectA: IndividualInstrument,
  objectB: IndividualInstrument
) {
  const countA =
    'numberOfPayoutLogicTranches' in objectA &&
    typeof objectA.numberOfPayoutLogicTranches === 'number'
      ? objectA.numberOfPayoutLogicTranches
      : 'trancheDloms' in objectA
      ? Object.keys(objectA.trancheDloms).length
      : 0;
  const countB =
    'numberOfPayoutLogicTranches' in objectB &&
    typeof objectB.numberOfPayoutLogicTranches === 'number'
      ? objectB.numberOfPayoutLogicTranches
      : 'trancheDloms' in objectB
      ? Object.keys(objectB.trancheDloms).length
      : 0;
  return countB - countA;
}

export const participationTableInstrumentByTranchesSortFn = (
  a: OrdinaryEquityInstrument,
  b: OrdinaryEquityInstrument
) => {
  const payoutComparison = Number(b.hasPayoutLogic) - Number(a.hasPayoutLogic);
  if (payoutComparison !== 0) {
    return payoutComparison;
  }

  const trancheComparison = Object.keys(b.tranches).length - Object.keys(a.tranches).length;
  if (trancheComparison !== 0) {
    return trancheComparison;
  }

  return a.instrumentNarrative.localeCompare(b.instrumentNarrative);
};
