import { SVGProps } from 'react';
const SvgEventInformation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="12.5" cy="12.5" r="12.5" fill="#6A7CF7" />
    <path
      d="M11.3479 5.72H13.6279V7.88H11.3479V5.72ZM11.3479 9.66H13.6279V20H11.3479V9.66Z"
      fill="white"
    />
  </svg>
);
export default SvgEventInformation;
