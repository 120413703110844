import React from 'react';
import styles from '../build-structure-tab/capital-structure.module.scss';
import { StructureDimension } from '@app/shared/models/contracts/enums/shared-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { useLocale } from '@app/core/hooks/useLocale';
import classNames from 'classnames';
import { selectCapitalStructure } from '@app/core/store/capital-structure-slice-selectors';
import { dateFormatter } from '@app/shared/formatters';

interface UnrelatedEventProps {
  eventId: string;
  dragAreaHeight: number;
}

const UnrelatedEventErf: React.FC<UnrelatedEventProps> = ({ eventId, dragAreaHeight }) => {
  const { l } = useLocale();
  const navigate = useNavigate();
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const relatedEventSetKeys = Object.keys(capitalStructure.eventSets).filter((key) =>
    capitalStructure.eventSets[key].events.some((event) => event === eventId)
  );
  const relatedCases = relatedEventSetKeys.flatMap((key) => {
    const caseItems = project.pwermInput.cases.filter((item) => item.eventSetId === key);
    return caseItems.map((c) => {
      return {
        narrative: c?.narrative,
        caseId: c?.caseId,
      };
    });
  });
  const eventName = capitalStructure.events[eventId].narrative;
  const eventDate = capitalStructure.events[eventId].eventDate;

  const handleCaseClick = (caseId: string | undefined) => {
    if (caseId !== undefined) {
      navigate(`../${caseId}`);
    }
  };

  return (
    <div
      className={classNames(styles['graph__wrapper-erf'], styles['unrelated-event'])}
      style={{
        width: `${StructureDimension.Width}px`,
        flex: `0 0 ${StructureDimension.Width}px`,
      }}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className={styles['graph__instrument-title']}>{eventName}</div>
        <div
          className={styles['unrelated-event-text']}
          style={{
            height: `${
              dragAreaHeight -
              StructureDimension.ErfFixedHeightElements +
              StructureDimension.ErfAdditionalHeightElements
            }px`,
          }}>
          {l('_UnrelatedEventsInformation')}
          <ul className={styles['case-select-text']}>
            {relatedCases.map((item, index) => (
              <li key={index} onClick={() => handleCaseClick(item.caseId)}>
                {item.narrative}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles['graph__date']}>{eventDate}</div>
      </div>
      <div className={styles['graph__date']}>
        {dateFormatter(eventDate.toString(), { dateStyle: 'short' })}
      </div>
    </div>
  );
};

export default UnrelatedEventErf;
