import { InstrumentOwnershipDto, OwnershipDto } from './../../models/contracts/project-dto';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { instrumentOwnersSortFn } from '@app/shared/helpers';
import { InstrumentDefinitionAndInitialValues } from '@app/core/store/capital-structure-slice-selectors';
import { OwnerType } from '@app/shared/models/contracts/enums/shared-enums';

export const getEquityInstrumentOwnerships = (
  instrument: InstrumentDefinitionAndInitialValues,
  having: (owner: InstrumentOwnershipDto) => number | null | undefined
) => {
  if (
    !instrument.initialValues?.ownership ||
    Object.values(instrument.initialValues.ownership).length === 0
  ) {
    return {} as { [key in keyof typeof OwnerType]: number | null };
  }

  return Object.entries(instrument.initialValues.ownership)
    .sort(([ownerTypeA, _], [ownerTypeB, __]) =>
      instrumentOwnersSortFn(
        ownerTypeA as keyof typeof OwnerType,
        ownerTypeB as keyof typeof OwnerType
      )
    )
    .reduce((result: { [key: string]: number | null }, [ownerType, ownership]) => {
      if (having(ownership)) {
        result[ownerType as keyof typeof OwnerType] = ownership.amount;
      }

      return result;
    }, {} as { [key in keyof typeof OwnerType]: number | null });
};

export const getEquityInstrumentOwnershipsLegacy = (
  instrument: EquityInstrumentDto,
  having: (owner: OwnershipDto) => number | null | undefined
) =>
  instrument.ownership
    .slice()
    .filter((owner) => having(owner))
    .sort((ownerA, ownerB) => instrumentOwnersSortFn(ownerA.owner, ownerB.owner));
